import { Injectable } from '@angular/core';
//import { Observable } from 'rxjs/Observable';
import { Observable, of } from 'rxjs';
//import 'rxjs/add/operator/map';
//import 'rxjs/add/operator/do';
//import 'rxjs/add/operator/catch';
import { IPagination } from '../_interfaces/pagination'
import { ILeistung } from '../_interfaces/leistung';
//import { IChoiceList } from '../_interfaces/_choice-list';
import * as moment from 'moment'; // DateTimeOffset-Fix
import * as cloneDeep from 'lodash/cloneDeep'; // DateTimeOffset-Fix

import { AppconfigService } from '../_services/appconfig.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';

import { CRUDBasicService, httpOptions } from '../_services/crud-basic.service';
import { faLacrosseStick } from '@fortawesome/pro-solid-svg-icons';

@Injectable()
export class LeistungService extends CRUDBasicService {

  //typeOf deprecated -> CRUDBasicService.propertyTypeOf
  /*typeOf(propertyName: string) {  // welchen Typs sind die Properties IN DER API ?
    let property = this.propertyTypes.find(f => f.name == propertyName);
    if(property != null) return property.type;
    else return null;    
  }*/
  propertyTypes = [ // welchen Typs sind die Properties IN DER API ?
    {name: 'leistung', type: 'string'},
    {name: 'typ', type: 'string'},
    {name: 'bezeichnung', type: 'string'},
    {name: 'icon', type: 'string'},
    {name: 'pumpentyp', type: 'bool'},
    {name: 'anstrich', type: 'bool'},
    {name: 'unterschriftKunde', type: 'bool'},
    {name: 'pruefergebnis', type: 'bool'},
    {name: 'createdBy', type: 'string'},
    {name: 'created', type: 'DateTimeOffset'},
    {name: 'modifiedBy', type: 'string'},
    {name: 'modified', type: 'DateTimeOffset'},
    {name: 'rowVersion', type: 'byte[]'},
    {name: 'summary', type: 'string'}
  ];
    
  getLeistungenCollection(pageNumber: number, pageSize: number, searchQuery: string): Observable<{ leistungen: ILeistung[], pagination: IPagination }> {
    return this.httpClient.get(this.apiBaseUrl + 'leistungen?pageNumber=' + pageNumber + '&pageSize=' + pageSize + '&searchQuery=' + searchQuery, { headers: httpOptions, observe: 'response', withCredentials: true })
      .pipe(
        map((response) => {
          return { leistungen: <ILeistung[]>response.body, pagination: /*<IPagination>JSON.parse(response.headers.get('x-pagination'))*/this.getDummyPagination((<any[]>response.body).length) };
        }), catchError(this.handleError))
  }

  getLeistung(id: /*number*/any): Observable<ILeistung> {
    if (id === 0 || id === '0') {
      // return Observable.create((observer: any) => {
      //   observer.next(this.initializeLeistung());
      //   observer.complete();
      // })
      return of(this.initializeLeistung());
    }

    return this.httpClient.get<ILeistung>(this.apiBaseUrl + 'leistungen?leistung=' + id, { headers: httpOptions, observe: 'body', withCredentials: true })
      .pipe(map((response) => 
            //response
            {
              console.log("KopfdatenService.getKopfdaten() response: ", response)
              return response[0];
            }
      ), catchError(this.handleError));
  }

    saveLeistung(leistung: ILeistung): Observable<ILeistung> {
    // DateTimeOffset-Fix
    let itemToSave = cloneDeep(leistung); // clonen, um sicherzustellen, dass das Original-Objekt nicht ver�ndert wird, evtl. passiert nach dem Save noch etwas damit ?
    //console.log("LeistungService.saveSingularCapitalized#() itemToSave before DateTimeOffset-Fix:", itemToSave);
    this.propertyTypes.filter(f => f.type.toLowerCase() == 'datetimeoffset').forEach(propertyType => {
      if (itemToSave[propertyType.name] != null) {
        itemToSave[propertyType.name] = moment(itemToSave[propertyType.name]).format('YYYY-MM-DDTHH:mm:ss.SSSZ'); // alle datetimeoffset-felder entspr. formatieren, dass in der API auch der Offset ankommt!
      }
    });
    //console.log("LeistungService.saveSingularCapitalized#() itemToSave after DateTimeOffset-Fix:", itemToSave);

    // woran erkennen, ob create oder update ?
    // da die LeistungenDetailComponent .id (was es ja eigentlich gar nicht gibt) = "leistung" setzt erkennen wir es daran:
    // id=irgend ein Wert -> update   / id=blank -> create
    if (leistung['id'] != undefined && leistung['id'] == "") {
      return this.createLeistung(itemToSave);
    }

    return this.updateLeistung(itemToSave)

  }

  updateLeistung(leistung: ILeistung): Observable<ILeistung> {

    return this.httpClient.put<ILeistung>(this.apiBaseUrl + 'leistungen' /*+ leistung.id*/, leistung, { headers: httpOptions, observe: 'body', withCredentials: true })
    .pipe(catchError(this.handleError))

  }

  deleteLeistung(id: /*number*/any) {

    return this.httpClient.delete(this.apiBaseUrl + 'leistungen?leistung=' + id, { headers: httpOptions, observe: 'body', withCredentials: true })
      .pipe(
        catchError(this.handleError)
      )
  }

  createLeistung(leistung: ILeistung): Observable</*ILeistung*/any> {
    return this.httpClient.post<ILeistung>(this.apiBaseUrl + 'leistungen', leistung, { headers: httpOptions, observe: 'body', withCredentials: true })
      .pipe(map((response) => response), catchError(this.handleError))
  }

  initializeLeistung(): ILeistung {
    return {
      createdBy: '',
      created: /*''*/'0001-01-01T00:00:00.000Z', // fix
      modifiedBy: '',
      modified: /*''*/'0001-01-01T00:00:00.000Z', // fix
      leistung: '',
      typ: '',
      bezeichnung: '',
      icon: '',
      geringfuegiger_Mangel: '',
      erheblicher_Mangel: '',

      pumpentyp: false,
      anstrich: false,
      unterschriftKunde: false,
      pruefergebnis: false,

      rowVersion: '',

      checklisteId: null,
      checkliste: null,

      summary: ''
    };
  }


}
