<div class="layout-wrapper" [ngClass]="{'p-input-filled': app.inputStyle === 'filled', 'p-ripple-disabled': !app.ripple,
        'layout-menu-static': isStatic(),
        'layout-menu-overlay': isOverlay(),
        'layout-menu-horizontal': isHorizontal(),
        'layout-menu-slim': isSlim(),
        'layout-menu-active': menuActive,
        'layout-menu-mobile-active': mobileMenuActive,
        'layout-topbar-mobile-active': mobileTopbarActive,
        'layout-rightmenu-active': rightMenuActive,
        'layout-rtl': app.isRTL}" [class]="'layout-menu-' + app.menuTheme + ' layout-topbar-' + app.topbarTheme">

    <app-topbar [style.display]="app.inAppMode != true ? 'unset' : 'none'"></app-topbar>
    <!-- warum 9.6 % der Bildschirmhöhe ?: iPhone 2 Screenshot vermessen -> 128px -> das sind von 1344px = 9.595% -->
    <div *ngIf="debugMode_showPlaceholderApp" class="blink" style="background-color: blue; height: calc(100vH * 9.6 / 100);">placeholder app header</div>

    <app-rightmenu [style.display]="app.inAppMode != true ? 'unset' : 'none'"></app-rightmenu>

    <!--<div class="menu-wrapper" (click)="onMenuClick($event)">
        <div class="layout-menu-container">
            <app-inline-menu *ngIf="app.inlineMenuPosition=='top' || app.inlineMenuPosition=='both'" key="top"></app-inline-menu>
            <app-menu></app-menu>
            <app-inline-menu *ngIf="app.inlineMenuPosition=='bottom' || app.inlineMenuPosition=='both'" key="bottom"></app-inline-menu>
        </div>
    </div>-->

    <div class="layout-main" style="{{app.inAppMode == true ? 'padding: 0px;' : null}}"><!--[style.padding]="app.inAppMode == true ? '0px' : 'unset'"  funkt. desktop mode nicht!-->
        <p-toast key="app.main" [baseZIndex]="99999"></p-toast>
        
        <!--<app-breadcrumb></app-breadcrumb>-->

        <div class="layout-content">
            <router-outlet></router-outlet>
        </div>

        <!--<app-footer></app-footer>-->
    </div>

    <!--<app-config></app-config>-->

    <div *ngIf="mobileMenuActive" class="layout-mask modal-in"></div>

</div>
