import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '../_services/translate.service'; // our translate service

@Pipe({
  name: 'translate',
  pure: false // impure pipe, update value when we change language
})
export class TranslatePipe implements PipeTransform {

  constructor(private _translate: TranslateService) { }

  transform(value: string, capitalized: boolean = false): string {

    if (!value) return null;

    return this._translate.instant(value,capitalized);
  }

}
