<form [formGroup]="CRUDForm" #form (ngSubmit)="validateAndSaveCRUDItem()" novalidate>

        <div #divCardToolbar class="card">
                <!-- [ngClass]="CRUDTemplatePluginTop == null ? 'card-toolbar' : null" [class.card-toolbar-static-inactive]="(app.isOverlay() || app.isHorizontal() || app.staticMenuDesktopInactive === true || !app.isDesktop()) && !app.isSlim()"
                [class.card-toolbar-horizontal-active]="!app.isOverlay() && app.isHorizontal() && app.isDesktop()"
                [class.card-toolbar-slim-active]="app.isSlim()"-->
                <!-- class="card card-toolbar" US 14470 Toolbar nur fixiert, wenn kein CRUDTemplatePluginTop drüber -->
                <span>
                  <button style="width: auto;" pButton 
                  type="submit" [icon]="'ui-icon-save'" [label]="translate.instant('OK',true)"></button>
                  <!-- [disabled]="!(CRUDForm.dirty && CRUDForm.valid)"-->
                </span>
                <span class="floatRight">
                  <button style="width: auto; margin-left: 5px;" pButton type="button" icon="ui-icon-close" [label]="translate.instant('Abbrechen',true)"
                    (click)="cancelCRUDEdit()"></button><!-- [routerLink]="['/'+CRUDItemRoutePlural]"-->
                </span>
              </div>

<div class="ui-g form-group">

        <div [formGroup]="CRUDForm" class="ui-g-12 ui-md-6 inputTextForm" [style.display]="askForOldPassword == true ? 'unset' : 'none'">
                <span class="md-inputfield">
                    <input formControlName="_oldpassword" autocomplete="new-password" [type]="passwordVisible ? 'text' : 'password'" class="inputFields"
                    promptLabel="Bitte tragen Sie das bisherige Passwort ein" weakLabel="schwach" mediumLabel="mittel"
                    strongLabel="stark" pPassword style="width: 92% !important" />
                    <!--  -->
                  <!-- width 92%, damit noch das auge dahinterpasst -->&nbsp;<i class="fa fa-fw"
                    [ngClass]="{'fa-eye-slash': passwordVisible, 'fa-eye': !passwordVisible}" (click)="passwordVisible = !passwordVisible"
                    title="{{ passwordVisible ? translate.instant('verstecken',true) : translate.instant('anzeigen',true)}}"
                    style="cursor: pointer;"></i>
                    <label>{{'Bisheriges Passwort'|translate:true}} <i class="fa-regular fa-circle-exclamation fa-xs"></i></label>
                </span>
              </div>
              <div class="ui-g-12 ui-md-6" [style.display]="askForOldPassword == true ? 'unset' : 'none'">
                <div class="errorMessage">
                  {{displayMessage._password}}
                  {{displayMessageForm.Passwort_ist_erforderlich}}
                </div>
              </div>
          

  <div [formGroup]="CRUDForm" class="ui-g-12 ui-md-6 inputTextForm" [style.display]="true ? 'unset' : 'none'">
    <span class="md-inputfield p-float-label">
        <input formControlName="_password" autocomplete="new-password" [type]="passwordVisible ? 'text' : 'password'" class="inputFields"
        promptLabel="Bitte tragen Sie ein Passwort ein" weakLabel="schwach" mediumLabel="mittel"
        strongLabel="stark" pPassword style="width: 92% !important" />
      <!-- width 92%, damit noch das auge dahinterpasst -->&nbsp;<i class="fa fa-fw"
        [ngClass]="{'fa-eye-slash': passwordVisible, 'fa-eye': !passwordVisible}" 
        [style.display]="askForOldPassword == true ? 'none' : 'unset'"
        (click)="passwordVisible = !passwordVisible"
        title="{{ passwordVisible ? translate.instant('verstecken',true) : translate.instant('anzeigen',true)}}"
        style="cursor: pointer;"></i>
        <label>{{'Neues Passwort'|translate:true}} <i class="fa-regular fa-circle-exclamation fa-xs"></i></label>
    </span>
  </div>
  <div class="ui-g-12 ui-md-6">
    <div class="errorMessage">
      {{displayMessage._password}}
      {{displayMessageForm.Passwort_ist_erforderlich}}
    </div>
  </div>

  <br>

  <div [formGroup]="CRUDForm" class="ui-g-12 ui-md-6 inputTextForm" [style.display]="true ? 'unset' : 'none'">
      <span class="md-inputfield p-float-label">
          <input formControlName="_passwordBestaetigen" [type]="passwordVisible ? 'text' : 'password'"
          autocomplete="new-password" [feedback]="false" class="inputFields"
          pPassword style="width: 92% !important" />
          <label>{{'Passwort best&auml;tigen'|translate:true}} <i class="fa-regular fa-circle-exclamation fa-xs"></i></label>
      </span>
    </div>
    <div class="ui-g-12 ui-md-6">
      <div class="errorMessage">
        {{displayMessage._passwordBestaetigen}}
        {{displayMessageForm.Die_Passwoerter_stimmen_nicht_ueberein}}
      </div>
    </div>


</div>
</form>
