import { Component, OnInit, Inject, forwardRef, Injector, ViewChild, Input, Output, EventEmitter, SimpleChange, SimpleChanges } from '@angular/core';
import { AppComponent } from '../app.component';
import { TranslateService } from '../_services/translate.service';
import SignaturePad from 'signature_pad';
import {
  FileUpload
} from 'primeng/fileupload';

declare var jquery: any;
declare var $: any;

@Component({
  selector: 'crud-basic-signature',
  templateUrl: './crud-basic-signature.component.html',
  styleUrls: ['./crud-basic-signature.component.css']
})
export class CRUDBasicSignatureComponent implements OnInit {
  @Input('value') value: string;
  @Input() bautagebuch: boolean = false;
  @Input() disable: boolean = false;
  @Input() disableAfterUpload: boolean = false;
  @Output('onChange') onChange: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('fileUpload', { static: true }) public fileUpload: FileUpload;

  canvas: any = null;
  signaturePad: SignaturePad = null;

  initialValue: any = null;

  file: any[] = [];
  base64: any;


  constructor(
    @Inject(forwardRef(() => AppComponent)) public _app: AppComponent,
    public translate: TranslateService,
  ) { }

  ngOnInit() {
    setTimeout(() => {
      this.canvas = document.querySelector("canvas");
      this.signaturePad = new SignaturePad(this.canvas);

      //let parentWidth = $(this.canvas).parent().outerWidth();
      //let parentHeight = $(this.canvas).parent().outerHeight();
      let parentWidth = 430;  // Original-Grösse wie im HTML Client
      let parentHeight = 215; // Original-Grösse wie im HTML Client

      if (this.bautagebuch == false) {
        this.canvas.setAttribute("width", parentWidth);
        this.canvas.setAttribute("height", parentHeight);
      }
      else {
        parentWidth = $(this.canvas).parent().innerWidth();
        this.canvas.setAttribute("width", parentWidth);
        this.canvas.setAttribute("height", 150);
      }

      this.signaturePad.fromDataURL("data:image/jpg;base64," + this.value);
      this.signaturePad.penColor = "#888888";

      let thisInstance = this;
      this.signaturePad.onEnd = (t => thisInstance.onChangeHandler());
      //this.signaturePad.addEventListener('endStroke', thisInstance.signaturePadEventHandler);  // signature_pad v4! ... unfertig!    

      this.initialValue = this.value;
    }, 100)
  }

  //signaturePadEventHandler(evt: Event) : void { // signature_pad v4! ... unfertig!
  //  console.log("CRUDBasicSignatureComponent.signaturePadEventHandler() evt:", evt);
  //  this.onChangeHandler();
  //}

  // save Logik von Demo: http://szimek.github.io/signature_pad/js/app.js
  save() {
    //console.log("CRUDBasicSignatureComponent.save()");
    //this.signaturePad.toDataURL(); // save image as PNG
    //this.signaturePad.toDataURL("image/jpeg"); // save image as JPEG
    //this.signaturePad.toDataURL("image/svg+xml"); // save image as SVG

    if (this.signaturePad.isEmpty()) {
      alert("Please provide a signature first.");
    } else {
      var dataURL = this.signaturePad.toDataURL();
      this.download(dataURL, "signature.png");
    }
  }

  download(dataURL, filename) {
    if (navigator.userAgent.indexOf("Safari") > -1 && navigator.userAgent.indexOf("Chrome") === -1) {
      window.open(dataURL);
    } else {
      var blob = this.dataURLToBlob(dataURL);
      var url = window.URL.createObjectURL(blob);

      var a = document.createElement("a");
      //a['style'] = "display: none";
      a.href = url;
      a.download = filename;

      document.body.appendChild(a);
      a.click();

      window.URL.revokeObjectURL(url);
    }
  }

  dataURLToBlob(dataURL) {
    // Code taken from https://github.com/ebidel/filer.js
    var parts = dataURL.split(';base64,');
    var contentType = parts[0].split(":")[1];
    var raw = window.atob(parts[1]);
    var rawLength = raw.length;
    var uInt8Array = new Uint8Array(rawLength);

    for (var i = 0; i < rawLength; ++i) {
      uInt8Array[i] = raw.charCodeAt(i);
    }

    return new Blob([uInt8Array], { type: contentType });
  }

  undo() {
    //console.log("CRUDBasicSignatureComponent.undo()");
    /* Einzelundo:
    var data = this.signaturePad.toData();
    if (data) {
      data.pop(); // remove the last dot or line
      this.signaturePad.fromData(data);
    }
    */
    this.value = this.initialValue;
    this.signaturePad.clear();
    this.signaturePad.fromDataURL("data:image/jpg;base64," + this.value);
    this.onChangeHandler();
  }

  clear() {
    //console.log("CRUDBasicSignatureComponent.clear()");
    this.signaturePad.clear();
    this.onChangeHandler();
  }

  onChangeHandler(upload?: boolean) {
    //console.log("CRUDBasicSignatureComponent.onChange()");
    this.onChange.emit(upload);
  }

  public getPNGData() {
    if(this.signaturePad.isEmpty()) {
      return null; // MODI: ansonsten gibt das auch nach clear noch "iVBORw..."-DAten zurück
    }
    let data = this.signaturePad.toDataURL(/*"image/jpeg"*/);
    if (data.substr(0, "data:image/png;base64,".length) == "data:image/png;base64,") {
      data = data.substr("data:image/png;base64,".length);
    }
    //console.log("CRUDBasicSignatureComponent.getPNGData() data:", data);
    return data;
  }

  upload() { // diese Funktion wird von der ParentComp über ViewChild gecallt: https://stackoverflow.com/questions/37587732/how-to-call-another-components-function-in-angular2
    //console.log("CRUDBasicLogo.upload()");
    //console.log("CRUDBasicLogo.upload() this.logoUpload:", this.logoUpload);
    this.fileUpload.basicFileInput.nativeElement.click();
  }


  uploader(event) {
    let thisInstance = this;
    let fileReader = new FileReader();
    for (let file of event.files) {
      fileReader.readAsDataURL(file);
      fileReader.onload = function () {
        //console.log("CRUDBasicSignature.uploader() reader.result:", fileReader.result);
        //thisInstance.logoBase64 = (""+fileReader.result).split(/,/)[1];
        thisInstance.base64 = "" + fileReader.result
        //console.log("MandantenDetailComponent.logoUploader() thisInstance.logoBase64:", thisInstance.logoBase64);

        thisInstance.value = ("" + thisInstance.base64).split(/,/)[1];
        thisInstance.signaturePad.clear();
        thisInstance.signaturePad.fromDataURL("data:image/jpg;base64," + thisInstance.value);
        thisInstance.onChangeHandler(true);
        //thisInstance.CRUDForm.markAsDirty();

        thisInstance.fileUpload.clear(); // sonst kann man nicht nochmal den Button klicken
      };
      break; // nur 1. Datei verarbeiten!
    }
  }

  reSizeCanvas(data) {
    let parentWidth = 430;  // Original-Grösse wie im HTML Client
    let parentHeight = 215; // Original-Grösse wie im HTML Client

    if (this.bautagebuch == false) {
      this.canvas.setAttribute("width", parentWidth);
      this.canvas.setAttribute("height", parentHeight);
      this.signaturePad.fromDataURL("data:image/jpg;base64," + data);
    }
    else {
      setTimeout(() => {
        parentWidth = $(this.canvas).parent().innerWidth();
        this.canvas.setAttribute("width", parentWidth);
        this.canvas.setAttribute("height", 150);

        this.signaturePad.fromDataURL("data:image/jpg;base64," + data);
      }, 200)
    }

  }

  isEmpty() {
    if (this.signaturePad != null)
      return this.signaturePad.isEmpty();
    else
    return true
  }

}
