import { /*CRUDBasicDetailComponent_Template, */CRUDBasicDetailComponent } from '../crud-basic-detail/crud-basic-detail.component';

import { Component, OnInit, Inject, forwardRef, Injector, ViewChild, Input } from '@angular/core';
import { Validators, FormControl, FormGroup, FormBuilder, AbstractControl, FormControlName } from '@angular/forms';
import { AppComponent } from '../app.component';
import { TranslateService } from '../_services/translate.service';
import { GenericValidator } from '../_helpers/generic-validator';
/*import { AutoComplete } from 'primeng/primeng';*/
import { Params } from '@angular/router';

import { IStammdatenKorrektur } from '../_interfaces/stammdaten-korrektur';
import { StammdatenKorrekturService } from '../_services/stammdaten-korrektur.service';
import { StammdatenKorrekturDetailGuard } from './stammdaten-korrektur-detail.guard';

import { IAuftragsdaten } from '../_interfaces/auftragsdaten';
import { AuftragsdatenService } from '../_services/auftragsdaten.service';

declare var jquery: any;
declare var $: any;

//import { StammdatenKorrekturDetailComponent_Template } from './stammdaten-korrektur-detail.component.include_template';

@Component({
  selector: 'app-stammdatenKorrektur-detail',
  //template: `${CRUDBasicDetailComponent_Template || ''}${StammdatenKorrekturDetailComponent_Template}`,
  templateUrl: './stammdaten-korrektur-detail.component.html',
  styleUrls: ['../crud-basic-detail/crud-basic-detail.component.css'],
  host: { '(window:keydown)': 'hotkeys($event)' }
})
export class StammdatenKorrekturDetailComponent extends CRUDBasicDetailComponent implements OnInit {
  // CHILD-spezifisch: Konstanten - START
  CRUDItemKurzform: string = "StammdatenKorrektur";
  CRUDPageTitleNeu: string = this.translate.instant("Änderung Kontaktdaten", true);
  CRUDPageTitleBearbeiten: string = this.translate.instant("Änderung Kontaktdaten", true);
  CRUDItemBezeichnungSingularCapitalized: string = "StammdatenKorrektur";
  CRUDItemBezeichnungPluralCapitalized: string = "StammdatenKorrekturen";
  CRUDItemBezeichnungSingular: string = "stammdatenKorrektur";
  CRUDItemBezeichnungPlural: string = "stammdatenKorrekturen";
  CRUDItemRouteSingular: string = "stammdaten-korrektur";
  CRUDItemRoutePlural: string = "stammdaten-korrekturen";
  CRUDItemHelpTopic: string = null;
  // CHILD-spezifisch: Konstanten - Ende

  // CHILD-spezifisch: zusätzliche Widgets (ausser Standard Inputs, Autocomplete) - START

  // queryParams
  queryParams: Params = null;

  // CHILD-spezifisch: zusätzliche Widgets (ausser Standard Inputs, Autocomplete) - ENDE

  constructor(
    @Inject( forwardRef(() => AppComponent)) public _app: AppComponent,
    private _injector: Injector,
    private _translate: TranslateService,
    private _crudItemService: StammdatenKorrekturService,
    private _guard: StammdatenKorrekturDetailGuard,
    private auftragsdatenService: AuftragsdatenService,

    // CHILD-spezifisch: zusätzliche Services - START
    // CHILD-spezifisch: zusätzliche Services - ENDE
  ) {
    super(_app, _injector);

    this.crudItemService = _crudItemService;
    this.guard = _guard;

    // CHILD-spezifisch die Validator Messages bestücken - START
    this.validationMessages =
    {
      status: {
        required: this._translate.instant('Status', true) + ': ' + this._translate.instant('ist erforderlich', true),
        maxlength: this._translate.instant('Status', true) + ' ' + this._translate.instant('darf 255 Zeichen nicht überschreiten', true)
      },

      kundennummer: {
        required: this._translate.instant('Kundennummer', true) + ': ' + this._translate.instant('ist erforderlich', true),
        maxlength: this._translate.instant('Kundennummer', true) + ' ' + this._translate.instant('darf 255 Zeichen nicht überschreiten', true)
      },

      behaelternummer: {
        required: this._translate.instant('Behälternummer', true) + ': ' + this._translate.instant('ist erforderlich', true),
        maxlength: this._translate.instant('Behälternummer', true) + ' ' + this._translate.instant('darf 255 Zeichen nicht überschreiten', true)
      },

      name_1: {
        required: this._translate.instant('Name 1', true) + ': ' + this._translate.instant('ist erforderlich', true),
        maxlength: this._translate.instant('Name 1', true) + ' ' + this._translate.instant('darf 255 Zeichen nicht überschreiten', true)
      },

      name_2: {
        maxlength: this._translate.instant('Name 2', true) + ' ' + this._translate.instant('darf 255 Zeichen nicht überschreiten', true)
      },

      strasse: {
        maxlength: this._translate.instant('Strasse', true) + ' ' + this._translate.instant('darf 255 Zeichen nicht überschreiten', true)
      },

      plz: {
        //required: this._translate.instant('PLZ', true) + ': ' + this._translate.instant('ist erforderlich', true),
        maxlength: this._translate.instant('PLZ', true) + ' ' + this._translate.instant('darf 255 Zeichen nicht überschreiten', true)
      },

      ort: {
        //required: this._translate.instant('Ort', true) + ': ' + this._translate.instant('ist erforderlich', true),
        maxlength: this._translate.instant('Ort', true) + ' ' + this._translate.instant('darf 255 Zeichen nicht überschreiten', true)
      },

      ortzusatz: {
        //maxlength: this._translate.instant('Ortzusatz', true) + ' ' + this._translate.instant('darf 255 Zeichen nicht überschreiten', true)
      },

      telefon: {
        maxlength: this._translate.instant('Telefon', true) + ' ' + this._translate.instant('darf 255 Zeichen nicht überschreiten', true)
      },

      eMail: {
        maxlength: this._translate.instant('E-Mail', true) + ' ' + this._translate.instant('darf 255 Zeichen nicht überschreiten', true)
      },

      bemerkung: {
        maxlength: this._translate.instant('Bemerkung', true) + ' ' + this._translate.instant('darf 255 Zeichen nicht überschreiten', true)
      },
};
    // CHILD-spezifisch die Validator Messages bestücken - ENDE
    
    this.genericValidator = new GenericValidator(this.validationMessages);

    this.buildForm();
  }

  buildForm() {
    // CHILD-spezifisch die Form aufbauen - START
    this.CRUDForm = this.fb.group({
      //status: [''/*, [Validators.required, Validators.maxLength(255)]*/],
      //kundennummer: ['', [Validators.required, Validators.maxLength(255)]],
      //behaelternummer: ['', [Validators.required, Validators.maxLength(255)]],
      name_1: ['', [Validators.required, Validators.maxLength(255)]],
      name_2: ['', [Validators.maxLength(255)]],
      //strasse: ['', [Validators.maxLength(255)]],
      //plz: ['', [/*Validators.required, */Validators.maxLength(255)]],
      //ort: ['', [/*Validators.required, */Validators.maxLength(255)]],
      //ortzusatz: ['', [Validators.maxLength(255)]],
      telefon: ['', [Validators.maxLength(255)]],
      eMail: ['', [Validators.maxLength(50)]],
      bemerkung: ['', [Validators.maxLength(255)]],
    });
    // CHILD-spezifisch die Form aufbauen - ENDE
  }

  ngOnInit() {
    this.blockedDocument = true;

    // CHILD-spezifische Zusätze, z.B. Nachladen anderer Entities - START

    this.route.queryParams.subscribe(params => {
      console.log("StammdatenKorrekturDetailComponent.ngOnInit() params/this:", params, this);
      this.queryParams = params;
    });
    
    // CHILD-spezifische Zusätze, z.B. Nachladen anderer Entities - ENDE

    super.ngOnInit();
  }

  getValuesFromForm() {
    let a = super.getValuesFromForm(); // Standard! do not change!

    // CHILD-spezifische assigns, z.B. spracheId aus strache.Id bestücken - START
    // CHILD-spezifische assigns, z.B. spracheId aus strache.Id bestücken - ENDE

    //console.log("StammdatenKorrekturDetailComponent.getValuesFromForm() a:", a);
    return a;
  }

  sendValuesToForm() {
    // CHILD-spezifisch die Form patchen - START
    this.CRUDForm.patchValue({
      //status: this.CRUDItem.status,
      //kundennummer: this.CRUDItem.kundennummer,
      //behaelternummer: this.CRUDItem.behaelternummer,
      name_1: this.CRUDItem.name_1,
      name_2: this.CRUDItem.name_2,
      //strasse: this.CRUDItem.strasse,
      //plz: this.CRUDItem.plz,
      //ort: this.CRUDItem.ort,
      //ortzusatz: this.CRUDItem.ortzusatz,
      telefon: this.CRUDItem.telefon,
      eMail: this.CRUDItem.eMail,
      bemerkung: this.CRUDItem.bemerkung
});
    // CHILD-spezifisch die Form patchen - ENDE
    //console.log("StammdatenKorrekturDetailComponent.sendValuesToForm() CRUDForm:", this.CRUDForm);
    super.sendValuesToForm(); // haben nicht alle DetailComponents - erst ab Ticket 9412 17.07.2019
  }

  getCRUDBezeichnung() { // Overwrite!
    //if(this.CRUDForm != null && this.CRUDForm.get('bezeichnung') != null) return this.CRUDForm.get('bezeichnung').value;
    return this.CRUDItem.name_1;
  }

  onCRUDItemRetrieved(CRUDItem: /*IAnrede*/any): void { // overwrite
    console.log("StammdatenKorrekturDetailComponent.onCRUDItemRetrieved() CRUDItem:", CRUDItem);

    if(this.dataId == 0) { // Neuanlage
      //if(this.queryParams != null) {
        CRUDItem.kundennummer = this.queryParams.kundenNr;
        CRUDItem.behaelternummer = this.queryParams.behaelterNr;
      //}

      this.auftragsdatenService.getAuftragsdaten(this.queryParams.auftragsId).subscribe((response) => {
        console.log("StammdatenKorrekturDetailComponent.onCRUDItemRetrieved() getAuftragsdaten() response:", response);

        //CRUDItem.bemerkung = response.bemerkung;
        CRUDItem.name_1 = response.name_1Ansprechpartner;
        CRUDItem.name_2 = response.name_2Ansprechpartner;
        //CRUDItem.ort = response.ortKunde;
        //CRUDItem.ortzusatz = response.ortzusatsKunde;
        //CRUDItem.plz = response.plzKunde;
        //CRUDItem.strasse = response.strasseKunde;
        CRUDItem.telefon = response.telefonAnsprechpartner;
        CRUDItem.eMail = response.eMailAnsprechpartner;

        //this.blockedDocument = false;
        super.onCRUDItemRetrieved(CRUDItem);
        this._app.iOSFixResizeWebview('StammdatenKorrekturDetail.onCRUDItemRetrieved');
      }, function (error) { 
        console.error("StammdatenKorrekturDetailComponent.onCRUDItemRetrieved() getAuftragsdaten() error:", error);
        return this.handleError(error); 
      });
    }
    else {
      super.onCRUDItemRetrieved(CRUDItem);
      this._app.iOSFixResizeWebview('StammdatenKorrekturDetail.onCRUDItemRetrieved');
    }

  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
    this._app.iOSFixResizeWebview('StammdatenKorrekturDetail.ngAfterViewInit');
  }

  //afterSaveCRUDItem() { 
  //  setTimeout(() => {
  //    this.messageWrapperService.postStaticMessage({ severity: 'info', summary: "Stammdatenänderung", detail: "Vielen Dank!<br><br>Die Stammdatenänderung wurde an Primagas übermittelt.<br><br>Achtung:<br>Die Änderung ist erst sichtbar, wenn Primagas die Daten überprüft hat." }); 
  //  }, 1000);
  //}

  navigateToMapOrList() {
    let navigateTo = "/map"; // default

    if(this.queryParams != null) {
      if(this.queryParams.nav == 'map') navigateTo = "/map"; // wenn queryParam vorhanden, dann entspr. nach map Oder Liste navigieren
      else if(this.queryParams.nav == 'list') navigateTo = "/list"; // wenn queryParam vorhanden, dann entspr. nach map Oder Liste navigieren
    }

    this.router.navigate([navigateTo]);
  }

  cancelCRUDEdit() { // overwrite
    //this.router.navigate(['/map']);
    this.navigateToMapOrList();
  }

  onSaveComplete(close: boolean): void { // overwrite - aber nur wegen dem navigate - und der abweichenden Meldung
    this.blockedDocument = false;
    let bezeichnung = this.getCRUDBezeichnung();

    //MODI:
    //this.messageWrapperService.postTimedMessage({ severity: 'success', summary: this.deleted ? this.translate.instant("Löschen erfolgreich", true) : this.translate.instant("Speichern erfolgreich", true), detail: bezeichnung }) // TO DO
    this.messageWrapperService.postStaticMessage({ severity: 'info', summary: "Änderung Kontaktdaten", detail: "Vielen Dank!<br><br>Die Änderung der Kontaktdaten wurde an Primagas übermittelt.<br><br>Achtung:<br>Die Änderung ist erst sichtbar, wenn Primagas die Daten überprüft hat." }); 

    this.CRUDForm.reset();
    // US 14152: Es gibt ein Problem, wenn man ein Feld ändert, NICHT verlässt, per STRG+S zwischenspeichert.
    // in dem Fall bleibt das Feld / die Form dirty. Der dirty muss aber raus, sonst kann man die Form nicht verlassen (guard!)
    if(close == false) {
      let activeElement = $(document.activeElement);
      if(activeElement != null) {
        activeElement.blur();
        activeElement.focus();
      }
    }
    if (close) {
      //console.log("CRUDBasicDetailComponent.onSaveComplete() routing to:", this.CRUDItemRoutePlural);
      //this.router.navigate(['/' + this.CRUDItemRoutePlural], { queryParamsHandling: "merge" });

      // wenn Abschliessen -> zur Map
      //this.router.navigate(['/map']);
      this.navigateToMapOrList();
     
    }
  }

  ngOnDestroy(): void {
    console.log("StammdatenKorrekturDetailComponent.ngOnDestroy()");

    if(this._app.mapComponent != null) {
      this._app.iOSFixResizeWebview('StammdatenKorrekturDetail.ngOnDestroy');
    }
  } 


}
