import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
//import 'rxjs/add/operator/map';
//import 'rxjs/add/operator/do';
//import 'rxjs/add/operator/catch';
import { IPagination } from '../_interfaces/pagination'
import { IStammdatenKorrektur } from '../_interfaces/stammdaten-korrektur';
//import { IChoiceList } from '../_interfaces/_choice-list';
import * as moment from 'moment'; // DateTimeOffset-Fix
import * as cloneDeep from 'lodash/cloneDeep'; // DateTimeOffset-Fix

import { AppconfigService } from '../_services/appconfig.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';

import { CRUDBasicService, httpOptions } from '../_services/crud-basic.service';

@Injectable()
export class StammdatenKorrekturService extends CRUDBasicService {

  //typeOf deprecated -> CRUDBasicService.propertyTypeOf
  /*typeOf(propertyName: string) {  // welchen Typs sind die Properties IN DER API ?
    let property = this.propertyTypes.find(f => f.name == propertyName);
    if(property != null) return property.type;
    else return null;    
  }*/
  propertyTypes = [ // welchen Typs sind die Properties IN DER API ?
    {name: 'id', type: 'int'},
    {name: 'createdBy', type: 'string'},
    {name: 'created', type: 'DateTimeOffset'},
    {name: 'modifiedBy', type: 'string'},
    {name: 'modified', type: 'DateTimeOffset'},
    {name: 'rowVersion', type: 'byte[]'},
    {name: 'status', type: 'string'},
    {name: 'kundennummer', type: 'string'},
    {name: 'behaelternummer', type: 'string'},
    {name: 'name_1', type: 'string'},
    {name: 'name_2', type: 'string'},
    {name: 'strasse', type: 'string'},
    {name: 'plz', type: 'string'},
    {name: 'ort', type: 'string'},
    {name: 'ortzusatz', type: 'string'},
    {name: 'telefon', type: 'string'},
    {name: 'eMail', type: 'string'},
    {name: 'bemerkung', type: 'string'}
  ];
    
  getStammdatenKorrekturenCollection(pageNumber: number, pageSize: number, searchQuery: string): Observable<{ stammdatenKorrekturen: IStammdatenKorrektur[], pagination: IPagination }> {
    return this.httpClient.get(this.apiBaseUrl + 'stammdatenkorrekturen?pageNumber=' + pageNumber + '&pageSize=' + pageSize + '&searchQuery=' + searchQuery, { headers: httpOptions, observe: 'response', withCredentials: true })
      .pipe(
        map((response) => {
          return { stammdatenKorrekturen: <IStammdatenKorrektur[]>response.body, pagination: /*<IPagination>JSON.parse(response.headers.get('x-pagination'))*/this.getDummyPagination((<any[]>response.body).length) };
        }), catchError(this.handleError))
  }

  getStammdatenKorrektur(id: number): Observable<IStammdatenKorrektur> {

    if (id === 0) {
      return Observable.create((observer: any) => {
        observer.next(this.initializeStammdatenKorrektur());
        observer.complete();
      })
    }

    return this.httpClient.get<IStammdatenKorrektur>(this.apiBaseUrl + 'stammdatenkorrekturen?id=' + id, { headers: httpOptions, observe: 'body', withCredentials: true })
      .pipe(map((response) => 
        //response
        {
               console.log("StammdatenKorrekturService.getKopfdaten() response: ", response)
               return response[0];
        }
      ), catchError(this.handleError));
  }

  saveStammdatenKorrektur(stammdatenKorrektur: IStammdatenKorrektur): Observable<IStammdatenKorrektur> {
    // DateTimeOffset-Fix
    let itemToSave = cloneDeep(stammdatenKorrektur); // clonen, um sicherzustellen, dass das Original-Objekt nicht ver�ndert wird, evtl. passiert nach dem Save noch etwas damit ?
    //console.log("StammdatenKorrekturService.saveSingularCapitalized#() itemToSave before DateTimeOffset-Fix:", itemToSave);
    this.propertyTypes.filter(f => f.type.toLowerCase() == 'datetimeoffset').forEach(propertyType => {
      if (itemToSave[propertyType.name] != null) {
        itemToSave[propertyType.name] = moment(itemToSave[propertyType.name]).format('YYYY-MM-DDTHH:mm:ss.SSSZ'); // alle datetimeoffset-felder entspr. formatieren, dass in der API auch der Offset ankommt!
      }
    });
    //console.log("StammdatenKorrekturService.saveSingularCapitalized#() itemToSave after DateTimeOffset-Fix:", itemToSave);

    if (stammdatenKorrektur.id === 0) {
      return this.createStammdatenKorrektur(/*stammdatenKorrektur*/itemToSave);
    }

    return this.updateStammdatenKorrektur(/*stammdatenKorrektur*/itemToSave)

  }

  updateStammdatenKorrektur(stammdatenKorrektur: IStammdatenKorrektur): Observable<IStammdatenKorrektur> {

    return this.httpClient.put<IStammdatenKorrektur>(this.apiBaseUrl + 'stammdatenkorrekturen' /*+ stammdatenKorrektur.id*/, stammdatenKorrektur, { headers: httpOptions, observe: 'body', withCredentials: true })
    .pipe(catchError(this.handleError))

  }

  deleteStammdatenKorrektur(id: number) {
    return this.httpClient.delete(this.apiBaseUrl + 'stammdatenkorrekturen?id=' + id, { headers: httpOptions, observe: 'body', withCredentials: true })
      .pipe(
        catchError(this.handleError)
      )
  }

  createStammdatenKorrektur(stammdatenKorrektur: IStammdatenKorrektur): Observable<IStammdatenKorrektur> {
    return this.httpClient.post<IStammdatenKorrektur>(this.apiBaseUrl + 'stammdatenkorrekturen', stammdatenKorrektur, { headers: httpOptions, observe: 'body', withCredentials: true })
      .pipe(map((response) => response), catchError(this.handleError))
  }

  initializeStammdatenKorrektur(): IStammdatenKorrektur {
    return {
      id: 0,
      createdBy: '',
      created: /*''*/'0001-01-01T00:00:00.000Z', // fix
      modifiedBy: '',
      modified: /*''*/'0001-01-01T00:00:00.000Z', // fix
      rowVersion: '',
      status: '',
      kundennummer: '',
      behaelternummer: '',
      name_1: '',
      name_2: '',
      strasse: '',
      plz: '',
      ort: '',
      ortzusatz: '',
      telefon: '',
      eMail: '',
      bemerkung: ''
    };
  }


}
