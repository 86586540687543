import { Injectable } from '@angular/core';
//import { Observable } from 'rxjs/Observable';
import { Observable, of } from 'rxjs';
//import 'rxjs/add/operator/map';
//import 'rxjs/add/operator/do';
//import 'rxjs/add/operator/catch';
import { IPagination } from '../_interfaces/pagination'
import { IChecklistePositionenFeld } from '../_interfaces/checkliste-positionen-feld';
//import { IChoiceList } from '../_interfaces/_choice-list';
import * as moment from 'moment'; // DateTimeOffset-Fix
import * as cloneDeep from 'lodash/cloneDeep'; // DateTimeOffset-Fix

import { AppconfigService } from '../_services/appconfig.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';

import { CRUDBasicService, httpOptions } from '../_services/crud-basic.service';

@Injectable()
export class ChecklistePositionenFeldService extends CRUDBasicService {

  //typeOf deprecated -> CRUDBasicService.propertyTypeOf
  /*typeOf(propertyName: string) {  // welchen Typs sind die Properties IN DER API ?
    let property = this.propertyTypes.find(f => f.name == propertyName);
    if(property != null) return property.type;
    else return null;    
  }*/
  propertyTypes = [ // welchen Typs sind die Properties IN DER API ?
    {name: 'id', type: 'int'},
    {name: 'createdBy', type: 'string'},
    {name: 'created', type: 'DateTimeOffset'},
    {name: 'modifiedBy', type: 'string'},
    {name: 'modified', type: 'DateTimeOffset'},
    {name: 'rowVersion', type: 'byte[]'},
    {name: 'reihenfolge', type: 'int'},
    {name: 'platzhalter', type: 'string'},
    {name: 'info', type: 'string'},
    {name: 'mussfeld', type: 'bool'},
    {name: 'abhaengigkeit', type: 'string'},
    {name: 'bezeichnung', type: 'string'},
    {name: 'typ', type: 'string'},
    {name: 'felddefinition', type: 'string'},
    {name: 'mangel', type: 'string' },
    {name: 'summary', type: 'string'}
  ];
    
  getChecklistePositionenFelderCollection(pageNumber: number, pageSize: number, searchQuery: string): Observable<{ checklistePositionenFelder: IChecklistePositionenFeld[], pagination: IPagination }> {
    return this.httpClient.get(this.apiBaseUrl + 'checklistepositionenfelder?pageNumber=' + pageNumber + '&pageSize=' + pageSize + '&searchQuery=' + searchQuery, { headers: httpOptions, observe: 'response', withCredentials: true })
      .pipe(
        map((response) => {
          return { checklistePositionenFelder: <IChecklistePositionenFeld[]>response.body, pagination: <IPagination>JSON.parse(response.headers.get('x-pagination')) };
        }), catchError(this.handleError))
  }

  getChecklistePositionenFeld(id: number): Observable<IChecklistePositionenFeld> {

    if (id === 0) {
      // return Observable.create((observer: any) => {
      //   observer.next(this.initializeChecklistePositionenFeld());
      //   observer.complete();
      // })
      return of(this.initializeChecklistePositionenFeld());
    }

    return this.httpClient.get<IChecklistePositionenFeld>(this.apiBaseUrl + 'checklistepositionenfelder/' + id, { headers: httpOptions, observe: 'body', withCredentials: true })
      .pipe(map((response) => response), catchError(this.handleError));
  }

  saveChecklistePositionenFeld(checklistePositionenFeld: IChecklistePositionenFeld): Observable<IChecklistePositionenFeld> {
    // DateTimeOffset-Fix
    let itemToSave = cloneDeep(checklistePositionenFeld); // clonen, um sicherzustellen, dass das Original-Objekt nicht ver�ndert wird, evtl. passiert nach dem Save noch etwas damit ?
    //console.log("ChecklistePositionenFeldService.saveSingularCapitalized#() itemToSave before DateTimeOffset-Fix:", itemToSave);
    this.propertyTypes.filter(f => f.type.toLowerCase() == 'datetimeoffset').forEach(propertyType => {
      if (itemToSave[propertyType.name] != null) {
        itemToSave[propertyType.name] = moment(itemToSave[propertyType.name]).format('YYYY-MM-DDTHH:mm:ss.SSSZ'); // alle datetimeoffset-felder entspr. formatieren, dass in der API auch der Offset ankommt!
      }
    });
    //console.log("ChecklistePositionenFeldService.saveSingularCapitalized#() itemToSave after DateTimeOffset-Fix:", itemToSave);

    if (checklistePositionenFeld.id === 0) {
      return this.createChecklistePositionenFeld(/*checklistePositionenFeld*/itemToSave);
    }

    return this.updateChecklistePositionenFeld(/*checklistePositionenFeld*/itemToSave)

  }

  updateChecklistePositionenFeld(checklistePositionenFeld: IChecklistePositionenFeld): Observable<IChecklistePositionenFeld> {

    return this.httpClient.put<IChecklistePositionenFeld>(this.apiBaseUrl + 'checklistepositionenfelder/' + checklistePositionenFeld.id, checklistePositionenFeld, { headers: httpOptions, observe: 'body', withCredentials: true })
    .pipe(catchError(this.handleError))

  }

  deleteChecklistePositionenFeld(id: number) {

    return this.httpClient.delete(this.apiBaseUrl + 'checklistepositionenfelder/' + id, { headers: httpOptions, observe: 'body', withCredentials: true })
      .pipe(
        catchError(this.handleError)
      )
  }

  createChecklistePositionenFeld(checklistePositionenFeld: IChecklistePositionenFeld): Observable<IChecklistePositionenFeld> {
    return this.httpClient.post<IChecklistePositionenFeld>(this.apiBaseUrl + 'checklistepositionenfelder', checklistePositionenFeld, { headers: httpOptions, observe: 'body', withCredentials: true })
      .pipe(map((response) => response), catchError(this.handleError))
  }

  initializeChecklistePositionenFeld(): IChecklistePositionenFeld {
    return {
      id: 0,
      createdBy: '',
      created: /*''*/'0001-01-01T00:00:00.000Z', // fix
      modifiedBy: '',
      modified: /*''*/'0001-01-01T00:00:00.000Z', // fix
      rowVersion: '',
      reihenfolge: 0,
      platzhalter: '',
      info: '',
      mussfeld: false,
      abhaengigkeit: '',
      bezeichnung: '',
      typ: '',
      felddefinition: '',
      mangel: '',
      summary: '',

      checklistePositionenFeldwerte: [],
    };
  }


}
