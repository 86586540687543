import {Component, OnInit} from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import {Message, MessageService} from 'primeng/api';
import { NavigationEnd, Router, ROUTER_INITIALIZER } from '@angular/router';
import { MessageWrapperService } from './_services/message-wrapper.service';
import { AuthService } from './auth/auth.service';

import { LeistungService } from './_services/leistung.service';
import { BenutzerService } from './_services/benutzer.service';
import { AuftragsdatenService } from './_services/auftragsdaten.service';

import { IAuftragsdaten } from './_interfaces/auftragsdaten';
import { IBenutzer } from './_interfaces/benutzer';

import { BehaviorSubject } from 'rxjs';
import { DienstleisterService } from './_services/dienstleister.service';
import { _SystemService } from './_services/_system.service';
import * as moment from 'moment'; // DateTimeOffset-Fix
import * as cloneDeep from 'lodash/cloneDeep'; // DateTimeOffset-Fix


declare var hybridWebViewHost: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
})
export class AppComponent implements OnInit{

    topbarTheme = 'blue';

    menuTheme = 'light';

    layoutMode = 'light';

    menuMode = 'static';

    inlineMenuPosition = 'bottom';

    inputStyle = 'filled';

    ripple = true;

    isRTL = false;


    public colorButtons: string = '#3F51B5';

    public inAppMode = false;
    appDebugShowLog: boolean = false;

    iOSFixResizeWebviewTimeoutId: any = null; // iOS 17.4.1 workaround: benötigt in gewissen Situationen ein Resize auf den Webview, damit alles neu gerendert wird (sonst zwar meist optisch ok, aber Felder nicht betretbar)

    public blockUI: boolean = false;

    //public benutzer: IBenutzer = null;
    public benutzerBehaviourSubject: BehaviorSubject<IBenutzer>;
    
    public auftraegeRoutenplanung: IAuftragsdaten[] = [];
    public ownLocation_Long: any = null;
    public ownLocation_Latt: any = null;
    /*public leistungen = [
        {leistung: '1', summary: 'Äussere Prüfung', icon: 'can-food', iconClass: 'fa fa-can-food', count: null},
        {leistung: '2', summary: 'Innere Prüfung', icon: 'bullseye-pointer', iconClass: 'fa fa-bullseye-pointer', count: null},
        {leistung: '3', summary: 'Allgemeine Reparatur', icon: 'toolbox', iconClass: 'fa fa-toolbox', count: null}, // unused
        {leistung: '4', summary: 'Anstrich', icon: 'brush', iconClass: 'fa fa-brush', count: null}, // unused
        {leistung: '5', summary: 'Rohrleitungsprüfung', icon: 'pipe-circle-check', iconClass: 'fa fa-pipe-circle-check', count: null},
        {leistung: '6', summary: 'Sicherheitsventil', icon: 'pipe-valve', iconClass: 'fa fa-pipe-valve', count: null},
        {leistung: '7', summary: 'Verdampfer', icon: 'droplet', iconClass: 'fa fa-droplet', count: null}, // unused
        {leistung: '8', summary: 'Wartung', icon: 'screwdriver-wrench', iconClass: 'fa fa-screwdriver-wrench', count: null}, // unused
        //{leistung: undefined, summary: undefined, icon: 'bomb', iconClass: 'fa fa-bomb', count: null},
        // weitere mögliche icons: wrench, screwdriver
        //{leistung: '*', summary: '???', icon: 'hammer', iconClass: 'fa fa-hammer', count: null},
    ];*/
    public leistungen = [
    ];
    public leistungenFiltered = null;
    public filterLeistungenSelected: any = null;
    public dienstleister = [
    ];
    public filterDienstleisterSelected: any = null;
    public filterPresets = [
        { id: 1, nurUeberfaelligJahre: false, nurUeberfaelligTage: false, summary: 'Alle' },
        { id: 2, nurUeberfaelligJahre: true,  nurUeberfaelligTage: false, summary: 'nur Überfällige Jahr' },
        { id: 3, nurUeberfaelligJahre: false, nurUeberfaelligTage: true, summary: 'nur Überfällige {n} Monate' },
    ];
    public filterPresetsSelected: any = null;
    public filterNurUeberfaelligJahre: boolean = false;
    public filterNurUeberfaelligTage: boolean = false;

    public filterBenutzerPresets = [
      { id: 1, nurMeine: false, summary: 'Alle' },
      { id: 2, nurMeine: true, summary: 'nur Meine' },
    ];
    public filterBenutzerPresetsSelected: any = null;
    public filterBenutzerNurMeine: boolean = false;

    public mapComponent: any = null;
    public topbarComponent: any = null;
    public auftragComponent: any = null;

    public mussFeldMarkerIconClass: string = "fa-regular fa-circle-exclamation fa-xs";

    //public lastCopiedElement: any = null; // copy/paste: enthält eine stringified Position o.Ä.

    benutzerAlleBasicDataOnly: IBenutzer[] = null;

    dispositionShowDialog: boolean = false;
    dispositionDatum: Date = null;
    dispositionBenutzer: IBenutzer = null;
    dispositionBenutzerOptions = [
    //{ id: 1, value: 1, bezeichnung: 'Benutzername' },
    ];
    dispositionAuftraege: IAuftragsdaten[] = null;
  
    constructor(private primengConfig: PrimeNGConfig,
        private messageService: MessageService,
        private messageWrapperService: MessageWrapperService,
        private authService: AuthService,
        private router: Router,
        private benutzerService: BenutzerService,
        private auftragsdatenService: AuftragsdatenService,
        private leistungService: LeistungService,
        private dienstleisterService: DienstleisterService,
        private _systemService: _SystemService
        ) {
            //console.log("AppComponent.constructor() initializing messageWrapperService/_app=this:", messageWrapperService, this);
            this.benutzerBehaviourSubject = new BehaviorSubject<IBenutzer>(null);
            messageWrapperService._app = this;
            authService._app = this;

            if(window.navigator.userAgent.indexOf('DLPApp_') >= 0) {
              console.log("AppMain: inAppMode since of userAgent! -> no titleBar etc...");
              this.inAppMode = true;

              let this_writeAppDebugLog = this.writeAppDebugLog;
              console.error = function error(arg1, arg2, arg3, arg4, arg5, arg6, arg7, arg8, arg9) {
                let msg = "console.error: " + arg1;
                if(arg2 != undefined) msg+=" / "+arg2;
                if(arg3 != undefined) msg+=" / "+arg3;
                if(arg4 != undefined) msg+=" / "+arg4;
                if(arg5 != undefined) msg+=" / "+arg5;
                if(arg6 != undefined) msg+=" / "+arg6;
                if(arg7 != undefined) msg+=" / "+arg7;
                if(arg8 != undefined) msg+=" / "+arg8;
                if(arg9 != undefined) msg+=" / "+arg9;
                this_writeAppDebugLog(msg, 0);
              }

              setTimeout(() => {
                this.writeAppDebugLog("window.navigator.userAgent: "+window.navigator.userAgent, 0);
              }, 1000);	
            }

            window['___DLPAppComponent'] = this;

            this._systemService.getSettings().subscribe(settings => {
              if(settings != null) {
                console.log("App.constructor() got settings:", settings);
                this.mussFeldMarkerIconClass = settings.mussFeldMarkerIconClass;
              }
            });
                    
            this.restoreLoginDataAtStartupThenInitialize();

            /*this.benutzerService.getBenutzerCollection(1, 0, "").subscribe(response => {
                console.log("AppComponent.constructor() getBenutzerCollection() response=", response);
                this.benutzer = response.benutzer[0];
              }, error => {
                console.error("AppComponent.constructor() getBenutzerCollection() error=", error);
              });

            let thisInstance = this;
            this.leistungService.getLeistungenCollection(1, 0, '').subscribe(function (response) {
                console.log("app.constructor() response from getLeistungenCollection. response:", response);
                thisInstance.leistungen = response.leistungen;
              }, function (error) { 
                console.error("app.constructor() error from getLeistungenCollection. error:", error);
                return this.handleError(error); 
              });*/
          
        }

    ngOnInit() {
        this.primengConfig.ripple = true;

        let DE: any = { 
          firstDayOfWeek: 1,
          dayNames: ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"],
          dayNamesShort: ["Son", "Mon", "Din", "Mit", "Don", "Fre", "Sam"],
          dayNamesMin: ["So","Mo","Di","Mi","Do","Fr","Sa"],
          monthNames: [ "Januar","Februar","März","April","Mai","Juni","Juli","August","September","Oktober","November","Dezember" ],
          monthNamesShort: [ "Jan", "Feb", "Mär", "Apr", "Mai", "Jun","Jul", "Aug", "Sep", "Okt", "Nov", "Dez" ],
          today: 'Heute',
          clear: 'Clear',
          dateFormat: 'dd/mm/yy',
          weekHeader: 'Wk'
        };

        this.primengConfig.setTranslation(DE);
    }

    getAllBenutzerBasicDataOnly() {
      this.benutzerService.getBenutzerCollectionBasicDataOnly(1, 0, '').subscribe(response => {
        console.log("App.getAllBenutzerBasicDataOnly() response:", response);
        this.benutzerAlleBasicDataOnly = response.benutzer;
      }, error => {
        console.error("App.getAllBenutzerBasicDataOnly() error:", error);
        ///*return*/ this.handleError(error); 
      });
    }

    loginFromApp(loginResponse: any) { // wird aus der App gerufen
      console.log("Login.loginFromApp() loginResponse:", loginResponse);
      localStorage.setItem('JWT_TOKEN', loginResponse['jwt']);
      localStorage.setItem('REFRESH_TOKEN', loginResponse['refreshToken']);
      localStorage.setItem('BENUTZER', JSON.stringify(loginResponse['benutzer']));
      this.restoreLoginDataAtStartupThenInitialize('loginFromAppCompleted'); // über Console/über JS wäre das: ng.getComponent($0).restoreLoginDataAtStartupThenInitialize();  bzw. window.___DLPAppComponent.loginFromApp(...);
    }

    public restoreLoginDataAtStartupThenInitialize(eventToSendBack?: string) {
        //let thisInstance = this;
        this.authService.checkAuthTestController().subscribe(response => {
          console.log("App.restoreLoginData() response.authTest:", response.authTest['authTest']);
          let authTestResult = response.authTest['authTest'];
          if(authTestResult == "success") {
            //this._benutzer = this.authService.getBenutzer();
            let benutzer = this.authService.getBenutzer();
            // dienstleister dazulesen
            this.dienstleisterService.getDienstleister(benutzer.dienstleisterId).subscribe(response => {
              console.log("app.restoreLoginDataAtStartupThenInitialize() got benutzer.dienstleister:", response);
              benutzer.dienstleister = response;

              // Aus Dienstleister auch den aktuellen User nehmen, weil der aktueller ist als aus LocalStorage!
              // evtl. wurde er inzw. geändert
              let benutzerAusDienstleister = response.benutzer.find(f => f.id == benutzer.id);
              if(benutzerAusDienstleister != null) {
                benutzer = benutzerAusDienstleister;
                benutzer.dienstleister = response;
              }

              // Herkunft der Benutzer-Info mitgeben:
              //let benutzerClone = cloneDeep(benutzer);
              //benutzerClone['___comesFrom'] = 'restoreLoginData';
              console.error("app.restoreLoginDataAtStartupThenInitialize() benutzer:", /*benutzerClone*/benutzer);
              console.error("app.restoreLoginDataAtStartupThenInitialize() benutzer.filterung:", /*benutzerClone*/benutzer.filterung);
              this.benutzerBehaviourSubject.next(/*benutzerClone*/benutzer);

              this.initialize(eventToSendBack);
            }, error => {
              console.error("app.restoreLoginDataAtStartupThenInitialize() error getting benutzer.dienstleister: error:", error);
              //return this.handleError(error); 
            });
          }
          else {
            //thisInstance.benutzer = null; // macht bereits der TokenInterceptor bei failed RefreshToken()
            //thisInstance.authService.removeTokens(); // macht bereits der TokenInterceptor bei failed RefreshToken()
            console.log("App.restoreLoginData() failed -> route to login (!=success)");
            this.router.navigate(['/login']);
          }
        }, error => {
          console.log("App.restoreLoginData() failed -> route to login (error), error:", error);

          let beginOfRelativePath = window.location.href.indexOf("#");
          let returnUrl = window.location.href.substring(beginOfRelativePath + ("#/").length);
          console.log("App.restoreLoginDataAtStartup() function error: route to /login with returnUrl:", returnUrl);

          // warum auch immer kommt er bei Einstieg über   http://localhost:4600/#/artikel-ansicht/2   2mal hier rein! (Test aus Inaktiv-Mail in Edge, wo noch nicht angemeldet), daher
          // wenn bereits ein "login?returnUrl=" enthalten, das rausscheiden
          if(returnUrl.indexOf("returnUrl=login%3FreturnUrl%3D")) {
            console.log("App.restoreLoginDataAtStartup cutting/replacing returnUrl from: ", returnUrl);
            returnUrl = returnUrl.replace("login?returnUrl=", "");
            returnUrl = returnUrl.replace("%2F", "/");
            console.log("App.restoreLoginDataAtStartup cutted/replaced returnUrl to   : ", returnUrl);
          }
          // DLP returnUrl=login ausfiltern
          if(returnUrl == "login") {
            console.log("App.restoreLoginDataAtStartup cutting/replacing 'login' from : ", returnUrl);
            returnUrl = null;
            console.log("App.restoreLoginDataAtStartup cutted/replaced 'login' from returnUrl to : ", returnUrl);
          }

         
          this.authService.removeTokens(); // MODI: vor dem Umleiten nach /login - noch den localStorage clearen

          this.router.navigate(['/login'], {queryParams: { returnUrl: returnUrl }} );
          if(error.status != 401 && error.status != 403) {
            return this.handleError(error); 
          }
          else {
            //
          }
        });
      }

    initialize(eventToSendBack?: string) {
        console.log("app.initialize()");
        this.leistungService.getLeistungenCollection(1, 0, '').subscribe(response => {
            console.log("app.initialize() response from getLeistungenCollection. response:", response);
            this.leistungen = response.leistungen;
            this.initialize_pt2(eventToSendBack);
          }, error => {
            console.error("app.initialize() error from getLeistungenCollection. error:", error);
            return this.handleError(error); 
          });
        
          this.getAllBenutzerBasicDataOnly();
    }

    initialize_pt2(eventToSendBack?: string) {
      console.log("app.initialize()");
      this.dienstleisterService.getDienstleisterCollection(1, 0, '').subscribe(response => {
          console.log("app.initialize() response from getDienstleisterCollection. response:", response);
          this.dienstleister = response.dienstleister;

          // Falls SuperAdmin (Admin ohne Zuordnung): gespeicherte Dienstleister-Auswahl restoren
          let storedDL = localStorage.getItem("SELECTED_DIENSTLEISTER");
          if(storedDL != null) {
          //if(this.topbarComponent.isDienstleisterAuswahlAvailable()) {  
            this.filterDienstleisterSelected = [];
            let storedDLSplitted = storedDL.split(',');
            storedDLSplitted.forEach(dlString => {
              //let newDL = this.dienstleisterService.initializeDienstleister();
              let newDL = this.dienstleister.filter(f => ""+f.id == dlString);
              if(newDL != null && newDL.length == 1) {
                this.filterDienstleisterSelected.push(newDL[0]);
              }
            });
          //}
            console.log("app.initialize() stored Dienstleister restored! refresh! filterDienstleisterSelected:", this.filterDienstleisterSelected);
            setTimeout(() => {
              // wenn man auf einer MapComponent neu lädt (F5) dann kam mit ziemlicher Sicherheit dieses Bestücken von filterDienstleisterSelected zu spät (map ist schon initialisiert!) - Daher:
              // Map refreshen
              if(this.mapComponent != null) { 
                this.mapComponent.refreshAuftraegeMarker();
              }
            }, 500);	

          }

          if(eventToSendBack != null) {
            let eventData = {
              eventType: eventToSendBack,
              data: "NOT_IN_USE"
            };
            this.sendMessageToDotNet(eventData);
          }

        }, error => {
          console.error("app.initialize() error from getDienstleisterCollection. error:", error);
          return this.handleError(error); 
      });
    }

    /*waitForBenutzerThen(callBackInstance: any, callBackFunction: string) {
        if(this.benutzer == null) {
          console.log("App.waitForBenutzerThen() not ready yet - retrying ...");
          setTimeout(() => {
            this.waitForBenutzerThen(callBackInstance, callBackFunction);
          }, 250);	
        }
        else {
            callBackInstance[callBackFunction]();
        }
    }*/

    startRoutenplanung() {
        console.log("App.startRoutenplanung()");

        if(this.auftraegeRoutenplanung == null || this.auftraegeRoutenplanung.length == 0) {
            this.messageService.add({key:'app.main', severity:'error', summary:'Route', detail:'Sie haben noch keine Aufträge in die Routenplanung aufgenommen.'});
            return;
        }

        //https://developers.google.com/maps/documentation/urls/get-started

        //let url = 'https://maps.openrouteservice.org/#/directions/Ilsfeld,BW,Deutschland/Lehrensteinsfeld,BW,Deutschland/Robert-Bosch-Straße,Tamm,BW,Deutschland/Danziger Straße,Steinheim an der Murr,BW,Deutschland/data/{"coordinates":"9.244986,49.055805;9.321176,49.128699;9.136791,48.939857;9.274867,48.970941","options":{"zoom":11,"profile":"driving-car","preference":"recommended"}}';
        
        let url = 'https://maps.openrouteservice.org/#/directions/$POINTS_TEXT$/data/{"coordinates":"$POINTS_COORDINATES$","options":{"zoom":11,"profile":"driving-car","preference":"recommended"}}';
        let pointsText = "";
        let pointsCoords = "";

        this.auftraegeRoutenplanung.forEach(auftrag => {
            if(pointsText.length > 0) {
                pointsText += '/';
            }
            //let pointAdress = auftrag.strasseKunde+","+auftrag.ortKunde+","+"BW"+","+"Deutschland"; // TODO: BW ? Deutschland ?
            let pointAdress = auftrag.name_1Behaelterstandort+","+auftrag.strasseBehaelterstandort+","+auftrag.ortBehaelterstandort;
            pointAdress = pointAdress.replace(/\//g, ' '); // TODO: "3/1" -> "3 1"

            pointsText += pointAdress;

            if(pointsCoords.length > 0) {
                pointsCoords += ';';
            }
            pointsCoords += (auftrag.geodaten_Laenge+","+auftrag.geodaten_Breite);
        });

        // Falls wir den aktuellen Standort vom Browser bekommen: den als 1. Punkt einstellen
        if(this.ownLocation_Long != null && this.ownLocation_Latt != null) {
            pointsText = "Mein Standort" + "/" + pointsText;
            pointsCoords = "" + this.ownLocation_Long + "," + this.ownLocation_Latt + ";" + pointsCoords;
        }

        url = url.replace("$POINTS_TEXT$", pointsText);
        url = url.replace("$POINTS_COORDINATES$", pointsCoords);

        console.log("App.startRoutenplanung_pt2() url:", url);

        let urlEncoded = encodeURI(url);
        console.log("App.startRoutenplanung_pt2() urlEncoded:", urlEncoded);

        window.open(urlEncoded, "DLP_Routenplanung");
    }

    dispositionStart(auftragId?: number) {
      console.log("App.dispositionStart()");

      // Modus: alle markierten ? dann prüfen:
      if(auftragId == null) {
        if(this.auftraegeRoutenplanung == null || this.auftraegeRoutenplanung.length == 0) {
          this.messageService.add({key:'app.main', severity:'error', summary:'Disposition', detail:'Sie haben noch keine Aufträge markiert.'});
          return;
        }

        let auftragMitAnderemDL = this.auftraegeRoutenplanung.find(f => f.dienstleisterId != this.auftraegeRoutenplanung[0].dienstleisterId);
        if(auftragMitAnderemDL != null) {
          this.messageService.add({key:'app.main', severity:'error', summary:'Disposition', detail:'Sie haben Aufträge von verschiedenen Dienstleistern markiert.'});
          return;
        }
      }

      this.dispositionDatum = null;
      this.dispositionBenutzer = null;
      this.dispositionBenutzerOptions = [];
      this.dispositionAuftraege = null;
      if(auftragId != null) { // einzelnen Auftrag prüfen: array mit nur diesem Auftrag bereitstellen:
        this.dispositionAuftraege = this.mapComponent.auftraege.filter(f => f.id == auftragId);
      }
      else { // alle markierten Aufträge prüfen: array mit allen markierten bereitstellen:
        this.dispositionAuftraege = this.auftraegeRoutenplanung;
      }

      this.benutzerAlleBasicDataOnly.filter(f => f.dienstleisterId == this./*auftraegeRoutenplanung*/dispositionAuftraege[0].dienstleisterId).forEach(benutzer => {
        this.dispositionBenutzerOptions.push({
          id: benutzer.id,
          value: benutzer.id,
          bezeichnung: benutzer.vorname+" "+benutzer.name
        });
      });
      this.dispositionShowDialog = true;
    }

    dispositionDatumChanged() {
      console.log("App.dispositionDatumChanged()");
      if(this.dispositionDatum != null && this.dispositionDatum < new Date()) {
        this.messageService.add({key:'app.main', severity:'warn', summary:'Disposition', detail:'Warnung Das Datum liegt in der Vergangenheit.'});
        return;
      }
    }

    dispositionSpeichern() {
      console.log("App.dispositionSpeichern()");
      
      let year = moment(this.dispositionDatum).year();
      let month = moment(this.dispositionDatum).month() + 1;
      let day = moment(this.dispositionDatum).date(); // day() ist day of week
      let dispodatum = this.dispositionDatum != null ? new Date(Date.UTC(year, month - 1, day, 0, 0, 0, 0)) : null;

      this.dispositionSpeichern_pt2(dispodatum);
    }
    dispositionSpeichern_pt2(dispodatum: Date) {
      let auftraegeInArr: number[] = [];
      this.dispositionAuftraege.forEach(auftrag => {
        auftraegeInArr.push(auftrag.id);
      });
      this.auftragsdatenService.updateAuftragsdatenForDisposition({
        dispodatum: dispodatum,
        benutzerId: this.dispositionBenutzer != null ? this.dispositionBenutzer.id : null,
        auftraege: auftraegeInArr
      }).subscribe(response => {
        console.log("App.dispositionSpeichern_pt2() response:", response);
        this.dispositionSpeichern_pt3(dispodatum);
      }, error => {
        console.error("App.dispositionSpeichern_pt2() error:", error);
        ///*return*/ this.handleError(error); 
      });
    }
    dispositionSpeichern_pt3(dispodatum: Date) {
      this./*auftraegeRoutenplanung*/dispositionAuftraege.forEach(auftrag => {
        auftrag.dispodatum = dispodatum;
        auftrag.benutzerId = this.dispositionBenutzer != null ? this.dispositionBenutzer.id : null;
        // auch die AuftraegeListComp updaten  (überflüssig, obiger code reicht!)
        /*if(this.mapComponent != null && this.mapComponent.auftragsdatenList != null) {
          let auftrageFoundInAuftraegeListComponent = this.mapComponent.auftragsdatenList.crudItems.find(f => f.id == auftrag.id);
          if(auftrageFoundInAuftraegeListComponent != null) {
            auftrageFoundInAuftraegeListComponent.dispodatum = this.dispositionDatum;
          }
        }*/
        if(this.mapComponent != null) {
          let leafletMarkerIcon = window['___lastclickedMarker'];
          let ueberfaelligJahr = this.mapComponent.isAuftragUeberfaelligJahr(auftrag);
          let ueberfaelligTage = this.mapComponent.isAuftragUeberfaelligTage(auftrag);
      
          // sameLocationCounter nicht zählen, sondern den beim Marker gespeicherten Wert nehmen. Weil: wenn gerade spiderfied ist ja immer 0! -> soll 0 bleiben!
          //let sameLocationCounter = this.countAuftragSameLocation(found); 
          let sameLocationCounter = 0;
          let existingMarker = auftrag['___marker'];  
          if(existingMarker != null && existingMarker != undefined) {
            sameLocationCounter = existingMarker['___sameLocationCounter'];
          }
          let markerIcon = this.mapComponent.getMarkerIcon(auftrag, ueberfaelligJahr, ueberfaelligTage, sameLocationCounter);
          existingMarker.setIcon(markerIcon);
        }
      });
      this.dispositionShowDialog = false;
    }

    /*getBenutzer() {
        return this.benutzer;
    }*/

    logout() {
        console.log("App.logout()");
        this.authService.doLogoutUser();
        this.router.navigate(['/login'])     
            .then(() => {
                window.location.reload(); // anschliessend neu laden, damit auch topBar neu initialisiert wird
        });
    }

    messageServiceAdd(message: Message) {
        this.messageService.add(message);      
    }

    setPageTitle(title) {
    /*    this.pageTitle = title;
        let eventData = {
          eventType: "title",
          data: title
        };
        this.invokeXamarinEvent(eventData);
        */
       // TODO
      }

    switchAppDebugLog() {
      console.log("App.switchAppDebugLog()");
      //this.writeAppDebugLog(new Date());
      this.appDebugShowLog = this.appDebugShowLog == true ? false : true;

      // map-mode anzeigen
      //if(this.mapComponent != null) {
      //  this.writeAppDebugLog("map mode: "+this.mapComponent.mode);
      //}
    }

    writeAppDebugLog(entry, retryCounter: number) {
      let dbgDiv = document.getElementById("inAppDebugDiv");
      if(dbgDiv != undefined && dbgDiv != null) {
        //if(this.appDebugShowLog != true) this.appDebugShowLog = true;
        dbgDiv.innerHTML += entry+"<br>";
        setTimeout(() => {
          dbgDiv.scrollTop = dbgDiv.scrollHeight;
        }, 250);
      }
      else {
        retryCounter ++;
        if(retryCounter < 10) { // bis zu 10 mal retry
          setTimeout(() => {
            this.writeAppDebugLog(entry, retryCounter);
          }, 1000);
        }
        else {
          alert("writeAppDebugLog (logDiv unavailable):"+entry);
        }
      }
    }

    uploadFileFromApp(funktion: string, bereich: string, dateiname: string, objectURL: string) {
      console.log("Login.uploadFileFromApp() funktion/bereich/dateiname:", funktion, bereich, dateiname);
      try {
        if(funktion == 'auftragPos') {
          this.auftragComponent.uploadFromCameraNative(bereich, dateiname, objectURL);
        }
        else {
          console.log("Login.uploadFileFromApp() Error: funktion nicht bekannt!");
        }
      }
      catch(e) {
        debugger;
        console.log("AuftragsChecklisteDetailComponent.ngOnDestroy() error:", e);
      }
    }

    public setfilterBenutzerNurMeine() {
      console.log("AuftragsChecklisteDetailComponent.setfilterBenutzerNurMeine()");
      this.filterBenutzerNurMeine = true;
      this.filterBenutzerPresetsSelected = {
          id: 2, nurMeine: true, summary: 'nur Meine' 
      };
    }

    // iOS 17.4.1 workaround: benötigt in gewissen Situationen ein Resize auf den Webview, damit alles neu gerendert wird (sonst zwar meist optisch ok, aber Felder nicht betretbar)
    public iOSFixResizeWebview(debugInfo) {
      if(window.navigator.userAgent.indexOf('DLPApp_iOS') >= 0) {
        if(this.iOSFixResizeWebviewTimeoutId != null) { // ggf. bestehenden Timeout clearen
          console.log("app.iOSFixResizeWebview("+debugInfo+") "+new Date().toISOString()+" clearing existing timeout...");
          clearTimeout(this.iOSFixResizeWebviewTimeoutId);
        }
        // neuen Timeout einplanen
        console.log("app.iOSFixResizeWebview("+debugInfo+") "+new Date().toISOString()+" plan new timeout...");
        this.iOSFixResizeWebviewTimeoutId = setTimeout(() => {
          console.log("app.iOSFixResizeWebview("+debugInfo+") "+new Date().toISOString()+" fire event...");
          let eventData = {
            eventType: "resizeWebview",
            data: null
          };
          this.sendMessageToDotNet(eventData);
        }, 200);		
      }
      else {
        console.log("app.iOSFixResizeWebview("+debugInfo+") "+new Date().toISOString()+" not running in iOS-App -> ignore.");
      }
    }

    //public sendMessageToDotNetMessageType0(/*messageType, */messageContent) { // messageType 0 = "raw"
    //  var message = JSON.stringify({ "MessageType": /*messageType*/0, "MessageContent": messageContent });

    //  if (window['chrome'] && window['chrome'].webview) { // Windows WebView2
    //      window['chrome'].webview.postMessage(message);
    //  }
    //  else if (window['webkit'] && window['webkit'].messageHandlers && window['webkit'].messageHandlers.webwindowinterop) { // iOS and MacCatalyst WKWebView
    //      window['webkit'].messageHandlers.webwindowinterop.postMessage(message);
    //  }
    //  else { // Android WebView
    //      hybridWebViewHost.sendMessage(message);
    //  }
    //}

    public sendMessageToDotNet(message) {
      setTimeout(() => {
        try {
          //let messageForDotNet = JSON.stringify({ "MessageType": 0, "MessageContent": message });
          //let messageContentForDotNet = JSON.stringify({ message });
          let messageContentForDotNet = JSON.stringify({ eventType: message.eventType, data: message.data });
          let messageForDotNetAsObj = { "MessageType": 0, "MessageContent": messageContentForDotNet };
          let messageForDotNet = JSON.stringify(messageForDotNetAsObj);
          console.log("App.sendMessageToDotNet() messageForDotNet:", messageForDotNet);

          if (window['chrome'] && window['chrome'].webview) { // Windows WebView2
            window['chrome'].webview.postMessage(messageForDotNet);
          }
          else if (window['webkit'] && window['webkit'].messageHandlers && window['webkit'].messageHandlers.webwindowinterop) { // iOS and MacCatalyst WKWebView
            window['webkit'].messageHandlers.webwindowinterop.postMessage(messageForDotNet);
          }
          else { // Android WebView
            hybridWebViewHost.sendMessage(messageForDotNet);
          }
        }
        catch(e) {
          console.error("error during sendMessageToDotNet(): "+e);
        }
      }, 1);	
    }

    /*private*/protected handleError(error) {
    console.log("App.handleError error: ", error);
    let errorMessage: string = null;
    if(error.status == 401 || error.status == 403) errorMessage = "Nicht authorisiert." + (error.error != null ? " " + error.error : "");
    else {
      if(error.message != null) errorMessage = error.message;
      //else if(error.error != null && error.error typeof String) errorMessage = error.error;
      else errorMessage = "unbekannter Fehler";
    }

    // MODI gg. zB IMKE: Fehler nur als Message anzeigen, wenn eingeloggt. Eingeloggt erkennen wir daran, dass localStorage BENUTZER != null (aber erst nach timeout, weil die auth-methoden, das ja erst löschen müssen)
    setTimeout(() => {
      let localStorageKeyBENUTZER = 'BENUTZER';
      let localStorageValueBENUTZER = localStorage.getItem(localStorageKeyBENUTZER);
      if(localStorageValueBENUTZER == null) {
        console.error("App.handleError App: Fehler: ", errorMessage);
      }
      else {
        this.messageWrapperService.postStaticMessage({severity: 'error', summary: 'App: Fehler: ', detail: errorMessage}); 
      }
    }, 250);
  }

}
