import { /*CRUDBasicDetailComponent_Template, */CRUDBasicDetailComponent } from '../crud-basic-detail/crud-basic-detail.component';

import { Component, OnInit, Inject, forwardRef, Injector, ViewChild, Input } from '@angular/core';
import { Validators, FormControl, FormGroup, FormBuilder, AbstractControl, FormControlName } from '@angular/forms';
import { AppComponent } from '../app.component';
import { TranslateService } from '../_services/translate.service';
import { GenericValidator } from '../_helpers/generic-validator';
//import { AutoComplete } from 'primeng/primeng';

//import { ILeistung } from '../_interfaces/leistung';
import { LeistungService } from '../_services/leistung.service';
import { LeistungDetailGuard } from './leistung-detail.guard';

//import { LeistungDetailComponent_Template } from './leistung-detail.component.include_template';
//import * as cloneDeep from 'lodash/cloneDeep';

@Component({
  selector: 'app-leistung-detail',
  //template: `${CRUDBasicDetailComponent_Template || ''}${LeistungDetailComponent_Template}`,
  templateUrl: './leistung-detail.component.html',
  styleUrls: ['../crud-basic-detail/crud-basic-detail.component.css'],
  host: { '(window:keydown)': 'hotkeys($event)' }
})
export class LeistungDetailComponent extends CRUDBasicDetailComponent implements OnInit {
  // CHILD-spezifisch: Konstanten - START
  CRUDItemKurzform: string = "Leistung";
  CRUDPageTitleNeu: string = this.translate.instant("Neue Leistung", true);
  CRUDPageTitleBearbeiten: string = this.translate.instant("Leistung bearbeiten", true);
  CRUDItemBezeichnungSingularCapitalized: string = "Leistung";
  CRUDItemBezeichnungPluralCapitalized: string = "Leistungen";
  CRUDItemBezeichnungSingular: string = "leistung";
  CRUDItemBezeichnungPlural: string = "leistungen";
  CRUDItemRouteSingular: string = "leistung";
  CRUDItemRoutePlural: string = "leistungen";
  CRUDItemHelpTopic: string = null;

  CRUDHideModified: boolean = true;
  //CRUDMethodNameGet: string = 'getaspnet_RoleForSettings';
  //CRUDMethodNameSave: string = 'saveaspnet_RoleForSettings';
  //CRUDMethodNameDelete: string = 'deleteaspnet_RoleForSettings';
  // CHILD-spezifisch: Konstanten - Ende

  // CHILD-spezifisch: zusätzliche Widgets (ausser Standard Inputs, Autocomplete) - START

  checklisteValueChangeSubscriptionActive = true;

  // CHILD-spezifisch: zusätzliche Widgets (ausser Standard Inputs, Autocomplete) - ENDE

  constructor(
    @Inject( forwardRef(() => AppComponent)) public _app: AppComponent,
    private _injector: Injector,
    private _translate: TranslateService,
    private _crudItemService: LeistungService,
    private _guard: LeistungDetailGuard,

    // CHILD-spezifisch: zusätzliche Services - START
    // CHILD-spezifisch: zusätzliche Services - ENDE
  ) {
    super(_app, _injector);

    // ACHTUNG: id / dataId (=leistung) ist in dieser Entity nicht number, sondern string:
    //delete this['dataId'];
    //let helperDataId='dataId';
    //this[helperDataId] = '';
    delete this.dataId;
    let subRoles: /*Subscription*/any;
    subRoles = this.route.params.subscribe(
      params => {
        this.dataId = params['id'];
      }
    )
    console.log("LeistungDetailComponent.constructor() overwriting dataId with string-value: dataId:", this.dataId);

    this.crudItemService = _crudItemService;
    this.guard = _guard;

    // CHILD-spezifisch die Validator Messages bestücken - START
    this.validationMessages =
    {
      leistung: {
        required: this._translate.instant('Leistung', true) + ': ' + this._translate.instant('ist erforderlich', true),
        maxlength: this._translate.instant('Leistung', true) + ' ' + this._translate.instant('darf 255 Zeichen nicht überschreiten', true)
      },

      typ: {
        required: this._translate.instant('Typ', true) + ': ' + this._translate.instant('ist erforderlich', true),
        maxlength: this._translate.instant('Typ', true) + ' ' + this._translate.instant('darf 255 Zeichen nicht überschreiten', true)
      },

      bezeichnung: {
        required: this._translate.instant('Bezeichnung', true) + ': ' + this._translate.instant('ist erforderlich', true),
        maxlength: this._translate.instant('Bezeichnung', true) + ' ' + this._translate.instant('darf 255 Zeichen nicht überschreiten', true)
      },

      icon: {
        required: this._translate.instant('Icon', true) + ': ' + this._translate.instant('ist erforderlich', true),
        maxlength: this._translate.instant('Icon', true) + ' ' + this._translate.instant('darf 255 Zeichen nicht überschreiten', true)
      },

      checkliste: {
        required: this._translate.instant('Checkliste', true) + ': ' + this._translate.instant('ist erforderlich', true),
      },

      geringfuegiger_Mangel: {
        required: this._translate.instant('Geringfügiger Mangel', true) + ': ' + this._translate.instant('ist erforderlich', true),
      },

      erheblicher_Mangel: {
        required: this._translate.instant('Erheblicher Mangel', true) + ': ' + this._translate.instant('ist erforderlich', true),
      },

};
    // CHILD-spezifisch die Validator Messages bestücken - ENDE
    
    this.genericValidator = new GenericValidator(this.validationMessages);

    this.buildForm();
  }

  buildForm() {
    // CHILD-spezifisch die Form aufbauen - START
    this.CRUDForm = this.fb.group({
      leistung: ['', [Validators.required, Validators.maxLength(255)]],
      typ: ['', [Validators.required, Validators.maxLength(255)]],
      bezeichnung: ['', [Validators.required, Validators.maxLength(255)]],
      icon: ['', [Validators.required, Validators.maxLength(255)]],
      checkliste: [null, [Validators.required]],
      geringfuegiger_Mangel: ['', [Validators.required, Validators.maxLength(255)]],
      erheblicher_Mangel: ['', [Validators.required, Validators.maxLength(255)]],
      pumpentyp: [false],
      anstrich: [false],
      unterschriftKunde: [false],
      pruefergebnis: [false],
    },{
      validator: this.formValidator // auf FormEbene!
    });

    // value-change-handler! // https://www.tektutorialshub.com/angular/valuechanges-in-angular-forms/#valuechanges-of-formcontrol
    this.CRUDForm.get("checkliste").valueChanges.subscribe(newValue => {
      console.log("LeistungDetailComponent valueChange checkliste: newValue:", newValue);
      //console.log("LeistungDetailComponent valueChange checkliste: .get():", this.CRUDForm.get("checkliste").value); // entspr. new Value
      console.log("LeistungDetailComponent valueChange checkliste: .value:", this.CRUDForm.value.checkliste); // enthält OLD Value (sofern nicht innerhalb timeout())
      console.log("LeistungDetailComponent valueChange checkliste: dataId:", this.dataId);
      let oldValue = this.CRUDForm.value.checkliste;
      
      if(this.checklisteValueChangeSubscriptionActive == true && this.dataId != '0') {
        if((newValue != null ? newValue.id : 0) != (oldValue != null ? oldValue.id : 0)) {
          this.messageWrapperService.postTimedMessage({ severity: 'warn', summary: "Checkliste", detail: "Das nachträgliche Ändern der Checkliste kann zu unerwünschtem Ergebnis bei Aufträgen führen, die begonnen, aber noch nicht abgeschlossen sind!" }); 
        }
      }
    })
    // CHILD-spezifisch die Form aufbauen - ENDE
  }

  ngOnInit() {
    this.blockedDocument = true;

    // CHILD-spezifische Zusätze, z.B. Nachladen anderer Entities - START
    // CHILD-spezifische Zusätze, z.B. Nachladen anderer Entities - ENDE

    super.ngOnInit();
  }

  ngAfterViewInit() { 
    super.ngAfterViewInit();
    if(this.dataId === '0') this.pageTitle = this.CRUDPageTitleNeu; // funktioniert an dieser Stelle nicht ohne, obwohl das CRUDBasicDetail eigentlich schon machen müsste. Funkt aber vermutlich aufgrund "Leistung hat keine id" nicht ?
  }

  onCRUDItemRetrieved(CRUDItem: any): void {
    // ACHTUNG: leistungen hat kein id, sondern leistung (String). Daher künstlich "id" erzeugen - für Standard-Programm-Logik (z.B. auch Refresh List)
    CRUDItem.id = CRUDItem.leistung;
    super.onCRUDItemRetrieved(CRUDItem);
  }

  saveCRUDItem(a: any, close: boolean) {
    // ACHTUNG: leistungen hat kein id, sondern leistung (String). Daher getCRUDItem_pt2() und list.refreshRow() nicht mit Parameter id aufrufen, sondern mit "leistung"
    //          Ansonsten ist die Routine 1:1 identisch wie mit CRUDBasicDetailComponent

    //this.crudItemService.saveCRUDItem(a)
    let methodName = this.CRUDMethodNameSave != null ? this.CRUDMethodNameSave : 'save' + this.CRUDItemBezeichnungSingularCapitalized;
    if (this.debugMode == true) console.log("LeistungDetailComponent.saveCRUDItem() method to call:crudItemService:", methodName);
    this.crudItemService[methodName](a)
      .subscribe(
        (/*titel: ITitel*/CRUDItem: any) => {
          if (this.CRUDSpecialOnlyOneRecord) { // SpezialFall "es gibt nur ein Satz (zB Customizing)" ? - dann kein close und kein zurück zur Liste
            this.onSaveComplete(false); // US 13411 onSaveComplete() muss VOR onCRUDItemRetrieved() laufen, weil onSaveComplete() die Form cleart, und onCRUDItemRetrieved() die Form wieder füllt

            // hier nicht die returnte entity darstellen, sondern nochmal explizit INCLUSIVE INCLUDES lesen
            this.getCRUDItem_pt2(CRUDItem.leistung);

            //this.onSaveComplete(false); // US 13411 onSaveComplete() muss VOR onCRUDItemRetrieved() laufen, weil onSaveComplete() die Form cleart, und onCRUDItemRetrieved() die Form wieder füllt
            this.afterSaveCRUDItem(); // US 13411 
          }
          else { // Default: kein SpezialFall
            this.onSaveComplete(close); // US 13411 onSaveComplete() muss VOR onCRUDItemRetrieved() laufen, weil onSaveComplete() die Form cleart, und onCRUDItemRetrieved() die Form wieder füllt

            if (this.CRUDBasicListComponentInstance != null) {
              this.CRUDBasicListComponentInstance.refreshRow(CRUDItem.leistung);
            }

            if (!close) {
              this.onCRUDItemRetrieved(/*anrede*/CRUDItem);
            }

            //this.onSaveComplete(close); // US 13411 onSaveComplete() muss VOR onCRUDItemRetrieved() laufen, weil onSaveComplete() die Form cleart, und onCRUDItemRetrieved() die Form wieder füllt
            this.afterSaveCRUDItem(); // US 13411 
          }
        },
        (error: any) => {
          console.log("LeistungDetailComponent.saveCRUDItem() error:", error);
          this.blockedDocument = false;
          this.handleError(error);
        }
      );
  }

  getValuesFromForm() {
    let a = super.getValuesFromForm(); // Standard! do not change!

    // CHILD-spezifische assigns, z.B. spracheId aus strache.Id bestücken - START
    //a = cloneDeep(a);
    this.checklisteValueChangeSubscriptionActive = false; // nach dem Save keine Warnung mehr!

    // MODI: im Gegensatz zu IMKE in den Navigations nur Id bestücken, nicht das Objekt! sonst gibt's Probleme beim PUT
        a.checklisteId = a.checkliste != null ? a.checkliste.id : null;
        delete a.checkliste;
    
    // CHILD-spezifische assigns, z.B. spracheId aus strache.Id bestücken - ENDE

    //console.log("LeistungDetailComponent.getValuesFromForm() a:", a);
    return a;
  }

  sendValuesToForm() {
    // CHILD-spezifisch die Form patchen - START
    this.CRUDForm.patchValue({
      leistung: this.CRUDItem.leistung,
      typ: this.CRUDItem.typ,
      bezeichnung: this.CRUDItem.bezeichnung,
      icon: this.CRUDItem.icon,
      geringfuegiger_Mangel: this.CRUDItem.geringfuegiger_Mangel,
      erheblicher_Mangel: this.CRUDItem.erheblicher_Mangel,
      //checkliste: this.CRUDItem.checkliste    // ohne emit, damit der valuechanges-handler nicht anspringt // https://www.tektutorialshub.com/angular/valuechanges-in-angular-forms/#valuechanges-of-formcontrol
      pumpentyp: this.CRUDItem.pumpentyp,
      anstrich: this.CRUDItem.anstrich,
      unterschriftKunde: this.CRUDItem.unterschriftKunde,
      pruefergebnis: this.CRUDItem.pruefergebnis,
    });
    this.CRUDForm.get("checkliste").setValue(this.CRUDItem.checkliste, { emitEvent: false }); // ohne emit, damit der valuechanges-handler nicht anspringt // https://www.tektutorialshub.com/angular/valuechanges-in-angular-forms/#valuechanges-of-formcontrol
    
    // CHILD-spezifisch die Form patchen - ENDE
    //console.log("LeistungDetailComponent.sendValuesToForm() CRUDForm:", this.CRUDForm);
    super.sendValuesToForm(); // haben nicht alle DetailComponents - erst ab Ticket 9412 17.07.2019
  }

  formValidator(form: FormGroup) { // validate auf FORM-Ebene!
    let errors : any[] = [];
    let errorsFound : boolean = false;

    //console.log("LeistungenDetail.formValidator() form:", form);

      if(form.value.leistung == "0") { 
        errors['zahl_0_nicht_zulaessig']=true;
        errorsFound = true;
      }

    //form.setErrors(errorsFound ? errors : null);
    if(errorsFound) return errors;
    else return null;
  }

  getCRUDBezeichnung() { // die Bezeichnung ermitteln - um sie z.B. beim Save als "Erfolgreich-Message" auszugeben. - kann von child-Klasse überschrieben werden!
    if (this.CRUDItem != null && this.CRUDItem.bezeichnung != null) return this.CRUDItem.bezeichnung;
    return '';
    //return this.CRUDItem.roleName;
  }


}
