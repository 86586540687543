// lang-en.ts

export const LANG_DE_NAME = 'de';

export const LANG_DE_TRANS = {
    'ERROR_UNSAVED_CHANGES': 'Es gibt ungespeicherte Änderungen auf dieser Seite. Trotzdem fortfahren?',
    'CONFIRM_DELETE_RECORD': 'Wollen Sie diesen Datensatz wirklich löschen?',
    'CONFIRM_PASTE_RECORD': 'Wollen Sie den kopierten Datensatz wirklich einfügen?',
    'zahl_0_nicht_zulaessig': '0 ist nicht zulässig',
    'bitte_dokument_hinterlegen': 'Bitte Dokument hinterlegen',
    'bitte_link_hinterlegen': 'Bitte Link hinterlegen',

    'Die_Passwoerter_stimmen_nicht_ueberein': 'Die Passwörter stimmen nicht überein',
    'Benutzername_ist_erforderlich': 'Benutzername: ist erforderlich',
    'Passwort_ist_erforderlich': 'Passwort: ist erforderlich',
    'Benutzer_ist_bereits_bei_anderem_Ansprechpartner_hinterlegt': 'Benutzer ist bereits bei anderem Ansprechpartner hinterlegt',
    'Benutzername_enthaelt_ungueltige_Zeichen': 'Benutzername enthält ungültige Zeichen',
    'Benutzername_exists': 'Benutzername ist bereits in Verwendung',


};