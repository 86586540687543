import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild, Input, Output, EventEmitter, SimpleChange, SimpleChanges, Inject, forwardRef, Injector } from '@angular/core';
import { ViewContainerRef } from '@angular/core';
import { TranslateService } from '../_services/translate.service';
import { AppComponent } from '../app.component';
import { MessageWrapperService } from '../_services/message-wrapper.service';
//import { GlobalService } from '../_services/global.service';
import { CRUDBasicPipe } from '../_pipes/crud-basic.pipe';

import * as cloneDeep from 'lodash/cloneDeep'; // DateTimeOffset-Fix

import {InputTextModule} from 'primeng/inputtext';

import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

declare var jquery: any;
declare var $: any;

@Component({
  selector: 'crud-basic-input',
  templateUrl: './crud-basic-input.component.html',
  styleUrls: ['./crud-basic-input.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CRUDBasicInputComponent),
      multi: true
    }
  ],
  host: { '(window:keydown)': 'hotkeys($event)' }
})
export class CRUDBasicInputComponent implements AfterViewInit, ControlValueAccessor {
    
  // die Komponente kann direkt formControlName arbeiten:
  //       https://alligator.io/angular/custom-form-control/
  //       https://netbasal.com/angular-custom-form-controls-made-easy-4f963341c8e2
  @Input('value') value: string; 
  @Input('decimals') decimals: number; 
  @Input('quantityUnit') quantityUnit: string; 

  @Input('type') type: string; // default: "numeric"
  @Input('noThousandSeparator') noThousandSeparator: boolean; 
  @Input('leadingZeros') leadingZeros: boolean; // führende Nullen zulassen 
  
  @Input('required') required: boolean;
  @Input('ngStyle') ngStyle: string;
  //@Output('change') change = new EventEmitter();
  @Input('disabled') disabled: boolean = false; 

  debugMode : boolean = false;

  // DE
  komma: string = ",";
  tausenderTrennzeichen: string = "."; 
  // EN
  //komma: string = "."; 
  //tausenderTrennzeichen: string = ","; 
  
  showWriteable: boolean = false;
  editValue: string = null; // hier wird immer , statt . enthalten sein
  lastEditValue: string = null;
  lastEditValueKnown: boolean = true;

  onChange_callbackFunction: any; // bekommt die function von Angular forms - wenn formControlName - die bei Änderung gecallt werden muss.
  onChange_lastEditValue: any; // enthält den jeweils zuletzt rückgemeldeten Wert - als Vergleich/Abfangmechanismus, um nicht unnötig oft zu callen
  onChange_lastEditValueKnown: boolean = false; 
  //onTouch_callbackFunction : any; // bekommt die function von Angular forms - wenn formControlName - die bei Touch gecallt werden muss.

  @ViewChild('inputWriteable', { static: true }) public inputWriteableControl: InputTextModule;
  @ViewChild('inputFormatted', { static: true }) public inputFormattedControl: InputTextModule;

  crudBasicPipe: CRUDBasicPipe;

  parentComponent: Component;

  constructor(
    @Inject(forwardRef(() => AppComponent)) public app: AppComponent,
    private viewContainerRef: ViewContainerRef,
    private injector: Injector,
    private translate: TranslateService,
    //private globalService: GlobalService,
    private messageWrapperService: MessageWrapperService
  ) {
    try {
      this.parentComponent = this.viewContainerRef['_view']['component'];

    }
    catch(e) {
      console.log("CRUDBasicInputComponent.constructor() ERROR finding parentComponent!");  
    }
    //console.log("CRUDBasicInputComponent.constructor() parentComponent:", this.parentComponent);
    //console.log("CRUDBasicInputComponent.constructor() globalService.getUser()", this.globalService.getUser());
    /*try {
      if(this.globalService.getUser().language=='en') {
        this.komma = "."; 
        this.tausenderTrennzeichen = ","; 
      }
    }
    catch(e) {
      //
    }*/

    this.crudBasicPipe = new CRUDBasicPipe(/*this.globalService*/);
  }

  ngAfterViewInit(): void {
    // dafür sorgen, dass die p-autocomplete nicht in ein <span ng_content_xxx> verpackt wird, sonst
    // funktionieren die ganzen Styles nicht! Gelöst durch replace "span" by "child"
    // saubere Lösung könnte sein:
    // "containerless components" - dazu gibt es jede Menge feature-requests bei angular (ging wohl mit AngularJS "replace")
    // :host { display: contents; }   -> funktioniert aber (noch???) nicht, ist ein experimental feature in chrome

    let inputWriteableElement = this.inputWriteableControl['nativeElement'];
    let inputFormattedElement = this.inputFormattedControl['nativeElement'];
    let parentNode = inputWriteableElement.parentNode;
    let parentparentNode = parentNode.parentNode;
    
    //console.log("CRUDBasicInput.ngOnInit() inputWriteableElement:", inputWriteableElement);
    //console.log("CRUDBasicInput.ngOnInit() inputFormattedElement:", inputFormattedElement);
    //console.log("CRUDBasicInput.ngOnInit() parentNode:", parentNode);
    //console.log("CRUDBasicInput.ngOnInit() parentparentNode:", parentparentNode);

    setTimeout(() => {
      //console.log("CRUDBasicInput.switchToWriteable() replacing parentNode...");
  
      while (parentNode.childNodes.length > 0) {
        //console.log("CRUDBasicInput.switchToWriteable() replacing:", parentNode.childNodes[0]);
        let node = parentNode.childNodes[0]
        let isIE = false || !!document['documentMode'];
        if (isIE == true) parentparentNode.insertBefore(parentNode.childNodes[0], parentparentNode.childNodes[0]); // IE!
        else parentparentNode.prepend(parentNode.childNodes[0]); // nicht IE!
      }
      //parentparentNode.remove(parentNode); // warum auch immer, aber damit verschwinden auch die bereits verschobenen childs

      //console.log("CRUDBasicInput.ngOnInit() parentparentNode:", parentparentNode);
    }, 50);

    // Ausserdem: Event Listener:
    let thisInstance = this;
    inputWriteableElement.oninput = inputWriteableElement.onpaste = function(event) {
      //alert(event.type + ' - ' + event.clipboardData.getData('text/plain'));
      thisInstance.onInput(event);
      //return false;
    };
  }

  onKeyPress(event) {
    //console.log("CRUDBasicInput.onKeyPress() event:", event);
    /*
    let keyOK = (event.charCode >= 48 && event.charCode <= 57) // 0-9
         || event.charCode == 0 // laut https://stackoverflow.com/questions/19011861/is-there-a-float-input-type-in-html5 nötig für backspace in firefox
         || event.key == '-'
         || event.key == ','
         ;
    if(!keyOK) return false;
    if(this.lastEditValueKnown) {
      let value = event.srcElement.value;
      if(value == "444") {
        return false;
      }
    }

    this.lastEditValueKnown = true;
    this.lastEditValue = event.srcElement.value;
    */
    return true;
  }

  onInput(event) {
    //console.log("CRUDBasicInput.onInput() event:", event);

    if(this.lastEditValueKnown) {
      let editValue = event.srcElement.value;

      // falls decimals == 0 -> dann soll er erst gar kein decimal-Trennzeichen eingeben!
      if(this.decimals < 1) {
        let punkt = editValue.indexOf(this.komma);
        if(punkt>=0) {
            //console.log("CRUDBasicInput.onInput() validation error: kein decimal, dann auch erst gar kein Trennzeichen!");
            this.editValue = this.lastEditValue;
            this.value = this.komma == ',' ? this.editValue.replace(',', '.') : this.editValue;
            return;
        }
      }

      // prüfen, dass kein . eingegeben (man soll komma eingeben)
      let punkt = editValue.indexOf(this.komma == ',' ? '.' : ',');
      if(punkt>=0) {
          //console.log("CRUDBasicInput.onInput() validation error: punkt statt komma eingegeben");
          this.editValue = this.lastEditValue;
          this.value = this.komma == ',' ? this.editValue.replace(',', '.') : this.editValue;
          return;
      }

      // komma => punkt
      let valueWithCorrectDecimalSeparator = this.komma == ',' ? editValue.replace(',', '.') : editValue;

      // prüfen, ob numerisch
      if(!isNaN(parseFloat(valueWithCorrectDecimalSeparator)) && isFinite(valueWithCorrectDecimalSeparator)) {
        // ok, numeric
      }
      else if(editValue.length == 0) {
        // ok, null
        //console.log("CRUDBasicInput.onInput() validation valid: it's null");
        this.value = null;
        this.editValue = null;
      }
      else {
        if(valueWithCorrectDecimalSeparator != '-') { // wenn nur noch ein - übrig ist, ist das auch ok (sonst kann man ja nicht alles, bis auf das - weglöschen)
          console.log("CRUDBasicInput.onInput() validation error: it's not numeric");
          this.editValue = this.lastEditValue;
          if(this.editValue != null) {
            this.value = this.komma == ',' ? this.editValue.replace(',', '.') : this.editValue;
          }
          else {
            this.value = null;
          }
          return;
        }
      }

      // prüfen, ob zu viele Nachkommastellen ?
      let komma = valueWithCorrectDecimalSeparator.indexOf(this.komma == ',' ? '.' : ',');
      if(komma>=0) {
        let nachkomma = valueWithCorrectDecimalSeparator.length - komma - 1;
        if(nachkomma > this.decimals) {
          //console.log("CRUDBasicInput.onInput() validation error: to many decimals");
          //console.log("CRUDBasicInput.onInput() komma:", komma);
          //console.log("CRUDBasicInput.onInput() nachkomma:", nachkomma);
          this.editValue = this.lastEditValue;
          this.value = this.komma == ',' ? this.editValue.replace(',', '.') : this.editValue;
          return;
        }
      }

    }

    // alles ok, last known value speichern
    this.lastEditValueKnown = true;
    this.lastEditValue = this.editValue;
  }

  onChange() {
    if(this.debugMode) console.log("CRUDBasicInput.onChange() event:", event);
    if(this.debugMode) console.log("CRUDBasicInput.onChange() this.editValue:", this.editValue);
    this.value = this.editValue != null && this.komma == ',' ? this.editValue.replace(',', '.') : this.editValue;
    //console.log("CRUDBasicInput.onChange() this.value:", this.value);
    if (this.value != this.onChange_lastEditValue || this.onChange_lastEditValueKnown == false) { // nur wenn der Wert != dem zuletzt gemeldeten Wert ist (doppel-Rückmeldungen vermeiden! Performance!)

      let callBackValue : any = null;
      callBackValue = this.value;

      //console.log("CRUDBasicInput.onChange() calling callback - value:", callBackValue);
      this.onChange_callbackFunction(callBackValue);
      this.onChange_lastEditValue = this.value;
      this.onChange_lastEditValueKnown = true;
    }
    else {
      //console.log("CRUDBasicInput.onChange() skip, since it's the same option as last time!");
    }
  }

  switchToWriteable() {
    //console.log("CRUDBasicInput.switchToWriteable()");
    this.editValue = this.value;
    if(this.editValue != null) {
      if(this.komma == ',') {
        this.editValue = (''+this.editValue).replace('.', ',');
      }
      else {
        this.editValue = (''+this.editValue);
      }
    }
    this.showWriteable = true;
    let thisInstance = this;
    setTimeout(() => {
      //console.log("CRUDBasicInput.switchToWriteable() inputWriteableControl:", thisInstance.inputWriteableControl);
      thisInstance.inputWriteableControl['nativeElement'].focus();
      thisInstance.inputWriteableControl['nativeElement'].select();
    }, 50);
    //$("#inputWriteable").focus();
  }

  focus() {
    if(this.debugMode) console.log("CRUDBasicInput.focus()");
    // Angenommen es gibt 2 CRUDBasicInput gleichzeitig auf dem Bildschirm - und beide würden sich schon bei OnInit als HotKeyHandler registrieren:
    // Dann würde es in der Liste des registrierten HotKeyHandler aussehen als wäre der 1. die ParentComponent des 2. 
    // Um das zu vermeiden werden Components wie CRUDBasicInput erst bei focus registriert
    //this.globalService.registerHotKeyHandler(this);
  }

  blur() {
    if(this.debugMode) console.log("CRUDBasicInput.blur() editValue:", this.editValue);
    //this.globalService.unRegisterHotKeyHandler(this);
    this.showWriteable = false;
  }

  /* // ausgelagert in CRUDBasicPipe
  getFormatedValue() { 
    if(this.value == null) return null;
    //return (""+this.value).replace(/./g, ",");
    //return (""+this.value).replace(".", ",");
    
    //https://blog.abelotech.com/posts/number-currency-formatting-javascript/
    if(this.komma == ',') {
      return (
        new Number(this.value)
          .toFixed(this.decimals) // always two decimal digits
          .replace('.', ',') // replace decimal point character with ,
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + (this.quantityUnit != null && this.quantityUnit.length>0 ? ' ' + this.quantityUnit : '')
      ) // use . as a separator
    }
    else {
      return (
        new Number(this.value)
          .toFixed(this.decimals) // always two decimal digits
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') + (this.quantityUnit != null && this.quantityUnit.length>0 ? ' ' + this.quantityUnit : '')
      ) 
    }
  }*/

  getFormatedValue() {
    if(this.value == null) return null;
    let formatedValue = this.crudBasicPipe.getFormatedValue(this.value, this.komma, this.quantityUnit, this.decimals, this.leadingZeros);
    if(this.noThousandSeparator != null && this.noThousandSeparator == true) {
      if(this.komma == ",") {
        //formatedValue = formatedValue.replace(/./g, "");
        formatedValue = formatedValue.split('.').join("");
      }
      else {
        //formatedValue = formatedValue.replace(/,/g, "");
        formatedValue = formatedValue.split(',').join("");
      }
    }
    return formatedValue;
  }

  //https://ngdev.space/angular-2-input-property-changes-detection-3ccbf7e366d2
  //ngOnChanges(changes: SimpleChanges) { // monitoring Input-Changes - nur monitoring/debug - ohne weitere Funktion!!! (kann man weglassen!)
  //  console.log("CRUDBasicInput.ngOnChanges():", changes);
  //}

  // aus ControlValueAccessor Interface: patchValue/setValue/new FormControl soll Wert im HTML aktualisieren
  writeValue(obj: any): void {
    //console.log("CRUDBasicInput.writeValue():", obj);
    //this.inputWriteableControl.writeValue(obj);
    this.value = obj;

    this.editValue = obj;
    if(this.editValue != null) {
      if(this.komma == ',') {
        this.editValue = (''+this.editValue).replace('.', ',');
      }
      else {
        this.editValue = (''+this.editValue);
      }
    }

    this.lastEditValue = this.editValue;
    this.lastEditValueKnown = true;
  }

  // Angular forms sendet uns eine Referenz auf eine Funktion, die wir "onChange" aufrufen sollen.
  // die zunächst NUR MERKEN, ggf. rufen wir die (siehe onChange()) auf
  registerOnChange(fn: (rating: number) => void): void {
    // console.log("CRUDBasicInput.registerOnChange() fn:", fn);
    this.onChange_callbackFunction = fn;
  }

  // Angular forms sendet uns eine Referenz auf eine Funktion, die wir "onTouch" aufrufen sollen. 
  registerOnTouched(fn: () => void): void {
    // console.log("CRUDBasicInput.registerOnTouched() fn:", fn);
    // Vermutlich reicht es aus, das 1:1 an p-autoComplete weiterzugeben, da p-autoComplete bereits formControlName unterstützt
    //this.inputWriteableControl.registerOnTouched(fn);
    //this.onTouch_callbackFunction = fn;
  }

  // Angular forms ruft diese Funktion, wenn sich der disabled-Status ändert.
  setDisabledState(isDisabled: boolean): void {
    // console.log("CRUDBasicInput.setDisabledState() isDisabled:", isDisabled);
    // Vermutlich reicht es aus, das 1:1 an p-autoComplete weiterzugeben, da p-autoComplete bereits formControlName unterstützt
    //this.inputWriteableControl.setDisabledState(isDisabled);
    //this.inputFormattedControl.setDisabledState(isDisabled);
  }

  //https://ngdev.space/angular-2-input-property-changes-detection-3ccbf7e366d2
  /*ngOnChanges(changes: SimpleChanges) { // monitoring Input-Changes - nur monitoring/debug - ohne weitere Funktion!!! (kann man weglassen!)
    if(this.debugMode==true) console.log("CRUDBasicInput.ngOnChanges():", changes);

    if(changes.value != null) {
      if(this.debugMode==true) console.log("CRUDBasicInput.ngOnChanges().it's value!");
    }
    else {
      if(this.debugMode==true) console.log("CRUDBasicSelectComponent.ngOnChanges().something else changed.");      
    }
  }*/

  hotkeys(event) {
    if(event.repeat == true) {
      // // repeated events interessieren uns generell überhaupt nicht!
      // z.B. bei CTRL (keydown) kommt der event endlos oft vor - so lange man auf CTRL bleibt
    }
    else {
      if(this.debugMode == true) {
        console.log("CRUDBasicInput.hotkeys() event:", event);
      }
      //if(this.globalService != null) this.globalService.handleHotKeys(this, event);
    }
  }

  handleHotkeys(event) {
    //console.log("CRUDBasicInput.handleHotkeys() event:", event);
    if(this.debugMode==true) {
      // "TypeError: Converting circular structure to JSON" vermeiden
      const getCircularReplacer = () => {
        const seen = new WeakSet();
        return (key, value) => {
          if (typeof value === "object" && value !== null) {
            if (seen.has(value)) {
              // AM Modi: wenn aufgrund Rekursion nicht weiter ausgegeben wird, dann wenigstens ein Hinweis, auf
              //          was für ein Objekttyp da kommt
              let objType: string = "???";
              try {
                objType = value.constructor.name;
              }
              catch (e) { }
              return '(recursive pointer) ' + objType;
            }
            seen.add(value);
          }
          return value;
        };
      };

      let eventString = JSON.stringify(event, getCircularReplacer(), 2); // ,2 = lesbare Formatierung
      console.log("CRUDBasicInput.handleHotkeys() event:", eventString);
    }
      
    // US 14791: Leider laufen die HotKey-Events vor allem anderen - auch vor den blur() und onChange() Events von CRUDBasicInput!
    // Daher: CRUDBasicInput (bei focus/blur) als HotKeyHandler registrieren - und dort dann:
    //        - erst onChange() (Wenn CTRL S/D)
    //        - dann den HotKey weiterleiten an die ParentComponent
  
    //STRG + S // save WITHOUT close
    if (event.keyCode == 83 && event.ctrlKey) {
      this.onChange();
      //event.preventDefault();
    }
    //STRG + D // save + close
    else if (event.keyCode == 68 && event.ctrlKey) {
      this.onChange();
      //event.preventDefault();
    }
    if(this.parentComponent != null && this.parentComponent != undefined)
    this.parentComponent['handleHotkeys'](event);
  }

  handleError(error: any) {
    //this.loading = false;
    //this.blockedDocument = false;

    console.log("CRUDBasicInput.handleError() type:", this.type);

    let errorMessage: string = "";

    let summary = this.translate.instant('Fehler', true);

    if (error.status === 422) {
      summary += ' (422)';
      if (error != null) {
        errorMessage = error.error.Concurrency || error.error.DbUpdateException || error.error.Error || 'Server Error';
      }
      else {
        errorMessage = "Server Error";
      }
    }
    else if (error.status === 401) {
      summary += ' (401)';
      errorMessage = "Unauthorized";
      //this.router.navigate(['/login'], { queryParams: { returnUrl: this.router.url } });
    }
    else {
      errorMessage = error.message;
    }

    this.messageWrapperService.postStaticMessage({ severity: 'error', summary: summary, detail: errorMessage });
  }
}
