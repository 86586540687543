//import { CRUDBasicListComponent_Template, CRUDBasicListComponent } from '../crud-basic-list/crud-basic-list.component'; // HTML Template Plugins
import { /*CRUDBasicListComponent_Template,*/ CRUDBasicListComponent } from '../crud-basic-list/crud-basic-list.component'; // HTML Template Plugins
import { /*CRUDBasicListComponent_Template,*/ CRUDBasicListForChildComponent } from '../crud-basic-list-for-child/crud-basic-list-for-child.component'; // HTML Template Plugins
//import { ChecklistePositionListComponent_Template } from './checkliste-position-list.component.include_template'; // HTML Template Plugins

import { Component, OnInit, Inject, forwardRef, Injector } from '@angular/core';
import { AppComponent } from '../app.component';

//import { IChecklistePosition } from '../_interfaces/checkliste-position';
import { ChecklistePositionService } from '../_services/checkliste-position.service';
import { IChecklistePosition } from '../_interfaces/checkliste-position';
import { CopyPaste } from '../_helpers/copy-paste';

@Component({
  selector: 'app-checkliste-position-list',
  //templateUrl: '../crud-basic-list/crud-basic-list.component.html',
  templateUrl: './checkliste-position-list.component.html',
  //template: `${CRUDBasicListComponent_Template || ''}${ChecklistePositionListComponent_Template}`,  // HTML Template Plugins
  styleUrls: ['../crud-basic-list/crud-basic-list.component.css'],
  host: {
    '(window:keydown)': 'hotkeys($event)',
    '(window:resize)': 'sizeTable()'
  }
})
export class ChecklistePositionListComponent extends CRUDBasicListForChildComponent implements OnInit {
  pageTitle: string = this.translate.instant("Checkliste Positionen", true);
  CRUDItemKurzform: string = "ChecklistePosition";
  CRUDItemButtonTitleNeu: string = this.translate.instant("Neue ChecklistePosition", true);
  CRUDItemBezeichnungSingularCapitalized: string = "ChecklistePosition";
  CRUDItemBezeichnungPluralCapitalized: string = "ChecklistePositionen";
  CRUDItemBezeichnungSingular: string = "checklistePosition";
  CRUDItemBezeichnungPlural: string = "checklistePositionen";
  CRUDItemHelpTopic: string = null;
  CRUDItemRouteSingular: string = "checkliste-position";
  CRUDItemRoutePlural: string = "checkliste-positionen";

  CRUDItemParentName : string = "checkliste"; // wie heisst die Navigation zum Parent innerhalb des CRUDItem ? // AM Parent-ChildTab-CRUD
  CRUDItemChildName : string = "checklistePositionen"; // wie heisst (das Array) der Childs innerhalb dem Parent ? // AM Parent-ChildTab-CRUD

  CRUDItemAvailableCols: any[] = [
          {
            field: 'createdBy',
            header: this.translate.instant('CreatedBy', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 10,
            showByDefault: false
          },
          {
            field: 'created',
            header: this.translate.instant('Created', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 20,
            showByDefault: false
          },
          {
            field: 'modifiedBy',
            header: this.translate.instant('ModifiedBy', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 30,
            showByDefault: false
          },
          {
            field: 'modified',
            header: this.translate.instant('Modified', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 40,
            showByDefault: false
          },
          {
            field: 'reihenfolge',
            header: this.translate.instant('Reihenfolge', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 50,
            showByDefault: true
          },
          {
            field: 'abhaengigkeit',
            header: this.translate.instant('Abhängigkeit', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 52,
            showByDefault: true
          },
          {
            field: 'bezeichnung',
            header: this.translate.instant('Bezeichnung', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 54,
            showByDefault: true
          },
          {
            field: 'hinweistext',
            header: this.translate.instant('Hinweistext', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 56,
            showByDefault: true
          },
          /*{
            field: 'info',
            header: this.translate.instant('Info', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 70,
            showByDefault: true
          },*/
          {
            field: 'platzhalter',
            header: this.translate.instant('Platzhalter', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 75,
            showByDefault: true
          },
          {
            field: 'bewertung',
            header: this.translate.instant('Bewertung', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 100,
            showByDefault: true,
            nullable: true
          },
          {
            field: 'behobenFotosMussfeld',
            header: this.translate.instant('Bilder der Behebung: Mussfeld', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 101,
            showByDefault: false,
            nullable: true
          },
          {
            field: 'mangelFotosMussfeld',
            header: this.translate.instant('Bilder des Mangels: Mussfeld', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 102,
            showByDefault: false,
            nullable: true
          },
          {
            field: 'fotos',
            header: this.translate.instant('Bilder', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 104,
            showByDefault: true,
            nullable: true
          },
          {
            field: 'fotosSindDokumente',
            header: this.translate.instant('Bilder: Dokumente', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 105,
            showByDefault: false,
            nullable: true
          },
          {
            field: 'fotosMussFeld',
            header: this.translate.instant('Bilder: Mussfeld', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 106,
            showByDefault: false,
            nullable: true
          },
          //{
          //  field: 'combobox',
          //  header: this.translate.instant('Combobox', true),
          //  styleClass: 'columnText',
          //  sortable: true,
          //  filterMatchMode: "contains",
          //  order: 104,
          //  showByDefault: true,
          //  nullable: true
          //},
          {
            field: 'mussfeld',
            header: this.translate.instant('Mussfeld', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 106,
            showByDefault: true,
            nullable: true
          },
          /*{
            field: 'checklisteId',
            header: this.translate.instant('Checkliste Id', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 130,
            showByDefault: true
          },
          {
            field: 'checklistePositionenFelder',
            header: this.translate.instant('Checkliste Positionen Felder', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 140,
            showByDefault: true
          }*/
    ];

    constructor(
      @Inject(forwardRef(() => AppComponent)) public _app: AppComponent,
      private _crudItemService: ChecklistePositionService,
      private _injector: Injector
    ) {
      super(_app, _injector);
      this.crudItemService = _crudItemService;

      
    this.CRUDAdditionalButtonsNeu = [
      {
        id: 'paste', label: this.translate.instant('Einfügen', true), icon: 'fa fa-paste', command: (res) => {
          this.pasteItem();
        }
      },
    ];
    }

    //ngOnInit() { // HTML Template Plugins
    //  super.ngOnInit();
    //  this.app.pageTitle = this['pageTitle'];
    //}

    ngOnInit() { 
      super.ngOnInit();
      let buttonItemSeparator = 
      {
        id: 'separatorZZZ', separator: true, automationId: 89
      };
      this.addButtonItem(buttonItemSeparator);
      let buttonItemCopy = 
      {
        id: 'copy', label: this.translate.instant(/*this.CRUDItemKurzform+' bearbeiten'*/'Kopieren', true), icon: 'fa fa-copy', automationId: 90, command: (res) => {
            this.copyItem(this.selectedCrudItem);
          }
      };
      this.addButtonItem(buttonItemCopy);
    }

    copyItem(item:/*IChecklistePosition*/any) {
      let copyPaste = new CopyPaste(this, this.messageWrapperService, this.translate, this.confirmationService);
      copyPaste.copyItem(item, 'checkliste-position');
    }
  
    pasteItem() {
      let copyPaste = new CopyPaste(this, this.messageWrapperService, this.translate, this.confirmationService);
      copyPaste.pasteItem('checkliste-position');
    }
  
}
