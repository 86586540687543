import { CRUDBasicListComponent } from '../crud-basic-list/crud-basic-list.component';
//import { CRUDBasicListComponent_Template, CRUDBasicListComponent } from '../crud-basic-list/crud-basic-list.component'; // HTML Template Plugins
//import { ChecklisteListComponent_Template } from './checkliste-list.component.include_template'; // HTML Template Plugins

import { Component, OnInit, Inject, forwardRef, Injector } from '@angular/core';
import { AppComponent } from '../app.component';

//import { ICheckliste } from '../_interfaces/checkliste';
import { ChecklisteService } from '../_services/checkliste.service';

@Component({
  selector: 'app-checkliste-list',
  templateUrl: '../crud-basic-list/crud-basic-list.component.html',
  //templateUrl: './checkliste-list.component.html',
  //template: `${CRUDBasicListComponent_Template || ''}${ChecklisteListComponent_Template}`,  // HTML Template Plugins
  styleUrls: ['../crud-basic-list/crud-basic-list.component.css'],
  host: {
    '(window:keydown)': 'hotkeys($event)',
    '(window:resize)': 'sizeTable()'
  }
})
export class ChecklisteListComponent extends CRUDBasicListComponent implements OnInit {
  pageTitle: string = this.translate.instant("Checklisten", true);
  CRUDItemKurzform: string = "Checkliste";
  CRUDItemButtonTitleNeu: string = this.translate.instant("Neue Checkliste", true);
  CRUDItemBezeichnungSingularCapitalized: string = "Checkliste";
  CRUDItemBezeichnungPluralCapitalized: string = "Checklisten";
  CRUDItemBezeichnungSingular: string = "checkliste";
  CRUDItemBezeichnungPlural: string = "checklisten";
  CRUDItemHelpTopic: string = null;
  CRUDItemRouteSingular: string = "checkliste";
  CRUDItemRoutePlural: string = "checklisten";

  CRUDMethodNameGetCollection: string = 'getChecklistenCollectionCroppedBare'; // Alternative Methode (im Service), die bei getCollection aufgerufen werden soll
  
  CRUDItemAvailableCols: any[] = [
          {
            field: 'createdBy',
            header: this.translate.instant('CreatedBy', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 10,
            showByDefault: false
          },
          {
            field: 'created',
            header: this.translate.instant('Created', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 20,
            showByDefault: false
          },
          {
            field: 'modifiedBy',
            header: this.translate.instant('ModifiedBy', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 30,
            showByDefault: false
          },
          {
            field: 'modified',
            header: this.translate.instant('Modified', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 40,
            showByDefault: false
          },
          {
            field: 'bezeichnung',
            header: this.translate.instant('Bezeichnung', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 50,
            showByDefault: true
          },
          {
            field: 'hinweistext',
            header: this.translate.instant('Hinweistext', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 60,
            showByDefault: true
          },
          {
            field: 'imAuftrag',
            header: this.translate.instant('Im Auftrag', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 70,
            showByDefault: true
          },
          {
            field: 'imAuftragTelefon',
            header: this.translate.instant('Im Auftrag Telefon', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 75,
            showByDefault: true
          },
          {
            field: 'dokument.bezeichnung',
            header: this.translate.instant('Dokument', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 80,
            showByDefault: true
          },
          /*{
            field: 'checklisteFreitexte',
            header: this.translate.instant('Checkliste Freitexte', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 80,
            showByDefault: true
          },*/
          /*{
            field: 'checklistePositionen',
            header: this.translate.instant('Checkliste Positionen', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 90,
            showByDefault: true
          }*/
    ];

    constructor(
      @Inject(forwardRef(() => AppComponent)) public _app: AppComponent,
      private _crudItemService: ChecklisteService,
      private _injector: Injector
    ) {
      super(_app, _injector);
      this.crudItemService = _crudItemService;
    }

    //ngOnInit() { // HTML Template Plugins
    //  super.ngOnInit();
    //  this.app.pageTitle = this['pageTitle'];
    //}
}
