<br>
<h5>&nbsp;&nbsp;Anleitungen und Sonstiges:</h5>

<ul class="fa-ul">
    <ng-container *ngFor="let rowData of crudItems">
        <li style="margin-top: 1.5em;">
            <span class="fa-li"><i class="fa-regular fa-file-circle-info"></i></span>
            
            <span *ngIf="rowData.typ == 'Lnk'" style="font-size: revent;"><a href="{{rowData.link}}" target="_blank'">{{rowData.bezeichnung}}</a></span>
            <br *ngIf="rowData.typ == 'Lnk'">
            <div *ngIf="rowData.typ == 'Lnk'" style="margin-top: 0.5em;"><a href="{{rowData.link}}" target="_blank'">{{rowData.beschreibung}}</a></div>
            
            <span *ngIf="rowData.typ != 'Lnk'" style="font-size: revent;"><a style="cursor: pointer;" (click)="downloadToBlob(rowData)">{{rowData.bezeichnung}}</a></span>
            <br *ngIf="rowData.typ != 'Lnk'">
            <div *ngIf="rowData.typ != 'Lnk'" style="margin-top: 0.5em;"><a style="cursor: pointer;" (click)="downloadToBlob(rowData)">{{rowData.beschreibung}}</a></div>
        </li>
    </ng-container>
</ul>