import {Component, ViewChild} from '@angular/core';
import { AppComponent } from './app.component';
import {AppMainComponent} from './app.main.component';
import { Sidebar } from 'primeng/sidebar';

@Component({
    selector: 'app-rightmenu',
    templateUrl: './app.rightmenu.component.html'
})
export class AppRightMenuComponent {
    constructor(public appMain: AppMainComponent, public app: AppComponent) {}

    onMenuClick($event) {
        //this.menuClick = true;

        //if (this.inlineMenuActive[this.currentInlineMenuKey] && !this.inlineMenuClick) {
        //    this.inlineMenuActive[this.currentInlineMenuKey] = false;
        //}
        console.log("AppRightMenuComponent.onMenuClick()");
    }
}
