//import { CRUDBasicListComponent_Template, CRUDBasicListComponent } from '../crud-basic-list/crud-basic-list.component'; // HTML Template Plugins
import { /*CRUDBasicListComponent_Template,*/ CRUDBasicListComponent } from '../crud-basic-list/crud-basic-list.component'; // HTML Template Plugins
import { /*CRUDBasicListComponent_Template,*/ CRUDBasicListForChildComponent } from '../crud-basic-list-for-child/crud-basic-list-for-child.component'; // HTML Template Plugins
//import { ChecklistePositionenFeldListComponent_Template } from './checkliste-positionen-feld-list.component.include_template'; // HTML Template Plugins

import { Component, OnInit, Inject, forwardRef, Injector, SimpleChanges } from '@angular/core';
import { AppComponent } from '../app.component';

//import { IChecklistePositionenFeld } from '../_interfaces/checkliste-positionen-feld';
import { ChecklistePositionenFeldService } from '../_services/checkliste-positionen-feld.service';
import { CopyPaste } from '../_helpers/copy-paste';

@Component({
  selector: 'app-checkliste-positionen-feld-list',
  //templateUrl: '../crud-basic-list/crud-basic-list.component.html',
  templateUrl: './checkliste-positionen-feld-list.component.html',
  //template: `${CRUDBasicListComponent_Template || ''}${ChecklistePositionenFeldListComponent_Template}`,  // HTML Template Plugins
  styleUrls: ['../crud-basic-list/crud-basic-list.component.css'],
  host: {
    '(window:keydown)': 'hotkeys($event)',
    '(window:resize)': 'sizeTable()'
  }
})
export class ChecklistePositionenFeldListComponent extends CRUDBasicListForChildComponent implements OnInit {
  pageTitle: string = this.translate.instant("Checkliste Positionen Felder", true);
  CRUDItemKurzform: string = "ChecklistePositionenFelder";
  CRUDItemButtonTitleNeu: string = this.translate.instant("Neues ChecklistePositionenFelder", true);
  CRUDItemBezeichnungSingularCapitalized: string = "ChecklistePositionenFeld";
  CRUDItemBezeichnungPluralCapitalized: string = "ChecklistePositionenFelder";
  CRUDItemBezeichnungSingular: string = "checklistePositionenFeld";
  CRUDItemBezeichnungPlural: string = "checklistePositionenFelder";
  CRUDItemHelpTopic: string = null;
  CRUDItemRouteSingular: string = "checkliste-positionen-feld";
  CRUDItemRoutePlural: string = "checkliste-positionen-felder";

  CRUDItemParentName : string = "checklistePosition"; // wie heisst die Navigation zum Parent innerhalb des CRUDItem ? // AM Parent-ChildTab-CRUD
  CRUDItemChildName : string = "checklistePositionenFelder"; // wie heisst (das Array) der Childs innerhalb dem Parent ? // AM Parent-ChildTab-CRUD
  
  CRUDItemAvailableCols: any[] = [
          {
            field: 'createdBy',
            header: this.translate.instant('CreatedBy', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 10,
            showByDefault: false
          },
          {
            field: 'created',
            header: this.translate.instant('Created', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 20,
            showByDefault: false
          },
          {
            field: 'modifiedBy',
            header: this.translate.instant('ModifiedBy', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 30,
            showByDefault: false
          },
          {
            field: 'modified',
            header: this.translate.instant('Modified', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 40,
            showByDefault: false
          },
          {
            field: 'reihenfolge',
            header: this.translate.instant('Reihenfolge', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 50,
            showByDefault: true
          },
          {
            field: 'abhaengigkeit',
            header: this.translate.instant('Abhängigkeit', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 53,
            showByDefault: true
          },
          {
            field: 'bezeichnung',
            header: this.translate.instant('Bezeichnung', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 55,
            showByDefault: true
          },
          {
            field: 'info',
            header: this.translate.instant('Info', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 58,
            showByDefault: true
          },
          {
            field: 'platzhalter',
            header: this.translate.instant('Platzhalter', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 60,
            showByDefault: true
          },
          {
            field: 'typ',
            header: this.translate.instant('Typ', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 110,
            showByDefault: true
          },
          {
            field: 'felddefinition',
            header: this.translate.instant('Felddefinition', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 120,
            showByDefault: true
          },
          /*{
            field: 'checklistePositionId',
            header: this.translate.instant('Checkliste Position Id', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 130,
            showByDefault: true
          }*/
          {
            field: 'mussfeld',
            header: this.translate.instant('Mussfeld', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 140,
            showByDefault: true,
            nullable: true
          },
          {
            field: 'mangel',
            header: this.translate.instant('Mangel', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 150,
            showByDefault: true
          },
    ];

    constructor(
      @Inject(forwardRef(() => AppComponent)) public _app: AppComponent,
      private _crudItemService: ChecklistePositionenFeldService,
      private _injector: Injector
    ) {
      super(_app, _injector);
      this.crudItemService = _crudItemService;

      this.CRUDAdditionalButtonsNeu = [
        {
          id: 'paste', label: this.translate.instant('Einfügen', true), icon: 'fa fa-paste', command: (res) => {
            this.pasteItem();
          }
        },
      ];

    }

    //ngOnInit() { // HTML Template Plugins
    //  super.ngOnInit();
    //  this.app.pageTitle = this['pageTitle'];
    //}

    ngOnInit() { 
      super.ngOnInit();
      let buttonItemSeparator = 
      {
        id: 'separatorZZZ', separator: true, automationId: 89
      };
      this.addButtonItem(buttonItemSeparator);
      let buttonItemCopy = 
      {
        id: 'copy', label: this.translate.instant(/*this.CRUDItemKurzform+' bearbeiten'*/'Kopieren', true), icon: 'fa fa-copy', automationId: 90, command: (res) => {
            this.copyItem(this.selectedCrudItem);
          }
      };
      this.addButtonItem(buttonItemCopy);
    }

    copyItem(item:/*IChecklistePosition*/any) {
      let copyPaste = new CopyPaste(this, this.messageWrapperService, this.translate, this.confirmationService);
      copyPaste.copyItem(item, 'checkliste-position-feld');
    }
  
    pasteItem() {
      let copyPaste = new CopyPaste(this, this.messageWrapperService, this.translate, this.confirmationService);
      copyPaste.pasteItem('checkliste-position-feld');
    }
}
