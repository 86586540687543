import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phoneCanonical'
  , pure: false
})
export class PhoneCanonicalPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    if(value.substring(0,1) == '+') {
      return this.strWithoutBlanks(value);
    }
    else if(value.substring(0,2) == '00') {
      return '+'+(this.strWithoutBlanks(value.substring(2)));
    }
    else if(value.substring(0,1) == '0') {
      return '+49'+(this.strWithoutBlanks(value.substring(1)));
    }
    else {
      return value;
    }
  }

  strWithoutBlanks(str) {
    let noWhitespace = str.replace(/\s/g, '');
    return noWhitespace;
  }

}
