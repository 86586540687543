//import { CRUDBasicListComponent_Template, CRUDBasicListComponent } from '../crud-basic-list/crud-basic-list.component'; // HTML Template Plugins
import { /*CRUDBasicListComponent_Template,*/ CRUDBasicListComponent } from '../crud-basic-list/crud-basic-list.component'; // HTML Template Plugins
import { /*CRUDBasicListComponent_Template,*/ CRUDBasicListForChildComponent } from '../crud-basic-list-for-child/crud-basic-list-for-child.component'; // HTML Template Plugins
//import { BenutzerListComponent_Template } from './benutzer-list.component.include_template'; // HTML Template Plugins

import { Component, OnInit, Inject, forwardRef, Injector, SimpleChanges } from '@angular/core';
import { AppComponent } from '../app.component';

import { IBenutzer } from '../_interfaces/benutzer';
import { BenutzerService } from '../_services/benutzer.service';

import { Subscription } from 'rxjs';
import { FilterMetadata } from 'primeng/api';


@Component({
  selector: 'app-benutzer-list',
  //templateUrl: '../crud-basic-list/crud-basic-list.component.html',
  templateUrl: './benutzer-list.component.html',
  //template: `${CRUDBasicListComponent_Template || ''}${BenutzerListComponent_Template}`,  // HTML Template Plugins
  styleUrls: ['../crud-basic-list/crud-basic-list.component.css'],
  host: {
    '(window:keydown)': 'hotkeys($event)',
    '(window:resize)': 'sizeTable()'
  }
})
export class BenutzerListComponent extends CRUDBasicListForChildComponent implements OnInit {
  pageTitle: string = this.translate.instant("Benutzer", true);
  CRUDItemKurzform: string = "Benutzer";
  CRUDItemButtonTitleNeu: string = this.translate.instant("Neuer Benutzer", true);
  CRUDItemBezeichnungSingularCapitalized: string = "Benutzer";
  CRUDItemBezeichnungPluralCapitalized: string = "Benutzer";
  CRUDItemBezeichnungSingular: string = "benutzer";
  CRUDItemBezeichnungPlural: string = "benutzer";
  CRUDItemHelpTopic: string = null;
  CRUDItemRouteSingular: string = "benutzer";
  CRUDItemRoutePlural: string = "benutzer";
  
  CRUDItemParentName : string = "dienstleister"; // wie heisst die Navigation zum Parent innerhalb des CRUDItem ? // AM Parent-ChildTab-CRUD
  CRUDItemChildName : string = "benutzer"; // wie heisst (das Array) der Childs innerhalb dem Parent ? // AM Parent-ChildTab-CRUD

  CRUDItemFilters /*FilterMetadata*/ = { // <!--MODI: vorbestückte filters!-->
    istAdmin: {value: false, matchMode: 'contains'}
  };

  //debugMode: boolean = true;

  passwordResetShow: boolean = false; // dialog
  isAdmin: boolean = false;
  benutzer: IBenutzer = null;
  benutzerSubscription: Subscription = null;

  CRUDItemAvailableCols: any[] = [
          {
            field: 'createdBy',
            header: this.translate.instant('CreatedBy', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 10,
            showByDefault: false
          },
          {
            field: 'created',
            header: this.translate.instant('Created', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 20,
            showByDefault: false
          },
          {
            field: 'modifiedBy',
            header: this.translate.instant('ModifiedBy', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 30,
            showByDefault: false
          },
          {
            field: 'modified',
            header: this.translate.instant('Modified', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 40,
            showByDefault: false
          },
          /*{
            field: 'aspNetUserId',
            header: this.translate.instant('Asp Net User Id', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 50,
            showByDefault: true
          },*/
          /*{
            field: 'istBenutzer',
            header: this.translate.instant('ist Benutzer', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 70,
            showByDefault: true
          },
          {
            field: 'istAnsprechpartner',
            header: this.translate.instant('ist Ansprechpartner', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 80,
            showByDefault: true
          },*/
          {
            field: 'userName',
            header: this.translate.instant('Benutzername', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 86,
            showByDefault: true
          },
          {
            field: 'email',
            header: this.translate.instant('E-Mail', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 88,
            showByDefault: true
          },
          {
            field: 'vorname',
            header: this.translate.instant('Vorname', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 90,
            showByDefault: true
          },
          {
            field: 'name',
            header: this.translate.instant('Name', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 100,
            showByDefault: true
          },
          {
            field: 'telefon',
            header: this.translate.instant('Telefon', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 110,
            showByDefault: false
          },
          {
            field: 'mobil',
            header: this.translate.instant('Mobil', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 120,
            showByDefault: false
          },
          /*{
            field: 'dienstleisterId',
            header: this.translate.instant('Dienstleister Id', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 140,
            showByDefault: true
          },
          {
            field: 'dienstleister',
            header: this.translate.instant('Dienstleister', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 150,
            showByDefault: true
          },
          {
            field: 'sessions',
            header: this.translate.instant('Sessions', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 160,
            showByDefault: true
          }*/
          {
            field: 'istAdmin',
            header: this.translate.instant('Administrator', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 135,
            showByDefault: false /* wird ggf. dynamisch aktiviert - siehe ngOnInit */
          },
          {
            field: 'aktiv',
            header: this.translate.instant('Aktiv', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 140,
            showByDefault: true
          },
          {
            field: 'prueferKennzeichen',
            header: this.translate.instant('Prüfer-Kennzeichen', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 150,
            showByDefault: true
          },
    ];

    constructor(
      @Inject(forwardRef(() => AppComponent)) public _app: AppComponent,
      private _crudItemService: BenutzerService,
      private _injector: Injector
    ) {
      super(_app, _injector);
      this.crudItemService = _crudItemService;
    }

    ngOnInit() { 
      super.ngOnInit();

      // weitere SplitButton-Funktion
      this.addButtonItem(
      {
        id: 'changePW', 
        label: this.translate.instant(/*this.CRUDItemKurzform+' löschen'*/'Passwort zurücksetzen', true),
        icon: 'fa fa-lock', automationId: 20, command: (res) => {
          this.passwordZuruecksetzen(this.selectedCrudItem);
        }
      });

      this.benutzerSubscription = this.app.benutzerBehaviourSubject.subscribe(benutzer => {
        if(benutzer != null) {
            console.log("BenutzerListComponent.ngOnInit() got benutzer:", benutzer);
            this.benutzer = benutzer;
            setTimeout(() => {
              this.benutzerSubscription.unsubscribe(); 
            }, 10);

            // admin ? dann Spalte Administrator + die Adminsitratoren-Zeilen einblenden
            this.isAdmin = this.benutzer.istAdmin == true;
            if(this.isAdmin == true) {
              let col = this.CRUDItemAvailableCols.filter(f => f.field == "istAdmin");
              col[0].showByDefault = true;

              delete this.CRUDItemFilters.istAdmin;
            }
            // ... ansonsten: Administratoren komplett ausblenden! -> würde an sich funktionieren, man sieht aber ganz kurz die ausgefilterten Daten bevor der filter greift.
            // Daher anders-rum gelöst: siehe CRUDItemFilters
            //else {
            //  setTimeout(() => {
            //    this.dataTable.filter(false, "istAdmin", "contains");
            //  }, 5)
            //}
            else {
              this.CRUDItemButtonTitleNeu = null; // wenn nicht Admin, dann neu ausblenden
            }
        }
      });
    }

    selectCRUDItem(selectedCrudItem) {
      if((this.benutzer == null || this.benutzer.istAdmin != true) && selectedCrudItem.id != this.benutzer.id) {
        this.messageWrapperService.postStaticMessage({ severity: 'error', summary: this.translate.instant("Auswahl", true), detail: this.translate.instant("Sie haben keine Berechtigung für diesen Benutzer", true) })
        return;
      }
      
      super.selectCRUDItem(selectedCrudItem);
    }

    deleteCRUDItem(selectedCrudItem) {
      if((this.benutzer == null || this.benutzer.istAdmin != true) && selectedCrudItem.id != this.benutzer.id) {
        this.messageWrapperService.postStaticMessage({ severity: 'error', summary: this.translate.instant("Löschen", true), detail: this.translate.instant("Sie haben keine Berechtigung für diesen Benutzer", true) })
        return;
      }
      
      super.deleteCRUDItem(selectedCrudItem);
    }

    passwordZuruecksetzen(benutzer: IBenutzer) {
      if((this.benutzer == null || this.benutzer.istAdmin != true) && benutzer.id != this.benutzer.id) {
        this.messageWrapperService.postStaticMessage({ severity: 'error', summary: this.translate.instant("Passwort", true), detail: this.translate.instant("Sie haben keine Berechtigung für diesen Benutzer", true) })
        return;
      }
      
      this.passwordResetShow = true;
    }
    passwordZuruecksetzenZurueckClicked() {
      this.passwordResetShow = false;
    }
    passwordZuruecksetzenSaveClicked() {
      this.passwordResetShow = false;
    }

    //debug(obj) {
    //  console.log("BenutzerListComponent.debug() dataTable:", this.dataTable);
    //}
  
}
