<p-toast position="top-left" key="messages" [baseZIndex]="99999"></p-toast>
<p-blockUI [blocked]="blockUI == true" styleClass="{{blockUI == true ? 'blockUI' : ''}}">
    <!--<i class="fa fa-spinner fa-pulse fa-3x fa-fw" style="position:absolute;top:25%;left:50%"></i>-->
</p-blockUI>
<!--<div class="blockUI" [style.display]="blockUI == true ? 'unset' : 'none'"></div>-->
<!--<div *ngIf="inAppMode==true" style="background-color: yellow; position: relative; top: 3em; height: 8em; width: calc(100vW - 1em); overflow: auto;" id="inAppDebugDiv"></div>-->
<div *ngIf="inAppMode==true" style="background-color: transparent; position: absolute; top: 3em; min-height: 3em; min-width: 3em; z-index: 9999;">
    <div style="position: fixed; background-color: lightgoldenrodyellow; overflow: auto; height: 8em; width: calc(100vW - 1em);" id="inAppDebugDiv"
    [style.display]="appDebugShowLog == true ? 'unset' : 'none'">
    </div>
    <div *ngIf="false" style="background-color: transparent; position: absolute; left: calc(100vW - 6em); min-height: 3em; min-width: 3em;">
        <button pButton type="button" badge="4" badgeClass="p-badge-danger" label="" icon="fa-solid fa-eye fa-xs" iconPos="left" class="p-button-rounded p-button-danger" style="float:left;" (click)="switchAppDebugLog()"></button>
    </div>    
</div>
<router-outlet></router-outlet>
<p-dialog [(visible)]="dispositionShowDialog" [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '50vw'}" modal="true">
    <ng-template pTemplate="header">
        
    </ng-template>
    <ng-template pTemplate="header">
        <div class="disposition-grid">
            <!-- zeile 1 -->
            <div>
                <i class="fa fa-regular fa-calendar fa-xl"></i>
            </div>
            <div>
                <big>Dispostition:</big>
            </div>
            <div></div>
            <div></div>
        </div>
    </ng-template>
    <div class="">
        <ng-container *ngFor="let auftrag of dispositionAuftraege">
            <div class="disposition-grid">
                <div>
                    <i class="fa fa-{{ auftrag.leistung | leistung : 'iconName' }}"></i>
                </div>
                <div>
                    {{auftrag.name_1Behaelterstandort}} {{auftrag.name_2Behaelterstandort}}
                </div>
                <div>
                    {{auftrag.strasseBehaelterstandort}}
                </div>
                <div>
                    {{auftrag.plzBehaelterstandort}} {{auftrag.ortBehaelterstandort}} {{auftrag.ortzusatzBehaelterstandort}}
                </div>
            </div>
        </ng-container>
    </div>

    <ng-template pTemplate="footer">
        <div class="disposition-felder-grid-container">
            <div class="detail-grid-sub inputTextForm">
                <span class="p-float-label" style="width: calc(100% - 0.5em)"><!--warum auch immer ist die Benutzerauswahl (s.u.) etwas schmäler, daher workaround:-->
                    <crud-basic-input-calendar dateFormat="dd.mm.yy" [showTime]="false" [stepMinute]="01" [showSeconds]="true" 
                    [defaultDate]="dispositionDatum" [utc]="true" [(ngModel)]="dispositionDatum" (ngModelChange)="dispositionDatumChanged()" [style]="{'width' : '100%'}"></crud-basic-input-calendar>
                    <label>{{'Dispodatum'|translate:true}}</label>
                </span>
              </div>
              <div class="detail-grid-sub">
                <div class="errorMessage">
                  <!--error?-->
                </div>
              </div>

              <div class="detail-grid-sub inputTextForm">
                <span class="p-float-label">
                    <crud-basic-autocomplete [options]="dispositionBenutzerOptions" [(ngModel)]="dispositionBenutzer"
                    [style]="{'width': '100%', 'height': '36px'}" [inputStyle]="{'width': '100%', 'height': '36px'}"
                    ></crud-basic-autocomplete>
                    <label>{{'Benutzer'|translate:true}}</label>
                </span>
              </div>
              <div class="detail-grid-sub">
                <div class="errorMessage">
                  <!--error?-->
                </div>
              </div>
        </div>

        <button pButton type="button" label="Speichern" iconPos="left" (click)="dispositionSpeichern()"></button>
    </ng-template>
</p-dialog>