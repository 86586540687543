import { Injectable } from '@angular/core';
import { throwError as observableThrowError, Observable } from 'rxjs';
import { IUserTableColumnState } from '../_interfaces/user-table-column-state';
import { AppconfigService } from '../_services/appconfig.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';

const httpOptions =
  new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*'
  });

@Injectable()
export class UserTableColumnStateService {

  apiBaseUrl: string;

  constructor(
    private httpClient: HttpClient,
    private config: AppconfigService) {
    this.apiBaseUrl = this.config.get('apiBaseUrl');
  }

  getUserTableColumnStates(tableName: string): Observable<IUserTableColumnState[]> {
    return this.httpClient.get<IUserTableColumnState[]>(this.apiBaseUrl + 'userTableColumnStates/' + tableName, { headers: httpOptions, observe: 'body', withCredentials: true })
      .pipe(
        catchError(this.handleError)
      )
  }

  upsertUserTableColumnStates(tableName: string, items: IUserTableColumnState[]): Observable<IUserTableColumnState[]> {
    return this.httpClient.put<IUserTableColumnState[]>(this.apiBaseUrl + 'userTableColumnStates/' + tableName, items, { headers: httpOptions, observe: 'body', withCredentials: true })
      .pipe(
        map((response) => {
          return response
        }),
        catchError(this.handleError)
      )
  }


  private handleError(error) {
    console.log("UserTableColumnStateService.handleError error: ", error);
    
    if(error.status === 422 && (error.error.DbUpdateException || error.error.Error)) { // Modi: ohne "error.error.DbUpdateException" webpack-error! / Ohne "error.error.Error" wird zB bei Tags der Fehler "Es existiert bereits ein Tag mit dieser Bezeichnung für dieses Projekt oder global." nicht angezeigt
      return observableThrowError(error);
    }
    else if (error.status === 422 || error.status === 401) {
      if (error.status === 422) {
        //console.log("CRUDBasicService.handleError error: ", error);
      }
      return observableThrowError(error.Concurrency || error.DbUpdateException || error.Error || 'Server Error');
    }
    else if(error.status >= 500 || error.status == 0 || error.status == 404 || error.status == 400){
      let errorDetails = {message: "Status Code: " + error.status + '<br>' + error.statusText};
      return observableThrowError(errorDetails);
    }

    return observableThrowError(error.error || 'Server error')
  }
}
