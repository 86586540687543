import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { _QrCodeService } from '../_services/_qr-code.service';
import { _SystemService } from '../_services/_system.service';
import { TranslateService } from '../_services/translate.service';
import { MessageWrapperService } from '../_services/message-wrapper.service';
import { Router } from '@angular/router';
//import { ErrorServiceService } from '../_services/error-service.service';

@Component({
  selector: 'app-qrcodegenerator',
  templateUrl: './qrcodegenerator.component.html',
  styleUrls: ['./qrcodegenerator.component.css']
})
/***
 * Erstellt einen QR Code für gegebene AspNetUserId
 */
export class QrcodegeneratorComponent implements OnInit, OnDestroy {

  //@Input('aspnetuserid') aspnetuserid: string;
  /**Steuert ob der download Btn sichtbar ist oder nicht im standard nicht sichtbar */
  @Input('showDLBtn') showDLBtn: boolean = false;
  @Input('dlBtnStyle') dlBtnStyle: any;
  @Input('smallIcon') smallIcon: boolean = false
  qrCodeSrc: any;
  qrCodeBlob: any;

  //Error
  loading: boolean;
  errorMessage: string;

  constructor(
    private qrCodeService: _QrCodeService,
    private systemService: _SystemService,
    public translate: TranslateService,
    private messageWrapperService: MessageWrapperService,
    //private errorService: ErrorServiceService,
    private router: Router
  ) { }

  ngOnDestroy(): void {
    this.resetQRCode();
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {  
    let a = document.getElementById("qrcodegenerator_a_get_absolute_url");
    let urlNG = window.location.origin+window.location.pathname;
    let urlAPI = this.systemService.getApiBaseUrl();
    //let urlAPI = "../DLP/api/"; // for Testing
    console.log("QrcodegeneratorComponent.ngAfterViewInit() urlNG", urlNG);
    console.log("QrcodegeneratorComponent.ngAfterViewInit() urlAPI", urlAPI);

    let urlAPIAbsolute = urlAPI;
    if(urlAPI != null && urlAPI.startsWith("../")) {
      // aus der relativen urlAPI - eine Absolute rechnen:
      a['href'] = urlNG+urlAPI;
      setTimeout(() => {
        let urlAPIAbsolute = a['href'];
        this.loadQRCode(urlNG, urlAPIAbsolute);
      }, 0)
    }
    else {
      this.loadQRCode(urlNG, urlAPIAbsolute);
    }
  }

  loadQRCode(urlNG: string, urlAPI: string) {
    console.log("QrcodegeneratorComponent.loadQRCode() urlNG/urlAPI:", urlNG, urlAPI);
    this.qrCodeService.getQRCode(urlNG, urlAPI, this.smallIcon).subscribe(
      res => {
        let base64String = btoa(new Uint8Array(res.body).reduce(function (data, byte) {
          return data + String.fromCharCode(byte);
        }, ''));

        this.qrCodeSrc = 'data:image/png;base64,' + base64String;
        this.qrCodeBlob = res.body;
      },
      error => {
        //this.errorService.handleError(error);
        this.handleError(error);
      }
    )
  }

  getAbsoluteUrl() {
    let a = null;
  
    return function(url) {
      if(!a) a = document.createElement('a');
      a.href = url;
  
      return a.href;
    };
  }

  downloadQRCode() {
    let a = document.createElement("a");
    document.body.appendChild(a);

    const blob = new Blob([this.qrCodeBlob], { type: 'image/png' });
    const url = window.URL.createObjectURL(blob);

    a.href = url;
    a.download = 'QR-Code.png';
    a.click();

    setTimeout(() => {
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }, 0)
  }

  resetQRCode() {
    this.qrCodeSrc = null;
    this.qrCodeBlob = null;
  }

  handleError(error: any) {
    console.log("QRCodeGenerator.handleError error", error);
    //this.globalService.addFeedbackByClone("error " + this.CRUDItemBezeichnungSingularCapitalized, "CRUDBasicDetailComponent.handleError()", error); // feedback

    //this.loading = false;
    //this.blockedDocument = false;
    let summary = this.translate.instant('Fehler', true);

    if (error.status === 422) {
      summary += ' (422)';
      if (error != null) {
        this.errorMessage = error.error.Concurrency || error.error.DbUpdateException || error.error.Error || 'Server Error';
        if(Array.isArray(this.errorMessage)) this.errorMessage = this.errorMessage[0]; // ist bei DbUpdateException der Fall!
      }
      else {
        this.errorMessage = "Server Error";
      }
    }
    else if (error.status === 401) {
      summary += ' (401)';
      this.errorMessage = "Unauthorized";
      this.router.navigate(['/login'], { queryParams: { returnUrl: this.router.url } });
    }
    if (error.status === 500) {
      summary += ' (500)';
      this.errorMessage = "Server Error";
    }
    else {
      this.errorMessage = error.message;
    }

    //if(this.errorMessage != null) this.errorMessage = this.errorMessage.replace(/\n/g, "<br>");
    this.messageWrapperService.postStaticMessage({ severity: 'error', summary: summary, detail: this.errorMessage }); // TO DO
  }

}
