<h4>DLP</h4>

Verbindungsdaten für App:
<br><br>
<div>
    &nbsp;
    <app-qrcodegenerator #qrgenerator [showDLBtn]="true" [smallIcon]="false" style="margin-left: 1em"></app-qrcodegenerator><!--[aspnetuserid]="'00000000-0000-0000-0000-000000000000'"-->
</div>


