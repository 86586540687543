import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms'
import { Observable, of } from 'rxjs';
import { map, debounceTime } from 'rxjs/operators';

// nicht async, abe abgeleitet von async validator:
// https://www.tektutorialshub.com/angular/angular-async-validator-example/
// with parameters (notInUse): 
// ECHTER ASYNC siehe Plattgruen

//export function validateCommaSeparatedEMails(control: AbstractControl):Observable<ValidationErrors> | null { // ohne Parameter
export function validateCommaSeparatedEMails(parmNotInUse: any): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {

    let value = control.value;
    //console.log("validateCommaSeparatedEMails() value: ", value);

    if(value == null) return null;

    let errors : any[] = [];
    let errorsFound : boolean = false;

    let invalidFound : boolean = false;
    var array = (""+value).split(',');
    array.forEach(email => {
      //console.log("validateCommaSeparatedEMails() email: ", email);
      if(email.trim().length>0 && ValidateEmail(email) == false) {
        invalidFound = true;
      }
    });

    if(invalidFound) {
      errors['email_ungueltig']=true;
      errorsFound = true;
    }      

    if(errorsFound) return errors;
    else return null;
  }
}

//https://www.w3resource.com/javascript/form/email-validation.php
function ValidateEmail(mail) 
{
 if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail))
  {
    return (true) // valid
  }
    //alert("You have entered an invalid email address!")
    return (false) // invalid
}
