import { Injectable } from '@angular/core';
//import { Observable } from 'rxjs/Observable';
//import { of } from 'rxjs'; // US 21119
//import 'rxjs/add/operator/map';
//import 'rxjs/add/operator/do';
//import 'rxjs/add/operator/catch';
import { Observable } from 'rxjs';
import { IPagination } from '../_interfaces/pagination'
//import { IPrioritaetMaengelverfolgung } from '../_interfaces/prioritaet-maengelverfolgung';
import { IChoiceList } from '../_interfaces/_choice-list';

import { AppconfigService } from '../_services/appconfig.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { CRUDBasicService, httpOptions } from '../_services/crud-basic.service';

@Injectable()
export class _QrCodeService extends CRUDBasicService {

  getQRCode(urlNG: string, urlAPI: string, small?: boolean) {
    let url = this.apiBaseUrl + '_qrcode/' + btoa(urlNG) + "/" + btoa(urlAPI);
    if (small == true)
      url += '?small=' + small;
    return this.httpClient.get(url, { headers: httpOptions, observe: 'response', responseType: 'arraybuffer', withCredentials: true }).pipe(
      tap((res) => { return res })
    )
  }
}
