<!--<img #image *ngIf="logoBase64!=null && (''+logoBase64).length > 0" [src]="logoBase64" style="max-height:100%; max-width:100%;" 
  [alt]="'('+translate.instant('Datei kann nicht dargestellt werden',true)+')'" 
>-->
<img *ngIf="dateiBase64!=null && (''+dateiBase64).length > 0" class="dokumentImage" #dokumentImg id=""
attr.data-basecode="{{value._file}}" attr.data-dateiname="{{value.dateiname}}"
attr.data-dateiendung="{{value.dateiendung}}"
src="assets/DLP/dateitypen/{{value.dateiendung | lowercase}}.png"
onerror="src='assets/DLP/dateitypen/txt.png'"
(click)="imageClicked()">

<br>
<span *ngIf="value !=null && value.dateiname != null && value.dateiname.length >0">
  {{value.dateiname}}.{{value.dateiendung}}
</span>

<span *ngIf="!(dateiBase64!=null && (''+dateiBase64).length > 0)"><br>({{labelNoImage == null ? ('Es ist keine Datei hinterlegt'|translate:true) : labelNoImage}})</span>

<!-- fileUpload wird unsichtbar dargestellt - der upload Buttons soll vom parent gerendert werden. Es muss aber (unsichtbar) vorhanden sein, dass die parentComp CRUDBasicLogo.upload() callen kann -->
<div style="display: none"><p-fileUpload #logoUpload [style]="{'width':'90%'}" mode="basic" name="logoFile[]" accept="application/vnd.oasis.opendocument.text,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf" maxFileSize="1000000" (onSelect)="logoUploader($event)" [auto]="true" [chooseLabel]="dateiBase64!=null && (''+dateiBase64).length > 0 ? translate.instant('Datei ersetzen',true) : translate.instant('Datei hinterlegen',true)"></p-fileUpload></div>