import { Component, OnInit, AfterViewInit, ViewChild, Input, Output, EventEmitter, SimpleChange, SimpleChanges, Inject, forwardRef, Injector, ElementRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '../_services/translate.service';

import { GuessMIMEType } from '../_helpers/guess-mime-type';
import * as cloneDeep from 'lodash/cloneDeep'; 

// Logo
import {
  FileUpload
} from 'primeng/fileupload';
import { _DateiService } from '../_services/_datei.service';
//import { DokumenteService } from '../_services/dokumente.service';
import { I_Datei } from '../_interfaces/_datei';

// VORLAGE hierzu war: Plattgruen UnternehmenDatei-Upload, was aber 
// - images verarbeitet (Vorschau)
// - evtl. mit eigenen Entities arbeitet ???

@Component({
  selector: 'app-dokument-datei-upload',
  templateUrl: './dokument-datei-upload.component.html',
  styleUrls: ['./dokument-datei-upload.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DokumentDateiUploadComponent),
      multi: true
    }
  ]
})
export class DokumentDateiUploadComponent implements /*OnInit, */AfterViewInit, ControlValueAccessor {

  @Input('value') value: I_Datei;
  @Input('labelNoImage') labelNoImage: string;

  @Output() iconClicked: EventEmitter<any> = new EventEmitter<any>();
  
  @ViewChild('logoUpload', { static: true }) public logoUpload: FileUpload;
  @ViewChild('image') public image: ElementRef;

  onChange_callbackFunction: any; // bekommt die function von Angular forms - wenn formControlName - die bei Änderung gecallt werden muss.
  onChange_lastValue: any; // enthält den jeweils zuletzt rückgemeldeten Wert - als Vergleich/Abfangmechanismus, um nicht unnötig oft zu callen
  //onTouch_callbackFunction : any; // bekommt die function von Angular forms - wenn formControlName - die bei Touch gecallt werden muss.

  // logo
  logoFile: any[] = [];
  dateiBase64: any;

  dateiendung: string = null;

  constructor(
    public translate: TranslateService,
    private _dateiService: _DateiService,
    //private dokumenteService: DokumenteService,
  ) {
  }

  //https://ngdev.space/angular-2-input-property-changes-detection-3ccbf7e366d2
  //ngOnChanges(changes: SimpleChanges) { // monitoring Input-Changes - nur monitoring/debug - ohne weitere Funktion!!! (kann man weglassen!)
  //  console.log("CRUDBasicLogo.ngOnChanges():", changes);
  //}

  ngAfterViewInit(): void {
    
  }

  onChange() {
      console.log("DokumentDateiUploadComponent.onChange() event:", event);
      if (this.value != this.onChange_lastValue) { // nur wenn der Wert != dem zuletzt gemeldeten Wert ist (doppel-Rückmeldungen vermeiden! Performance!)
          let callBackValue: any = null;
          callBackValue = this.value;

          console.log("DokumentDateiUploadComponent.onChange() calling callback - value (first 100 characters):", callBackValue/*.substring(0,100)*/);

          this.onChange_callbackFunction(callBackValue);
          this.onChange_lastValue = cloneDeep(this.value); // Wichtig! bei Objekten clonen!
      } else {
          console.log("DokumentDateiUploadComponent.onChange() skip, since it's the same option as last time!");
      }
  }

  // aus ControlValueAccessor Interface: patchValue/setValue/new FormControl soll Wert im HTML aktualisieren
  writeValue(obj: any): void {
      console.log("DokumentDateiUploadComponent.writeValue():", obj);
      //this.inputWriteableControl.writeValue(obj);
      this.value = obj;

      this.value = obj;
      if (this.value != null && this.value._file != null && this.value._file.length > 0) {
        //let guessMIMEType = new GuessMIMEType();
        //let logoMIMEType = guessMIMEType.guessMIMEType(this.value._file);
        //guessMIMEType = null;
        //console.log("CRUDBasicLogo.writeValue() logoMIMEType:", logoMIMEType);
        this.dateiBase64 = /*'data:image/'+logoMIMEType+';base64,'+*/this.value._file;
      }

      this.onChange_lastValue = cloneDeep(this.value); // Wichtig! bei Objekten clonen!
  }

  // Angular forms sendet uns eine Referenz auf eine Funktion, die wir "onChange" aufrufen sollen.
  // die zunächst NUR MERKEN, ggf. rufen wir die (siehe onChange()) auf
  registerOnChange(fn: (rating: number) => void): void {
      // console.log("CRUDBasicLogo.registerOnChange() fn:", fn);
      this.onChange_callbackFunction = fn;
  }

  // Angular forms sendet uns eine Referenz auf eine Funktion, die wir "onTouch" aufrufen sollen. 
  registerOnTouched(fn: () => void): void {
      // console.log("CRUDBasicLogo.registerOnTouched() fn:", fn);
      // Vermutlich reicht es aus, das 1:1 an p-autoComplete weiterzugeben, da p-autoComplete bereits formControlName unterstützt
      //this.inputWriteableControl.registerOnTouched(fn);
      //this.onTouch_callbackFunction = fn;
  }

  // Angular forms ruft diese Funktion, wenn sich der disabled-Status ändert.
  setDisabledState(isDisabled: boolean): void {
      // console.log("CRUDBasicLogo.setDisabledState() isDisabled:", isDisabled);
      // Vermutlich reicht es aus, das 1:1 an p-autoComplete weiterzugeben, da p-autoComplete bereits formControlName unterstützt
      //this.inputWriteableControl.setDisabledState(isDisabled);
      //this.inputFormattedControl.setDisabledState(isDisabled);
  }

  upload() { // diese Funktion wird von der ParentComp über ViewChild gecallt: https://stackoverflow.com/questions/37587732/how-to-call-another-components-function-in-angular2
    //console.log("CRUDBasicLogo.upload()");
    //console.log("CRUDBasicLogo.upload() this.logoUpload:", this.logoUpload);
    this.logoUpload.basicFileInput.nativeElement.click();
  }


  logoUploader(event) {
      let thisInstance = this;
      let fileReader = new FileReader();
      for (let file of event.files) {
          fileReader.readAsDataURL(file);
          fileReader.onload = function() {
              //console.log("CRUDBasicLogo.logoUploader() reader.result:", fileReader.result);
              //thisInstance.logoBase64 = (""+fileReader.result).split(/,/)[1];
              thisInstance.dateiBase64 = "" + fileReader.result
              //console.log("MandantenDetailComponent.logoUploader() thisInstance.logoBase64:", thisInstance.logoBase64);

              //thisInstance.value = (""+thisInstance.logoBase64).split(/,/)[1];

              thisInstance.dateiendung = file.name.substr(file.name.lastIndexOf('.') + 1);
              if(thisInstance.value == null) {
                console.log("dokumentDateiUpload.logoUploader() value == null -> initialize!");
                thisInstance.value = thisInstance._dateiService.initialize_Datei();
              }
              else {
                console.log("dokumentDateiUpload.logoUploader() value != null");
              }
              thisInstance.value._file = (""+thisInstance.dateiBase64).split(/,/)[1];
              thisInstance.value.dateiname = file.name.substr(0,file.name.length-thisInstance.dateiendung.length-1);
              thisInstance.value.dateiendung = thisInstance.dateiendung;
              //thisInstance.value.sortierung = 1;
              //thisInstance.value.typ = "G";
              //thisInstance.value.unternehmenId = thisInstance.unternehmenId;

              thisInstance.onChange();
              //thisInstance.CRUDForm.markAsDirty();

              thisInstance.logoUpload.clear(); // sonst kann man nicht nochmal den Button klicken
          };
          break; // nur 1. Datei verarbeiten!
      }
  }

  clear() {
    this.dateiBase64 = null;
    this.value = null;
    this.onChange();
  }

  imageClicked() {
    this.iconClicked.emit();
  }

  getImageNaturalWidth() {
    //console.log("CRUDBasicLogo.getImageNaturalWidth() image:", this.image);
    if(this.image == undefined) return undefined;
    let w = this.image.nativeElement.naturalWidth;
    //console.log("CRUDBasicLogo.getImageNaturalWidth():", w);
    return w;
  }
  getImageNaturalHeight() {
    //console.log("CRUDBasicLogo.getImageNaturalHeight() image:", this.image);
    if(this.image == undefined) return undefined;
    let h = this.image.nativeElement.naturalHeight;
    //console.log("CRUDBasicLogo.getImageNaturalHeight():", h);
    return h;
  }

}