import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { StammdatenKorrekturDetailComponent } from './stammdaten-korrektur-detail.component';
//import { GlobalService } from '../_services/global.service';
import { TranslateService } from '../_services/translate.service';

@Injectable()
export class StammdatenKorrekturDetailGuard implements CanActivate {

  constructor(//private globalService: GlobalService,
    public translate: TranslateService) {

  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return true;
  }

  canDeactivate(component: StammdatenKorrekturDetailComponent): boolean {
    if (component.CRUDForm.dirty && !component.deleted && !component.errorMessage) {
      //let bezeichnung = component.CRUDForm.get('bezeichnung').value || "#pageTitleAdd#";
      //return false;
      return confirm(this.translate.instant('ERROR_UNSAVED_CHANGES'));
    }

    return true;
  }
}
