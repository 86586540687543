// HTML Text als innerHTML ausgeben: https://medium.com/@AAlakkad/angular-2-display-html-without-sanitizing-filtering-17499024b079
import { Pipe, PipeTransform } from '@angular/core';
import { Inject, forwardRef } from '@angular/core';

import { AppComponent } from '../app.component';

import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ name: 'leistung', pure: false })
export class LeistungPipe implements PipeTransform {
  constructor(@Inject(forwardRef(() => AppComponent)) public app: AppComponent,
    private sanitizer: DomSanitizer) {
  }

  transform(content, mode: string) {
    //console.log('LeistungenPipe.transform() content/mode:', content, mode);
    let leistung = this.getLeistungForAuftrag(content);
    if(leistung == null) {
      if(mode != null && mode == 'plainText') return null;
      else if(mode != null && mode == 'iconName') return null;
      else return this.sanitizer.bypassSecurityTrustHtml(`<!--leistung '${content}' not found!-->`);    
    }
    else {
      if(mode != null && mode == 'plainText') return `${leistung.bezeichnung}`;
      else if(mode != null && mode == 'iconName') return `${leistung.icon}`;
      else return this.sanitizer.bypassSecurityTrustHtml(`<i class="fa fa-${leistung.icon}"></i>&nbsp;${leistung.bezeichnung}`);
    }
  }

  getLeistungForAuftrag(leistungToSearchFor) {
    if(this.app.leistungen == null || this.app.leistungen.length == 0) console.log('LeistungenPipe.getIconForAuftrag() WARNING! app.leistungen not available (yet)!');
    let leistung = this.app.leistungen.find( f => f.leistung == leistungToSearchFor );
    //console.log('LeistungenPipe.getIconForAuftrag() leistung:', leistung);
    return leistung;
  }
}