import { Component, OnInit } from '@angular/core';
import { MapComponent } from '../map/map.component';

@Component({
  selector: 'app-liste',
  templateUrl: '../map/map.component.html',
  styleUrls: ['../map/map.component.scss'],
  //providers: [MessageService]
})
export class ListeComponent extends MapComponent implements OnInit {

  // macht gar Nichts anders als Map!  Ist nur deshalb eine "Kopie/Vererbung" um sicherzustellen, dass der ng Router beim Umschalten zw. Map und Liste neu lädt! (sonst "map already initialized von leaflet")
}  
