import { Injectable } from '@angular/core';
//import { Observable } from 'rxjs/Observable';
import { Observable, of } from 'rxjs';
//import 'rxjs/add/operator/map';
//import 'rxjs/add/operator/do';
//import 'rxjs/add/operator/catch';
import { IPagination } from '../_interfaces/pagination'
import { IAnstrich } from '../_interfaces/anstrich';
import { IChoiceList } from '../_interfaces/_choice-list';
import * as moment from 'moment'; // DateTimeOffset-Fix
import * as cloneDeep from 'lodash/cloneDeep'; // DateTimeOffset-Fix

import { AppconfigService } from '../_services/appconfig.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';

import { CRUDBasicService, httpOptions } from '../_services/crud-basic.service';

@Injectable()
export class AnstrichService extends CRUDBasicService {

  //typeOf deprecated -> CRUDBasicService.propertyTypeOf
  /*typeOf(propertyName: string) {  // welchen Typs sind die Properties IN DER API ?
    let property = this.propertyTypes.find(f => f.name == propertyName);
    if(property != null) return property.type;
    else return null;    
  }*/

  propertyTypes = [ // welchen Typs sind die Properties IN DER API ?
    {name: 'id', type: 'int'},
    {name: 'anstrich', type: 'string'},
    {name: 'bezeichnung', type: 'string'},
    {name: 'createdBy', type: 'string'},
    {name: 'created', type: 'DateTimeOffset'},
    {name: 'modifiedBy', type: 'string'},
    {name: 'modified', type: 'DateTimeOffset'},
    {name: 'rowVersion', type: 'byte[]'},
    {name: 'summary', type: 'string'}
  ];
    
  getAnstricheCollection(pageNumber: number, pageSize: number, searchQuery: string): Observable<{ anstriche: IAnstrich[], pagination: IPagination }> {
    return this.httpClient.get(this.apiBaseUrl + 'anstriche?pageNumber=' + pageNumber + '&pageSize=' + pageSize + '&searchQuery=' + searchQuery, { headers: httpOptions, observe: 'response', withCredentials: true })
      .pipe(
        map((response) => {
          return { anstriche: <IAnstrich[]>response.body, 
            pagination: /*<IPagination>JSON.parse(response.headers.get('x-pagination'))*/this.getDummyPagination((<any[]>response.body).length)
          };
        }), catchError(this.handleError))
  }

  getAnstrich(id: number): Observable<IAnstrich> {

    if (id === 0) {
      // return Observable.create((observer: any) => {
      //   observer.next(this.initializeAnstrich());
      //   observer.complete();
      // })
      return of(this.initializeAnstrich());
    }

    return this.httpClient.get<IAnstrich>(this.apiBaseUrl + 'anstriche/?id=' + id, { headers: httpOptions, observe: 'body', withCredentials: true })
      .pipe(map((response) => 
        //response
        {
          return response[0];
        }
      ), catchError(this.handleError));
  }

  initializeAnstrich(): IAnstrich {
    return {
      id: 0,
      anstrich: '',
      bezeichnung: '',
      createdBy: '',
      created: '',
      modifiedBy: '',
      modified: '',
      rowVersion: '',
      summary: ''
    };
  }


}
