//import { CRUDBasicListComponent } from '../crud-basic-list/crud-basic-list.component';
import { /*CRUDBasicListComponent_Template,*/ CRUDBasicListComponent } from '../crud-basic-list/crud-basic-list.component'; // HTML Template Plugins
import { /*CRUDBasicListComponent_Template,*/ CRUDBasicListForChildComponent } from '../crud-basic-list-for-child/crud-basic-list-for-child.component'; // HTML Template Plugins
//import { AuftragsdatenListComponent_Template } from './auftragsdaten-list.component.include_template'; // HTML Template Plugins

import { Component, OnInit, Inject, forwardRef, Injector, Input } from '@angular/core';

import { ActivatedRoute, Router, ActivatedRouteSnapshot, DetachedRouteHandle, Route, RouteReuseStrategy } from '@angular/router';
import { AppComponent } from '../app.component';

//import { IAuftragsdaten } from '../_interfaces/auftragsdaten';
import { AuftragsdatenService } from '../_services/auftragsdaten.service';
import { DokumenteService } from '../_services/dokumente.service';
import { DomSanitizer } from '@angular/platform-browser';
import { LeistungPipe } from '../_pipes/leistung.pipe';
import { BenutzerPipe } from '../_pipes/benutzer.pipe';
import { DatumOhneZeitPipe } from '../_pipes/datum-ohne-zeit.pipe';
import { IAuftragsdaten } from '../_interfaces/auftragsdaten';

@Component({
  selector: 'app-auftragsdaten-list',
  //templateUrl: '../crud-basic-list/crud-basic-list.component.html',
  templateUrl: './auftragsdaten-list.component.html',
  //template: `${CRUDBasicListComponent_Template || ''}${AuftragsdatenListComponent_Template}`,  // HTML Template Plugins
  styleUrls: ['../crud-basic-list/crud-basic-list.component.css','./auftragsdaten-list.component.scss'],
  host: {
    '(window:keydown)': 'hotkeys($event)',
    '(window:resize)': 'sizeTable()'
  }
})
export class AuftragsdatenListComponent extends CRUDBasicListForChildComponent implements OnInit {
  @Input('mode') mode: string; // map oder list

  pageTitle: string = this.translate.instant("Auftragsdaten", true);
  CRUDItemKurzform: string = "Auftragsdaten";
  CRUDItemButtonTitleNeu: string = /*this.translate.instant("Neue Auftragsdaten", true)*/ null;
  CRUDItemBezeichnungSingularCapitalized: string = "Auftragsdaten";
  CRUDItemBezeichnungPluralCapitalized: string = "Auftragsdaten";
  CRUDItemBezeichnungSingular: string = "auftragsdaten";
  CRUDItemBezeichnungPlural: string = "auftragsdaten";
  CRUDItemHelpTopic: string = /*"Auftragsdaten"*/ null;
  CRUDItemRouteSingular: string = "auftragsdaten";
  CRUDItemRoutePlural: string = "auftragsdaten";

  CRUDHideMehrButton: boolean = true;

  multimark_selectionMode: string = "single"; // ist umschaltbar -> multiple für mehrere Aufträge gleichzeitg markieren
  multimark_selectionButtonItems = [
    {
      id: 'startmulti', label: this.translate.instant(/*this.CRUDItemKurzform+' bearbeiten'*/this.multimark_selectionMode == 'multiple' ? 'Auswahl beenden' : 'Auswählen', true), icon: 'fa fa-bars', automationId: 2, command: (res) => {
        this.multimark_selectionModeChange();
      }
    },
    {
      id: 'mark', label: this.translate.instant(/*this.CRUDItemKurzform+' bearbeiten'*/'Markieren', true), disabled: this.multimark_selectionMode != 'multiple', icon: 'fa fa-square-check', automationId: 10, command: (res) => {
        this.multimark_markDeMarkFromList(false);
      }
    },
    {
      id: 'demark', label: this.translate.instant(/*this.CRUDItemKurzform+' bearbeiten'*/'Markierung aufheben', true), disabled: this.multimark_selectionMode != 'multiple', icon: 'fa fa-square', automationId: 20, command: (res) => {
        this.multimark_markDeMarkFromList(true);
      }
    },
    {
      id: 'deletemark', label: this.translate.instant(/*this.CRUDItemKurzform+' löschen'*/'Auswahl aufheben', true), disabled: this.multimark_selectionMode != 'multiple', icon: 'fa fa-light fa-square', automationId: 30, command: (res) => {
        this.selectedCrudItem = [];
      }
    }
  ];

  // ACHTUNG: killTable_(not)InUse ist HIER IN USE !!!
  killTable_InUse: boolean = false;

  //debugMode: boolean = true;

  // customPipe !!!
  leistungPipe: LeistungPipe = new LeistungPipe(this._app, this.sanitizer);
  benutzerPipe: BenutzerPipe = new BenutzerPipe(this._app);
  datumOhneZeitPipe: DatumOhneZeitPipe = new DatumOhneZeitPipe();

  //showList: boolean = true;
  //alreadyAusgeblendet = false;

  CRUDItemAvailableCols: any[] = [
  ];

    constructor(
      @Inject(forwardRef(() => AppComponent)) public _app: AppComponent,
      private _crudItemService: AuftragsdatenService,
      private dokumenteService: DokumenteService,
      //private route: ActivatedRoute,
      private sanitizer: DomSanitizer,
      private _injector: Injector
    ) {
      super(_app, _injector);
      this.crudItemService = _crudItemService;

      //this.mode = this.app.mapComponent.mode; // ist sonst zu der Zeit noch nicht initialisiert
      //this.initSelectedFields();
    }

    ngOnInit() { 
      // wenn Map-Mode, dann anderer UserTableColumnState
      if(this.mode == 'map' || this.mode == 'mapOnly') {
        this.CRUDUserTableColumnStateKey = this.CRUDItemBezeichnungSingular + /*'-list'*/ '-map';
      }
      
      super.ngOnInit(); // HTML Template Plugins
      //this.app.pageTitle = this['pageTitle']; // HTML Template Plugins
    }

    ngAfterViewInit() { // HTML Template Plugins
      if(this._app.inAppMode == true) { // bei inAppMode das Padding vom TableHeader ausblenden
        let tableNativeElement = this.dataTable.el.nativeElement;
        console.log("AuftragsdatenListComponent.ngAfterViewInit() tableNativeElement:", tableNativeElement);
        let firstChild = tableNativeElement.children[0];
        let firstChildFirstChild = firstChild.children[0];
        firstChildFirstChild['style'].padding = '0px';
      }

      super.ngAfterViewInit();
      this.initSelectedFields();
    }

    initSelectedFields() {
      this.CRUDItemAvailableCols = [
        /*{
          field: 'createdBy',
          header: this.translate.instant('CreatedBy', true),
          styleClass: 'columnText',
          sortable: true,
          filterMatchMode: "contains",
          order: 10,
          showByDefault: false
        },
        {
          field: 'created',
          header: this.translate.instant('Created', true),
          styleClass: 'columnText',
          sortable: true,
          filterMatchMode: "contains",
          order: 20,
          showByDefault: false
        },
        {
          field: 'modifiedBy',
          header: this.translate.instant('ModifiedBy', true),
          styleClass: 'columnText',
          sortable: true,
          filterMatchMode: "contains",
          order: 30,
          showByDefault: false
        },
        {
          field: 'modified',
          header: this.translate.instant('Modified', true),
          styleClass: 'columnText',
          sortable: true,
          filterMatchMode: "contains",
          order: 40,
          showByDefault: false
        },*/
        {
          field: 'auftraggeber',
          header: this.translate.instant('Auftraggeber', true),
          styleClass: 'columnText',
          sortable: true,
          filterMatchMode: "contains",
          order: 50,
          showByDefault: false
        },
        {
          field: 'rechnungsempfaenger',
          header: this.translate.instant('Rechnungsempfänger', true),
          styleClass: 'columnText',
          sortable: true,
          filterMatchMode: "contains",
          order: 60,
          showByDefault: false
        },
        {
          field: 'nrVersorgungsunternehmen',
          header: this.translate.instant('Nr. Versorgungsunternehmen', true),
          styleClass: 'columnText',
          sortable: true,
          filterMatchMode: "contains",
          order: 70,
          showByDefault: false
        },
        {
          field: 'niederlassungdesVersorgers',
          header: this.translate.instant('Niederlassung des Versorgers', true),
          styleClass: 'columnText',
          sortable: true,
          filterMatchMode: "contains",
          order: 80,
          showByDefault: false
        },
        {
          field: 'vorgangsart',
          header: this.translate.instant('Vorgangsart', true),
          styleClass: 'columnText',
          sortable: true,
          filterMatchMode: "contains",
          order: 100,
          showByDefault: false
        },
        {
          field: '_pruefergebnis',
          header: this.translate.instant('Prüfergebnis', true),
          styleClass: 'columnText',
          sortable: true,
          filterMatchMode: "contains",
          order: 109,
          showByDefault: this.mode == 'list',
        },
        {
          field: 'auftragsdatum',
          header: this.translate.instant('Auftragsdatum', true),
          styleClass: 'columnText',
          sortable: true,
          filterMatchMode: "contains",
          order: 110,
          showByDefault: false,
          customPipe: this.datumOhneZeitPipe
        },
        {
          field: 'leistung',
          header: this.translate.instant('Leistung', true),
          styleClass: 'columnText',
          sortable: true,
          filterMatchMode: "contains",
          order: 120,
          showByDefault: this.mode == 'list',
          //spezialContentType: 'HTML', // irrelevant weil im HTML separat behandelt
          //customPipe: this.leistungPipe
        },
        {
          field: 'dummy2',
          header: this.translate.instant('Auftrags-Bezeichnung', true),
          styleClass: 'columnText',
          sortable: true,
          filterMatchMode: "contains",
          order: 121,
          showByDefault: this.mode == 'list'
        },
        {
          field: 'pruefdatum',
          header: this.translate.instant('Datum durchgeführte Prüfung', true),
          styleClass: 'columnText',
          sortable: true,
          filterMatchMode: "contains",
          order: 122,
          showByDefault: this.mode == 'list',
          customPipe: this.datumOhneZeitPipe
        },
        {
          field: 'auftragsbemerkung',
          header: this.translate.instant('Auftragsbemerkung', true),
          styleClass: 'columnText',
          sortable: true,
          filterMatchMode: "contains",
          order: 130,
          showByDefault: false
        },
        {
          field: 'bestellposition',
          header: this.translate.instant('Bestellposition', true),
          styleClass: 'columnText',
          sortable: true,
          filterMatchMode: "contains",
          order: 150,
          showByDefault: false
        },
        {
          field: 'endedatum',
          header: this.translate.instant('Fälligkeit', true),
          styleClass: 'columnText',
          sortable: true,
          filterMatchMode: "contains",
          order: 160,
          showByDefault: this.mode == 'list',
          customPipe: this.datumOhneZeitPipe
        },
        {
          field: 'bestellnummer',
          header: this.translate.instant('Bestell-Nr.', true),
          styleClass: 'columnText',
          sortable: true,
          filterMatchMode: "contains",
          order: 161,
          showByDefault: this.mode == 'list'
        },
        {
          field: 'id',
          header: this.translate.instant('Auftrags-Nr.', true),
          styleClass: 'columnText',
          sortable: true,
          filterMatchMode: "contains",
          order: 162,
          showByDefault: this.mode == 'list',
        },
        {
          field: 'terminabspracheerforderlich',
          header: this.translate.instant('Terminabsprache erforderlich', true),
          styleClass: 'columnText',
          sortable: true,
          filterMatchMode: "contains",
          order: 170,
          showByDefault: false
        },
        {
          field: 'anredekennzeichenKunde',
          header: this.translate.instant('Anredekennzeichen Kunde', true),
          styleClass: 'columnText',
          sortable: true,
          filterMatchMode: "contains",
          order: 180,
          showByDefault: false
        },
        {
          field: 'name_1Kunde',
          header: this.translate.instant('Name Kunde', true),
          styleClass: 'columnText',
          sortable: true,
          filterMatchMode: "contains",
          order: 190,
          showByDefault: false
        },
        {
          field: 'name_2Kunde',
          header: this.translate.instant('Name 2 Kunde', true),
          styleClass: 'columnText',
          sortable: true,
          filterMatchMode: "contains",
          order: 200,
          showByDefault: false
        },
        {
          field: 'strasseKunde',
          header: this.translate.instant('Strasse Kunde', true),
          styleClass: 'columnText',
          sortable: true,
          filterMatchMode: "contains",
          order: 210,
          showByDefault: false
        },
        {
          field: 'plzKunde',
          header: this.translate.instant('PLZ Kunde', true),
          styleClass: 'columnText',
          sortable: true,
          filterMatchMode: "contains",
          order: 220,
          showByDefault: false
        },
        {
          field: 'ortKunde',
          header: this.translate.instant('Ort Kunde', true),
          styleClass: 'columnText',
          sortable: true,
          filterMatchMode: "contains",
          order: 230,
          showByDefault: false
        },
          {
          field: 'ortzusatsKunde',
          header: this.translate.instant('Ortzusatz Kunde', true),
          styleClass: 'columnText',
          sortable: true,
          filterMatchMode: "contains",
          order: 240,
          showByDefault: false
        },
        {
          field: 'telefonKunde',
          header: this.translate.instant('Telefon Kunde', true),
          styleClass: 'columnText',
          sortable: true,
          filterMatchMode: "contains",
          order: 250,
          showByDefault: false
        },
        {
          field: 'anredekennzeichen',
          header: this.translate.instant('Anredekennzeichen', true),
          styleClass: 'columnText',
          sortable: true,
          filterMatchMode: "contains",
          order: 260,
          showByDefault: false
        },
        {
          field: 'kundennummer',
          header: this.translate.instant('Kunden-Nr.', true),
          styleClass: 'columnText',
          sortable: true,
          filterMatchMode: "contains",
          order: 261,
          showByDefault: this.mode == 'list',
        },
        {
          field: 'name_1Behaelterstandort',
          header: this.translate.instant('Name '+(this._app.inAppMode != true && this.mode != 'map' ? 'Behälterstandort' : ''), true),
          styleClass: 'columnText',
          sortable: true,
          filterMatchMode: "contains",
          order: 270,
          showByDefault: true,
          tdStyleClass: this.mode == 'map' || this.mode == 'mapOnly' ? 'maplist_col_name' : null,
          thStyleClass: this.mode == 'map' || this.mode == 'mapOnly' ? 'maplist_col_name' : null,
        },
        {
          field: 'name_2Behaelterstandort',
          header: this.translate.instant('Name 2 Behälterstandort', true),
          styleClass: 'columnText',
          sortable: true,
          filterMatchMode: "contains",
          order: 280,
          showByDefault: false
        },
        {
          field: 'strasseBehaelterstandort',
          header: this.translate.instant('Strasse Behälterstandort', true),
          styleClass: 'columnText',
          sortable: true,
          filterMatchMode: "contains",
          order: 290,
          showByDefault: false
        },
        {
          field: 'behaelternummer',
          header: this.translate.instant('Beh.-Nr.', true),
          styleClass: 'columnText',
          sortable: true,
          filterMatchMode: "contains",
          order: 291,
          showByDefault: this.mode == 'list'
        },
        {
          field: 'tankfarbe',
          header: this.translate.instant('Tankfarbe', true),
          styleClass: 'columnText',
          sortable: true,
          filterMatchMode: "contains",
          order: 295,
          showByDefault: this.mode == 'list'
        },
        {
          field: 'notizDienstleister',
          header: this.translate.instant('Notiz', true),
          styleClass: 'columnText',
          sortable: true,
          filterMatchMode: "contains",
          order: 298,
          showByDefault: this.mode == 'list'
        },
        {
          field: 'plzBehaelterstandort',
          header: this.translate.instant('PLZ '+(this._app.inAppMode != true && this.mode != 'map' ? 'Behälterstandort' : ''), true),
          styleClass: 'columnText',
          sortable: true,
          filterMatchMode: "contains",
          order: 305,
          showByDefault: true,
          tdStyleClass: this.mode == 'map' || this.mode == 'mapOnly' ? 'maplist_col_plz' : null,
          thStyleClass: this.mode == 'map' || this.mode == 'mapOnly' ? 'maplist_col_plz' : null,
        },
        {
          field: 'ortBehaelterstandort',
          header: this.translate.instant('Ort '+(this._app.inAppMode != true && this.mode != 'map' ? 'Behälterstandort' : ''), true),
          styleClass: 'columnText',
          sortable: true,
          filterMatchMode: "contains",
          order: 310,
          showByDefault: true,
          tdStyleClass: this.mode == 'map' || this.mode == 'mapOnly' ? 'maplist_col_ort' : null,
          thStyleClass: this.mode == 'map' || this.mode == 'mapOnly' ? 'maplist_col_ort' : null,
        },
        {
          field: 'ortzusatzBehaelterstandort',
          header: this.translate.instant('Ortzusatz Behälterstandort', true),
          styleClass: 'columnText',
          sortable: true,
          filterMatchMode: "contains",
          order: 320,
          showByDefault: false
        },
        {
          field: 'telefonBehaelterstandort',
          header: this.translate.instant('Telefon Behälterstandort', true),
          styleClass: 'columnText',
          sortable: true,
          filterMatchMode: "contains",
          order: 330,
          showByDefault: false
        },
        {
          field: 'bemerkungzumBehaelterstandort',
          header: this.translate.instant('Bemerkung', true),
          styleClass: 'columnText',
          sortable: true,
          filterMatchMode: "contains",
          order: 340,
          showByDefault: false
        },
        {
          field: 'hersteller',
          header: this.translate.instant('Hersteller', true),
          styleClass: 'columnText',
          sortable: true,
          filterMatchMode: "contains",
          order: 360,
          showByDefault: false
        },
        {
          field: 'baujahr',
          header: this.translate.instant('Baujahr', true),
          styleClass: 'columnText',
          sortable: true,
          filterMatchMode: "contains",
          order: 370,
          showByDefault: false,
          customPipe: this.datumOhneZeitPipe
        },
        {
          field: 'behaeltervolumen',
          header: this.translate.instant('Behältervolumen', true),
          styleClass: 'columnText',
          sortable: true,
          filterMatchMode: "contains",
          order: 380,
          showByDefault: false
        },
        {
          field: 'fuellvolumen',
          header: this.translate.instant('Füllvolumen', true),
          styleClass: 'columnText',
          sortable: true,
          filterMatchMode: "contains",
          order: 390,
          showByDefault: false
        },
        {
          field: 'lagerart',
          header: this.translate.instant('Lagerart', true),
          styleClass: 'columnText',
          sortable: true,
          filterMatchMode: "contains",
          order: 400,
          showByDefault: false
        },
        {
          field: 'schutzart',
          header: this.translate.instant('Schutzart', true),
          styleClass: 'columnText',
          sortable: true,
          filterMatchMode: "contains",
          order: 410,
          showByDefault: false
        },
        {
          field: 'ersteAbnahmePruefung',
          header: this.translate.instant('Erste Abnahme Prüfung', true),
          styleClass: 'columnText',
          sortable: true,
          filterMatchMode: "contains",
          order: 420,
          showByDefault: false,
          customPipe: this.datumOhneZeitPipe
        },
        {
          field: 'letztePruefungvorInbetriebnahme',
          header: this.translate.instant('Letzte Prüfung vor Inbetriebnahme', true),
          styleClass: 'columnText',
          sortable: true,
          filterMatchMode: "contains",
          order: 430,
          showByDefault: false,
          customPipe: this.datumOhneZeitPipe
        },
        {
          field: 'letzteInnerePruefungAnlagenpruefung',
          header: this.translate.instant('Letzte Innere Prüfung Anlagenprüfung', true),
          styleClass: 'columnText',
          sortable: true,
          filterMatchMode: "contains",
          order: 440,
          showByDefault: false,
          customPipe: this.datumOhneZeitPipe
        },
        {
          field: 'letzteDruckPruefungFestigkeitspruefung',
          header: this.translate.instant('Letzte Druck-Prüfung Festigkeitsprüfung', true),
          styleClass: 'columnText',
          sortable: true,
          filterMatchMode: "contains",
          order: 450,
          showByDefault: false,
          customPipe: this.datumOhneZeitPipe
        },
        {
          field: 'letzteAeusserePruefung',
          header: this.translate.instant('Letzte Äussere Prüfung', true),
          styleClass: 'columnText',
          sortable: true,
          filterMatchMode: "contains",
          order: 460,
          showByDefault: false,
          customPipe: this.datumOhneZeitPipe
        },
        {
          field: 'baumusternummerBehaelter',
          header: this.translate.instant('Baumuster-Nr. Behälter', true),
          styleClass: 'columnText',
          sortable: true,
          filterMatchMode: "contains",
          order: 470,
          showByDefault: false
        },
        {
          field: 'peilrohrlaenge',
          header: this.translate.instant('Peilrohrlänge', true),
          styleClass: 'columnText',
          sortable: true,
          filterMatchMode: "contains",
          order: 480,
          showByDefault: false
        },
        {
          field: 'sicherheitsventilNr',
          header: this.translate.instant('Sicherheitsventil-Nr.', true),
          styleClass: 'columnText',
          sortable: true,
          filterMatchMode: "contains",
          order: 490,
          showByDefault: false
        },
        {
          field: 'technischerLieferstopp',
          header: this.translate.instant('Technischer Lieferstopp', true),
          styleClass: 'columnText',
          sortable: true,
          filterMatchMode: "contains",
          order: 500,
          showByDefault: false
        },
        {
          field: 'verwaltungsdaten',
          header: this.translate.instant('Verwaltungsdaten', true),
          styleClass: 'columnText',
          sortable: true,
          filterMatchMode: "contains",
          order: 510,
          showByDefault: false
        },
        {
          field: 'geodaten_Laenge',
          header: this.translate.instant('Geodaten Länge', true),
          styleClass: 'columnText',
          sortable: true,
          filterMatchMode: "contains",
          order: 530,
          showByDefault: false
        },
/*        {
          field: 'geoLocation',
          header: this.translate.instant('Geo Location', true),
          styleClass: 'columnText',
          sortable: true,
          filterMatchMode: "contains",
          order: 540,
          showByDefault: false
        },*/
        {
          field: 'ankaNr',
          header: this.translate.instant('Anka-Nr.', true),
          styleClass: 'columnText',
          sortable: true,
          filterMatchMode: "contains",
          order: 550,
          showByDefault: false
        },
        {
          field: 'inbetriebnahmeKKSAnlage',
          header: this.translate.instant('Inbetriebnahme KKS Anlage', true),
          styleClass: 'columnText',
          sortable: true,
          filterMatchMode: "contains",
          order: 560,
          showByDefault: false,
          customPipe: this.datumOhneZeitPipe
        },
        {
          field: 'letztePruefungKKSAnlage',
          header: this.translate.instant('Letzte Prüfung KKS Anlage', true),
          styleClass: 'columnText',
          sortable: true,
          filterMatchMode: "contains",
          order: 570,
          showByDefault: false,
          customPipe: this.datumOhneZeitPipe
        },
        {
          field: 'anKa_Nr_AnlagenschluesselDruck_Anl',
          header: this.translate.instant('AnKa-Nr. Anlagenschlüssel Druck Anl', true),
          styleClass: 'columnText',
          sortable: true,
          filterMatchMode: "contains",
          order: 580,
          showByDefault: false
        },
        {
          field: 'anKa_Nr_Anlagenschluessel_Ex_Anl',
          header: this.translate.instant('AnKa-Nr. Anlagenschlüssel Ex Anl', true),
          styleClass: 'columnText',
          sortable: true,
          filterMatchMode: "contains",
          order: 590,
          showByDefault: false
        },
        {
          field: 'nutzungsart',
          header: this.translate.instant('Nutzungsart', true),
          styleClass: 'columnText',
          sortable: true,
          filterMatchMode: "contains",
          order: 600,
          showByDefault: false
        },
        {
          field: 'anlagenbeschreibung',
          header: this.translate.instant('Anlagenbeschreibung', true),
          styleClass: 'columnText',
          sortable: true,
          filterMatchMode: "contains",
          order: 610,
          showByDefault: false
        },
        {
          field: 'avisierungsart',
          header: this.translate.instant('Avisierungsart', true),
          styleClass: 'columnText',
          sortable: true,
          filterMatchMode: "contains",
          order: 620,
          showByDefault: false
        },
        {
          field: 'kontaktDaten_Avisierung_Telefon',
          header: this.translate.instant('Kontakt Daten Avisierung Telefon', true),
          styleClass: 'columnText',
          sortable: true,
          filterMatchMode: "contains",
          order: 630,
          showByDefault: false
        },
        {
          field: 'kontaktdaten_Avisierung_SMS',
          header: this.translate.instant('Kontaktdaten Avisierung SMS', true),
          styleClass: 'columnText',
          sortable: true,
          filterMatchMode: "contains",
          order: 640,
          showByDefault: false
        },
        {
          field: 'kontaktdaten_Avisierung_EMail',
          header: this.translate.instant('Kontaktdaten Avisierung E-Mail', true),
          styleClass: 'columnText',
          sortable: true,
          filterMatchMode: "contains",
          order: 650,
          showByDefault: false
        },
        {
          field: 'dienstleister',
          header: this.translate.instant('Dienstleister', true),
          styleClass: 'columnText',
          sortable: true,
          filterMatchMode: "contains",
          order: 660,
          showByDefault: false,
        },
        {
          field: 'dispodatum',
          header: this.translate.instant('Dispodatum', true),
          styleClass: 'columnText',
          sortable: true,
          filterMatchMode: "contains",
          order: 670,
          showByDefault: false,
          customPipe: this.datumOhneZeitPipe
        },
        {
          field: 'benutzerId',
          header: this.translate.instant('Benutzer', true),
          styleClass: 'columnText',
          sortable: true,
          filterMatchMode: "contains",
          order: 680,
          showByDefault: false,
          customPipe: this.benutzerPipe
        },
        {
          field: 'ingenieur',
          header: this.translate.instant('Ingenieur', true),
          styleClass: 'columnText',
          sortable: true,
          filterMatchMode: "contains",
          order: 690,
          showByDefault: this.mode == 'list'
        },
      ];

      this.cols = this.CRUDItemAvailableCols.filter(f => f.showByDefault == true);
      this.columnOptions = this.CRUDItemAvailableCols.filter(f => f.showByDefault == false);
    }

    selectCRUDItem(CRUDItem: /*ITitel*/any) { // Overwrite zum CRUDBasic Standard // AM Parent-ChildTab-CRUD
      console.log("AuftragsdatenList.selectCRUDItem() selectedCrudItem:", this.selectedCrudItem);
      console.log("AuftragsdatenList.selectCRUDItem() CRUDItem:", CRUDItem);

      if(CRUDItem.status == 'O') {
        if(CRUDItem.dokumentGuid == null) { // sollte in der Praxis eigentlich nicht vorkommen
          this.messageWrapperService.postTimedMessage({key:'app.main', severity:'error', summary:'Abgeschlossene Aufgabe', detail:'Zu der abgeschlossenen Aufgabe ist kein Prüfergebnis hinterlegt.'});
        }
        else {
          this.messageWrapperService.postTimedMessage({key:'app.main', severity:'info', summary:'Abgeschlossene Aufgabe', detail:'Das Prüfergebnis wird heruntergeladen.'});
          this.dokumenteService.downloadResultByGuidURLViaBlob(CRUDItem.dokumentGuid, 'N');
        }
      }
      else {
        this.app.mapComponent.selectMarker(CRUDItem.id);

        // wenn AppMode: Klick in Liste -> Sprung zur Map + Detail öffnen
        /*if(this.app.inAppMode == true) {
          let urlParamsObj:any = {};
          if(this.app.mapComponent != null) {
            urlParamsObj = this.app.mapComponent.getCurrentViewParms(); // aktuelle Werte beim Wechsel zw. Map und List mitgeben
            //urlParamsObj.openDetail = CRUDItem.id;
          }
          this.router.navigateByUrl('/dummy', {skipLocationChange: true}).then(() => {
            setTimeout(() => {
              this.router.navigate(['/map'], { 
                //queryParamsHandling: "merge",
                queryParams: urlParamsObj // aktuelle Werte beim Wechsel zw. Map und List mitgeben
              }).then(() => {
                // Auf der Map dann nochmal den Marker blinken lassen
                setTimeout(() => {
                  this.app.mapComponent.selectMarker(CRUDItem.id);
                  // und den Marker-Klick simulieren
                  setTimeout(() => {
                    this.app.mapComponent.markerOnClick(CRUDItem.id);
                  }, 10);	
                }, 500);	
              });
            }, 10);		
          });
        }*/
      }

      // Im Multi-Mark Modus: Wenn ein Abgeschlossenen gewählt - sofort wieder aus der Auswahl nehmen! 
      if(this.multimark_selectionMode == 'multiple' && CRUDItem.status == 'O') {
        let auftragIdx = this.selectedCrudItem.findIndex(f => f.id == CRUDItem.id);
        if(auftragIdx != null && auftragIdx > -1) this.selectedCrudItem.splice(auftragIdx);
      }
    }

    /*showList_changed() {
      console.log("AuftragsdatenList.showList_changed()");
      if(this.mode == 'map') this.app.mapComponent.setViewType('mapOnly');
      if(this.mode == 'list') this.app.mapComponent.setViewType('listOnly');
      this.alreadyAusgeblendet = true;
    }*/

    onCRUDItemsRetrieved(CRUDItems: /*ITitel*/any[], pagination: /*IPagination*/any) {
      if(CRUDItems != null) {  // solange noch nicht alle Felder wirklich existieren - und wir den "dummy" einsetzen
        CRUDItems.forEach(auftrag => {
          auftrag['_pruefergebnis'] = auftrag.status == 'O' ? 'Abgeschlossen' : 'Offen';
          auftrag['dummy2'] = null;
          //auftrag['dummy3'] = null;
        });
      }
      super.onCRUDItemsRetrieved(CRUDItems, pagination);
    }

    sizeTable(forceListRedraw?: boolean) { // Overwrite zum CRUDBasic Standard 
      if(this.app.inAppMode == true) {
        // im inAppMode ist alles viel einfacher:
        let landscape = screen.availWidth > screen.availHeight;
        if(landscape) {
          this.scrollHeight = screen.availHeight + "px";
          console.log("AuftragsdatenList.sizeTable() (inAppMode) scrollHeight:", this.scrollHeight);
        }
        else {
          this.scrollHeight = (screen.availHeight / 2) + "px";
          console.log("AuftragsdatenList.sizeTable() (inAppMode) scrollHeight:", this.scrollHeight);
        }
      }
      else {
        let auftragslistDiv = document.getElementById("auftragslistDiv");
        if(auftragslistDiv != null) {
          //let auftragslistDivBounding = auftragslistDiv.getBoundingClientRect();
          let auftragslistDivParent = auftragslistDiv.parentElement;
          if(auftragslistDivParent != null) {
            let auftragslistDivParentBounding = auftragslistDivParent.getBoundingClientRect();
            let dataTableHeaders = document.getElementsByClassName('p-datatable-header');
            if(dataTableHeaders != null && dataTableHeaders.length == 1) {
              let dataTableHeaderBounding = dataTableHeaders[0].getBoundingClientRect();
  
              if(auftragslistDivParentBounding.height != 0 && dataTableHeaderBounding.height != 0) {
                //let scrollbarHeight = 20; // Angenommene Höhe der Scrollbar
                let buffer = 1; // "Vorsichtshalber"
                let availHeightPixels = Math.floor(auftragslistDivParentBounding.height - dataTableHeaderBounding.height - buffer);
                
                let scrollHeight_before = this.scrollHeight;

                //this.scrollHeight = "calc(100vh - " + availHeightPixels.toString() + "px)";
                this.scrollHeight = (availHeightPixels).toString() + "px";

                // Seit virtualScroll gibt es ein Problem:
                // TableRows sind zunächt unsichtbar. Erst wenn man danach die Fenstergrösse ändert wird alles neu gerendert.
                // scrollHeight zu ändern reicht dazu aber nicht - es ist anscheinend ein primeNg-interner Event der das fixt.
                // Abhilfe wäre die scrollHeigt schon ganz zu Beginn zu setzen -> unmöglich, wissen wir ja nicht, ODER:
                // table nach 1. Errechnung der scrollHeight komplett neu rendern (nur bei virtualScroll = mode == 'list'):
                if(this.mode == 'list' && (scrollHeight_before == null || forceListRedraw === true)) {
                  this.killTable_InUse = true;
                  setTimeout(() => {
                    this.killTable_InUse = false;
                  }, 10);	
                }
                
                //console.log("AuftragsdatenList.sizeTable() auftragslistDivParent:", auftragslistDivParent);
                console.log("AuftragsdatenList.sizeTable() auftragslistDivParentBounding.height/dataTableHeaderBounding.height/availHeightPixels:", auftragslistDivParentBounding.height, dataTableHeaderBounding.height, availHeightPixels);
              }
              else { // passiert im Maps-Mode
                this.scrollHeight = null;
                console.log("AuftragsdatenList.sizeTable() setting scrollHeight to null!");
              }
            }
            else {
              console.log("AuftragsdatenList.sizeTable() dataTableHeaders not ready yet.");
            }
          }
          else {
            console.log("AuftragsdatenList.sizeTable() auftragslistDivParent not ready yet.");
          }
        }
      }
    }

    multimark_selectionModeChange() {
      console.log("AuftragsdatenList.multimark_selectionModeChange()");
      if(this.multimark_selectionMode == 'single') {
        this.multimark_selectionMode = 'multiple';
        this.selectedCrudItem = [];
        this.messageWrapperService.postTimedMessage({key:'app.main', severity:'info', summary:'Mehrfach-Auswahl', detail:'Mehrfach-Auswahl aktiviert.'});
      }
      else {
        this.multimark_selectionMode = 'single';
        this.selectedCrudItem = null;
        this.messageWrapperService.postTimedMessage({key:'app.main', severity:'info', summary:'Mehrfach-Auswahl', detail:'Mehrfach-Auswahl deaktiviert.'});
      }

      /*let _menusave = this.multimark_selectionButtonItems;
      this.multimark_selectionButtonItems = null;
      setTimeout(() => {
        this.multimark_selectionButtonItems = _menusave;
      }, 10);		*/
      this.multimark_refreshMenu();
    }

    multimark_refreshMenu() {
      let item = this.multimark_selectionButtonItems.find(f => f.id == 'startmulti');
      item.label = this.translate.instant(/*this.CRUDItemKurzform+' bearbeiten'*/this.multimark_selectionMode == 'multiple' ? 'Auswahl beenden' : 'Auswählen', true);

      item = this.multimark_selectionButtonItems.find(f => f.id == 'mark');
      item.disabled = this.multimark_selectionMode != 'multiple';

      item = this.multimark_selectionButtonItems.find(f => f.id == 'demark');
      item.disabled = this.multimark_selectionMode != 'multiple';

      item = this.multimark_selectionButtonItems.find(f => f.id == 'deletemark');
      item.disabled = this.multimark_selectionMode != 'multiple';
    }

    /*multimark_deleteClickEvent(elementId: string) {
      console.log("AuftragsdatenList.deleteClickEvent() elementId:", elementId);
      let element = document.getElementById(elementId);
      console.log("AuftragsdatenList.deleteClickEvent() element:", element);
      let child0 = element.childNodes[0];
      console.log("AuftragsdatenList.deleteClickEvent() child0:", child0);
      let child1 = child0.childNodes[1];
      console.log("AuftragsdatenList.deleteClickEvent() child1:", child1);
      child1.removeEventListener("click", null);
      child1.removeEventListener("onclick", null);
      
      //child0.onclick = null; 
      //child0['removeAttribute']("onclick"); 
    }*/

    multimark_isRowSelected(crudItem: IAuftragsdaten) {
      //console.log("AuftragsdatenList.isRowSelected() crudItem:", crudItem);
      //console.log("AuftragsdatenList.isRowSelected() selectedCrudItem:", this.selectedCrudItem);
      if(this.selectedCrudItem == null) return false;
      let found = this.selectedCrudItem.filter(f => f.id == crudItem.id);
      //console.log("AuftragsdatenList.isRowSelected() found:", found);
      return found != null && found.length == 1;
    }

    //Manuelles Öffnen des Auswahl-Buttons wenn auf diesen geklickt wird
    //Funktioniert momentan nicht im Internet Explorer vermutlich aufgrund 'triggerEvent'
    multimark_openDropdownMenu() {
      let buttonId = "#_DLP_auftrList_splitBtn_auswahl";
      let parentDropDownBtn = document.querySelector(buttonId);
      let dropDownBtn = parentDropDownBtn.children[0].children[1];
      this.triggerEvent(dropDownBtn, "click");
    }

    multimark_markDeMarkFromList(demark: boolean) {
      console.log("AuftragsdatenList.multimark_markDeMarkFromList() selectedCrudItem/demark:", this.selectedCrudItem, demark);
      if(this.app.mapComponent != null) {
        // selectedCrudItem ist im Multi-Modus ein Array - Die Programmlogik geht aber an den meisten Stellen davon aus, 
        // das es genau ein Auftrag ist (der aktuell markierte)
        // daher:
        // - copy des arrays erstellen
        // - im loop selectedCrudItem mit jeweils 1 Auftrag bestücken - und verarbeiten
        // - danach selectedCrudItem wieder mit dem array bestücken, bzw. clearen!
        if(this.selectedCrudItem != null && this.selectedCrudItem.length > 0) {
          let copyOfSelectenCrudItemsArray = []; 
          this.selectedCrudItem.forEach(auftrag => {
            copyOfSelectenCrudItemsArray.push(auftrag);
          });
          copyOfSelectenCrudItemsArray.forEach(auftrag => {
            this.selectCRUDItem(auftrag);

            if(demark != true) { // markieren-Modus
              if(this.app.auftraegeRoutenplanung.find(f => f.id == auftrag.id) == null) { // auftrag ist noch nicht markiert
                this.app.mapComponent.markierenClicked(auftrag.id);
              }
            }
            else { // de-markieren-Modus
              if(this.app.auftraegeRoutenplanung.find(f => f.id == auftrag.id) != null) { // auftrag ist markiert!
                this.app.mapComponent.demarkierenClicked(auftrag.id);
              }
            }
          });

          //this.selectedCrudItem = []; // Auswahl aufheben

          this.selectedCrudItem = []; 
          copyOfSelectenCrudItemsArray.forEach(auftrag => {
            this.selectedCrudItem.push(auftrag);
          });
        }
      }
    }
}
