// MessageWrapperService: soll sich ähnlich verhalten wie früher IMKE-eigener "MessageService", den quasi ablösen.
// Die Nachricht wird an den primeNg-"MessageService" weitergeleitet
import { Injectable } from '@angular/core';
import { Message } from 'primeng/api';
import { MessageService} from 'primeng/api';
// über app.components messageService senden! (aus einer normalen component den messageService.add() abzusenden, aber die toasts zentral (app.component) zu haben, funktioniert (warum auch immer) nicht!)
import { Inject, forwardRef } from '@angular/core';
import { AppComponent } from '../app.component';

@Injectable({
  providedIn: 'root'
})
export class MessageWrapperService {

  public _app: AppComponent; // muss aus AppComponent bei start initialisiert werden !!!
  constructor(
    /*public*/private messageService: MessageService
  )  {}

  postStaticMessage(message: Message) {
    message.sticky = true;
    message.life = null;
    message.key = "messages";
    if(message.detail != null) message.detail = message.detail.replace(/<br>/g, "\n"); // innerhalb Toasts CR statt BR (wie bei Growls)
    //this.messageService.add(message);
    this._app.messageServiceAdd(message); // über app.components messageService senden! (aus einer normalen component den messageService.add() abzusenden, aber die toasts zentral (app.component) zu haben, funktioniert (warum auch immer) nicht!)
  }

  postTimedMessage(message: Message) {
    message.sticky = false;
    message.life = 5000;
    message.key = "messages";
    message.detail = message.detail.replace(/<br>/g, "\n"); // innerhalb Toasts CR statt BR (wie bei Growls)
    //this.messageService.add(message);
    this._app.messageServiceAdd(message); // über app.components messageService senden! (aus einer normalen component den messageService.add() abzusenden, aber die toasts zentral (app.component) zu haben, funktioniert (warum auch immer) nicht!)

  }
}
