<!--Aus CRUDBasicStandard START -->
<p-confirmDialog [key]="'CRUDBasicDetailConfirmDialog_'+CRUDItemBezeichnungPluralCapitalized" [appendTo]="confirmDialogAppendTo()" [acceptLabel]="translate.instant('Ja',true)" [rejectLabel]="translate.instant('Nein',true)"></p-confirmDialog>
<!-- [style]="{'z-index': '9999'}" -->
<p-blockUI [blocked]="blockedDocument">
  <i class="fa fa-spinner fa-pulse fa-3x fa-fw" style="position:absolute;top:25%;left:50%"></i>
</p-blockUI>
<ng-container *ngTemplateOutlet="CRUDTemplatePluginTop"></ng-container>
<div class="ui-g ui-fluid">
  <div class="ui-g-12 ui-g-nopad">
    <div class="ui-g-12 ui-lg-12" style="padding-top:0">
      <form [formGroup]="CRUDForm" #form (ngSubmit)="validateAndSaveCRUDItem(true)" novalidate>
        <div #divCardToolbar class="card">
          <!-- [ngClass]="CRUDTemplatePluginTop == null ? 'card-toolbar' : null" [class.card-toolbar-static-inactive]="(app.isOverlay() || app.isHorizontal() || app.staticMenuDesktopInactive === true || !app.isDesktop()) && !app.isSlim()"
          [class.card-toolbar-horizontal-active]="!app.isOverlay() && app.isHorizontal() && app.isDesktop()"
          [class.card-toolbar-slim-active]="app.isSlim()"-->
          <!-- class="card card-toolbar" US 14470 Toolbar nur fixiert, wenn kein CRUDTemplatePluginTop drüber -->

          <!--MODI ggü IMKE: Die Buttonleiste ist in einem Grid, weil in der Mitte: pageTitle -->
          <div [ngClass]="{'titlebar-button-grid': !app.inAppMode, 'titlebar-button-grid-inAppMode': app.inAppMode}">
            <div>
              <!--<button style="width: auto;" pButton type="submit" icon="ui-icon-save" [label]="translate.instant('Speichern',true)"></button>-->
              <button style="width: auto;" pButton type="submit" [icon]="app.inAppMode ? 'fa fa-floppy-disk' : CRUDButtonSaveIcon" [style.width]="app.inAppMode ? '2.5rem' : 'unset'" [label]="app.inAppMode ? null : translate.instant(CRUDButtonSaveLabel,true)"></button>
            </div>
            <div style="text-align: center;">
              <span style="line-height: 35px; font-size: medium; font-weight: 500;">{{pageTitle}}</span>
            </div>
            <div>
              <span class="floatRight">
              <!--<button style="width: auto;" pButton type="button" icon="ui-icon-help" [label]="translate.instant('Hilfe',true)" (click)="showHelp()"></button>-->
              <button style="width: auto; margin-left: 5px;" pButton type="button" [icon]="app.inAppMode ? 'fa fa-xmark' : null" [style.width]="app.inAppMode ? '2.5rem' : 'unset'" [label]="app.inAppMode ? null : translate.instant('Abbrechen',true)"
                (click)="cancelCRUDEdit()"></button><!-- [routerLink]="['/'+CRUDItemRoutePlural]"-->
              </span>
            </div>
          </div>
        </div>

        <div [ngClass]="CRUDTemplatePluginTop == null ? 'card-container' : null"><!-- class="card-container" US 14470 Toolbar nur fixiert, wenn kein CRUDTemplatePluginTop drüber -->
          <div class="card">
            <div class="ui-g form-group" id="parentDiv">

              <!--CHILD_CONTENT-->
              <!--<ng-container *ngIf="true==true; then CRUDChildTemplate">das hier dazw. wird niemals angezeigt!</ng-container>-->
              <ng-container *ngTemplateOutlet="CRUDChildTemplate"></ng-container>

              <!--<div class="ui-g-12 ui-md-12">
                  <div class="ui-g-12 ui-md-6">
                    <button pButton type="button" icon="ui-icon-delete" class="ui-button-danger" [label]="translate.instant(this.CRUDItemKurzform+ ' löschen',true)"
                      (click)="deleteCRUDItem()"></button>
                  </div>
              </div>-->

            </div>
          </div>
          <div class="card" style="display: none;"><!--MODI: kein Footer-->
            <!--<app-footer-info *ngIf="CRUDHideModified == false" [data]="CRUDItem" [deleteButtonLabel]="CRUDDisableLoeschen ? null : translate.instant(this.CRUDItemKurzform+ ' löschen',true)" (delete)="deleteCRUDItem()"></app-footer-info>-->

            <div *ngIf="CRUDHideModified == true" class="ui-g">
              <div class="ui-g-12 ui-md-12">
                <div class="ui-g-12 ui-md-6 ui-lg-3">
                  <button pButton type="button" icon="ui-icon-delete" class="ui-button-danger" [label]="translate.instant('Löschen',true)"
                    (click)="deleteCRUDItem()"></button>
                </div>
              </div>
            </div>
            
          </div>
        </div>
        <!--</fieldset>-->
      </form>
    </div>
  </div>
</div>
<button *ngIf="debugMode == true" pButton (click)="debug(form)" icon="fa-bug" title="debug form"></button>
<!--Aus CRUDBasicStandard ENDE -->

<ng-template #CRUDChildTemplate>
  <div class="card">
    <div [ngClass]="{'detail-grid': !_app.inAppMode, 'detail-grid-inAppMode': _app.inAppMode}">
      <div [formGroup]="CRUDForm" class="detail-grid-sub inputTextForm">
        <span class="p-float-label" *ngIf="CRUDItem != null && CRUDItem.kundennummer != null">
          <!--<input style="width:90%" type="text" pInputText formControlName="kundennummer">
          <label>{{'Kundennummer'|translate:true}} <i class="fa-regular fa-circle-exclamation fa-xs"></i></label>-->
          Kundennummer:&nbsp;&nbsp;{{CRUDItem.kundennummer}}
        </span>
      </div>
      <div class="ui-g-12 ui-md-6">
        <div class="errorMessage">
          {{displayMessage.kundennummer}}
        </div>
      </div>
  
      <div [formGroup]="CRUDForm" class="detail-grid-sub inputTextForm">
        <span class="p-float-label" *ngIf="CRUDItem != null && CRUDItem.behaelternummer != null">
          <!--<input style="width:90%" type="text" pInputText formControlName="behaelternummer">
          <label>{{'Behälternummer'|translate:true}} <i class="fa-regular fa-circle-exclamation fa-xs"></i></label>-->
          Behälternummer:&nbsp;{{CRUDItem.behaelternummer}}
        </span>
      </div>
      <div class="ui-g-12 ui-md-6">
        <div class="errorMessage">
          {{displayMessage.behaelternummer}}
        </div>
      </div>
    </div>
  </div>

  <p-fieldset legend="Kontaktdaten techn. Ansprechpartner" [toggleable]="false">
    <div [ngClass]="{'detail-grid': !_app.inAppMode, 'detail-grid-inAppMode': _app.inAppMode}">
  
      <div [formGroup]="CRUDForm" class="detail-grid-sub inputTextForm">
        <span class="p-float-label">
          <input style="width:90%" type="text" pInputText formControlName="name_1">
          <label>{{'Name 1'|translate:true}} <i class="fa-regular fa-circle-exclamation fa-xs"></i></label>
        </span>
      </div>
      <div class="ui-g-12 ui-md-6">
        <div class="errorMessage">
          {{displayMessage.name_1}}
        </div>
      </div>
  
      <div [formGroup]="CRUDForm" class="detail-grid-sub inputTextForm">
        <span class="p-float-label">
          <input style="width:90%" type="text" pInputText formControlName="name_2">
          <label>{{'Name 2'|translate:true}}</label>
        </span>
      </div>
      <div class="ui-g-12 ui-md-6">
        <div class="errorMessage">
          {{displayMessage.name_2}}
        </div>
      </div>
       
<!--      <div [formGroup]="CRUDForm" class="detail-grid-sub inputTextForm">
        <span class="p-float-label">
          <input style="width:90%" type="text" pInputText formControlName="strasse">
          <label>{{'Strasse'|translate:true}}</label>
        </span>
      </div>
      <div class="ui-g-12 ui-md-6">
        <div class="errorMessage">
          {{displayMessage.strasse}}
        </div>
      </div>

      <div [formGroup]="CRUDForm" class="detail-grid-sub inputTextForm">
        <span class="p-float-label">
          <input style="width:90%" type="text" pInputText formControlName="plz">
          <label>{{'Postleitzahl'|translate:true}} <i class="fa-regular fa-circle-exclamation fa-xs"></i></label>
        </span>
      </div>
      <div class="ui-g-12 ui-md-6">
        <div class="errorMessage">
          {{displayMessage.plz}}
        </div>
      </div>

      <div [formGroup]="CRUDForm" class="detail-grid-sub inputTextForm">
        <span class="p-float-label">
          <input style="width:90%" type="text" pInputText formControlName="ort">
          <label>{{'Ort'|translate:true}} <i class="fa-regular fa-circle-exclamation fa-xs"></i></label>
        </span>
      </div>
      <div class="ui-g-12 ui-md-6">
        <div class="errorMessage">
          {{displayMessage.ort}}
        </div>
      </div>
  
      <div [formGroup]="CRUDForm" class="detail-grid-sub inputTextForm">
        <span class="p-float-label">
          <input style="width:90%" type="text" pInputText formControlName="ortzusatz">
          <label>{{'Ortzusatz'|translate:true}}</label>
        </span>
      </div>
      <div class="ui-g-12 ui-md-6">
        <div class="errorMessage">
          {{displayMessage.ortzusatz}}
        </div>
      </div>
-->  
      <!--<div [formGroup]="CRUDForm" class="detail-grid-sub inputTextForm">
        <span class="p-float-label">
          <input style="width:90%" type="text" pInputText formControlName="status">
          <label>{{'Status'|translate:true}} <i class="fa-regular fa-circle-exclamation fa-xs"></i></label>
        </span>
      </div>
      <div class="ui-g-12 ui-md-6">
        <div class="errorMessage">
          {{displayMessage.status}}
        </div>
      </div>-->
  
      <div [formGroup]="CRUDForm" class="detail-grid-sub inputTextForm">
        <span class="p-float-label">
          <input style="width:90%" type="text" pInputText formControlName="telefon">
          <label>{{'Telefon'|translate:true}}</label>
        </span>
      </div>
      <div class="ui-g-12 ui-md-6">
        <div class="errorMessage">
          {{displayMessage.telefon}}
        </div>
      </div>

      <div [formGroup]="CRUDForm" class="detail-grid-sub inputTextForm">
        <span class="p-float-label">
          <input style="width:90%" type="text" pInputText formControlName="eMail">
          <label>{{'E-Mail'|translate:true}}</label>
        </span>
      </div>
      <div class="ui-g-12 ui-md-6">
        <div class="errorMessage">
          {{displayMessage.eMail}}
        </div>
      </div>

      <div [formGroup]="CRUDForm" class="detail-grid-sub inputTextForm">
        <span class="p-float-label">
          <!--<input style="width:90%" type="text" pInputText formControlName="bemerkung">-->
          <textarea #bemerkung style="width: 90%" [rows]="4" pInputTextarea
          formControlName="bemerkung"></textarea><!--kein autoResize="autoResize" sonst optisches Problem beim wieder-reinkommen-->
          <label>{{'Bemerkung'|translate:true}}</label>
        </span>
      </div>
      <div class="ui-g-12 ui-md-6">
        <div class="errorMessage">
          {{displayMessage.bemerkungzum}}
        </div>
      </div>

  
    </div><!--class="detail-grid"-->
  </p-fieldset>

</ng-template>
    
