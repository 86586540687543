//import { /*CRUDBasicDetailComponent_Template,*/ CRUDBasicDetailComponent } from '../crud-basic-detail/crud-basic-detail.component';
import { CRUDBasicDetailForChildComponent } from '../crud-basic-detail-for-child/crud-basic-detail-for-child.component';

import { Component, OnInit, Inject, forwardRef, Injector, ViewChild, Input } from '@angular/core';
import { Validators, FormControl, FormGroup, FormBuilder, AbstractControl, FormControlName } from '@angular/forms';
import { AppComponent } from '../app.component';
import { TranslateService } from '../_services/translate.service';
import { GenericValidator } from '../_helpers/generic-validator';
//import { AutoComplete } from 'primeng/autocomplete';

import { IChecklistePosition } from '../_interfaces/checkliste-position';
import { ChecklistePositionService } from '../_services/checkliste-position.service';
import { ChecklistePositionDetailGuard } from './checkliste-position-detail.guard';

//import { ChecklistePositionDetailComponent_Template } from './checkliste-position-detail.component.include_template';

@Component({
  selector: 'app-checklistePosition-detail',
  //template: `${CRUDBasicDetailComponent_Template || ''}${ChecklistePositionDetailComponent_Template}`,
  templateUrl: './checkliste-position-detail.component.html',
  styleUrls: ['./checkliste-position-detail.component.scss'],
  host: { '(window:keydown)': 'hotkeys($event)' }
})
export class ChecklistePositionDetailComponent extends CRUDBasicDetailForChildComponent implements OnInit {
  // CHILD-spezifisch: Konstanten - START
  CRUDItemKurzform: string = "ChecklistePosition";
  CRUDPageTitleNeu: string = this.translate.instant("Neue Checkliste Position", true);
  CRUDPageTitleBearbeiten: string = this.translate.instant(/*"Checkliste Position bearbeiten"*/"Checkliste Position", true);
  CRUDItemBezeichnungSingularCapitalized: string = "ChecklistePosition";
  CRUDItemBezeichnungPluralCapitalized: string = "ChecklistePositionen";
  CRUDItemBezeichnungSingular: string = "checklistePosition";
  CRUDItemBezeichnungPlural: string = "checklistePositionen";
  CRUDItemRouteSingular: string = "checkliste-position";
  CRUDItemRoutePlural: string = "checkliste-positionen";
  CRUDItemHelpTopic: string = null;

  debugMode: boolean = false;
  // CHILD-spezifisch: Konstanten - Ende

  // CHILD-spezifisch: zusätzliche Widgets (ausser Standard Inputs, Autocomplete) - START
  // CHILD-spezifisch: zusätzliche Widgets (ausser Standard Inputs, Autocomplete) - ENDE

  constructor(
    @Inject( forwardRef(() => AppComponent)) public _app: AppComponent,
    private _injector: Injector,
    private _translate: TranslateService,
    private _crudItemService: ChecklistePositionService,
    private _guard: ChecklistePositionDetailGuard,

    // CHILD-spezifisch: zusätzliche Services - START
    // CHILD-spezifisch: zusätzliche Services - ENDE
  ) {
    super(_app, _injector);

    this.crudItemService = _crudItemService;
    this.guard = _guard;

    // CHILD-spezifisch die Validator Messages bestücken - START
    this.validationMessages =
    {
      platzhalter: {
        maxlength: this._translate.instant('Platzhalter', true) + ' ' + this._translate.instant('darf 255 Zeichen nicht überschreiten', true)
      },

      info: {
        maxlength: this._translate.instant('Info', true) + ' ' + this._translate.instant('darf 255 Zeichen nicht überschreiten', true)
      },

      abhaengigkeit: {
        maxlength: this._translate.instant('Abhängigkeit', true) + ' ' + this._translate.instant('darf 255 Zeichen nicht überschreiten', true)
      },

      bezeichnung: {
        required: this._translate.instant('Bezeichnung', true) + ': ' + this._translate.instant('ist erforderlich', true),
        maxlength: this._translate.instant('Bezeichnung', true) + ' ' + this._translate.instant('darf 255 Zeichen nicht überschreiten', true)
      },

      hinweistext: {
        maxlength: this._translate.instant('Hinweistext', true) + ' ' + this._translate.instant('darf 255 Zeichen nicht überschreiten', true)
      },

      checklisteId: {
        required: this._translate.instant('Checkliste Id', true) + ': ' + this._translate.instant('ist erforderlich', true),
      },

      checklistePositionenFelder: {
        required: this._translate.instant('Checkliste Positionen Felder', true) + ': ' + this._translate.instant('ist erforderlich', true),
      },
};
    // CHILD-spezifisch die Validator Messages bestücken - ENDE
    
    this.genericValidator = new GenericValidator(this.validationMessages);

    this.buildForm();
  }

  buildForm() {
    // CHILD-spezifisch die Form aufbauen - START
    this.CRUDForm = this.fb.group({
      reihenfolge: [0],
      platzhalter: ['', [Validators.maxLength(255)]],
      info: ['', [Validators.maxLength(255)]],
      mussfeld: [false],
      behobenFotosMussfeld: [false],
      mangelFotosMussfeld: [false],
      abhaengigkeit: ['', [Validators.maxLength(255)]],
      bewertung: [false],
      fotos: [false],
      fotosSindDokumente: [false],
      fotosMussFeld: [false],
      //combobox: [false],
      bezeichnung: ['', [Validators.required, Validators.maxLength(255)]],
      hinweistext: ['', [Validators.maxLength(255)]],
      //checklisteId: [0, [Validators.required]],
      //checklistePositionenFelder: [null, [Validators.required]],
    });
    // CHILD-spezifisch die Form aufbauen - ENDE
  }

  ngOnInit() {
    this.blockedDocument = true;

    // CHILD-spezifische Zusätze, z.B. Nachladen anderer Entities - START
    // CHILD-spezifische Zusätze, z.B. Nachladen anderer Entities - ENDE

    super.ngOnInit();
  }

  getValuesFromForm() {
    let a = super.getValuesFromForm(); // Standard! do not change!

    // CHILD-spezifische assigns, z.B. spracheId aus strache.Id bestücken - START
    // CHILD-spezifische assigns, z.B. spracheId aus strache.Id bestücken - ENDE

    //console.log("ChecklistePositionDetailComponent.getValuesFromForm() a:", a);
    return a;
  }

  sendValuesToForm() {
    // CHILD-spezifisch die Form patchen - START
    this.CRUDForm.patchValue({
      reihenfolge: this.CRUDItem.reihenfolge,
      platzhalter: this.CRUDItem.platzhalter,
      info: this.CRUDItem.info,
      mussfeld: this.CRUDItem.mussfeld,
      behobenFotosMussfeld: this.CRUDItem.behobenFotosMussfeld,
      mangelFotosMussfeld: this.CRUDItem.mangelFotosMussfeld,
      abhaengigkeit: this.CRUDItem.abhaengigkeit,
      bewertung: this.CRUDItem.bewertung,
      fotos: this.CRUDItem.fotos,
      fotosSindDokumente: this.CRUDItem.fotosSindDokumente,
      fotosMussFeld: this.CRUDItem.fotosMussFeld,
      //combobox: this.CRUDItem.combobox,
      bezeichnung: this.CRUDItem.bezeichnung,
      hinweistext: this.CRUDItem.hinweistext,
      checklisteId: this.CRUDItem.checklisteId,
      checklistePositionenFelder: this.CRUDItem.checklistePositionenFelder
    });
    // CHILD-spezifisch die Form patchen - ENDE
    //console.log("ChecklistePositionDetailComponent.sendValuesToForm() CRUDForm:", this.CRUDForm);
    super.sendValuesToForm(); // haben nicht alle DetailComponents - erst ab Ticket 9412 17.07.2019
  }

  onCrudItemsValueChangeChecklistePositionenFelder($event) { // Parent+Child-CRUD
    console.log("ChecklistePositionenDetailComponent.onCrudItemsValueChangeChecklistePositionenFelder() this:", this);
    console.log("ChecklistePositionenDetailComponent.onCrudItemsValueChangeChecklistePositionenFelder() $event:", $event);
    this.CRUDItem.checklistePositionenFelder = $event;
    this.CRUDForm.markAsDirty();

    //this.displayMessageForm = {}; // validity neu prüfen
    //this.CRUDForm.updateValueAndValidity(); // validity neu prüfen
  }

  fotosCheckBoxChanged($event) {
    if($event.checked == false) {
      this.CRUDForm.patchValue({
        fotosSindDokumente: false,
        fotosMussFeld: false
      });
    }
  }
}
