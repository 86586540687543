import {Component, OnInit, Input} from '@angular/core';
import {AppComponent} from './app.component';
import { Router } from '@angular/router';
import { IBenutzer } from './_interfaces/benutzer';

import { Subscription } from 'rxjs';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html',
    styleUrls: ['./app.menu.component.scss'],
})
export class AppMenuComponent implements OnInit {

    benutzer: IBenutzer = null;
    benutzerSubscription: Subscription = null;
    modelUserNotSignedIn: any[];
    modelUser: any[];
    model: any[];
    router: Router;

    // da es mit dem Standard aus der primeNG Vorlage zu Verschiebungen beim 2. Öffnen kommt -> alles in ein Array / UL packen!
    modelAllInOne: any[];
    userCnd_isNull: string = "N";
    userCnd_isNOTNull: string = "NN";

    constructor(public app: AppComponent,
        _router: Router
        ) {
            this.router = _router;
        }

    routeToMyDienstleister() {
        let dienstleisterId = this.benutzer.dienstleisterId
        this.router.navigate(['/dienstleister/' + dienstleisterId]);
    }

    ngOnInit() {
        this.benutzerSubscription = this.app.benutzerBehaviourSubject.subscribe(benutzer => {
            if(benutzer != null) {
                console.log("AppMenuComponent.ngOnInit() got benutzer:", benutzer);
                this.benutzer = benutzer;
                //this.model = [...this.model];                 //trigger change detection
                setTimeout(() => {
                    this.benutzerSubscription.unsubscribe(); 
                }, 10);
            }
        });

        this.modelUser = [
            {
                items: [
                    {label: 'Logout', icon: 'fa-solid fa-power-off', routeTo: "logout", badge: null, badgeClass: 'p-badge-info'},
                ]
            },
        ];

        this.modelUserNotSignedIn = [
            {
                items: [
                    {label: 'Login', icon: 'fa-solid fa-right-to-bracket', routerLink: ['/login'], badge: null, badgeClass: 'p-badge-info'},
                ]
            },
        ];

        this.model = [
            {
                //label: 'DLP', icon: 'pi pi-fw pi-home',
                items: [
                    // Karte+Liste über Umweg (home, dann erst Karte/Liste) routen, sonst Fehler Leaflet: Map already initialized
                    //{label: 'Karte', icon: fa-solid fa-map', routerLink: ['/map'], badge: null, badgeClass: 'p-badge-info'},
                    {label: 'Karte', icon: 'fa-solid fa-map', routeTo: 'map', badge: null, badgeClass: 'p-badge-info'},
                    //{label: 'Liste der Aufträge', icon: 'fa-solid fa-list', routerLink: ['/list'], badge: null, badgeClass: 'p-badge-info'},
                    {label: 'Liste der Aufträge', icon: 'fa-solid fa-list', routeTo: "list", badge: null, badgeClass: 'p-badge-info'},

                    {label: 'Routenplanung', icon: 'fa-solid fa-route', routeTo: "route", badge: null, badgeClass: 'p-badge-info'},
                    //{label: 'Aufträge disponieren', icon: 'fa-regular fa-calendar', routeTo: "dispo", badge: null, badgeClass: 'p-badge-info'},

                    {label: 'Anleitungen und Sonstiges', icon: 'fa-regular fa-file-circle-info', routerLink: ['/leitfaeden'], badge: null, badgeClass: 'p-badge-info'},

                    {label: 'App', icon: 'fa-regular fa-tablet', routerLink: ['/app'], badge: null, badgeClass: 'p-badge-info'},

                    {adminOnly: true, label: 'Einstellung: Dienstleister', icon: 'fa-solid fa-cog', routerLink: ['/dienstleister'], badge: null, badgeClass: 'p-badge-info'},
                    {adminOnly: true, label: 'Einstellung: Dokumente', icon: 'fa-solid fa-cog', routerLink: ['/dokumente'], badge: null, badgeClass: 'p-badge-info'},
                    {adminOnly: true, label: 'Einstellung: Checklisten', icon: 'fa-solid fa-cog', routerLink: ['/checklisten'], badge: null, badgeClass: 'p-badge-info'},
                    {adminOnly: true, label: 'Einstellung: Leistungen', icon: 'fa-solid fa-cog', routerLink: ['/leistungen'], badge: null, badgeClass: 'p-badge-info'},

                    //{label: 'uikit/panel', icon: 'fa-solid fa-map', routerLink: ['/uikit/panel'], badge: null, badgeClass: 'p-badge-info'},
                    
                ]
            }
        ];

        this.modelAllInOne = [
            {
                //label: 'DLP', icon: 'pi pi-fw pi-home',
                items: [
                    {userCnd: this.userCnd_isNOTNull, label: 'Logout', icon: 'fa-solid fa-power-off', routeTo: "logout", badge: null, badgeClass: 'p-badge-info'},
                    {userCnd: this.userCnd_isNull, label: 'Login', icon: 'fa-solid fa-right-to-bracket', routerLink: ['/login'], badge: null, badgeClass: 'p-badge-info'},

                    // Leerzeile
                    {userCnd: null, delimiter: true, label: null, icon: null, badge: null, badgeClass: 'p-badge-info'},

                    // Karte+Liste über Umweg (home, dann erst Karte/Liste) routen, sonst Fehler Leaflet: Map already initialized
                    //{label: 'Karte', icon: fa-solid fa-map', routerLink: ['/map'], badge: null, badgeClass: 'p-badge-info'},
                    {userCnd: this.userCnd_isNOTNull, label: 'Karte', icon: 'fa-solid fa-map', routeTo: 'map', badge: null, badgeClass: 'p-badge-info'},
                    //{label: 'Liste der Aufträge', icon: 'fa-solid fa-list', routerLink: ['/list'], badge: null, badgeClass: 'p-badge-info'},
                    {userCnd: this.userCnd_isNOTNull, label: 'Liste der Aufträge', icon: 'fa-solid fa-list', routeTo: "list", badge: null, badgeClass: 'p-badge-info'},

                    {userCnd: this.userCnd_isNOTNull, label: 'Routenplanung', icon: 'fa-solid fa-route', routeTo: "route", badge: null, badgeClass: 'p-badge-info'},
                    {userCnd: this.userCnd_isNOTNull, adminOnly: true, label: 'Aufträge disponieren', icon: 'fa-regular fa-calendar', routeTo: "dispo", badge: null, badgeClass: 'p-badge-info'},

                    {userCnd: this.userCnd_isNOTNull, label: 'Anleitungen und Sonstiges', icon: 'fa-regular fa-file-circle-info', routerLink: ['/leitfaeden'], badge: null, badgeClass: 'p-badge-info'},

                    {userCnd: null, label: 'Über DLP', icon: 'fa-regular fa-circle-info', routerLink: ['/info'], badge: null, badgeClass: 'p-badge-info'},

                    {userCnd: this.userCnd_isNOTNull, adminOnly: true, label: 'Einstellung: Dienstleister', icon: 'fa-solid fa-cog', routerLink: ['/dienstleister'], badge: null, badgeClass: 'p-badge-info'},
                    {userCnd: this.userCnd_isNOTNull, adminOnly: true, label: 'Einstellung: Dokumente', icon: 'fa-solid fa-cog', routerLink: ['/dokumente'], badge: null, badgeClass: 'p-badge-info'},
                    {userCnd: this.userCnd_isNOTNull, adminOnly: true, label: 'Einstellung: Checklisten', icon: 'fa-solid fa-cog', routerLink: ['/checklisten'], badge: null, badgeClass: 'p-badge-info'},
                    {userCnd: this.userCnd_isNOTNull, adminOnly: true, label: 'Einstellung: Leistungen', icon: 'fa-solid fa-cog', routerLink: ['/leistungen'], badge: null, badgeClass: 'p-badge-info'},
                ]
            }
        ];



    }

}
