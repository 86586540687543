import { Injectable } from '@angular/core';
//import { Observable } from 'rxjs/Observable';
import { Observable, of } from 'rxjs';
//import 'rxjs/add/operator/map';
//import 'rxjs/add/operator/do';
//import 'rxjs/add/operator/catch';
import { IPagination } from '../_interfaces/pagination'
import { IChecklistePosition } from '../_interfaces/checkliste-position';
//import { IChoiceList } from '../_interfaces/_choice-list';
import * as moment from 'moment'; // DateTimeOffset-Fix
import * as cloneDeep from 'lodash/cloneDeep'; // DateTimeOffset-Fix

import { AppconfigService } from '../_services/appconfig.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';

import { CRUDBasicService, httpOptions } from '../_services/crud-basic.service';

@Injectable()
export class ChecklistePositionService extends CRUDBasicService {

  //typeOf deprecated -> CRUDBasicService.propertyTypeOf
  /*typeOf(propertyName: string) {  // welchen Typs sind die Properties IN DER API ?
    let property = this.propertyTypes.find(f => f.name == propertyName);
    if(property != null) return property.type;
    else return null;    
  }*/
  propertyTypes = [ // welchen Typs sind die Properties IN DER API ?
    {name: 'id', type: 'int'},
    {name: 'createdBy', type: 'string'},
    {name: 'created', type: 'DateTimeOffset'},
    {name: 'modifiedBy', type: 'string'},
    {name: 'modified', type: 'DateTimeOffset'},
    {name: 'rowVersion', type: 'byte[]'},
    {name: 'reihenfolge', type: 'int'},
    {name: 'platzhalter', type: 'string'},
    {name: 'info', type: 'string'},
    {name: 'mussfeld', type: 'bool'},
    {name: 'behobenFotosMussfeld', type: 'bool'},
    {name: 'mangelFotosMussfeld', type: 'bool'},
    {name: 'abhaengigkeit', type: 'string'},
    {name: 'bewertung', type: 'bool'},
    {name: 'fotos', type: 'bool'},
    {name: 'fotosSindDokumente', type: 'bool'},
    {name: 'fotosMussFeld', type: 'bool'},
    {name: 'combobox', type: 'bool'},
    {name: 'bezeichnung', type: 'string'},
    {name: 'hinweistext', type: 'string'},
    {name: 'summary', type: 'string'}
  ];
    
  getChecklistePositionenCollection(pageNumber: number, pageSize: number, searchQuery: string): Observable<{ checklistePositionen: IChecklistePosition[], pagination: IPagination }> {
    return this.httpClient.get(this.apiBaseUrl + 'checklistepositionen?pageNumber=' + pageNumber + '&pageSize=' + pageSize + '&searchQuery=' + searchQuery, { headers: httpOptions, observe: 'response', withCredentials: true })
      .pipe(
        map((response) => {
          return { checklistePositionen: <IChecklistePosition[]>response.body, pagination: <IPagination>JSON.parse(response.headers.get('x-pagination')) };
        }), catchError(this.handleError))
  }

  getChecklistePosition(id: number): Observable<IChecklistePosition> {

    if (id === 0) {
      // return Observable.create((observer: any) => {
      //   observer.next(this.initializeChecklistePosition());
      //   observer.complete();
      // })
      return of(this.initializeChecklistePosition());
    }

    return this.httpClient.get<IChecklistePosition>(this.apiBaseUrl + 'checklistepositionen/' + id, { headers: httpOptions, observe: 'body', withCredentials: true })
      .pipe(map((response) => response), catchError(this.handleError));
  }

  saveChecklistePosition(checklistePosition: IChecklistePosition): Observable<IChecklistePosition> {
    // DateTimeOffset-Fix
    let itemToSave = cloneDeep(checklistePosition); // clonen, um sicherzustellen, dass das Original-Objekt nicht ver�ndert wird, evtl. passiert nach dem Save noch etwas damit ?
    //console.log("ChecklistePositionService.saveSingularCapitalized#() itemToSave before DateTimeOffset-Fix:", itemToSave);
    this.propertyTypes.filter(f => f.type.toLowerCase() == 'datetimeoffset').forEach(propertyType => {
      if (itemToSave[propertyType.name] != null) {
        itemToSave[propertyType.name] = moment(itemToSave[propertyType.name]).format('YYYY-MM-DDTHH:mm:ss.SSSZ'); // alle datetimeoffset-felder entspr. formatieren, dass in der API auch der Offset ankommt!
      }
    });
    //console.log("ChecklistePositionService.saveSingularCapitalized#() itemToSave after DateTimeOffset-Fix:", itemToSave);

    if (checklistePosition.id === 0) {
      return this.createChecklistePosition(/*checklistePosition*/itemToSave);
    }

    return this.updateChecklistePosition(/*checklistePosition*/itemToSave)

  }

  updateChecklistePosition(checklistePosition: IChecklistePosition): Observable<IChecklistePosition> {

    return this.httpClient.put<IChecklistePosition>(this.apiBaseUrl + 'checklistepositionen/' + checklistePosition.id, checklistePosition, { headers: httpOptions, observe: 'body', withCredentials: true })
    .pipe(catchError(this.handleError))

  }

  deleteChecklistePosition(id: number) {

    return this.httpClient.delete(this.apiBaseUrl + 'checklistepositionen/' + id, { headers: httpOptions, observe: 'body', withCredentials: true })
      .pipe(
        catchError(this.handleError)
      )
  }

  createChecklistePosition(checklistePosition: IChecklistePosition): Observable<IChecklistePosition> {
    return this.httpClient.post<IChecklistePosition>(this.apiBaseUrl + 'checklistepositionen', checklistePosition, { headers: httpOptions, observe: 'body', withCredentials: true })
      .pipe(map((response) => response), catchError(this.handleError))
  }

  initializeChecklistePosition(): IChecklistePosition {
    return {
      id: 0,
      createdBy: '',
      created: /*''*/'0001-01-01T00:00:00.000Z', // fix
      modifiedBy: '',
      modified: /*''*/'0001-01-01T00:00:00.000Z', // fix
      rowVersion: '',
      reihenfolge: 0,
      platzhalter: '',
      info: '',
      mussfeld: false,
      behobenFotosMussfeld: false,
      mangelFotosMussfeld: false,
      abhaengigkeit: '',
      bewertung: false,
      fotos: false,
      fotosSindDokumente: false,
      fotosMussFeld: false,
      combobox: false,
      bezeichnung: '',
      hinweistext: '',
      summary: '',

      checklistePositionenFelder: []
    };
  }


}
