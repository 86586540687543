// HTML Text als innerHTML ausgeben: https://medium.com/@AAlakkad/angular-2-display-html-without-sanitizing-filtering-17499024b079
import { Pipe, PipeTransform } from '@angular/core';
import { Inject, forwardRef } from '@angular/core';

import { AppComponent } from '../app.component';

import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ name: 'benutzer', pure: false })
export class BenutzerPipe implements PipeTransform {
  constructor(@Inject(forwardRef(() => AppComponent)) public app: AppComponent) {
  }

  transform(content, mode: string /* not in use */) {
    //console.log('BenutzerPipe.transform() content/mode:', content, mode);
    let benutzer = this.getBenutzer(content);
    if(benutzer == null) {
      return null;
    }
    else {
      return `${benutzer.vorname} ${benutzer.name}`;
   }
  }

  getBenutzer(benutzerId) {
    if(this.app.benutzerAlleBasicDataOnly == null || this.app.benutzerAlleBasicDataOnly.length == 0) console.log('BenutzerPipe.getBenutzer() WARNING! app.benutzerAlleBasicDataOnly not available (yet)!');
    let benutzer = this.app.benutzerAlleBasicDataOnly.find( f => f.id == benutzerId );
    //console.log('BenutzerPipe.getBenutzer() benutzer:', benutzer);
    return benutzer;
  }
}