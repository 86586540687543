import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpClient, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { AuthService } from './auth.service';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, filter, take, switchMap } from 'rxjs/operators';
//import { Observable } from 'rxjs/Observable'; // für den 1. Versuch
//import { Observable } from 'rxjs/Rx'; // für den 2. versuch

//import { Observable, throwError } from 'rxjs';
//import { catchError, switchMap } from 'rxjs/operators';


@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  debugMode: boolean = false;

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null); // BehaviourSubject: Subject ist wie Observable - aber gleichzeitig auch Observer. Man kann also nicht nur subscriben, sondern auch Werte senden
          // BehaviourSubject: die subscribers erhalten die values ab dem Zeitpunkt wo sie subscriben (evtl. vorherige Werte werden nicht nachgesendet)
          //                   Weitere Besonderheiten: https://stackoverflow.com/questions/39494058/behaviorsubject-vs-observable

  constructor(public authService: AuthService,
    private http: HttpClient) { }

  // Der TokenInterceptor von https://dev-academy.com/angular-jwt führte ncoh zu einem Problem: man konnte die Fehler bei "expired REFRESHToken" nicht abfangen.
  // Warum auch immer, habe ihn durch den ersetzt: https://gist.github.com/monkov/6bf7e385a565ae842153a78777349852
  /*intercept___(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
  {
      if(req.withCredentials != true) {
        console.log("TokenInterceptor.intercept() it's without withCredentials -> just handle like it is: req:", req);
        return next.handle(req).catch(err => {
          console.log("TokenInterceptor.intercept() catched err :", err);
          if(req.url.toLowerCase().endsWith("auth/refreshToken")) {
            console.log("TokenInterceptor.intercept() since the catched err was from ''refreshToken' -> clear tokens!");
            this.authService.removeTokens();
          }
          return Observable.throw(err);
        });
      }
      else { // mit credentials bzw Bearer Auth
        if (this.authService.getJwtToken()) {
          req = this.addToken(req, this.authService.getJwtToken());
          console.log("TokenInterceptor.intercept() it's with withCredentials -> remove 'withCredentials', add Token instead! ...", req);
        }
        else {
          console.log("TokenInterceptor.intercept() it's with withCredentials BUT WE DON'T HAVE A TOKEN YET -> can't change request: ", req);
        }
  
        return next.handle(req).catch(err => {
          console.log("TokenInterceptor.intercept() catched err:  ", err);
            if (err.status === 401) {
                if (true) { // wir bekommen von der API kein spez. Hinweis-Text -> müssen davon ausgehen, dass 401 immer TokenExpired ist
                    //console.log("TokenInterceptor.intercept() err:", err);
                    //Genrate params for token refreshing
                  let params = {
                    //token: this.authService.getJwtToken(),
                    refreshToken: this.authService.getRefreshToken()
                  };
                  return this.http.post('https://localhost:44354/api/auth/refreshToken', params).flatMap(
                    (data: any) => {
                      //If reload successful update tokens
                      if (data.status == 200) {
                        console.log("TokenInterceptor.intercept() successfully refreshed Token! result:", data.result);
                        //Update tokens
                        //localStorange.setItem("api-token", data.result.token);
                        //localStorange.setItem("refreshToken", data.result.refreshToken);
                        this.authService.storeTokens(data.result);
                        //Clone our fieled request ant try to resend it
                        req = req.clone({
                          setHeaders: {
                            'Authorization': `Bearer ${this.authService.getJwtToken()}`
                          }
                        });
                        return next.handle(req).catch(err => {
                          //Catch another error
                          console.log("TokenInterceptor.intercept() catched err AFTER SUCCESSFUL REFRESH:", err);
                          return Observable.throw(err);
                        });
                      }else {
                        console.log("TokenInterceptor.intercept() failed to refreshed Token! result:", data.result);
                        //Logout from account
                        //this.authService.logout();
                      }
                    }
                  );
                }else {
                    console.log("TokenInterceptor.intercept() catched 401, but it's not because of an expired token !?!?! err:", err);
                    // wird niemals vorkommen, siehe dazugehöriger If
                }
            }
            return Observable.throw(err);
        });
      }
  }
*/
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if(request.withCredentials != true) {
      if(this.debugMode==true) console.log("TokenInterceptor.intercept() it's without withCredentials -> just handle like it is: req:", request);
    }
    else {
      if (this.authService.getJwtToken()) {
        request = this.addToken(request, this.authService.getJwtToken());
        if(this.debugMode==true) console.log("TokenInterceptor.intercept() added Token! - new request:", request);
      }
      else {
        request = this.addToken(request, this.authService.getJwtToken()); // trotzdem aufrufen, damit er wenigstens den "withCredentials" wegnimmt
        if(this.debugMode==true) console.log("TokenInterceptor.intercept() Warning! it's with withCredentials! - but we do not have a token! - try it anyway:", request);
      }
    }

    /*if (this.authService.getJwtToken()) {
      request = this.addToken(request, this.authService.getJwtToken());
      console.log("TokenInterceptor.intercept() added Token! - new request:", request)
    }
    else {
      console.log("TokenInterceptor.intercept() didn't change request:", request)
    }*/

    return next.handle(request).pipe(catchError(error => {
      if(this.debugMode==true) {
        console.log("TokenInterceptor.intercept() catched error:", error);
        //console.log("TokenInterceptor.intercept() catched error.status:", error.status);
        //console.log("TokenInterceptor.intercept() catched error.message:", error.message);
        //console.log("TokenInterceptor.intercept() catched error/request:", error, request);
      }
      if (error instanceof HttpErrorResponse && error.status === 401) {
        return this.handle401Error(request, next);
      } else {
        return throwError(error);
      }
    }));
  }
  private addToken(request: HttpRequest<any>, token: string) {
    if(token != null) {
      return request.clone({
        setHeaders: {
          'Authorization': `Bearer ${token}`
        },
        withCredentials: false
      });
    }
    else { // wenigstens den "withCredentials" wegnehmen
      return request.clone({
        withCredentials: false
      });
    }
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if(this.debugMode==true) console.log("TokenInterceptor.handle401Error() isRefreshing/request:", this.isRefreshing, request)
    if (!this.isRefreshing) {  // "Refreshing" hat noch nicht begonnen? Dann jetzt refreshen!
      this.isRefreshing = true;  
      this.refreshTokenSubject.next(null);  // wir setzen den aktuellen Wert auf null (siehe unten "Refreshing bereits begonnen")
                                            // dort wird die Ausführung der weiteren Requests blockiert, bis ungleich null!

      if(this.debugMode==true) console.log("TokenInterceptor.handle401Error() refresh start ...");
      return this.authService.refreshToken().pipe(
        switchMap((token: any) => {
          if(this.debugMode==true) console.log("TokenInterceptor.handle401Error() refresh ends!");
          this.isRefreshing = false;
          this.refreshTokenSubject.next(token.jwt); // wir setzen den aktuellen Wert wieder ungleich null, damit wird die 
                                                    // die Verarbeitung fortgesetzt (siehe unten "Refreshing bereits begonnen")
          if(this.debugMode==true) console.log("TokenInterceptor.handle401Error() handle original request: ", request);
          return next.handle(this.addToken(request, token.jwt)); // den ursprünglichen Request, der zum 401 geführt hat an den eigentlichen ng-HTTPHandler weiterleiten, sprich verarbeiten!
        }
        )
        // MODI ggü der Vorlage: Auch der RefreshToken kann fehlschlagen (z.B. zu alt)
        // das müssen wir catchen und an den Aufrufer zurückgeben, damit Fehlermeldung ausgegeben werden kann.
        // und noch wichtiger: this.isRefreshing = false -> sonst denkt der Client ewig er sei bereits am refrehen ...
        // (RefreshToken gibt bei zu altem Token 403 "Forbidden" aus, nicht 401 "UnAuth"!)
        ,catchError(error => { 
          this.isRefreshing = false; 

          this.authService.removeTokens();
          //this.authService._app.benutzer = null;
          this.authService._app.benutzerBehaviourSubject.next(null);

          return throwError(error)
        })
        );
    } else {  // "Refreshing" hat bereits begonnen ? Dann zunächst alles puffern
      if(this.debugMode==true) console.log("TokenInterceptor.handle401Error() IS REFRESHING! waiting ... request: ", request);
      let observable: Observable<any> = this.refreshTokenSubject.pipe(
        filter(token => token != null), // blocke alle weiteren Requests solange das Refreshing läuft (Das Refreshing setzt den value auf null!)
        take(1), // aus dem nächsten Event ein Observable machen, das finished nach 1 event ... 
        switchMap(jwt => {
          if(this.debugMode==true) console.log("TokenInterceptor.handle401Error() IS REFRESHING! handling request: ", request);
          return next.handle(this.addToken(request, jwt)); // ... und diesen "gepufferten" Request an den eigentlichen ng-HTTPHandler weiterleiten, sprich verarbeiten!
        }));
        if(this.debugMode==true) console.log("TokenInterceptor.handle401Error() IS REFRESHING! waiting ... observable: ", observable);
      return observable;
    }
  }
  
}