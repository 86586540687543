import { CRUDBasicListComponent } from '../crud-basic-list/crud-basic-list.component';
//import { CRUDBasicListComponent_Template, CRUDBasicListComponent } from '../crud-basic-list/crud-basic-list.component'; // HTML Template Plugins
//import { DokumentListComponent_Template } from './dokument-list.component.include_template'; // HTML Template Plugins

import { Component, OnInit, Inject, forwardRef, Injector } from '@angular/core';
import { AppComponent } from '../app.component';

import { IDokumente } from '../_interfaces/dokumente';
import { DokumenteService } from '../_services/dokumente.service';

@Component({
  selector: 'app-dokument-list',
  templateUrl: '../crud-basic-list/crud-basic-list.component.html',
  //templateUrl: './dokument-list.component.html',
  //template: `${CRUDBasicListComponent_Template || ''}${DokumentListComponent_Template}`,  // HTML Template Plugins
  styleUrls: ['../crud-basic-list/crud-basic-list.component.css'],
  host: {
    '(window:keydown)': 'hotkeys($event)',
    '(window:resize)': 'sizeTable()'
  }
})
export class DokumentListComponent extends CRUDBasicListComponent implements OnInit {
  pageTitle: string = this.translate.instant("Dokumente", true);
  CRUDItemKurzform: string = "Dokumente";
  CRUDItemButtonTitleNeu: string = this.translate.instant("Neues Dokument", true);
  CRUDItemBezeichnungSingularCapitalized: string = "Dokument";
  CRUDItemBezeichnungPluralCapitalized: string = "Dokumente";
  CRUDItemBezeichnungSingular: string = "dokument";
  CRUDItemBezeichnungPlural: string = "dokumente";
  CRUDItemHelpTopic: string = null;
  CRUDItemRouteSingular: string = "dokument";
  CRUDItemRoutePlural: string = "dokumente";
  
  CRUDItemAvailableCols: any[] = [
          {
            field: 'createdBy',
            header: this.translate.instant('CreatedBy', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 10,
            showByDefault: false
          },
          {
            field: 'created',
            header: this.translate.instant('Created', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 20,
            showByDefault: false
          },
          {
            field: 'modifiedBy',
            header: this.translate.instant('ModifiedBy', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 30,
            showByDefault: false
          },
          {
            field: 'modified',
            header: this.translate.instant('Modified', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 40,
            showByDefault: false
          },
/*          {
            field: 'dokument',
            header: this.translate.instant('Dokument', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 50,
            showByDefault: true
          },*/
          {
            field: 'bezeichnung',
            header: this.translate.instant('Bezeichnung', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 60,
            showByDefault: true
          },
          {
            field: 'beschreibung',
            header: this.translate.instant('Beschreibung', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 70,
            showByDefault: true
          },
/*          {
            field: 'kopfdaten',
            header: this.translate.instant('Kopfdaten', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 80,
            showByDefault: true
          }*/
          {
            field: 'typ_ChoiceListEntry.bezeichnung',
            header: this.translate.instant('Typ', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 90,
            showByDefault: true
          },
          {
            field: 'reihenfolge',
            header: this.translate.instant('Reihenfolge', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 100,
            showByDefault: true
          },
    ];

    constructor(
      @Inject(forwardRef(() => AppComponent)) public _app: AppComponent,
      private _crudItemService: DokumenteService,
      private _injector: Injector
    ) {
      super(_app, _injector);
      this.crudItemService = _crudItemService;
    }

    //ngOnInit() { // HTML Template Plugins
    //  super.ngOnInit();
    //  this.app.pageTitle = this['pageTitle'];
    //}
}
