<div class="leaflet-control-locate leaflet-bar leaflet-control leaflet-bar-part leaflet-bar-part-single" style="background-color: transparent; position: absolute; top: 0.75em; left: calc(100vW - 3em); height: 32px; width: 32px; box-shadow: none;"
[style.visibility]="app.mapComponent != null ? 'unset' : 'hidden'">
    <i pButton type="button" badge="3" badgeClass="p-badge-secondary" label="" icon="fa-solid fa-filter" iconPos="left" style="float:left; background-color: white; color: #333333; border-color: #777777; border-style: solid; border-width: thin; height: 100%; width: 100%;" (click)="filterShowDialogClicked()"></i>
    <!--<button pButton type="button" badge="3" badgeClass="p-badge-secondary" label="" icon="fa-solid fa-filter" iconPos="left" class="p-button-outlined p-button-secondary" style="float:left; background-color: white; color: #222222; height: 30px; width: 30px;" (click)="filterShowDialogClicked()"></button>-->
</div>

<p-dialog [(visible)]="filterDialogShow" [modal]="true" [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{maxWidth: '26em', minHeight: '20em', maxHeight: '36em'}">
    <ng-template pTemplate="header">
        <div class="details-grid-container">
            <div>
                <i class="fa fa-filter"></i>
            </div>
            <div>
                Filter
            </div>
        </div>
    </ng-template>
    <ng-container>
        <br>
        Leistungsart:<br>
        <p-multiSelect
        [options]="app.leistungenFiltered" [(ngModel)]="app.filterLeistungenSelected" optionLabel="bezeichnung"
            [selectedItemsLabel]="'{0} Leistungsarten ausgewählt'" [style]="{'width': '23.5em', 'height': '36px'}"
            [scrollHeight]="app.mapComponent != null && app.mapComponent.landscape == true ? '22em' : '26em'"
            styleClass="topbar-multiselect"
            appendTo="body" 
            [panelStyle]="{'width':'200px', 'margin-left': app.mapComponent != null && app.mapComponent.landscape == true ? '-10em' : '0em'}" [filter]="false"><!--filter false, weil sonst in der App gleich die Tastatur aufgeht-->
            <ng-template let-leistung pTemplate="item">
                    <div class="leistungen-select-grid-container" style="align-items: center;">
                    <!-- zeile -->
                    <div>
                        <i class="fa fa-{{leistung.icon}}"></i>
                    </div>
                    <div style="white-space: break-spaces;">{{leistung.bezeichnung}}</div>
                    <div style="margin-top: 0;">
                        <!--{{leistungen.count != null ? '('+leistungen.count+')' : ''}}-->
                        <p-badge *ngIf="leistung.count != null" [value]="leistung.count" [style]="{'background': leistung.count != 0 ? '#4488ff' : 'lightgrey'}"></p-badge>
                    </div>
                </div>
            </ng-template>
        </p-multiSelect>

        <br><br>

        Zeitraum:<br>
        <p-dropdown [options]="app.filterPresets" [(ngModel)]="app.filterPresetsSelected" optionLabel="summary"
        [style]="{'width': '23.5em', 'height': '36px'}"
        (onChange)="filterPresetChanged($event)"
        appendTo="body">
        </p-dropdown>

        <ng-container *ngIf="benutzer != null && (benutzer.dienstleister.zuordnung == null || benutzer.dienstleister.zuordnung == ''); then thenTemplateName; else elseTemplateName">
            Here is never showing
        </ng-container>
            
        <ng-template #thenTemplateName>
            <!--<div [style.display]="benutzer != null && (benutzer.dienstleister.zuordnung == null || benutzer.dienstleister.zuordnung == '')? 'unset' : 'none'">-->
            <div>
                &nbsp;<br>
                Dienstleister:<br>
                <!--<span class="p-inputgroup-addon"><i class="far fa-clock"></i></span>-->
                <span class="p-float-label">
                    <p-multiSelect
                    [options]="app.dienstleister" [(ngModel)]="app.filterDienstleisterSelected" optionLabel="bezeichnung"
                        [selectedItemsLabel]="'{0} Dienstleister ausgewählt'"
                        [style]="{'width': '23.5em', 'height': '36px'}"
                        [scrollHeight]="app.mapComponent != null && app.mapComponent.landscape == true ? '22em' : '26em'"
                        styleClass="topbar-multiselect"
                        appendTo="body" 
                        [panelStyle]="{'width':'200px', 'margin-left': app.mapComponent != null && app.mapComponent.landscape == true ? '-10em' : '0em'}" [filter]="false"><!--filter false, weil sonst in der App gleich die Tastatur aufgeht-->                    
                        <ng-template let-dienstleister pTemplate="item">
                            <div style="align-items: center;">
                                <!-- zeile -->
                                <div style="white-space: break-spaces;">{{dienstleister.bezeichnung}}</div>
                            </div>
                        </ng-template>
                    </p-multiSelect>
                </span>
            </div>
        </ng-template>
            
        <ng-template #elseTemplateName>
            <div [style.display]="benutzer != null && benutzer.filterung=='U' ? 'unset' : 'none'">
                &nbsp;<br>
                Zuordnung:<br>
                <p-dropdown [options]="app.filterBenutzerPresets" [(ngModel)]="app.filterBenutzerPresetsSelected" optionLabel="summary"
                [style]="{'width': '23.5em', 'height': '36px'}"
                (onChange)="filterBenutzerPresetChanged($event)"
                appendTo="body">
                </p-dropdown>
            </div>
        </ng-template>

    </ng-container>
    <ng-template pTemplate="footer">
        <button pButton type="button" label="Aktualisieren" icon="pi pi-refresh" iconPos="left" (click)="filterRefreshClicked()"></button>
    </ng-template>
</p-dialog>
