import { throwError as observableThrowError } from 'rxjs';
import { Injectable, OnInit, AfterViewInit } from '@angular/core';

//import { Observable } from 'rxjs/Observable';
import { Observable, of } from 'rxjs';

import { AppconfigService } from '../_services/appconfig.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
//import { TranslateService } from './translate.service';

export const httpOptions =
  new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*'
  });


  // MODIs gegenüber IMKE-Standard IN ALLEN crudbuilder-SERVICES angepasst:
  //
  // getXXX(id: number)
  //   this.apiBaseUrl + 'xxx?id=' + id       statt     this.apiBaseUrl + 'xxx/' + id
  //   , withCredentials: true
  //   //response
  //   {
  //     console.log("xxxService.getKopfdaten() response: ", response)
  //     return response[0];
  //   }
  //
  // getXXXCollection()
  //   , withCredentials: true
  //   return ... pagination: /*<IPagination>JSON.parse(response.headers.get('x-pagination'))*/this.getDummyPagination((<any[]>response.body).length)
  //
  // updateXXX(...)
  //   this.apiBaseUrl + 'xxx'      statt     this.apiBaseUrl + 'xxx/' + id
  //
  // initialize...()
  //   createdBy: '',
  //   created: /*''*/'0001-01-01T00:00:00.000Z', // fix
  //   modifiedBy: '',
  //   modified: /*''*/'0001-01-01T00:00:00.000Z', // fix


@Injectable()
export class CRUDBasicService{

  apiBaseUrl: string;

  constructor(
    /*private*/protected httpClient: HttpClient,
    //protected translate: TranslateService,
    /*private*/protected config: AppconfigService
    ) 
  {
  
    //console.log("CRUDBASIC SERVICE THIS: ", this)

    this.apiBaseUrl = this.config.get('apiBaseUrl');
  }


  propertyTypeOf(propertyName: string) {  // welchen Typs sind die Properties IN DER API ?
    let property = this.propertyTypes.find(f => f.name == propertyName);
    if(property != null) return property;
    else return null;    
  }
  propertyTypes = [ // welchen Typs sind die Properties IN DER API ?
    //{name: 'myField', type: 'decimal', format: '$'},
  ];

  getDummyPagination(totalCount: number) { // simuliert die pagination im IMKE-Stil, die normalerweise der Controller zurückgibt
    let dummyPagination = {
      previousPageLink: null,
      nextPageLink: null,
      totalCount: totalCount,
      pageSize: totalCount,
      currentPage: 1,
      totalPages: 1
    };
    return dummyPagination;
  }

  /*private*/protected handleError(error) {
    console.log("CRUDBasicService.handleError error: ", error);
    //console.trace();

    if(error.status === 422 && (error.error.DbUpdateException || error.error.Error)) { // Modi: ohne "error.error.DbUpdateException" webpack-error! / Ohne "error.error.Error" wird zB bei Tags der Fehler "Es existiert bereits ein Tag mit dieser Bezeichnung für dieses Projekt oder global." nicht angezeigt
      return observableThrowError(error);
    }
    else if (error.status === 422 || error.status === 401) {
      if (error.status === 422) {
        //console.log("CRUDBasicService.handleError error: ", error);
      }
      return observableThrowError(error.Concurrency || error.DbUpdateException || error.Error || 'Server Error');
    }
    else if(error.status >= 500 || error.status == 404 || error.status == 400 || (error.status == 0 || error.statusText == 'Unknown Error')){
      let errorDetails = {message: "Status Code: " + error.status + '<br>' + error.statusText + (error.error != null && typeof error.error == 'string' ? '\r\n' + '(' + error.error + ')' : '')};
      return observableThrowError(errorDetails);
    }

    return observableThrowError(error.error || 'Server error')
  }
}
