import { Injectable } from '@angular/core';
//import { Observable } from 'rxjs/Observable';
import { Observable, of } from 'rxjs';
//import 'rxjs/add/operator/map';
//import 'rxjs/add/operator/do';
//import 'rxjs/add/operator/catch';
import { IPagination } from '../_interfaces/pagination'
import { IDienstleister } from '../_interfaces/dienstleister';
//import { IChoiceList } from '../_interfaces/_choice-list';
import * as moment from 'moment'; // DateTimeOffset-Fix
import * as cloneDeep from 'lodash/cloneDeep'; // DateTimeOffset-Fix


import { AppconfigService } from '../_services/appconfig.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';

import { CRUDBasicService, httpOptions } from '../_services/crud-basic.service';

  // initialize...()

@Injectable()
export class DienstleisterService extends CRUDBasicService {

  //typeOf deprecated -> CRUDBasicService.propertyTypeOf
  /*typeOf(propertyName: string) {  // welchen Typs sind die Properties IN DER API ?
    let property = this.propertyTypes.find(f => f.name == propertyName);
    if(property != null) return property.type;
    else return null;    
  }*/
  propertyTypes = [ // welchen Typs sind die Properties IN DER API ?
    {name: 'id', type: 'int'},
    {name: 'createdBy', type: 'string'},
    {name: 'created', type: 'DateTimeOffset'},
    {name: 'modifiedBy', type: 'string'},
    {name: 'modified', type: 'DateTimeOffset'},
    {name: 'rowVersion', type: 'byte[]'},
    {name: 'bezeichnung', type: 'string'},
    {name: 'email', type: 'string'},
    {name: 'strasse', type: 'string'},
    {name: 'plz', type: 'string'},
    {name: 'ort', type: 'string'},
    {name: 'ortzusatz', type: 'string'},
    {name: 'zuordnung', type: 'string'},
    {name: 'benutzer', type: 'ICollection<Benutzer>'},
    {name: 'kopfdaten', type: 'ICollection<Kopfdaten>'},
    {name: 'summary', type: 'string'}
  ];
    
  getDienstleisterCollection(pageNumber: number, pageSize: number, searchQuery: string): Observable<{ dienstleister: IDienstleister[], pagination: IPagination }> {
    return this.httpClient.get(this.apiBaseUrl + 'dienstleister?pageNumber=' + pageNumber + '&pageSize=' + pageSize + '&searchQuery=' + searchQuery, { headers: httpOptions, observe: 'response', withCredentials: true })
      .pipe(
        map((response) => {
          return { dienstleister: <IDienstleister[]>response.body, pagination: /*<IPagination>JSON.parse(response.headers.get('x-pagination'))*/this.getDummyPagination((<any[]>response.body).length) };
        }), catchError(this.handleError))
  }

  getDienstleister(id: number): Observable<IDienstleister> {

    if (id === 0) {
      return Observable.create((observer: any) => {
        observer.next(this.initializeDienstleister());
        observer.complete();
      })
    }

    return this.httpClient.get<IDienstleister>(this.apiBaseUrl + 'dienstleister?id=' + id, { headers: httpOptions, observe: 'body', withCredentials: true })
      .pipe(map((response) => 
        //   //response
        {
          console.log("KopfdatenService.getKopfdaten() response: ", response)
          return response[0];
        }
      ), catchError(this.handleError));
  }

  saveDienstleister(dienstleister: IDienstleister): Observable<IDienstleister> {
    // DateTimeOffset-Fix
    let itemToSave = cloneDeep(dienstleister); // clonen, um sicherzustellen, dass das Original-Objekt nicht ver�ndert wird, evtl. passiert nach dem Save noch etwas damit ?
    //console.log("DienstleisterService.saveSingularCapitalized#() itemToSave before DateTimeOffset-Fix:", itemToSave);
    this.propertyTypes.filter(f => f.type.toLowerCase() == 'datetimeoffset').forEach(propertyType => {
      if (itemToSave[propertyType.name] != null) {
        itemToSave[propertyType.name] = moment(itemToSave[propertyType.name]).format('YYYY-MM-DDTHH:mm:ss.SSSZ'); // alle datetimeoffset-felder entspr. formatieren, dass in der API auch der Offset ankommt!
      }
    });
    //console.log("DienstleisterService.saveSingularCapitalized#() itemToSave after DateTimeOffset-Fix:", itemToSave);

    if (dienstleister.id === 0) {
      return this.createDienstleister(/*dienstleister*/itemToSave);
    }

    return this.updateDienstleister(/*dienstleister*/itemToSave)

  }

  updateDienstleister(dienstleister: IDienstleister): Observable<IDienstleister> {

    return this.httpClient.put<IDienstleister>(this.apiBaseUrl + 'dienstleister'/*/' + dienstleister.id*/, dienstleister, { headers: httpOptions, observe: 'body', withCredentials: true })
    .pipe(catchError(this.handleError))

  }

  deleteDienstleister(id: number) {

    return this.httpClient.delete(this.apiBaseUrl + 'dienstleister?id=' + id, { headers: httpOptions, observe: 'body', withCredentials: true })
      .pipe(
        catchError(this.handleError)
      )
  }

  createDienstleister(dienstleister: IDienstleister): Observable<IDienstleister> {
    return this.httpClient.post<IDienstleister>(this.apiBaseUrl + 'dienstleister', dienstleister, { headers: httpOptions, observe: 'body', withCredentials: true })
      .pipe(map((response) => response), catchError(this.handleError))
  }

  initializeDienstleister(): IDienstleister {
    return {
      id: 0,
      createdBy: '',
      created: /*''*/'0001-01-01T00:00:00.000Z', // fix
      modifiedBy: '',
      modified: /*''*/'0001-01-01T00:00:00.000Z', // fix
      rowVersion: '',
      bezeichnung: '',
      email: '',
      strasse: '',
      plz: '',
      ort: '',
      ortzusatz: '',
      zuordnung: '',
      benutzer: null,
      kopfdaten: null,
      summary: ''
    };
  }


}
