import {RouterModule} from '@angular/router';
import {NgModule} from '@angular/core';
import {DashboardComponent} from './demo/view/dashboard.component';
import {DashboardAnalyticsComponent} from './demo/view/dashboardanalytics.component';
import {FormLayoutDemoComponent} from './demo/view/formlayoutdemo.component';
import {FloatLabelDemoComponent} from './demo/view/floatlabeldemo.component';
import {InvalidStateDemoComponent} from './demo/view/invalidstatedemo.component';
import {PanelsDemoComponent} from './demo/view/panelsdemo.component';
import {OverlaysDemoComponent} from './demo/view/overlaysdemo.component';
import {MediaDemoComponent} from './demo/view/mediademo.component';
import {MessagesDemoComponent} from './demo/view/messagesdemo.component';
import {MiscDemoComponent} from './demo/view/miscdemo.component';
import {EmptyDemoComponent} from './demo/view/emptydemo.component';
import {ChartsDemoComponent} from './demo/view/chartsdemo.component';
import {FileDemoComponent} from './demo/view/filedemo.component';
import {DocumentationComponent} from './demo/view/documentation.component';
import {AppMainComponent} from './app.main.component';
import {AppNotfoundComponent} from './pages/app.notfound.component';
import {AppErrorComponent} from './pages/app.error.component';
import {AppAccessdeniedComponent} from './pages/app.accessdenied.component';
import {AppLoginComponent} from './pages/app.login.component';
import {InputDemoComponent} from './demo/view/inputdemo.component';
import {ButtonDemoComponent} from './demo/view/buttondemo.component';
import {TableDemoComponent} from './demo/view/tabledemo.component';
import {ListDemoComponent} from './demo/view/listdemo.component';
import {TreeDemoComponent} from './demo/view/treedemo.component';
import {IconsComponent} from './utilities/icons.component';
import {AppCrudComponent} from './pages/app.crud.component';
import {AppCalendarComponent} from './pages/app.calendar.component';
import {AppTimelineDemoComponent} from './pages/app.timelinedemo.component';
import {AppInvoiceComponent} from './pages/app.invoice.component';
import {AppHelpComponent} from './pages/app.help.component';
import {BlocksComponent} from './blocks/blocks/blocks.component';
import {AppWizardComponent} from './pages/app.wizard.component';
import { AppContactusComponent } from './pages/app.contactus.component';

import { LoginComponent } from './auth/login.component';
import { UeberComponent } from './ueber/ueber.component';
//import { RegisterComponent } from './auth/register.component';
import { LoginGuard } from './auth/login.guard';
import { AdminGuard } from './auth/admin.guard';
import { AuthorizedOnlyRouteGuard } from './auth/authorizedOnlyRoute.guard';

import { MapComponent } from './map/map.component';
import { ListeComponent } from './liste/liste.component';
import { DummyComponent } from './dummy/dummy.component';

import { AuftragsChecklisteDetailComponent } from './auftrags-checkliste-detail/auftrags-checkliste-detail.component';
import { AuftragsChecklisteDetailGuard } from './auftrags-checkliste-detail/auftrags-checkliste-detail.guard';

import { ChecklisteListComponent } from './checkliste-list/checkliste-list.component';
import { ChecklisteDetailComponent } from './checkliste-detail/checkliste-detail.component';
import { ChecklisteDetailGuard } from './checkliste-detail/checkliste-detail.guard';

import { LeistungListComponent } from './leistung-list/leistung-list.component';
import { LeistungDetailComponent } from './leistung-detail/leistung-detail.component';
import { LeistungDetailGuard } from './leistung-detail/leistung-detail.guard';

import { DokumentListComponent } from './dokument-list/dokument-list.component';
import { DokumentDetailComponent } from './dokument-detail/dokument-detail.component';
import { DokumentDetailGuard } from './dokument-detail/dokument-detail.guard';

import { StammdatenKorrekturListComponent } from './stammdaten-korrektur-list/stammdaten-korrektur-list.component';
import { StammdatenKorrekturDetailComponent } from './stammdaten-korrektur-detail/stammdaten-korrektur-detail.component';
import { StammdatenKorrekturDetailGuard } from './stammdaten-korrektur-detail/stammdaten-korrektur-detail.guard';
import { LeitfaedenListComponent } from './leitfaeden-list/leitfaeden-list.component';

import { DienstleisterListComponent } from './dienstleister-list/dienstleister-list.component';
import { DienstleisterDetailComponent } from './dienstleister-detail/dienstleister-detail.component';
import { DienstleisterDetailGuard } from './dienstleister-detail/dienstleister-detail.guard';


@NgModule({
    imports: [
        RouterModule.forRoot([
            //{path: 'map', component: MapComponent},
            //{path: 'list', component: ListeComponent},
            //{path: 'dummy', component: DummyComponent},

            //{ path: 'auftragscheckliste/:id', canDeactivate: [AuftragsChecklisteDetailGuard], canActivate: [AuftragsChecklisteDetailGuard], component: AuftragsChecklisteDetailComponent },
            {
                path: '', component: AppMainComponent,
                children: [
                    //{path: '', component: DashboardComponent},

                    {path: '', component: MapComponent},

                    {
                        path: 'login',
                        component: LoginComponent/*,
                        canActivate: [LoginGuard]*/
                    },

                    {path: 'map', component: MapComponent, canActivate: [AuthorizedOnlyRouteGuard], data: { shouldReuse: true, shouldReuseWhenPreviousPath: ['auftragscheckliste/*', 'stammdaten-korrektur/*'] }},
                    {path: 'list', component: ListeComponent, canActivate: [AuthorizedOnlyRouteGuard], data: { shouldReuse: true, shouldReuseWhenPreviousPath: ['auftragscheckliste/*', 'stammdaten-korrektur/*'] }},
                    {path: 'dummy', component: DummyComponent/*, data: { shouldReuse: false }*/},

                    { path: 'auftragscheckliste/:id', canDeactivate: [AuftragsChecklisteDetailGuard], canActivate: [AuthorizedOnlyRouteGuard, AuftragsChecklisteDetailGuard], component: AuftragsChecklisteDetailComponent },

                    { path: 'leitfaeden', canActivate: [AuthorizedOnlyRouteGuard], component: LeitfaedenListComponent},

                    {
                        path: 'info',
                        component: UeberComponent/*,
                        canActivate: [LoginGuard]*/
                    },

                    { path: 'dokumente', canActivate: [AuthorizedOnlyRouteGuard, AdminGuard], component: DokumentListComponent, data: { shouldReuse: true, shouldReuseWhenPreviousPath: ['dokument/*'] } },
                    { path: 'dokument/:id', canDeactivate: [DokumentDetailGuard], canActivate: [AuthorizedOnlyRouteGuard, AdminGuard, DokumentDetailGuard], component: DokumentDetailComponent },
                    { path: 'checklisten', canActivate: [AuthorizedOnlyRouteGuard, AdminGuard], component: ChecklisteListComponent/*, data: { shouldReuse: true, shouldReuseWhenPreviousPath: ['checkliste/*'] }*/ },
                    { path: 'checkliste/:id', canDeactivate: [ChecklisteDetailGuard], canActivate: [AuthorizedOnlyRouteGuard, AdminGuard, ChecklisteDetailGuard], component: ChecklisteDetailComponent },
                    { path: 'leistungen', canActivate: [AuthorizedOnlyRouteGuard, AdminGuard], component: LeistungListComponent, data: { shouldReuse: true, shouldReuseWhenPreviousPath: ['leistung/*'] } },
                    { path: 'leistung/:id', canDeactivate: [LeistungDetailGuard], canActivate: [AuthorizedOnlyRouteGuard, AdminGuard, LeistungDetailGuard], component: LeistungDetailComponent },
                    { path: 'dienstleister', canActivate: [AuthorizedOnlyRouteGuard, AdminGuard], component: DienstleisterListComponent, data: { shouldReuse: true, shouldReuseWhenPreviousPath: ['dienstleister/*'] } },
                    { path: 'dienstleister/:id', canDeactivate: [DienstleisterDetailGuard], canActivate: [AuthorizedOnlyRouteGuard, DienstleisterDetailGuard], component: DienstleisterDetailComponent },

                    { path: 'stammdaten-korrekturen', canActivate: [AuthorizedOnlyRouteGuard, AdminGuard], component: StammdatenKorrekturListComponent, data: { shouldReuse: true, shouldReuseWhenPreviousPath: ['stammdaten-korrektur/*'] } },
                    { path: 'stammdaten-korrektur/:id', canDeactivate: [StammdatenKorrekturDetailGuard], canActivate: [AuthorizedOnlyRouteGuard, StammdatenKorrekturDetailGuard], component: StammdatenKorrekturDetailComponent },

                    {path: 'favorites/dashboardanalytics', component: DashboardAnalyticsComponent},
                    {path: 'uikit/formlayout', component: FormLayoutDemoComponent},
                    {path: 'uikit/floatlabel', component: FloatLabelDemoComponent},
                    {path: 'uikit/invalidstate', component: InvalidStateDemoComponent},
                    {path: 'uikit/input', component: InputDemoComponent},
                    {path: 'uikit/button', component: ButtonDemoComponent},
                    {path: 'uikit/table', component: TableDemoComponent},
                    {path: 'uikit/list', component: ListDemoComponent},
                    {path: 'uikit/tree', component: TreeDemoComponent},
                    {path: 'uikit/panel', component: PanelsDemoComponent},
                    {path: 'uikit/overlay', component: OverlaysDemoComponent},
                    {path: 'uikit/menu', loadChildren: () => import('./demo/view/menus/menus.module').then(m => m.MenusModule)},
                    {path: 'uikit/media', component: MediaDemoComponent},
                    {path: 'uikit/message', component: MessagesDemoComponent},
                    {path: 'uikit/misc', component: MiscDemoComponent},
                    {path: 'uikit/charts', component: ChartsDemoComponent},
                    {path: 'uikit/file', component: FileDemoComponent},
                    {path: 'utilities/icons', component: IconsComponent},
                    {path: 'pages/crud', component: AppCrudComponent},
                    {path: 'pages/calendar', component: AppCalendarComponent},
                    {path: 'pages/timeline', component: AppTimelineDemoComponent},
                    {path: 'pages/invoice', component: AppInvoiceComponent},
                    {path: 'pages/help', component: AppHelpComponent},
                    {path: 'pages/empty', component: EmptyDemoComponent},
                    {path: 'documentation', component: DocumentationComponent},
                    {path: 'blocks', component: BlocksComponent},
                ]
            },
            {path: 'error', component: AppErrorComponent},
            {path: 'access', component: AppAccessdeniedComponent},
            {path: 'notfound', component: AppNotfoundComponent},
            //{path: 'login', component: AppLoginComponent},
            {path: 'wizard', component: AppWizardComponent},
            {path: 'contactus', component: AppContactusComponent},
            {path: '**', redirectTo: '/notfound'},
        ], {scrollPositionRestoration: 'enabled', onSameUrlNavigation: 'reload'})
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
