import { Inject, forwardRef } from '@angular/core';
import { Router } from '@angular/router';
import { KopfdatenService } from '../_services/kopfdaten.service';
import { DokumenteService } from '../_services/dokumente.service';
import { MessageWrapperService } from '../_services/message-wrapper.service';

import { AppComponent } from '../app.component';

export class RouteBuilderAuftragsCheckliste {

    constructor(
        @Inject(forwardRef(() => AppComponent)) public app: AppComponent,
        private router: Router,
        private kopfdatenService: KopfdatenService,
        private dokumenteService: DokumenteService,
        private messageWrapperService: MessageWrapperService
    ) { }

    routeTo(auftragsId: number, fromMapOrList: string /* 'map' or 'list' wegen navigate back / queryparm 'nav' */) {
        let thisInstance = this;
        this.kopfdatenService.getKopfdatenIdAndChecklisteIdAndDokumentIdForAuftragsId(auftragsId).subscribe(response => {
            console.log("RouteBuilderAuftragsCheckliste.routeTo() response:", response);
            if(response.dokumentTyp != null && response.dokumentTyp == "pdf") {
                if(this.app.inAppMode == true) {
                    setTimeout(() => {
                        let eventData = {
                            eventType: "openDocumentPDF",
                            data: JSON.stringify(
                            {
                                documentId: response.dokumentId
                            })
                            //"textTestTextTest"
                        };
                        this.app.sendMessageToDotNet(eventData);

                        this.app.iOSFixResizeWebview('RouteBuilderAuftragsCheckliste.routeTo');
                    }, 10);		
                    thisInstance.app.blockUI = false;
                }
                else {
                    thisInstance.app.blockUI = false;
                    setTimeout(() => {
                        this.messageWrapperService.postTimedMessage({ severity: 'info', summary: "Aufgabe", detail: "Die Aufgabe wird als PDF-Dokument heruntergeladen." }); 
                        //let urlPDF = this.dokumenteService.openInNewTab(response.dokumentId);
                        //console.log("RouteBuilderAuftragsCheckliste.routeTo() open PDF from url:", urlPDF);
                        //window.open(urlPDF, "_blank");
                        this.dokumenteService.openInNewTabViaBlob(response.dokumentId, 'N');
                    }, 10);		
                }
            }
            else {
                thisInstance.app.blockUI = false;
                // Achtung: wichtig: wenn AUS DEM DIALOG zu auftragscheckliste navigiert wird, kann man in der auftragscheckliste nicht scrollen!
                // wir müssen also erst den Dialog schliessen!
                if(thisInstance.app.mapComponent != null) {
                    thisInstance.app.mapComponent.dialogAuftragHide();
                }
                setTimeout(() => {
                    thisInstance.router.navigate(['/auftragscheckliste/' + response.kopfdatenId], { queryParams: { checklisteId: response.checklisteId, auftragsId: auftragsId, dokumentId: response.dokumentId, nav: fromMapOrList} });
                }, 10);		
            }
        }, error => {
            console.error("RouteBuilderAuftragsCheckliste.routeTo() error:", error);
            thisInstance.app.blockUI = false;
            //return this.handleError(error); 
        });
    }
}
