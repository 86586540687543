import { Injectable } from '@angular/core';

import { I_Datei} from '../_interfaces/_datei';

import { CRUDBasicService, httpOptions } from '../_services/crud-basic.service';

@Injectable({
  providedIn: 'root'
})
export class _DateiService extends CRUDBasicService {

  propertyTypes = [ // welchen Typs sind die Properties IN DER API ?
  {name: 'dateiname', type: 'string'},
  {name: 'dateiendung', type: 'string'},
];
  
initialize_Datei(): I_Datei {
  return {
    dateiname: '',
    dateiendung: '',
    _file: null
  };
}

}
