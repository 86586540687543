<!-- START CRUD STANDARD -->
<p-confirmDialog [key]="'CRUDBasicDetailConfirmDialog_'+CRUDItemBezeichnungPluralCapitalized" [appendTo]="confirmDialogAppendTo()" [acceptLabel]="translate.instant('Ja',true)" [rejectLabel]="translate.instant('Nein',true)"></p-confirmDialog>
<!-- [style]="{'z-index': '9999'}" -->
<p-blockUI [blocked]="blockedDocument">
  <i class="fa fa-spinner fa-pulse fa-3x fa-fw" style="position:absolute;top:25%;left:50%"></i>
</p-blockUI>
<ng-container *ngTemplateOutlet="CRUDTemplatePluginTop"></ng-container>
<div class="ui-g ui-fluid">
  <div class="ui-g-12 ui-g-nopad">
    <div class="ui-g-12 ui-lg-12" style="padding-top:0">
      <form [formGroup]="CRUDForm" #form (ngSubmit)="validateAndSaveCRUDItem(true)" novalidate>
        <div #divCardToolbar class="card">
          <!-- [ngClass]="CRUDTemplatePluginTop == null ? 'card-toolbar' : null" [class.card-toolbar-static-inactive]="(app.isOverlay() || app.isHorizontal() || app.staticMenuDesktopInactive === true || !app.isDesktop()) && !app.isSlim()"
          [class.card-toolbar-horizontal-active]="!app.isOverlay() && app.isHorizontal() && app.isDesktop()"
          [class.card-toolbar-slim-active]="app.isSlim()"-->
          <!-- class="card card-toolbar" US 14470 Toolbar nur fixiert, wenn kein CRUDTemplatePluginTop drüber -->

          <!--MODI ggü IMKE: Die Buttonleiste ist in einem Grid, weil in der Mitte: pageTitle -->
          <div class="titlebar-button-grid">
            <div>
              <!--<button style="width: auto;" pButton type="submit" icon="ui-icon-save" [label]="translate.instant('Speichern',true)"></button>-->
              <button style="width: auto;" pButton type="submit" [icon]="CRUDButtonSaveIcon" [label]="translate.instant(CRUDButtonSaveLabel,true)"></button>
            </div>
            <div style="text-align: center;">
              <span style="line-height: 35px; font-size: medium; font-weight: 500;">{{pageTitle}}</span>
            </div>
            <div>
              <span class="floatRight">
              <!--<button style="width: auto;" pButton type="button" icon="ui-icon-help" [label]="translate.instant('Hilfe',true)" (click)="showHelp()"></button>-->
              <button style="width: auto; margin-left: 5px;" pButton type="button" icon="ui-icon-close" [label]="translate.instant('Abbrechen',true)"
                (click)="cancelCRUDEdit()"></button><!-- [routerLink]="['/'+CRUDItemRoutePlural]"-->
              </span>
            </div>
          </div>

        </div>

        <div [ngClass]="CRUDTemplatePluginTop == null ? 'card-container' : null"><!-- class="card-container" US 14470 Toolbar nur fixiert, wenn kein CRUDTemplatePluginTop drüber -->
          <div class="card">
            <div class="ui-g form-group" id="parentDiv">

              <!--CHILD_CONTENT-->
              <!--<ng-container *ngIf="true==true; then CRUDChildTemplate">das hier dazw. wird niemals angezeigt!</ng-container>-->
              <ng-container *ngTemplateOutlet="CRUDChildTemplate"></ng-container>

              <!--<div class="ui-g-12 ui-md-12">
                  <div class="ui-g-12 ui-md-6">
                    <button pButton type="button" icon="ui-icon-delete" class="ui-button-danger" [label]="translate.instant(this.CRUDItemKurzform+ ' löschen',true)"
                      (click)="deleteCRUDItem()"></button>
                  </div>
              </div>-->

            </div>
          </div>
          <div class="card" style="display: none;"><!--MODI: kein Footer-->
            <!--<app-footer-info *ngIf="CRUDHideModified == false" [data]="CRUDItem" [deleteButtonLabel]="CRUDDisableLoeschen ? null : translate.instant(this.CRUDItemKurzform+ ' löschen',true)" (delete)="deleteCRUDItem()"></app-footer-info>-->

            <div *ngIf="CRUDHideModified == true" class="ui-g">
              <div class="ui-g-12 ui-md-12">
                <div class="ui-g-12 ui-md-6 ui-lg-3">
                  <button pButton type="button" icon="ui-icon-delete" class="ui-button-danger" [label]="translate.instant('Löschen',true)"
                    (click)="deleteCRUDItem()"></button>
                </div>
              </div>
            </div>
            
          </div>
        </div>
        <!--</fieldset>-->
      </form>
    </div>
  </div>
</div>
<button *ngIf="debugMode == true" pButton (click)="debug(form)" icon="fa-bug" title="debug form"></button>
<!-- ENDE CRUD STANDARD -->

<ng-template #CRUDChildTemplate>
    <div class="detail-grid">
        <div [formGroup]="CRUDForm" class="detail-grid-sub inputTextForm">
            <span class="p-float-label">
                <input style="width:90%" type="text" pInputText formControlName="leistung">
                <label>{{'Leistung'|translate:true}} <i class="fa-regular fa-circle-exclamation fa-xs"></i></label>
            </span>
        </div>
        <div class="detail-grid-sub">
            <div class="errorMessage">
                {{displayMessage.leistung}}
                {{displayMessageForm.zahl_0_nicht_zulaessig}}
            </div>
        </div>

        <div [formGroup]="CRUDForm" class="detail-grid-sub inputTextForm">
            <span class="p-float-label">
                <input style="width:90%" type="text" pInputText formControlName="typ">
                <label>{{'Typ'|translate:true}} <i class="fa-regular fa-circle-exclamation fa-xs"></i></label>
            </span>
        </div>
        <div class="detail-grid-sub">
            <div class="errorMessage">
                {{displayMessage.typ}}
            </div>
        </div>

        <div [formGroup]="CRUDForm" class="detail-grid-sub inputTextForm">
            <span class="p-float-label">
                <input style="width:90%" type="text" pInputText formControlName="bezeichnung">
                <label>{{'Bezeichnung'|translate:true}} <i class="fa-regular fa-circle-exclamation fa-xs"></i></label>
            </span>
        </div>
        <div class="detail-grid-sub">
            <div class="errorMessage">
                {{displayMessage.bezeichnung}}
            </div>
        </div>

        <div [formGroup]="CRUDForm" class="detail-grid-sub inputTextForm">
            <div class="p-inputgroup" style="width:90%">
                <span class="p-float-label">
                    <input style="width:90%" type="text" pInputText formControlName="icon">
                    <span class="p-inputgroup-addon">
                        <i class="fa fa-{{CRUDForm.value.icon}}"></i>
                    </span>
                    <label>{{'Icon'|translate:true}} <i class="fa-regular fa-circle-exclamation fa-xs"></i></label>
                </span>
            </div>
            <a href="https://fontawesome.com/search?q={{CRUDForm.value.icon}}" style="float: right; margin-right: 10%;" target="_blank">
                <i class="fa fa-arrow-up-right-from-square"></i>&nbsp;fontawesome.com/search
            </a>
        </div>
        <div class="detail-grid-sub">
            <div class="errorMessage">
                {{displayMessage.icon}}
            </div>
        </div>

        <div [formGroup]="CRUDForm" class="detail-grid-sub inputTextForm">
            <span class="p-float-label">
                <input style="width:90%" type="text" pInputText formControlName="geringfuegiger_Mangel">
                <label>{{'Geringfügiger Mangel'|translate:true}} <i class="fa-regular fa-circle-exclamation fa-xs"></i></label>
            </span>
        </div>
        <div class="detail-grid-sub">
            <div class="errorMessage">
                {{displayMessage.geringfuegiger_Mangel}}
            </div>
        </div>

        <div [formGroup]="CRUDForm" class="detail-grid-sub inputTextForm">
            <span class="p-float-label">
                <input style="width:90%" type="text" pInputText formControlName="erheblicher_Mangel">
                <label>{{'Erheblicher Mangel'|translate:true}} <i class="fa-regular fa-circle-exclamation fa-xs"></i></label>
            </span>
        </div>
        <div class="detail-grid-sub">
            <div class="errorMessage">
                {{displayMessage.erheblicher_Mangel}}
            </div>
        </div>

        <div [formGroup]="CRUDForm" class="detail-grid-sub inputTextForm">
            <span class="p-float-label">
                <!--<input style="width:90%" type="text" pInputText formControlName="checkliste">-->
                <crud-basic-autocomplete [formGroup]="CRUDForm" style="width: 100%;" [autoCompleteStyle]="{'width':'90%'}"
                                         formControlName="checkliste"
                                         id="checkliste" [required]="false" [type]="'checkliste'"
                                         [allowUnvalidatedInput]="false">
                </crud-basic-autocomplete>
                <label>{{'Checkliste'|translate:true}} <i class="fa-regular fa-circle-exclamation fa-xs"></i></label>
            </span>
        </div>
        <div class="detail-grid-sub">
            <div class="errorMessage">
                {{displayMessage.checkliste}}
            </div>
        </div>

        <div [formGroup]="CRUDForm" class="detail-grid-sub checkboxes">
            <span class="p-float-label">
                <crud-basic-checkbox formControlName="pumpentyp" [nullable]="true" [label]="translate.instant('Pumpentyp',true)"></crud-basic-checkbox>
                <!--<p-checkbox label="Pumpentyp" pTooltip="Pumpentyp" formControlName="pumpentyp" [binary]="true" [style]="{'margin-left': '0.76em'}"></p-checkbox>-->
            </span>
        </div>
        <div class="detail-grid-sub">
            <div class="errorMessage">
                {{displayMessage.pumpentyp}}
            </div>
        </div>

        <div [formGroup]="CRUDForm" class="detail-grid-sub checkboxes">
            <span class="p-float-label">
                <crud-basic-checkbox formControlName="anstrich" [nullable]="true" [label]="translate.instant('Anstrich',true)"></crud-basic-checkbox>
                <!--<p-checkbox label="Anstrich" pTooltip="Anstrich" formControlName="anstrich" [binary]="true" [style]="{'margin-left': '0.76em'}"></p-checkbox>-->
            </span>
        </div>
        <div class="detail-grid-sub">
            <div class="errorMessage">
                {{displayMessage.anstrich}}
            </div>
        </div>

        <div [formGroup]="CRUDForm" class="detail-grid-sub checkboxes">
            <span class="p-float-label">
                <crud-basic-checkbox formControlName="unterschriftKunde" [nullable]="true" [label]="translate.instant('Unterschrift Kunde',true)"></crud-basic-checkbox>
                <!--<p-checkbox label="UnterschriftKunde" pTooltip="UnterschriftKunde" formControlName="unterschriftKunde" [binary]="true" [style]="{'margin-left': '0.76em'}"></p-checkbox>-->
            </span>
        </div>
        <div class="detail-grid-sub">
            <div class="errorMessage">
                {{displayMessage.unterschriftKunde}}
            </div>
        </div>

        <div [formGroup]="CRUDForm" class="detail-grid-sub checkboxes">
            <span class="p-float-label">
                <crud-basic-checkbox formControlName="pruefergebnis" [nullable]="true" [label]="translate.instant('Prüfergebnis',true)"></crud-basic-checkbox>
                <!--<p-checkbox label="Pruefergebnis" pTooltip="Pruefergebnis" formControlName="pruefergebnis" [binary]="true" [style]="{'margin-left': '0.76em'}"></p-checkbox>-->
            </span>
        </div>
        <div class="detail-grid-sub">
            <div class="errorMessage">
                {{displayMessage.pruefergebnis}}
            </div>
        </div>

    </div>
</ng-template>
    
