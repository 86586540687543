<div class="layout-topbar shadow-4" [style.backgroundColor]="backgroundColor != null ? backgroundColor : 'unset'">
    <div [ngClass]="{'topbar-grid-container-3widgets': benutzerDisplay3Widgets, 'topbar-grid-container-normal': !benutzerDisplay3Widgets}"
     style="background-color: inherit;" [style.backgroundColor]="backgroundColor != null ? backgroundColor : 'unset'">
        <div [ngClass]="{'topbar-grid-container-3widgets-left': benutzerDisplay3Widgets, 'topbar-grid-container-normal-left': !benutzerDisplay3Widgets}">
            <div>&nbsp;&nbsp;</div>
            <div class="p-inputgroup" [style.visibility]="app.mapComponent != null ? 'unset' : 'hidden'">
                <!--<span class="p-inputgroup-addon"><i class="far fa-clock"></i></span>-->
                <span class="p-float-label">
                    <p-multiSelect [options]="app.leistungenFiltered" [(ngModel)]="app.filterLeistungenSelected" optionLabel="bezeichnung"
                                   [selectedItemsLabel]="'{0} Leistungsarten ausgewählt'" [style]="{'width': '270px', 'height': '36px'}"
                                   scrollHeight="400px" styleClass="topbar-multiselect">
                        <ng-template let-leistung pTemplate="item">
                            <div class="leistungen-select-grid-container" style="align-items: center;">
                                <!-- zeile -->
                                <div>
                                    <i class="fa fa-{{leistung.icon}}"></i>
                                </div>
                                <div style="white-space: break-spaces;">{{leistung.bezeichnung}}</div>
                                le
                                <div style="margin-top: 0;">
                                    <!--{{leistungen.count != null ? '('+leistungen.count+')' : ''}}-->
                                    <p-badge *ngIf="leistung.count != null" [value]="leistung.count" [style]="{'background': leistung.count != 0 ? '#4488ff' : 'lightgrey'}"></p-badge>
                                </div>
                            </div>
                        </ng-template>
                    </p-multiSelect>
                    <label class="topbarLabel">Leistungsart</label>
                </span>
            </div>

            <div>&nbsp;&nbsp;</div>
            <div class="p-inputgroup" [style.visibility]="app.mapComponent != null ? 'unset' : 'hidden'">
                <!--<span class="p-inputgroup-addon"><i class="far fa-clock"></i></span>-->
                <span class="p-float-label">
                    <p-dropdown [options]="app.filterPresets" [(ngModel)]="app.filterPresetsSelected" optionLabel="summary"
                                [style]="{'width': '160px', 'height': '36px'}"
                                (onChange)="filterPresetChanged($event)">
                    </p-dropdown>
                    <label class="topbarLabel"><!--Filter--><i class="fa-solid fa-filter"></i>&nbsp;&nbsp;Zeitraum</label>
                </span>
            </div>

            <!--<p-checkbox binary="true" [(ngModel)]="app.filterNurUeberfaelligJahre" label="nur Fällige"></p-checkbox>-->
            <!--<div class="grid" style="width: 150px; margin-top: 0;">
        <div class="col-8" style="text-align: right;">
            nur Fällige
        </div>
        <div class="col-4">
            <p-inputSwitch [(ngModel)]="app.filterNurUeberfaelligJahre"></p-inputSwitch>
        </div>
    </div>-->


            <ng-container *ngIf="app.mapComponent != null && benutzer != null && (benutzer.dienstleister.zuordnung == null || benutzer.dienstleister.zuordnung == ''); then thenTemplateName; else elseTemplateName">
                Here is never showing
            </ng-container>

            <ng-template #thenTemplateName>
                <div>&nbsp;&nbsp;</div>
                <!--<div class="p-inputgroup" style="margin-left: 7px; margin-top: -18px;" [style.display]="app.mapComponent != null && benutzer != null && (benutzer.dienstleister.zuordnung == null || benutzer.dienstleister.zuordnung == '')? 'inline-block' : 'none'">-->
                <div class="p-inputgroup" style="margin-left: 7px; margin-top: -18px;">
                    <!--<span class="p-inputgroup-addon"><i class="far fa-clock"></i></span>-->
                    <span class="p-float-label">
                        <p-multiSelect [options]="app.dienstleister" [(ngModel)]="app.filterDienstleisterSelected" optionLabel="bezeichnung"
                                       [selectedItemsLabel]="'{0} Dienstleister ausgewählt'" [style]="{'width': '200px', 'height': '36px'}"
                                       scrollHeight="400px">
                            <ng-template let-dienstleister pTemplate="item">
                                <div style="align-items: center;">
                                    <!-- zeile -->
                                    <div style="white-space: break-spaces;">{{dienstleister.bezeichnung}}</div>
                                </div>
                            </ng-template>
                        </p-multiSelect>
                        <label class="topbarLabel">Dienstleister</label>
                    </span>
                </div>
            </ng-template>

            <ng-template #elseTemplateName>
                <div class="p-inputgroup" style="margin-left: 7px; margin-top: -18px;" [style.display]="app.mapComponent != null && benutzer != null && benutzer.filterung=='U' ? 'inline-block' : 'none'">
                    <!--<span class="p-inputgroup-addon"><i class="far fa-clock"></i></span>-->
                    <span class="p-float-label">
                        <p-dropdown [options]="app.filterBenutzerPresets" [(ngModel)]="app.filterBenutzerPresetsSelected" optionLabel="summary"
                                    [style]="{'width': '160px', 'height': '36px'}"
                                    (onChange)="filterBenutzerPresetChanged($event)">
                        </p-dropdown>
                        <label class="topbarLabel"><!--Filter--><i class="fa-solid fa-filter"></i>&nbsp;&nbsp;Zuordnung</label>
                    </span>
                </div>
            </ng-template>

            <div style="margin-left: 10px; vertical-align: middle;"
                 [style.visibility]="app.mapComponent != null ? 'unset' : 'hidden'"
                 [style.marginTop]="'-1em'">
                <!-- [style.marginTop]="isFirefox == true ? '-4em' : '-1em'" -->
                <a class="layout-topbar-action rounded-circle" (click)="app.mapComponent.refreshAuftraegeMarkerAndCounter()" pRipple>
                    <i class="pi pi-refresh fs-large"></i>
                </a>
            </div>
        </div>
        <div [ngClass]="{'topbar-grid-container-3widgets-middle': benutzerDisplay3Widgets, 'topbar-grid-container-normal-middle': !benutzerDisplay3Widgets}">
            <div style="width: 100%; text-align: center;">
                <div style="display: inline-block; width: 3rem; height: 4rem; background-color: inherit; margin-top: -2rem; background-image: url('assets/DLP/dash_bulk.png'); background-size: 90% 90%; background-position: 0px 20px;">
                    <!--<img src="assets/DLP/dash_bulk.png" alt="">-->
                </div>

                <font size="5em">DLP</font>
            </div>
        </div>
        <div [ngClass]="{'topbar-grid-container-3widgets-right': benutzerDisplay3Widgets, 'topbar-grid-container-normal-right': !benutzerDisplay3Widgets}">
            <div style="margin-right: 0.75em; vertical-align: middle; margin-top: 0.5em;">
                <a class="layout-topbar-action rounded-circle" (click)="appMain.onRightMenuButtonClick($event)" pRipple>
                    <i class="pi fs-large" [ngClass]="{'pi-bars': true}"></i><!--[ngClass]="{'pi-arrow-left': !app.isRTL, 'pi-arrow-right': app.isRTL}"-->
                </a>
            </div>
            <div>&nbsp;&nbsp;&nbsp;</div>
            <div id="divSearch" style="background-color: white; border-radius: 4px; vertical-align: middle; margin-top: 0.25em;">
            </div>

        </div>
    </div>
</div>

