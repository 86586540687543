import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
//import { Observable } from 'rxjs/Observable';
import { Observable, of } from 'rxjs';
//import 'rxjs/add/operator/map';
//import 'rxjs/add/operator/do';
//import 'rxjs/add/operator/catch';

import { AuftragsChecklisteDetailComponent } from './auftrags-checkliste-detail.component';
//import { GlobalService } from '../_services/global.service';
import { TranslateService } from '../_services/translate.service';

@Injectable()
export class AuftragsChecklisteDetailGuard implements CanActivate {

  constructor(//private globalService: GlobalService,
    public translate: TranslateService) {

  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return true;
  }

  canDeactivate(component: AuftragsChecklisteDetailComponent): boolean {
    try {
      if (component.CRUDForm.dirty && !component.deleted && !component.errorMessage || (component != null && component.hasTouchedPositions())) {
        //let bezeichnung = component.CRUDForm.get('bezeichnung').value || "#pageTitleAdd#";
        //return false;
        return confirm(this.translate.instant('ERROR_UNSAVED_CHANGES'));
      }
    }
    catch(e) {
      console.error("AuftragsChecklisteDetailGuard.canDeactivate() exception:", e);
    }

    return true;
  }
}
