import { Component, ElementRef, ViewChild } from '@angular/core';
import { trigger, style, transition, animate, AnimationEvent } from '@angular/animations';
import { MegaMenuItem } from 'primeng/api';
import { AppComponent } from './app.component';
import { AppMainComponent } from './app.main.component';
import { IBenutzer } from './_interfaces/benutzer';
import { _SystemService } from './_services/_system.service';
import { AppconfigService } from './_services/appconfig.service';
import { AppTopBarComponent } from './app.topbar.component';

@Component({
    selector: 'app-topbar-replacement-for-app',
    templateUrl: './app.topbar.replacement.for.app.component.html',
    /*animations: [
        trigger('topbarActionPanelAnimation', [
            transition(':enter', [
                style({opacity: 0, transform: 'scaleY(0.8)'}),
                animate('.12s cubic-bezier(0, 0, 0.2, 1)', style({ opacity: 1, transform: '*' })),
              ]),
              transition(':leave', [
                animate('.1s linear', style({ opacity: 0 }))
              ])
        ])
    ],*/
    styleUrls: ['./app.topbar.replacement.for.app.component.scss'],
})
export class AppTopBarReplacementForAppComponent extends AppTopBarComponent {
    benutzer: IBenutzer = null;

    filterDialogShow: boolean = false;

    constructor(
        public appMain: AppMainComponent, 
        public app: AppComponent,
        protected _systemService: _SystemService,
        /*private*/protected config: AppconfigService,
        ) {
            super(appMain, app, _systemService, config);
    }

    ngAfterViewInit(): void {  
        let benutzerSubscription = this.app.benutzerBehaviourSubject.subscribe(benutzer => {
            if(benutzer != null) {
                console.log("AppTopBarReplacementForAppComponent.ngAfterViewInit() got benutzer:", benutzer);
                this.benutzer = benutzer;

                // wenn der Benutzer nur seine eigenen sehen (oder umschalten) darf, dann jetzt setzen! (Achtung, passiert auch in map. Siehe dort)
                if(benutzer.filterung == 'E' || benutzer.filterung == 'U') {
                    this.app.setfilterBenutzerNurMeine();
                }

                setTimeout(() => {
                    benutzerSubscription.unsubscribe(); 
                }, 10);
    
            }
        });
    }

    filterShowDialogClicked() {
        this.filterDialogShow = true;
    }

    filterRefreshClicked() {
        console.log("AppTopBarReplacementForAppComponent.filterRefreshClicked()")
        this.app.mapComponent.refreshAuftraegeMarkerAndCounter();
        this.filterDialogShow = false;
    }
}
