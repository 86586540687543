import { /*CRUDBasicDetailComponent_Template,*/ CRUDBasicDetailComponent } from '../crud-basic-detail/crud-basic-detail.component';
//export { CRUDBasicDetailComponent_Template };

import { Component, OnInit, OnDestroy, Inject, forwardRef, Injector, ViewChild, Input, Output, EventEmitter, ElementRef } from '@angular/core';
import { Validators, FormControl, FormGroup, FormBuilder, AbstractControl, FormControlName } from '@angular/forms';
import { AppComponent } from '../app.component';
import { TranslateService } from '../_services/translate.service';
import { GenericValidator } from '../_helpers/generic-validator';

//import { ElementQueries, ResizeSensor } from "css-element-queries";

declare var jquery: any;
declare var $: any;

@Component({
  selector: 'app-crud-basic-detail-for-child', // NOT IN USE
  styleUrls: ['./crud-basic-detail-for-child.component.css'],
  
  templateUrl: '../crud-basic-detail/crud-basic-detail.component.html',
  //template: `${CRUDBasicDetailComponent_Template || ''}`,
  //template: `${CRUDBasicDetailComponent_Template || ''}${AufgabenlistePositionDetailComponent_Template}`,
  
  host: { '(window:keydown)': 'hotkeysForChild($event)' }
})

// ACHTUNG! Die Component überschreibt die Standard-CRUD-Logik. Damit sollen Childs in Parents (zB "AufgabenlistePositionen" in "Aufgabenliste")
//          eingebunden werden - liest und schreibt also nicht direkt mit der API !

export class CRUDBasicDetailForChildComponent extends CRUDBasicDetailComponent implements OnInit {
  @Input('CRUDItem') CRUDItem: /*IAufgabenlistePosition*/any; // AM Parent-ChildTab-CRUD
  // da CRUDItem = Object by reference -> aktualisieren wir bei Änderung immer gleich den parent!
  @Input('CRUDDialogId') CRUDDialogId: string; // AM Parent-ChildTab-CRUD
  @Output('CRUDItemValueChange') CRUDItemValueChange: EventEmitter</*IAufgabenlistePosition*/any> = new EventEmitter</*IAufgabenlistePosition*/any>(); // AM Parent-ChildTab-CRUD
  @Output('onClose') onClose: EventEmitter<any> = new EventEmitter(); // AM Parent-ChildTab-CRUD

  @ViewChild('divCardToolbar') public divCardToolbar: ElementRef;
  
  // ForChild:
  CRUDButtonSaveIcon = "ui-icon-check";
  CRUDButtonSaveLabel = "OK";
  CRUDDisablePageTitleUpdates:boolean = true; 

  ngOnInit() {
    this.blockedDocument = true;

    // CHILD-spezifische Zusätze, z.B. Nachladen anderer Entities - START
    
    /*
    // UPDATE 28.06.2019: "modal" funktioniert jetzt doch!
    // da "modal" hier nicht funktioniert, gibt es den p-blockUI - innerhalb dem der dialog ist!
    // das hat aber wiederum den Nachteil, dass der Dialog die opacity des p-blockUI erbt!
    // daher:
    // letzten p-blockUI finden (das wird der sein, den wir hier geadded haben)
    // bei diesem p-blockUI: die opacity entfernen, stattdessen per background-color argb(r,g,b,opacity) einfügen -> das wird nicht an den Dialog vererbt!

    //let uiblock = <HTMLElement>document.getElementById("aboutUIBlock");
    let uiblocks = document.getElementsByClassName('ui-blockui');
    for(let i : number = 0; i<uiblocks.length; i++) {
      let uiblock = <HTMLElement>uiblocks[i];
      //console.log("uiblock:", uiblock);
      
      //nur den letzten UI-block (den den wir hier extra erzeugt haben) ändern:
      if(i == uiblocks.length-1 || true) {
      //console.log("uiblock.style.backgroundColor:", uiblock.style.backgroundColor);

      let red=88;
      let green=87;
      let blue=92;
      let opacity=0.8;

      uiblock.style.backgroundColor = "rgba("+red+","+green+","+blue+","+opacity+")";
      uiblock.style.opacity = "1";
      }
    }
    */

    // CHILD-spezifische Zusätze, z.B. Nachladen anderer Entities - ENDE

    // CRUDBasicForChildV2: statt im Dialog zu laufen: Alles bisherige ausblenden (und bei beenden wieder einblenden)
    //let uppermostDivs = document.getElementsByClassName("ui-fluid");
    //let uppermostDiv = <HTMLElement>uppermostDivs[0];
    //console.log("CRUDBasicDetailForChildComponent.ngOnInit() uppermostDiv:", uppermostDiv);
    //uppermostDiv.style.display = "none";


    super.ngOnInit();
    //this.globalService.registerHotKeyHandler(this); // nicht machen! weil super.ngOnInit() macht das bereits!
  }

  ngAfterViewInit(): void {
    // sofern dieses CRUDDetail als Child in einem p-dialog läuft (quasi immer bei childs!), dann Grösse der card-Toolbar dynamisch anpassen:
    // dazu benötigen wir den 1. div innerhalb p-dialog - die id des p-dialogs bekommen wir vom parent als parameter!
    try {
      if(this.CRUDDialogId != null && this.CRUDDialogId.length > 0) {
        let divCardToolbarNativeElement = this.divCardToolbar.nativeElement;
        //console.log("CRUDBasicDetailForChildComponent.ngAfterViewInit() this.divCardToolbar.nativeElement:", divCardToolbarNativeElement);
        let divDialogFirstChildren = $('#'+this.CRUDDialogId).children().get(0);
        //console.log("CRUDBasicDetailForChildComponent.ngAfterViewInit() divDialogFirstChildren:", divDialogFirstChildren);
        //console.log("CRUDBasicDetailForChildComponent.ngAfterViewInit() scrollWidth:", divDialogFirstChildren.scrollWidth);
        //console.log("CRUDBasicDetailForChildComponent.ngAfterViewInit() offsetWidth:", divDialogFirstChildren.offsetWidth);
    
        /*let thisInstance = this;
        new ResizeSensor($(divDialogFirstChildren), function (resizedTo) {
          thisInstance.resizeCardToolbar(divDialogFirstChildren, divCardToolbarNativeElement, resizedTo.width) ;
        });
    
        setTimeout(() => {
          this.resizeCardToolbar(divDialogFirstChildren, divCardToolbarNativeElement, divDialogFirstChildren.offsetWidth) ;
        }, 100);*/
    
      }
    }
    catch(e) {
      console.log("CRUDBasicDetailForChildComponent.ngAfterViewInit() warning: ", e);
    }
    
    super.ngAfterViewInit();
  }

  resizeCardToolbar(divDialogFirstChildren: any, divCardToolbarNativeElement:any, width:any) {
    //console.log("CRUDBasicDetailForChildComponent.resizeCardToolbar(): width", width);
    
    if (divDialogFirstChildren.scrollWidth < (divDialogFirstChildren.offsetWidth - 2 /*border*/)) {
      $(divCardToolbarNativeElement).css('width','calc('+(width - 15/*scrollbar*/)+'px - 2.9em)');
      //console.log("CRUDBasicDetailForChildComponent.resizeCardToolbar(): scrollbar ist sichtbar!" );
    }      
    else  {
      $(divCardToolbarNativeElement).css('width','calc('+width+'px - 2.9em)')
      //console.log("CRUDBasicDetailForChildComponent.resizeCardToolbar(): scrollbar ist NICHT sichtbar!");
    }
  }

  initDataId() {
    // dataId beim Child nicht aus der URL ableiten, sondern aus dem CRUDItem
    // d.h. hier NICHTS!, aber siehe getCRUDItem()
  }
  getCRUDItem(idNOTINUSE: number) { // Overwrite zum CRUDBasic Standard // AM Parent-ChildTab-CRUD
    // dataId beim Child nicht aus der URL ableiten, sondern aus dem CRUDItem
    this.dataId = this.CRUDItem.id;

    this.onCRUDItemRetrieved(this.CRUDItem); // das bereits per Input-Parameter erhaltene CRUDItem nehmen!
  }

  saveCRUDItem(a : any, close: boolean) { // Overwrite zum CRUDBasic Standard // AM Parent-ChildTab-CRUD
    Object.assign(this.CRUDItem, a); // da CRUDItem = Object by reference -> dated das gleich im parent up!
    this.handleChange();
    this.onClose.emit(); // Edit-Dialog im Parent schliessen

    this.blockedDocument = false;
    let bezeichnung = this.getCRUDBezeichnung();
    //this.messageWrapperService.postTimedMessage({ severity: 'success', summary: this.deleted ? this.translate.instant("Löschen erfolgreich", true) : this.translate.instant("Speichern erfolgreich", true), detail: bezeichnung })
  }

  handleChange(/*a : any*/) { // AM Parent-ChildTab-CRUD
    console.log("CRUDBasicDetailForChildComponent.handleChange() this.CRUDItem:", this.CRUDItem);
    this.CRUDItemValueChange.emit(/*a*/this.CRUDItem);
  }

  cancelCRUDEdit() {
    if(this.CRUDItem.id == null || this.CRUDItem.id <= 0) delete this.CRUDItem['_alreadyAddedToArray']; // neue Items bei cancel nicht speichern
    this.onClose.emit(); // Edit-Dialog im Parent schliessen
  }

  deleteCRUDItem(): void { // Overwrite zum CRUDBasic Standard // AM Parent-ChildTab-CRUD
    //console.log("CRUDBasicDetailForChildComponent.deleteCRUDItem() deleted=", this.deleted);
    this.confirmationService.confirm({
      message: this.translate.instant('CONFIRM_DELETE_RECORD'),
      header: this.translate.instant('Löschen', true) + '?',
      icon: 'fa fa-trash',
      key: 'CRUDBasicDetailConfirmDialog_'+this.CRUDItemBezeichnungPluralCapitalized, 
      accept: () => {
        this.blockedDocument = true;
        this.CRUDItem['_deleteFromArray'] = true;
        this.handleChange();
        this.onClose.emit(); // Edit-Dialog im Parent schliessen
        this.blockedDocument = false;
        let bezeichnung = this.getCRUDBezeichnung();
      },
      reject: () => {
      }
    });
  }

  confirmDialogAppendTo() { // normaler Detail Dialog soll appendTo=body sein, ein DetailForChild aber nicht!
    return null;
  }

  hotkeysForChild(event) {
    if(event.repeat == true) {
      // // repeated events interessieren uns generell überhaupt nicht!
      // z.B. bei CTRL (keydown) kommt der event endlos oft vor - so lange man auf CTRL bleibt
    }
    else {
      if (this.debugMode == true) console.log("CRUDBasicDetailForChild.hotkeys() event:", event);
      //if(this.globalService != null) this.globalService.handleHotKeys(this, event);
    }
  }

  handleHotkeys(event) {
    // bei forChild kein STRG+S - hier gibt's kein save ohne schliessen!
    // ansonsten 1:1 wie ohne forChild - STRG+D ist = [OK]
    if (this.debugMode == true) console.log("CRUDBasicDetailForChildComponent.hotkeysForChild()");
    //STRG + D // save + close
    if (event.keyCode == 68 && event.ctrlKey) {
      this.validateAndSaveCRUDItem(true);
      event.preventDefault();
    }
    //STRG + ENTF
    else if (event.keyCode == 46 && event.ctrlKey) {
      this.deleteCRUDItem();
      event.preventDefault();
    }
    //ESC
    else if (event.keyCode == 27) {
      this.router.navigate(['/' + this.CRUDItemRoutePlural])
      event.preventDefault();
    }
  }
}
