import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild, Inject, forwardRef, Injector, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '../_services/translate.service';
//import { ITitel } from '../_interfaces/titel';
//import { TitelService } from '../_services/titel.service';
import { IPagination } from '../_interfaces/pagination';
import { MessageWrapperService } from '../_services/message-wrapper.service';
//import { GlobalService } from '../_services/global.service'; // feedback
//import { RouteDataService } from '../_services/route-data.service';
import { NavigationEnd } from '@angular/router';
import { UserTableColumnStateService } from '../_services/user-table-column-state.service';
import { IUserTableColumnState } from '../_interfaces/user-table-column-state';
import { MenuItem, ConfirmationService, LazyLoadEvent } from 'primeng/api';
import {TriStateCheckbox} from 'primeng/tristatecheckbox';
import {Calendar} from 'primeng/calendar';
import { Table } from 'primeng/table';
import * as moment from 'moment/moment';
//import { DateFormatService } from "../_services/date-format.service";
import { AppComponent } from '../app.component';
import { AppModule } from '../app.module';
//import { ElementQueries, ResizeSensor } from "css-element-queries";
//import { TableHeaderFixer } from "../_helpers/table-header-fixer";
import { AppconfigService } from '../_services/appconfig.service';
//import { CustomizingBasisService } from '../_services/customizing-basis.service'; // help
import * as cloneDeep from 'lodash/cloneDeep'; // DateTimeOffset-Fix

declare var $: any;

//import { CRUDBasicListComponent_Template } from './crud-basic-list.component.include_template';
//export { CRUDBasicListComponent_Template };

@Component({
  selector: 'app-crud-basic-list_NOT_IN_USE',
  templateUrl: './crud-basic-list.component.html',
  //template: `${CRUDBasicListComponent_Template || ''}`,
  styleUrls: ['./crud-basic-list.component.css'],
  host: {
    '(window:keydown)': 'hotkeys($event)',
    '(window:resize)': 'sizeTable()'
  }
})
export class CRUDBasicListComponent implements OnInit, AfterViewInit {
  @ViewChild('dt', /* TODO: add static flag */ {}) public dataTable: Table;
  @ViewChild('gb') public globalFilter: ElementRef;
  //@ViewChild('filterUnternehmen') public filterUntenehmen: TriStateCheckbox;
  @ViewChild('filterModified') public filterModified: Calendar;
  @ViewChild('filterCreated') public filterCreated: Calendar;

  // Attribute, die von SubClasses überschrieben werden müssen!  
  pageTitle: string;
  CRUDItemKurzform: string;
  CRUDItemButtonTitleNeu: string;
  CRUDItemBezeichnungSingularCapitalized: string;
  CRUDItemBezeichnungPluralCapitalized: string;
  CRUDItemBezeichnungSingular: string;
  CRUDItemBezeichnungPlural: string;
  CRUDItemHelpTopic: string;
  CRUDItemRouteSingular: string;
  CRUDItemRoutePlural: string;
  CRUDDisableLoeschen: boolean = false;

  CRUDHideMehrButton: boolean = false;

  //CRUDTemplateDefaultTableCaption: boolean = true; // ggf. false für: KEINE Table-Caption (Header-Zeile mmit Buttons etc.) - weil z.B. statt dessen mit CRUDTemplatePluginTableCaption eine eigene gesetzt wird
  CRUDTemplatePluginTableCaption: any = null; // dummy! ist nur nötig, damit der publish nicht meckert, dass gewisse Child-Klassen das nicht haben (ist ein ng-container im html)
  CRUDTemplatePluginTop: any = null; // dummy! ist nur nötig, damit der publish nicht meckert, dass gewisse Child-Klassen das nicht haben (ist ein ng-container im html)

  // Attribute, die von SubClasses eher selten überschrieben werden (nur bei abweichener Logik vom Standard CRUD: 
  CRUDLazyLoad: boolean = false; // "Pseudo"-Lazyload
  CRUDLazyLoadVirtualRowHeight: number = null; // bei lazyLoading / virtualScroll muss die TR eine fixe Grösse haben, sonst Scrolling ungenau! - Hier gewünschte Höhe in px angeben

  CRUDItemButtonTitleAktualisieren: string = "Aktualisieren";
  CRUDSortField: string = null;
  CRUDDisablePageTitleUpdates: boolean = false;
  CRUDMethodNameGetCollection: string = null; // Alternative Methode (im Service), die bei getCollection aufgerufen werden soll
  CRUDMethodNameGet: string = null; // Alternative Methode (im Service), die bei get aufgerufen werden soll
  CRUDMethodNameDelete: string = null; // Alternative Methode (im Service), die bei delete aufgerufen werden soll

  CRUDAdditionalButtonsNeu: MenuItem[] = null; // durch anhängen von MenuItems kann hier das "Neu"-Button zum Splitbutton werden - der weitere Aktionen hat.

  CRUDUserTableColumnStateKey: string = null; // UserTableColumnState-Key ist normalerweise = CRUDItemBezeichnungSingular + '-list' -> das kann hiermit überschrieben werden!

  CRUDBasicListForChildMode: boolean = false; // NIEMALS manuell setzen! das macht CRUDBasicListForChild!

  debugMode: boolean = false;

  CRUDItemAvailableCols: any[] = [
    {
      field: 'bezeichnung',
      header: 'Bezeichnung',
      styleClass: 'columnText', // styleClass, die auf die span angesetzt werden soll
      //tdStyleClass: 'textAlignRight', // styleClass, die auf die TD angesetzt werden soll
      //thStyleClass: 'textAlignRight', // styleClass, die auf die TD angesetzt werden soll
      sortable: true,
      filterMatchMode: "contains",
      order: 1,
      showByDefault: true,
      nullable: false,        // bei booleans relevant
      //customPipe: null, // customPipe !!!
      //spezialContentType: 'HTML', // HTML als innerHTML ausgeben
      //format: '%' - % hinten anstellen
      //        '$' - currency hinten anstellen - aktuell hardcoded €
    }
  ];
  crudItemService: any;
  // ENDE Attribute, die von SubClasses überschrieben werden müssen!  


  crudItems: /*ITitel*/any[];
  crudItemsAll: /*ITitel*/any[]; // wenn Pseudo-Lazyload ..
  crudItemsAllFiltered: /*ITitel*/any[]; // wenn Pseudo-Lazyload ..
  selectedCrudItem: /*ITitel*/any;
  initializedCrudItem: any; // hier wird aus dem Service das initialize...() aufgerufen. Hier kann ich dann die typeofs ermitteln - und sehe z.B. bei einem 
  // "myFeld : boolean, dass aktuell = null ist" trotzdem, dass es boolean ist, weil im initialize...() mit false initialisiert!
  dateFormat: string;
  loading: boolean;
  loadingTable: boolean = false; // nur die Table abdunkeln, während lazyloading nachlädt ...
  //enableConfirm: boolean = true; // Workaround ***W1: primeNg scheint hier ein bug zu haben ? Wenn man im DETAIL löschen klickt und den confirm mit ja beantwortet, geht der confirmDialog zu.
  // danach erfolgt eine navigate zurück auf diese List -> soweit alles ok!, aber: in dieser List zeit der confirmDialog NOCHMAL die Frage aus DETAIL an!!!
  // als workaround: vor betreten des detail: confirmDialog hidden (ngIf), bei NavigationEnd (also wenn wieder hier zurück): nach 500 MS wieder anzeigen.
  // UPDATE 25.06.2019: NICHT mehr nötig, seit "key" (p-configDialog key=...)
  //TODO: brauchen wir das?
  totalRecords: number;
  scrollHeight: string;
  errorMessage: string;
  lastScrollPosition: number; // bei lazyLoad / virtualScroll: nach dem reuse Component: wieder dort hinscrollen, wo er war
  //lastClickedTR: any; // bei lazyLoad / virtualScroll: nach dem reuse Component: wieder dort hinscrollen, wo er war
  lastFiltersJSON: string = null;
  lastGlobalFilter: string = null;
  lastSortField: string = null;
  lastSortOrder: number = 1;

  userTableColumnStates: IUserTableColumnState[] = [];

  buttonItems: MenuItem[];
  buttonItemsNeuButton: MenuItem[];

  availableCols: any[];
  cols: any[];
  columnOptions: any[];

  filterEnabled: boolean;

  killTable_notInUse: boolean = false;

  propertyTypeModifiedAndCreated = { name: '', type: 'DateTimeOffset', format: '' };

  // services, die NICHT (von der child-class) injected werden (sonst müssten die child-classes alle Services kennen)
  //globalService: GlobalService; // feedback
  translate: TranslateService;
  messageWrapperService: MessageWrapperService;
  router: Router;
  //routeData: RouteDataService;
  userTableColumnStateService: UserTableColumnStateService;
  confirmationService: ConfirmationService;
  //dateFormatService: DateFormatService;
  config: AppconfigService;

  /*DE: any = { // aktivieren: beim p-calendar einstellen: [locale]="DE"
    firstDayOfWeek: 1,
    dayNames: ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"],
    dayNamesShort: ["Son", "Mon", "Din", "Mit", "Don", "Fre", "Sam"],
    dayNamesMin: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
    monthNames: ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"],
    monthNamesShort: ["Jan", "Feb", "Mär", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez"],
    today: 'Heute',
    clear: 'Clear',
    dateFormat: 'dd/mm/yy',
    weekHeader: 'Wk'
  }*/

  constructor(
    @Inject(forwardRef(() => AppComponent)) public app: AppComponent,
    private injector: Injector
  ) {
    // services, die NICHT (von der child-class) injected werden (sonst müssten die child-classes alle Services kennen)
    // stattdessen: die child-classes injecten nur den injector selbst - und geben den hierher weiter. Wir injecten dann programmatisch selbst!
    // Alternative wäre: den "injector" global über AppModule bereitzustellen: https://stackoverflow.com/questions/39101865/angular-2-inject-dependency-outside-constructor
    //this.globalService = injector.get(GlobalService); // feedback
    this.translate = injector.get(TranslateService);
    this.messageWrapperService = injector.get(MessageWrapperService);
    this.router = injector.get(Router);
    //this.routeData = injector.get(RouteDataService);
    this.userTableColumnStateService = injector.get(UserTableColumnStateService);
    this.confirmationService = injector.get(ConfirmationService);
    //this.dateFormatService = injector.get(DateFormatService);
    this.config = injector.get(AppconfigService);
  }

  sizeTable() {
    /*
    var collection = document.getElementsByClassName('ui-widget-header');

    var height = 211

    //if (this.app.isHorizontal() && this.app.isDesktop()) {
    //  height += 41;
    //}

    for (var i = 0; i < collection.length; i++) {
      height += collection[i].clientHeight;
    }
    this.scrollHeight = "calc(100vh - " + height.toString() + "px)";
    */
  }

  getCalendarInputString(event) {
    //console.log("CRUDBasicListComponent.getCalendarInputString() event:", event);
    this.dateFormat = event.srcElement.value;
  }

  // das 'field' in CRUDItemAvailableCols splitten nach objektname und propertyname - z.B. field: 'sprache.bezeichnung'
  splitFieldName(feldname, getPropertynameInsteadOfObjektname) {
    let idx = feldname.indexOf(".");
    if (idx < 0) {
      //console.log("splitFieldName() there is no dot (.)");
      return getPropertynameInsteadOfObjektname ? '' : feldname;
    }
    else {
      let objektname: string = feldname.substr(0, idx);
      let propertyname: string = feldname.substr(idx + 1);
      //console.log("splitFieldName() objektname='"+objektname+"' propertyname='"+propertyname+"'");
      return getPropertynameInsteadOfObjektname ? propertyname : objektname;
    }
  }

  typeOfCRUDItem(propertyName: string) {
    let fieldName = this.splitFieldName(propertyName, false);
    let value = this.initializedCrudItem[fieldName];
    let type = typeof value;
    //console.log("CRUDBasicList.typeOfCRUDItem('"+propertyName+"') return "+type, type);
    return type;
  }

  isCRUDItemNullable(propertyName: string) {
    let fieldName = this.splitFieldName(propertyName, false);
    let crudItemCol = this.CRUDItemAvailableCols.filter(c => c.field == propertyName);
    return crudItemCol[0].nullable;
  }

  /*
    typeOf(value : any) {
      if(value == null) {
        console.log("typeOf() value == null -> return 'null'! value:", value);
        return 'null';
      }
      let ret = typeof value;
      console.log("typeOf() return "+ret+"   value:", value);
      return ret;
    }
  
    valueOfBool(boolval : any) {
      console.log("valueOfBool", boolval);
      return boolval;
    }
  */

  ngOnInit() {
    //console.log("CRUDBasicList.ngOnInit()");
    //if(this.CRUDBasicListForChildMode == false) this.globalService.registerHotKeyHandler(this); // US 14791: registerHotKeyHandler muss bereits im Init passieren! Wenn erst im AfterViewInit kann es passieren, dass die ParentComponent erst nach dem Child CRUDBasicInput registriert, eben weil die ParentComponent später fertig ist mit rendern als das Child!
    this.clearFeedback();

    if (this.CRUDDisablePageTitleUpdates == false) this.app.setPageTitle(this.pageTitle);

    if(this.CRUDLazyLoad) this.crudItems = []; // US 19194

    // aus initializedCrudItem kann ich dann die typeofs ermitteln - und sehe z.B. bei einem 
    // "myFeld : boolean, dass aktuell = null ist" trotzdem, dass es boolean ist, weil im initialize...() mit false initialisiert!
    //console.log("CRUDBasicListComponent.ngOnInit() calling initialize"+this.CRUDItemBezeichnungSingularCapitalized+"()");
    this.initializedCrudItem = this.crudItemService['initialize' + this.CRUDItemBezeichnungSingularCapitalized]();

    //Die einzelnen Auswahl möglichkeiten des SplitButtons 'Einstellungen' unter jedem account
    //Die Property AutomationId dient dabei als Sortierung der Buttons
    this.buttonItems = [
      {
        id: 'detail', label: this.translate.instant(/*this.CRUDItemKurzform+' bearbeiten'*/'Bearbeiten', true), icon: 'fa fa-edit', automationId: 10, command: (res) => {
          this.selectCRUDItem(this.selectedCrudItem);
        }
      },
      {
        id: 'delete', label: this.translate.instant(/*this.CRUDItemKurzform+' löschen'*/'Löschen', true), icon: 'fa fa-trash', automationId: 20, command: (res) => {
          this.deleteCRUDItem(this.selectedCrudItem);
        }
      }
    ];

    // Neu-Splitbutton aufbereiten:
    if (this.CRUDAdditionalButtonsNeu != null) {
      // hier fehlt noch:
      // falls CRUDItemButtonTitleNeu == null   (also Neu soll ausgeblendet werden)
      // dann das "Neu" durch den 1. Eintrag aus dem array CRUDAdditionalButtonsNeu ersetzen, so dass quasi die 1. Funktion nachrückt.

      this.buttonItemsNeuButton = [];
      this.CRUDAdditionalButtonsNeu.forEach(element => {
        this.buttonItemsNeuButton.push(element);
      });
    }

    if (this.CRUDDisableLoeschen) {
      //this.buttonItems.find
      let idx = this.buttonItems.findIndex(b => b.id == 'delete');
      if (idx >= 0) {
        this.buttonItems.splice(idx, 1);
      }
    }

    this.loading = true;

    this.userTableColumnStateService.getUserTableColumnStates(this.CRUDUserTableColumnStateKey != null ? this.CRUDUserTableColumnStateKey : this.CRUDItemBezeichnungSingular + '-list')
      .subscribe(
        response => {
          this.userTableColumnStates.push(...response);
    
          this.availableCols = this.CRUDItemAvailableCols;

          for (var item of this.availableCols) {
            //Falls die Spalte noch nicht in UserTabelColumnState vorhanden, dann hier mit den Standarwerten anlegen
            if (response.filter(i => i.columnName == item.field).length == 0) {
              this.userTableColumnStates.push({

                tableName: this.CRUDUserTableColumnStateKey != null ? this.CRUDUserTableColumnStateKey : this.CRUDItemBezeichnungSingular + '-list',
                columnName: item.field,
                order: item.order,
                showByDefault: item.showByDefault
              })
            }
            //Falls die Spalte vorhanden ist, dann bestimmen. ob sichtbar und an welcher Stelle.
            else {
              let userTableColumnState = response.filter(i => i.columnName == item.field)[0];

              item.order = (userTableColumnState.order == null ? 0 : userTableColumnState.order);
              item.showByDefault = userTableColumnState.showByDefault;
            }

          }

          //cols ist die Variable die auf der Maske für die Spalten verwendet wird. 
          //Die Variable wird hier gefüllt mit dem Array der angezeigten Spalten in der richtigen Reihenfolge.

          // MODI ggü. IMKE: kein stringify und parse - sonst Crash bei Leistungen! Wohl wegen der customPipe, die wiederum eine Property "App" hat
          /*
          this.cols = JSON.parse(JSON.stringify(this.availableCols.filter(col => col.showByDefault).sort((item1, item2) => {
            return item1['order'] - item2['order'];
          })));
          */
          let availableColsFilteredAndSorted = this.availableCols.filter(col => col.showByDefault).sort((item1, item2) => {
            return item1['order'] - item2['order'];
          });
          //let availableColsString = JSON.stringify(availableColsFilteredAndSorted);
          //let availableColsObj = JSON.parse(availableColsString);
          let availableColsObj = availableColsFilteredAndSorted;
          this.cols = availableColsObj;

          //columOptions ist die Auswahlliste für das Multiselect mit dem man sich die anzuzeigenden Spalten auswählen kann.
          //Die Variable wird hier mit den availableColums gefüllt.
          this.columnOptions = [];
          for (let i = 0; i < this.availableCols.length; i++) {
            this.columnOptions.push({ 
              /*label*/header: this.availableCols[i].header, // Modi/Fix: header nicht label - label ist falsch, weil im MultiSelect ist als key = header angegeben!
              value: this.availableCols[i],
              field: this.availableCols[i].field  // Modi/Fix: field wird gebraucht, sonst Fehler biem Einfügen eines Feld (handleColumnOptionChange)
            }); 
          }

          //this.columnOptions = JSON.parse(JSON.stringify(this.columnOptions)); // MODI ggü. IMKE: kein stringify und parse - sonst Crash bei Leistungen! Wohl wegen der customPipe, die wiederum eine Property "App" hat


          this.getCRUDItems();  
        },
        error => this.handleError(error)
      );

/*
    // Version ohne userTableColumnState
    this.cols = this.CRUDItemAvailableCols.filter(f => f.showByDefault == true);
    this.columnOptions = this.CRUDItemAvailableCols.filter(f => f.showByDefault == false);
    console.log("CRUDBasicList.ngOnInit() cols:", this.cols);
    this.getCRUDItems();  
*/


    // auf reuse (RouteReuseStrategy) reagieren: https://stackoverflow.com/questions/50392691/angular-5-how-to-reload-current-data-when-i-use-routereusestrategy
    this.router.onSameUrlNavigation = 'reload';
    this.router.events.subscribe(event => {
      if ((event instanceof NavigationEnd)) {
        let navigationEnd: NavigationEnd = <NavigationEnd>event;
        if (navigationEnd.url != null) {
          //console.log("CRUDBasicListComponent event NavigationEnd.url:", navigationEnd.url);

          let url: string = navigationEnd.url;
          if (url.startsWith("/" + this.CRUDItemRoutePlural) && !url.startsWith("/" + this.CRUDItemRoutePlural + "/")) {
            // beim reUse wird der pageTitle nicht autom. gesetzt -> übernehmen!
            //console.log("CRUDBasicListComponent.NavigationEnd... resetting pageTitle", event);
            if (this.CRUDDisablePageTitleUpdates == false) this.app.setPageTitle(this['pageTitle']);
            // workaround (***W1) // UPDATE 25.06.2019: NICHT mehr nötig, seit "key" (p-configDialog key=...)
            //setTimeout(() => { 
            //  this.enableConfirm = true;
            //}, 500);

            if (this.CRUDLazyLoad == true) {
              setTimeout(() => {
                console.log("CRUDBasicList.NavigationEnd... resetting scrollTop  this.lastScrollPosition:", this.lastScrollPosition);
                let scrollableTableBody = document.getElementsByClassName('ui-table-scrollable-body')[0];
                //scrollableTableBody.scrollTop = this.lastScrollPosition;
                scrollableTableBody.scrollTo(0, this.lastScrollPosition);
                // Es kommt aber vor, dass es inzw. einige Pixel-Verschiebungen gab, weil sich zB. die Daten geändert haben
                // in grossen Tab. wie Bankleitzahlen
                // daher: check, ob damit wirklich wieder sichtbar, ansonsten die TR.scrollIntoView() 
                // => funktioniert leider nicht!
                //if(!this.isElementVisible(this.lastClickedTR, scrollableTableBody)) {
                //  console.log("CRUDBasicList.NavigationEnd... resetted scrollTop, but TR is still not visible. scrolling into View ...");
                //  this.lastClickedTR.scrollIntoView();
                //}
              }, 50);
            }
          }
          else { // woanders hin-navigiert - also NICHT zurück zu hier! -> scrollTop merken
            if (this.CRUDLazyLoad == true) {
              let scrollableTableBody = document.getElementsByClassName('ui-table-scrollable-body')[0];
              this.lastScrollPosition = scrollableTableBody.scrollTop;
              console.log("CRUDBasicList.NavigationEnd... saving  this.lastScrollPosition:", this.lastScrollPosition);
            }
          }
        }
      }
    });
  }

  ngOnDestroy() {
    if(this.debugMode == true) console.log("CRUDBasicList.ngOnDestroy()");
    //if(this.CRUDBasicListForChildMode == false) this.globalService.unRegisterHotKeyHandler(this);
  }

  neuSplitButtonClicked($event) {
    console.log("CRUDBasicList.neuSplitButtonClicked() $event:", $event);
    if (this.CRUDItemButtonTitleNeu != null) { // Neu ist nicht ausgeblendet - also ist steht Neu auf dem Button!
      this.addCRUDItem();
    }
    else { // Neu ist ausgeblendet, d.h. auf dem Button steht die 1. Funktion aus 
      this.CRUDAdditionalButtonsNeu[0].command();
    }
  }

  neuSplitButtonDropDown($event) {
    console.log("CRUDBasicList.neuSplitButtonDropDown() $event:", $event);
  }

  clickTR($event) {
    //console.log("CRUDBasicList.clickTR() $event:", $event);
    //this.lastClickedTR = $event;
  }

  /*isElementVisible (el, holder) {
    holder = holder || document.body
    const { top, bottom, height } = el.getBoundingClientRect()
    const holderRect = holder.getBoundingClientRect()
  
    console.log("CRUDBasicList.isElementVisible() holderRect:", holderRect);
    console.log("CRUDBasicList.isElementVisible() top:", top);
    console.log("CRUDBasicList.isElementVisible() bottom:", bottom);
    console.log("CRUDBasicList.isElementVisible() height:", height);

    return top <= holderRect.top
      ? holderRect.top - top <= height
      : bottom - holderRect.bottom <= height
  }*/

  clearFeedback() {
    //this.globalService.clearFeedback(); // feedback
  }

  ngAfterViewInit() {
    //console.log("CRUDBasicList.ngAfterViewInit()");
    //this.globalService.registerHotKeyHandler(this); // US 14791: registerHotKeyHandler muss bereits im Init passieren! Wenn erst im AfterViewInit kann es passieren, dass die ParentComponent erst nach dem Child CRUDBasicInput registriert, eben weil die ParentComponent später fertig ist mit rendern als das Child!
    //TableHeaderFixer.prototype.fixTableHeader('crudDataTable', 'crudTableScroll');

    if(this.CRUDLazyLoad == true) {
      // primeNG 10 Fix: Bei LazyLoad in z.B. Dialogen ist ein Resize nötig - weil PrimeNg sonst nur 1 Satz darstellt (optisches Problem bei Lazyload: siehe auch: https://github.com/primefaces/primeng/issues/9766)
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, 1);
    }
  }

  getCRUDItems() {
    //console.log("CRUDBasicList.getCRUDItems() crudItemService:", this.crudItemService);
    //this.crudItemService.getTitel(1, 0, "")
    //this.crudItemService['get'+this.CRUDItemBezeichnungPluralCapitalized+'Collection'](1, 0, "")

    let methodName = this.CRUDMethodNameGetCollection != null ? this.CRUDMethodNameGetCollection : 'get' + this.CRUDItemBezeichnungPluralCapitalized + 'Collection';
    //console.log("CRUDBasicListComponent.getCRUDItems() method to call:crudItemService:", methodName);
    this.crudItemService[methodName](1, 0, "")
      .subscribe(
        //response => this.onCRUDItemsRetrieved(response.titel, response.pagination),
        response => {
          this.onCRUDItemsRetrieved(response[this.CRUDItemBezeichnungPlural], response.pagination);
        },
        error => this.handleError(error)
      );
  }

  onCRUDItemsRetrieved(CRUDItems: /*ITitel*/any[], pagination: IPagination) {
    if (this.CRUDLazyLoad == true) {
      this.crudItemsAll = CRUDItems;
      this.crudItemsAllFiltered = CRUDItems;
      //console.log("CRUDBasicList.onCRUDItemsRetrieved() crudItemsAll:", this.crudItemsAll); // vorsicht! viele Daten! console overflow ?
      //this.globalService.addFeedbackByClone("CRUDItems "+this.CRUDItemBezeichnungPluralCapitalized, "CRUDBasicListComponent.onCRUDItemsRetrieved()", CRUDItems); // feedback
      this.totalRecords = pagination.totalCount;

      let thisInstance = this;
      setTimeout(() => {
        //thisInstance.resetTable(thisInstance.dataTable); // muss sein, weil primeNg schon zum 1. Mal loadLazy() aufruft, bevor Daten geladen ... daher jetzt nochmal resetten. // US 19194
        // US 19194 dieser Reset scheint mit primeNg 10 nicht mehr zu funktionieren: Er liest nur die 1. Portion (0-25), die 2. lässt er aus, daher stattdessen: kill und neu!
        /*thisInstance.killTable = true;
        setTimeout(() => {
          thisInstance.killTable = false;
        }, 100);*/
        this.dataTable['clear'](); // der clear() führt autom. wieder zum load()!
        //this.dataTable.clearCache(); // kein clearCache, weil zum Start liest er immer die 1. Portion, dann die 2. - wenn danach ein clearCache() kommt -> liest er die 1. Portion nochmal!

        this.sizeTable();
        this.loading = false;
      }, 100);
    }
    else {
      this.loading = false;

      this.crudItems = CRUDItems;
      if (this.debugMode == true) console.log("CRUDBasicList.onCRUDItemsRetrieved() crudItems:", this.crudItems);
      //this.globalService.addFeedbackByClone("CRUDItems " + this.CRUDItemBezeichnungPluralCapitalized, "CRUDBasicListComponent.onCRUDItemsRetrieved()", CRUDItems); // feedback
      this.totalRecords = pagination.totalCount;

      this.sizeTable();
    }

    //this.recoverState(); // AM Modi: weg damit! wir machen das per CustomReuseStrategy
  }

  recoverState() {
    return // AM Modi: weg damit! wir machen das per CustomReuseStrategy
    /*
    let recoveryState = this.routeData.getAndDeleteValuesFromStorage(this.CRUDItemBezeichnungSingular+'-list-state');
    if (recoveryState.length > 0) {
      this.cols = JSON.parse(JSON.stringify(recoveryState[0].cols));
      this.dataTable.sortField = recoveryState[0].dataTable.sortField;
      this.dataTable.sortOrder = recoveryState[0].dataTable.sortOrder;
      this.dataTable.filters = recoveryState[0].dataTable.filters;

      if (recoveryState[0].dataTable.filteredValue != null) {
        this.filterEnabled = true;
      }
      this.dataTable.sortSingle();
      setTimeout(() => {
        this.dataTable.sortSingle();
        setTimeout(() => {
          for (var filterProperty in recoveryState[0].dataTable.filters) {
            if (filterProperty == 'unternehmen') {
              this.filterUntenehmen.writeValue(recoveryState[0].dataTable.filters[filterProperty].value)
            }
            else if (filterProperty == 'modified') {
              this.filterModified.writeValue(moment(recoveryState[0].dataTable.filters[filterProperty].value).format('DD.MM.YYYY'));
            }
            else if (filterProperty == 'created') {
              this.filterCreated.writeValue(moment(recoveryState[0].dataTable.filters[filterProperty].value).format('DD.MM.YYYY'));
            }
            else {
              $('#filter_' + filterProperty).first().val(recoveryState[0].dataTable.filters[filterProperty].value);
            }
          }

          this.dataTable.rows = recoveryState[0].dataTable.rows;
          this.dataTable.first = recoveryState[0].dataTable.first;
        }, 0);
      }, 0);
    }
    */
  }

  selectCRUDItem(CRUDItem: /*ITitel*/any) {
    //this.enableConfirm = false; // AM Modi: Workaround (***W1) // UPDATE 25.06.2019: NICHT mehr nötig, seit "key" (p-configDialog key=...)
    console.log("CRUDBasicListComponent.selectCRUDItem() CRUDItem:", CRUDItem);
    this.routeToCRUDItemDetail(CRUDItem.id);
  }

  //Ist dafür, dass man die Message mit einer Custommessage überschreiben kann
  getDeleteMessage(): string {
    return this.translate.instant('CONFIRM_DELETE_RECORD');
  }

  deleteCRUDItem(CRUDItem: /*ITitel*/any) {

    this.confirmationService.confirm({
      message: this.getDeleteMessage(),
      header: this.translate.instant('Löschen', true) + '?',
      icon: 'fa fa-trash',
      key: 'CRUDBasicListConfirmDialog_' + this.CRUDItemBezeichnungPluralCapitalized,
      accept: () => {
        this.loading = true;
        //this.crudItemService.deleteTitel(CRUDItem.id)
        let methodName = this.CRUDMethodNameDelete != null ? this.CRUDMethodNameDelete : 'delete' + this.CRUDItemBezeichnungSingularCapitalized;
        //console.log("CRUDBasicListComponent.deleteCRUDItem() method to call:crudItemService:", methodName);
        this.crudItemService[methodName](CRUDItem.id)
          .subscribe(
            res => {
              this.getCRUDItems();
              this.messageWrapperService.postTimedMessage({ severity: 'success', summary: this.translate.instant("Löschen erfolgreich", true), detail: CRUDItem.bezeichnung })
            },
            (error: any) => {
              this.handleError(error);
            }
          );
      },
      reject: () => {
      }
    });
  }

  filterClicked(dt: Table) {
    this.filterEnabled = !this.filterEnabled;

    if (!this.filterEnabled) {
      this.resetTable(dt);
    }

    setTimeout(() => {
      this.sizeTable();
    }, 0);

  }

  addCRUDItem() {
    this.routeToCRUDItemDetail(0);
  }

  routeToCRUDItemDetail(id: number) {
    // AM Modi: customReuseStrategy ! // this.routeData.pushItemToStorage({ key: this.CRUDItemBezeichnungSingular+'-list-state', value: { dataTable: this.dataTable, cols: this.cols } });
    console.log("CRUDBasicListComponent.routeToCRUDItemDetail() route to ", ['/'+this.CRUDItemRouteSingular, id]);
    this.router.navigate(['/' + this.CRUDItemRouteSingular, id]);
  }

  handleColReorder(event) {
    console.log("CRUDBasicListComponent.handleColReorder() event: ", event);
    debugger;
    let iCounter = 1;
    for (var item of event.columns) {
      if (item['field'] != null) {
        this.cols.find(i => i.field == item['field']).order = iCounter;
        this.userTableColumnStates.find(i => i.columnName == item['field']).order = iCounter;
        iCounter++;
      }
    }

    this.cols = /*JSON.parse(JSON.stringify(*/this.cols.sort((item1, item2) => { // MODI ggü. IMKE: kein stringify und parse - sonst Crash bei Leistungen! Wohl wegen der customPipe, die wiederum eine Property "App" hat

      return item1['order'] - item2['order'];

    })/*))*/;

    this.userTableColumnStates = this.userTableColumnStates.sort((item1, item2) => {

      return item1['order'] - item2['order'];

    });

    this.userTableColumnStateService.upsertUserTableColumnStates(this.CRUDUserTableColumnStateKey != null ? this.CRUDUserTableColumnStateKey : this.CRUDItemBezeichnungSingular + '-list', this.userTableColumnStates)
      .subscribe(response => {
        true
      },
        error => true);
  }

  handleColumnOptionChange(event) {
    this.loading = true;
    if (event.value != null) {
      for (let item of this.userTableColumnStates) {
        if (event.value.filter(i => i.field == item.columnName).length > 0) {
          item.showByDefault = true;
        }
        else {
          item.showByDefault = false;
        }
      }

      let iCounter: number = 1;
      for (let item of this.cols) {
        item.order = iCounter;
        //console.log("CrudBasicList.handleColumnOptionChange() handling item: ", item);
        this.userTableColumnStates.filter(i => i.columnName == item['field'])[0].order = iCounter;

        iCounter++;
      }

      this.userTableColumnStateService.upsertUserTableColumnStates(this.CRUDUserTableColumnStateKey != null ? this.CRUDUserTableColumnStateKey : this.CRUDItemBezeichnungSingular + '-list', this.userTableColumnStates)
        .subscribe(response => {
          this.loading = false;
          return true
        },
          error => {
            this.loading = false;
            return true;
          });
    }
  }

  //Manuelles Öffnen der des Einstellungs Buttons wenn auf diesen geklickt wird
  //Funktioniert momentan nicht im Internet Explorer
  triggerEvent(ele, event) {
    var clickEvent = new Event(event);
    ele.dispatchEvent(clickEvent);
  }


  /*onSort(dataTable) {
    console.log("CRUDBasicList.onSort()");
  }

  onFilter(dataTable) {
    console.log("CRUDBasicList.onFilter()");
    if(this.CRUDLazyLoad) {
      let thisInstance = this;
      setTimeout(() => {
        thisInstance.resetTable(thisInstance.dataTable);
      }, 100);
    }
  }*/

  filterGlobal(row, value) { // nur im LazyLoading Modus aktiv, sonst primeNg-Standard!
    for (let i = 0; i < this.CRUDItemAvailableCols.length; i++) {
      let column: /*Column*/any = this.CRUDItemAvailableCols[i];

      // AM Modi: column ermitteln. Bei Objekten (z.B. mandant.summary) geht das nicht einfach per row[columnName] - denn es muss heissen: row[mandant][summary] - also 2mal Klammer!
      let _column: any = null;
      let columnNameSplitted = column.field.split(".");
      if (columnNameSplitted != null && columnNameSplitted.length > 0) {
        _column = /*cloneDeep(*/row[columnNameSplitted[0]]/*)*/;
        for (let i = 1; i < columnNameSplitted.length; i++) {
          //if(this.debugMode == true) {
          //  console.log("CRUDBasicList.filterGlobal() _column: (before continuing handling the splits)", _column); // AM Modi
          //}
          _column = _column[columnNameSplitted[i]];
        }
      }
      else {
        _column = /*cloneDeep(*/row[column.field]/*)*/;
      }
      //if(this.debugMode == true) {
      //  console.log("CRUDBasicList.filterGlobal() column.field="+column.field+" row[column.field]:", row[column.field]);
      //  console.log("CRUDBasicList.filterGlobal() column.field="+column.field+" _column:", _column);
      //}

      if (/*row[column.field]*/_column == null) {
        //console.log("CRUDBasicList.filterGlobal() skipping: ", column.field); // AM Modi
        continue;
      }
      let rowValue: String = /*row[column.field]*/_column.toString().toLowerCase();
      if (rowValue.includes(value.toLowerCase())) {
        //console.log("CRUDBasicList.filterGlobal() found in: ", column.field); // AM Modi
        return true;
      }
    }
    //console.log("CRUDBasicList.filterGlobal() return false since not found"); // AM Modi
    return false;
  }

  filterField(row, filter) { // nur im LazyLoading Modus aktiv, sonst primeNg-Standard!
    //if(this.debugMode == true) console.log("CRUDBasicList.filterField() filter: ", filter); // AM Modi
    for (var columnName in filter) {
      //if(this.debugMode == true) console.log("CRUDBasicList.filterField() columnName: ", columnName); // AM Modi
      if (columnName == 'global' || columnName == '__proto__') {
        //console.log("CRUDBasicList.filterField() skipping: ", columnName); // AM Modi
        continue; // AM Modi
      }
      // AM Modi: column ermitteln. Bei Objekten (z.B. mandant.summary) geht das nicht einfach per row[columnName] - denn es muss heissen: row[mandant][summary] - also 2mal Klammer!
      let _column: any = null;
      let columnNameSplitted = columnName.split(".");
      if (columnNameSplitted != null && columnNameSplitted.length > 0) {
        _column = /*cloneDeep(*/row[columnNameSplitted[0]]/*)*/;
        for (let i = 1; i < columnNameSplitted.length; i++) {
          //if(this.debugMode == true) {
          //  console.log("CRUDBasicList.filterField() _column: (before continuing handling the splits)", _column); // AM Modi
          //}
          _column = _column[columnNameSplitted[i]];
        }
      }
      else {
        _column = /*cloneDeep(*/row[columnName]/*)*/;
      }
      //if(this.debugMode == true) {
      //  console.log("CRUDBasicList.filterField() value column: ", _column); // AM Modi
      //}

      if (/*row[columnName]*/_column == null) {
        console.log("CRUDBasicList.filterField() error: field does not exist, exit:", columnName); // AM Modi
        return false;
      }
      let rowValue: String = /*row[columnName]*/_column.toString().toLowerCase();
      let filterMatchMode: String = filter[columnName].matchMode;
      //console.log("CRUDBasicList.filterField() filtering by filterMatchMode:", filterMatchMode); // AM Modi
      if (filterMatchMode.includes("contains")) {
        if (!rowValue.includes(filter[columnName].value.toLowerCase())) {
          //console.log("CRUDBasicList.filterField() filter '"+filter[columnName].value.toLowerCase()+"' '"+filterMatchMode+"' does not match, exit:", columnName); // AM Modi
          return false;
        }
        continue;
      } else if (filterMatchMode.includes("startsWith")) {
        if (!rowValue.startsWith(filter[columnName].value.toLowerCase())) {
          //console.log("CRUDBasicList.filterField() filter '"+filter[columnName].value.toLowerCase()+"' '"+filterMatchMode+"' does not match, exit:", columnName); // AM Modi
          return false;
        }
        continue;
      } else if (filterMatchMode.includes("in")) {
        if (!filter[columnName].value.includes(rowValue)) {
          //console.log("CRUDBasicList.filterField() filter '"+filter[columnName].value.toLowerCase()+"' '"+filterMatchMode+"' does not match, exit:", columnName); // AM Modi
          return false;
        }
        continue;
      } else {
        console.log("CRUDBasicList.filterField() error: field does not have a valid matchmode ('" + filterMatchMode + "'):", columnName); // AM Modi
        return false; // AM Modi
      }
    }
    return true; // AM Modi
  }

  compareField(rowA, rowB, field: string)/*: number*/ {
    if (rowA[field] == null) return 1;
    if (typeof rowA[field] === 'string') {
      return rowA[field].localeCompare(rowB[field]);
    }
    if (typeof rowA[field] === 'number') {
      if (rowA[field] > rowB[field]) return 1;
      else return -1;
    }
  }

  exportCSV(dataTable: Table) {
    console.log("CRUDBasicList.exportCSV() this:", this);
    if (!this.CRUDLazyLoad) {
      //console.log("CRUDBasicList.exportCSV() exporting dataTable (not-lazyLoad mode!)...");
      dataTable.exportCSV();
    }
    else {
      if (this.crudItemsAllFiltered.length < 5000) { // sind nicht so viele, einfach machen:
        console.log("CRUDBasicList.exportCSV() exporting full dataTable (lazyLoad mode!) <5000 ...");
        let fullDataTable = cloneDeep(dataTable);
        fullDataTable.value = this.crudItemsAllFiltered;
        fullDataTable.exportCSV();
      }
      else { // so viele ? wirklich machen ?
        console.log("CRUDBasicList.exportCSV() exporting full dataTable (lazyLoad mode!) >=5000 ...");
        this.confirmationService.confirm({
          message: this.translate.instant('CONFIRM_EXPORT_LARGE', true).replace('$AMOUNT$', '' + (this.crudItemsAllFiltered.length)),
          header: this.translate.instant('Export', true) + '?',
          icon: 'fa fa-trash',
          key: 'CRUDBasicListConfirmDialog_' + this.CRUDItemBezeichnungPluralCapitalized,
          accept: () => {
            this.loading = true;
            let fullDataTable = cloneDeep(dataTable);
            fullDataTable.value = this.crudItemsAllFiltered;
            fullDataTable.exportCSV();
            this.loading = false;
          },
          reject: () => {
          }
        });

      }
    }
  }

  refreshRow(id) {
    console.log("refresh() id=" + id);
    if (id != 0) {
      let idStillExists: boolean = false; // erkennen, ob es die konfig nicht mehr gibt (gelöscht)
      let foundInExistingItems: boolean = false; // erkennen, ob es die id überhaupt schon gab, oder ob die neu ist

      let methodName = this.CRUDMethodNameGet != null ? this.CRUDMethodNameGet : 'get' + this.CRUDItemBezeichnungSingularCapitalized;
      //console.log("CRUDBasicListComponent.refreshRow() method to call:crudItemService:", methodName);

      this.crudItems.forEach((crudItem: any) => {
        if (crudItem.id == id) {
          foundInExistingItems = true;
          //console.log("refresh() refreshing - before:", crudItem);

          this.crudItemService[methodName](id).subscribe(response => {
            //console.log("refresh() response=", response);
            idStillExists = true;
            //console.log("refresh() idStillExists!");

            // alle felder aus response nach konfiguration mappen:
            //let saveSelected = crudItem._selected;
            for (let i in response) {
              if (crudItem.hasOwnProperty(i)) {
                crudItem[i] = response[i];
              }
            }
            this.afterRefreshRow(crudItem); // evtl. Entity-individuelle Nachbearbeitung (muss dann in der entspr. Klasse überschreiben werden)
            //crudItem._selected = saveSelected;

            //console.log("refresh() refreshing - after:", crudItem);

            // die konfig gibt es nicht mehr ? (gelöscht)
            if (!idStillExists) {
              // die konfig aus dem array löschen
              this.crudItems = this.crudItems.filter((val, i) => val.id != id)
              //console.log("refresh() deleted ", crudItem);
              //console.log("refresh() after delete", this.crudItems);
            }
            this.refreshDataTable(idStillExists, id);
          }, error => {
            console.log('Error getting (refresh()).');

            this.refreshDataTable(false, id);
          });
        }
      });
      // ... oder ist das item neu ?
      if (!foundInExistingItems) { // item ist neu!
        this.crudItemService[methodName](id).subscribe(response => {
          //console.log("refresh() response=", response);
          let crudItem: /*ITitel*/any = response;
          this.afterRefreshRow(crudItem); // evtl. Entity-individuelle Nachbearbeitung (muss dann in der entspr. Klasse überschreiben werden)
          this.crudItems.push(crudItem);
        }, error => {
          console.log('Error getting (refresh()).');
        });
      }
    }
    else {
      console.log("refresh() skipped, since id=0");
    }
  }

  afterRefreshRow(crudItem: any) {
    // macht im Standard Nichts!
    // evtl. soll es aber eine Entity-individuelle Nachbearbeitung nach dem RefreshRow() geben.
    // die kann dann in der entspr. Klasse überschreiben werden
  } 

  refreshDataTable(idStillExists : boolean, id : number) {
    //console.log("CRUDBasicList.refreshDataTable()");
    // wenn sich die Konfigurationsgruppe geändert hat, dann müssen wir neu sortieren!
    // da es dafür anscheinend keine Funktion gibt: Daten abhängen, nach einer Sekunde hier anhängen.
    //console.log("this.dataTable=", this.dataTable);
    let tableValuesSave = this.dataTable.value;
    // auch hier: die konfig gibt es nicht mehr ? (gelöscht)
    if (!idStillExists) {
      tableValuesSave = tableValuesSave.filter((val, i) => val.id != id);
    }
    this.dataTable.value = null;
    setTimeout(() => {
      this.dataTable.value = tableValuesSave;
    }, 1000);
  }

  refresh(dt: Table, gb: any) {
    // US 19194 bei refresh auch crudItems resetten
    this.crudItems = []; // siehe load() - wenn length == 0 wird dort gleich ein neues Array gesetzt
    //this.dataTable.clear(); // aus load() kein clear() absetzen! weil der clear() unmittelbar wieder zum load() führt!
    //this.dataTable.clearCache(); TODO
    
    gb.value = "";
    this.getCRUDItems();
    this.resetTable(dt);
  }

  resetTable(dt: Table) {
    dt.reset();
    this.filterEnabled = false;
    dt.totalRecords = this.totalRecords;
  }

  //Manuelles Öffnen des Einstellungs Buttons wenn auf diesen geklickt wird
  //Funktioniert momentan nicht im Internet Explorer vermutlich aufgrund 'triggerEvent'
  openDropdownMenu(CRUDItem: /*ITitel*/any) {

    this.selectedCrudItem = CRUDItem;
    var buttonId = "#splitBtn_" + CRUDItem.id;
    var parentDropDownBtn = document.querySelector(buttonId);
    var dropDownBtn = parentDropDownBtn.children[0].children[1];

    this.triggerEvent(dropDownBtn, "click");

  }

  handleCalendarBlur(dt: Table, field, filterMatchMode, cal: Calendar) {
    //console.log("CRUDBasicListComponent.handleCalendarBlur() dt/field/filterMatchMode/cal:", dt, field, filterMatchMode, cal);
  }

  filterCheckBoxChanged(dt: Table, field, filterMatchMode, checkbox: TriStateCheckbox) {
    //console.log("CRUDBasicListComponent.filterCheckBoxChanged() field/filterMatchMode/$event:", field, filterMatchMode, checkbox);
    setTimeout(() => {
      dt.filter(checkbox.value == null ? '' : "" + checkbox.value, field, filterMatchMode);
    }, 500)
  }

  //Wenn der Splitbutton geklickt wird (der DropdownPfeil des Einstellungen Buttons) bzw direkt drauf.
  //Speichert aktuell geklickte User Informationen
  dropdownClicked(CRUDItem: /*ITitel*/any) {
    this.selectedCrudItem = CRUDItem;
  }

  transformByCustomPipe(field: string, value: any) {
    // wir müssen hier die customPipe aus CRUDItemAvailableCols selbst ermitteln, WEIL:
    // direkt per aus HTML als parm col.customPipe übergeben funktioniert nicht, WEIL:
    // col.customPipe ist leer, weil "cols" über Umweg  JSON.parse(...)  gefüllt wurde und dabei der Zeiger auf die pipe verlohrenging.
    let customPipe = this.CRUDItemAvailableCols.find(f => f.field == field).customPipe;
    //console.log("CRUDBasicListComponent.transformByCustomPipe() field: ", field);
    //console.log("CRUDBasicListComponent.transformByCustomPipe() customPipe: ", customPipe);
    //console.log("CRUDBasicListComponent.transformByCustomPipe() value: ", value);
    if (customPipe != null) return customPipe.transform(value);
    else return value;
  }

  hotkeys(event) {
    if(event.repeat == true) {
      // // repeated events interessieren uns generell überhaupt nicht!
      // z.B. bei CTRL (keydown) kommt der event endlos oft vor - so lange man auf CTRL bleibt
    }
    else {
      if(this.debugMode == true) console.log("CRUDBasicList.hotkeys() event:", event);
      //if(this.globalService != null) this.globalService.handleHotKeys(this, event);
    }
  }
  handleHotkeys(event) {
    //STRG + EINFG
    if (event.keyCode == 45 && event.ctrlKey) {
      this.addCRUDItem();
      event.preventDefault();
    }
  }

  showHelp() {
  }

  loadLazy(event: LazyLoadEvent) {
    if(this.debugMode==true) console.log("CRUDBasicList.loadLazy() event:", event);
    this.loadingTable = true;

    //event.first = First row offset
    //event.rows = Number of rows per page
    //event.sortField = Field name to sort with
    //event.sortOrder = Sort order as number, 1 for asc and -1 for dec
    //filters: FilterMetadata object having field as key and filter value, filter matchMode as value

    setTimeout(() => {
      //if (this.crudItemsAllFiltered) {
      //    this.crudItems = this.crudItemsAllFiltered.slice(event.first, (event.first + event.rows));
      //    this.loadingTable = false;
      //}

      // Optimierung: Filtern und Sortieren nur, wenn sich was geändert hat / Sonst nur Blättern
      // wir gehen davon aus, dass wenn der 1. Satz angefordert wird (event.first = 0) -> dann Filtern/Sortieren
      if (this.crudItemsAll) {
        // ermitteln, ob sich filter oder Sortierung geändert haben ? wenn ja Daten neu aufbereiten!
        let newFiltersOrSorting = false;
        if (!(JSON.stringify(event.filters) === this.lastFiltersJSON)) newFiltersOrSorting = true;
        if (event.globalFilter != this.lastGlobalFilter) newFiltersOrSorting = true;
        if (event.sortField != this.lastSortField) newFiltersOrSorting = true;
        if (event.sortOrder != this.lastSortOrder) newFiltersOrSorting = true;

        if (newFiltersOrSorting == true || this.crudItemsAllFiltered == null) {
          // filtern und sortieren. Vorlage aus https://blog.csdn.net/weixin_40264719/article/details/78670282
          // weitere Optimierungen könnten sein:
          // - beim nur umsortieren - nicht vorher neu filtern
          // - das filterfield anders aufbauen: erst nach filter1 filtern, das ergebnis dann nach filter2 ... -> möglicherweise schneller ?
          if (event.filters != null) {
            this.crudItemsAllFiltered = this.crudItemsAll.filter(row => this.filterField(row, event.filters));
            //console.log("CRUDBasicListComponent.loadLazy field filters applied! crudItemsAllFiltered:", this.crudItemsAllFiltered);
          }
          else {
            this.crudItemsAllFiltered = this.crudItemsAll;
            //console.log("CRUDBasicListComponent.loadLazy no field filters! crudItemsAllFiltered:", this.crudItemsAllFiltered);
          }

          if (event.globalFilter != null && event.globalFilter.length > 0) {
            this.crudItemsAllFiltered = this.crudItemsAllFiltered.filter(row => this.filterGlobal(row, event.globalFilter));
            //console.log("CRUDBasicListComponent.loadLazy global filter applied! crudItemsAllFiltered:", this.crudItemsAllFiltered);
          }

          if (event.sortField != null) {
            this.crudItemsAllFiltered.sort((a, b) => this.compareField(a, b, event.sortField) * event.sortOrder);
            //console.log("CRUDBasicListComponent.loadLazy after sort: crudItemsAllFiltered:", this.crudItemsAllFiltered);
          }

          this.totalRecords = this.crudItemsAllFiltered.length;

          // US 19194 wenn neu gefiltert, oder neu sortiert auch crudItems resetten
          this.crudItems = []; // s.u. - wenn length == 0 wird im Anschluss gleich ein neues Array gesetzt
          //this.dataTable.clear(); // aus load() kein clear() absetzen! weil der clear() unmittelbar wieder zum load() führt!
          //this.dataTable.clearCache(); //TODO
          //console.log("CRUDBasicListComponent.loadLazy resetted table! totalRecords:", this.totalRecords);

          this.lastFiltersJSON = JSON.stringify(event.filters);
          this.lastGlobalFilter = event.globalFilter;
          this.lastSortField = event.sortField;
          this.lastSortOrder = event.sortOrder;

          //console.log("CRUDBasicListComponent.loadLazy done. lastFiltersJSON:", this.lastFiltersJSON);
        }

        // US 19194
        //this.crudItems = this.crudItemsAllFiltered.slice(event.first, (event.first + event.rows));

        //if(this.totalRecords == null) this.totalRecords = response.pagination.totalCount;
        if(this.crudItems == null || this.crudItems.length == 0) {
          this.crudItems = Array.from({ length: this.totalRecords });
          //console.log("CRUDBasicListComponent.loadLazy created array with size = totalRecords:", this.totalRecords);
        }

        let arraySizeBeforeSplice = this.crudItems.length; // Sicherheitsprüfung, s.u.
        Array.prototype.splice.apply(this.crudItems, [
          ...[event.first, event.rows],
          ...this.crudItemsAllFiltered.slice(event.first, (event.first + event.rows))
        ]);
        if(arraySizeBeforeSplice != 0 && this.crudItems.length != arraySizeBeforeSplice) {
          console.error("CRUDBasicList.loadLazy() Warning(Error?): Array-Grösse hat sich durch das nachladen verändert!");
          debugger;
        }
      
        //trigger change detection
        this.crudItems = [...this.crudItems];

        if(this.debugMode==true) console.log("CRUDBasicList.loadLazy() crudItems:", this.crudItems);




        this.loadingTable = false;
      }
    }, 100);
  }

  debug(obj) {
    console.log("CRUDBasicListComponent.debug() crudItems:", this.crudItems);
  }

  addButtonItem(item: MenuItem) {
    this.buttonItems.push(item);

    this.buttonItems.sort(function (a, b) {
      return (a.automationId - b.automationId);
    })
    //console.log("CRUDBasicListComponent.addButtonItem() buttonItems:", this.buttonItems);
  }

  deleteAllObjectsInObject(obj: object) { // innerhalb eines objects alle objekte löschen - z.B. um z.B. vor Zurücksenden an die API alle Rekursivitäten zu vermeiden
    for(var propertyName in obj) {
      if(typeof obj[propertyName] === 'object') {
        delete obj[propertyName];
      }
    }
  }

  handleError(error: any) {
    console.log("CRUDBasicListComponent.handleError error", error);
    //this.globalService.addFeedbackByClone("error " + this.CRUDItemBezeichnungSingularCapitalized, "CRUDBasicListComponent.handleError()", error); // feedback
    this.loading = false;

    let summary = this.translate.instant('Fehler', true);
    if (error.status === 422) {
      summary += ' (422)';
      if (error.error != null) {
        this.errorMessage = error.error.Concurrency || error.error.DbUpdateException || error.error.Error || 'Server Error';
      }
      else {
        this.errorMessage = "Server Error";
      }
    }
    else if (error.status === 401) {
      summary += ' (401)';
      this.errorMessage = "Unauthorized";
      this.router.navigate(['/login'], { queryParams: { returnUrl: this.router.url } });
    }
    else {
      this.errorMessage = <any>error
    }

    if((error.message != null && error.message != '') && error.message.indexOf("Unknown Error") > 0){
      this.errorMessage = error.message;
    }

    // MODI gg. zB IMKE: Fehler nur als Message anzeigen, wenn eingeloggt. Eingeloggt erkennen wir daran, dass localStorage BENUTZER != null (aber erst nach timeout, weil die auth-methoden, das ja erst löschen müssen)
    setTimeout(() => {
      let localStorageKeyBENUTZER = 'BENUTZER';
      let localStorageValueBENUTZER = localStorage.getItem(localStorageKeyBENUTZER);
      if(localStorageValueBENUTZER == null) {
        console.error("CRUDBasicListComponent.handleError Fehler: ", this.errorMessage);
      }
      else {
        this.messageWrapperService.postStaticMessage({ severity: 'error', summary: summary, detail: this.errorMessage });
      }
    }, 250);
  }

}
