import { Component, OnInit, Inject, forwardRef, Injector, Input, Output, EventEmitter} from '@angular/core';
import { Validators, FormControl, FormGroup, FormBuilder, AbstractControl, FormControlName } from '@angular/forms';
import { AppComponent } from '../app.component';
import { TranslateService } from '../_services/translate.service';
import { GenericValidator } from '../_helpers/generic-validator';
//import { AutoComplete } from 'primeng/primeng';

import { IBenutzer, IBenutzerForPWChange } from '../_interfaces/benutzer';
import { BenutzerService } from '../_services/benutzer.service';

import { MessageWrapperService } from '../_services/message-wrapper.service';
import { Message } from 'primeng/api';

@Component({
  selector: 'app-passwort-reset',
  templateUrl: './passwort-reset.component.html',
  styleUrls: ['./passwort-reset.component.scss']
})
export class PasswortResetComponent implements OnInit {
  @Input('benutzerId') benutzerId: number; 
  @Input('askForOldPassword') askForOldPassword: boolean;
  @Output() zurueckClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() saveClicked: EventEmitter<any> = new EventEmitter<any>();

  // Use with the generic validation message class
  displayMessage: { [key: string]: string } = {};
  displayMessageForm: { [key: string]: string } = {}; // wie displayMessage - enthält aber die Meldungen aus formValidators (nicht aus controlValidators)
  /*private*/public validationMessages: { [key: string]: { [key: string]: string } };
  /*private*/public genericValidator: GenericValidator;

  fb: FormBuilder;
  CRUDForm: FormGroup;
  
  passwordVisible: boolean = false;
  
  constructor(
    @Inject( forwardRef(() => AppComponent)) public _app: AppComponent,
    private injector: Injector,
    public translate: TranslateService,
    private benutzerService: BenutzerService,
    public messageWrapperService: MessageWrapperService

    // CHILD-spezifisch: zusätzliche Services - START
    // CHILD-spezifisch: zusätzliche Services - ENDE
  ) {
  }

  ngOnInit(): void {
    this.fb = this.injector.get(FormBuilder);

    this.genericValidator = new GenericValidator(this.validationMessages);

    this.buildForm();
  }

  buildForm() {
    // CHILD-spezifisch die Form aufbauen - START
    this.CRUDForm = this.fb.group({
      _oldpassword: ['', [/*Validators.required, */Validators.maxLength(255)]], // im Modify-Mode keine Validators - siehe ngOnInit
      _password: ['', [/*Validators.required, */Validators.maxLength(255)]], // im Modify-Mode keine Validators - siehe ngOnInit
      _passwordBestaetigen: ['', [/*Validators.required, */Validators.maxLength(255)]], // im Modify-Mode keine Validators - siehe ngOnInit
    },{
      validator: this.formValidator // auf FormEbene!
    });

    this.CRUDForm['___component'] = this; // trick, um im formValidator wieder an die component zu kommen.
    // CHILD-spezifisch die Form aufbauen - ENDE
  }

  validateAndSaveCRUDItem(): void {
    console.log("PasswordReset.validateAndSaveCRUDItem()");
    //this.globalService.addFeedbackByClone("CRUDForm (cutted)" + this.CRUDItemBezeichnungSingularCapitalized, "CRUDBasicDetailComponent.validateAndSaveCRUDItem()", this.cutFormObjectForFeedback(this.CRUDForm)); // feedback // Form nur "gecutted", weil sonst z.B. bei Kaufpreisrate-Kategorie zu gross (weil hier in der Form noch die ___component gepseichert wird)

    Object.keys(this.CRUDForm.controls).forEach(key => {
      this.CRUDForm.get(key).markAsDirty();
    });

    //this.forceValidate = true;
    //this.displayMessage = this.genericValidator.processMessages(this.CRUDForm, this.forceValidate);
    
    this.displayMessageForm = {};
    this.CRUDForm.updateValueAndValidity();
    
    if (this.CRUDForm.valid) {
      //this.blockedDocument = true;
     

      if (this.CRUDForm.dirty && this.CRUDForm.valid) {

        //let a = Object.assign({}, this.CRUDItem, this.CRUDForm.value);
        //a.spracheId = a.sprache.id;
        //let a = this.getValuesFromForm();

        //if (this.debugMode == true) console.log("CRUDBasicDetailComponent.validateAndSaveCRUDItem() getValuesFromForm...a/crudItem:", a);

        this.saveCRUDItem();
      }
    }
    else {
      let errorMessage: string = "";
 

      for (let key of Object.keys(this.displayMessage)) {

        if (this.displayMessage[key] != null && this.displayMessage[key] != '') {
          errorMessage += '<br>' + this.displayMessage[key];
        }
       

      }
      if (this.CRUDForm.errors != null) {
    
        for (let error of Object.keys(this.CRUDForm.errors)) {

          //if (errorMessage != '') {
          //  errorMessage += '<br>';
          //}

          errorMessage += '<br>' + this.translate.instant(error, true);

          // Fehlermeldungen aus formValidators (nicht controlValidators) festhalten, damit die im HTML dargestellt werden können
          this.displayMessageForm[error] = this.translate.instant(error, true);
        }
      }

      let message: Message = { severity: 'error', summary: this.translate.instant('Validierungsfehler', true), detail: `${errorMessage}` };
      this.messageWrapperService.postTimedMessage(message); 
      //this.globalService.addFeedbackByClone("Message " + this.CRUDItemBezeichnungSingularCapitalized, "CRUDBasicDetailComponent.validateAndSaveCRUDItem()", message); // feedback
    }
  }

  saveCRUDItem() {
    let thisInstance = this;
    let benutzerForPasswordReset: IBenutzerForPWChange = {
      id: this.benutzerId,
      _mode: this.askForOldPassword == true ? "C" : "R",
      _oldpassword: this.CRUDForm.value._oldpassword,
      _newpassword: this.CRUDForm.value._password
    };
    this.benutzerService.resetPassword(benutzerForPasswordReset).subscribe(function (response) {
      console.log("PasswordReset.validateAndSaveCRUDItem() response: ", response);
      thisInstance.messageWrapperService.postStaticMessage({ severity: 'success', summary: "Passwort", detail: /*this.*/"Das Passwort wurde erfolgreich geändert." }); // TO DO
      thisInstance.saveClicked.emit(null);
    }, function (error) { return thisInstance.handleError(thisInstance, error); });
  }

  cancelCRUDEdit() {
    this.zurueckClicked.emit(null);
  }

  formValidator(form: FormGroup) { // validate auf FORM-Ebene!
    let errors : any[] = [];
    let errorsFound : boolean = false;

    console.log("PasswordReset.formValidator() form:", form);

    // dazu erstmal an die in der Form verlinkte Component kommen
    if(form['___component'] != null/* && form['___component'].dataId != null && form['___component'].dataId == 0*/) {
      let thisInstance = form['___component'];

      let createMode = form['___component'].dataId != null && form['___component'].dataId == 0;

      let valueAskForOldPassword = thisInstance.askForOldPassword;
      let valueOldPassword = form.value._oldpassword;
      let valuePassword = form.value._password;
      let valuePasswordBestaetigen = form.value._passwordBestaetigen;

      if(valueAskForOldPassword == true && (valueOldPassword == null || valueOldPassword == "")) {
        errors['Altes_Passwort_ist_erforderlich']=true;
        errorsFound = true;
      }

      if(valuePassword == null || valuePassword == "") {
        errors['Passwort_ist_erforderlich']=true;
        errorsFound = true;
      }

      if(valuePassword != null && valuePassword != "" /*&& valuePasswordBestaetigen != null*/ && valuePassword != valuePasswordBestaetigen) {
        errors['Die_Passwoerter_stimmen_nicht_ueberein']=true;
        errorsFound = true;
      }
    }

    //form.setErrors(errorsFound ? errors : null);

    console.log("PasswordReset.formValidator() form:", form);

    if(errorsFound) return errors;
    else return null;
  }

  handleError(instance: PasswortResetComponent, error: any) {
    console.log("PasswordReset.handleError error", error);
    //this.globalService.addFeedbackByClone("error " + this.CRUDItemBezeichnungSingularCapitalized, "CRUDBasicDetailComponent.handleError()", error); // feedback

    //this.loading = false;
    //this.blockedDocument = false;
    let errorMessage: string = "";
    let summary = instance.translate.instant('Fehler', true);

    if (error.status === 422) {
      summary += ' (422)';
      if (error != null) {
        /*this.*/errorMessage = error.error.Concurrency || error.error.DbUpdateException || error.error.Error || 'Server Error';
      }
      else {
        /*this.*/errorMessage = "Server Error";
      }
    }
    else if (error.status === 401) {
      summary += ' (401)';
      /*this.*/errorMessage = "Unauthorized";
      //this.router.navigate(['/login'], { queryParams: { returnUrl: this.router.url } });
    }
    else {
      /*this.*/errorMessage = error.message;
    }

    //if(this.errorMessage != null) this.errorMessage = this.errorMessage.replace(/\n/g, "<br>");
    instance.messageWrapperService.postStaticMessage({ severity: 'error', summary: summary, detail: /*this.*/""+errorMessage }); // TO DO
  }

}
