import { Injectable } from '@angular/core';
//import { Observable } from 'rxjs/Observable';
import { Observable, of } from 'rxjs';
import { debounceTime} from 'rxjs/operators';
//import 'rxjs/add/operator/map';
//import 'rxjs/add/operator/do';
//import 'rxjs/add/operator/catch';
import { IPagination } from '../_interfaces/pagination'
//import { IBenutzer, IBenutzerForPWChange } from '../_interfaces/benutzer';
//import { IChoiceList } from '../_interfaces/_choice-list';
import * as moment from 'moment'; // DateTimeOffset-Fix
import * as cloneDeep from 'lodash/cloneDeep'; // DateTimeOffset-Fix

import { AppconfigService } from '../_services/appconfig.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';

import { CRUDBasicService, httpOptions } from '../_services/crud-basic.service';

@Injectable()
export class _SystemService extends CRUDBasicService {

  //typeOf deprecated -> CRUDBasicService.propertyTypeOf
  /*typeOf(propertyName: string) {  // welchen Typs sind die Properties IN DER API ?
    let property = this.propertyTypes.find(f => f.name == propertyName);
    if(property != null) return property.type;
    else return null;    
  }*/
  propertyTypes = [ // welchen Typs sind die Properties IN DER API ?
    {name: 'ueberfaelligTageDays', type: 'int'},
    {name: 'unterschriftKundeEMailRequired', type: 'bool'},
    {name: 'unterschriftKundeRequired', type: 'bool'},
  ];
    
  getSettings(): Observable<any> {
    return this.httpClient.get<any>(this.apiBaseUrl + '_system/settings', { headers: httpOptions, observe: 'body', withCredentials: true })
      .pipe(map((response) => 
         //response
         {
           console.log("_SystemService.getSettings() response: ", response)
          return response;
         }
      ), catchError( this.handleError/*NoMessageBox*/));
  }

    ///*private*/protected handleErrorNoMessageBox(error) {
    //  console.error("_SystemService.handleErrorNoMessageBox error: ", error);
    //  return observableThrowError(error);
    //}

  getApiBaseUrl(): string {
    console.log("_SystemService.getApiBaseUrl():", this.apiBaseUrl);
    return this.apiBaseUrl;
  }
  
}
