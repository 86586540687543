import { CRUDBasicListComponent } from '../crud-basic-list/crud-basic-list.component';
//import { CRUDBasicListComponent_Template, CRUDBasicListComponent } from '../crud-basic-list/crud-basic-list.component'; // HTML Template Plugins // AM Parent-ChildTab-CRUD
//export { CRUDBasicListComponent_Template };

import { Component, OnInit, AfterViewInit, OnDestroy, Inject, forwardRef, Injector, Input, Output, EventEmitter } from '@angular/core';
import { AppComponent } from '../app.component';

import { IPagination } from '../_interfaces/pagination'; // AM Parent-ChildTab-CRUD
import * as cloneDeep from 'lodash/cloneDeep'; // AM Parent-ChildTab-CRUD

@Component({
  selector: 'app-crud-basic-list-for-child', // NOT IN USE
  templateUrl: '../crud-basic-list/crud-basic-list.component.html',

  //template: `${CRUDBasicListComponent_Template || ''}`,  // HTML Template Plugins // AM Parent-ChildTab-CRUD
  //template: `${CRUDBasicListComponent_Template || ''}${AufgabenlistePositionListComponent_Template}`,  // HTML Template Plugins // AM Parent-ChildTab-CRUD

  styleUrls: ['../crud-basic-list/crud-basic-list.component.css'],
  host: {
    //'(window:keydown)': 'hotkeys($event)', // ListForChild haben keinen hotKey-Handler!
    '(window:resize)': 'sizeTable()'
  }
})

// ACHTUNG! Die Component überschreibt die Standard-CRUD-Logik. Damit sollen Childs in Parents (zB "AufgabenlistePositionen" in "Aufgabenliste")
//          eingebunden werden - liest und schreibt also nicht direkt mit der API !

export class CRUDBasicListForChildComponent extends CRUDBasicListComponent implements OnInit, AfterViewInit {
  @Input('parentCrudItem') parentCrudItem: /*IAufgabenliste*/any; // AM Parent-ChildTab-CRUD
  @Input('crudItems') crudItems: /*IAufgabenlistePosition*/any[]; // AM Parent-ChildTab-CRUD
  @Output('crudItemsValueChange') crudItemsValueChange: EventEmitter</*IAufgabenlistePosition*/any[]> = new EventEmitter</*IAufgabenlistePosition*/any[]>(); // AM Parent-ChildTab-CRUD
  showEditDialog : boolean = false; // AM Parent-ChildTab-CRUD

  CRUDItemButtonTitleAktualisieren: string = null; // // AM Parent-ChildTab-CRUD - Kein aktualisieren-Button!
  CRUDDisablePageTitleUpdates:boolean = true; 

  // Attribute, die von SubClasses überschrieben werden müssen!  
  CRUDDetailFullscreen : boolean = false; // Detail zu dieser List ist von Typ CRUDBasicDetailForChildFullscreen (statt) CRUDBasicDetailForChild - also eine Fullscreen-Component (per Routing), kein PopUp!
  CRUDDetailFullscreenRoute : string; // (nur bei CRUDDetailFullscreen==true): Route zu dem Detail, also z.B. 'benutzer__benachrichtigung'

  CRUDItemParentName : string; // wie heisst die Navigation zum Parent innerhalb des CRUDItem ? z.B. bei aufgabenlistenPositionen -> aufgabenlisten
  CRUDItemChildName : string; // wie heisst (das Array) der Childs innerhalb dem Parent ? z.B. bei aufgabenlisten -> aufgabenlistenPositionen
  // Attribute, die von SubClasses überschrieben werden müssen! - ENDE

  CRUDBasicListForChildMode: boolean = true; // NIEMALS manuell setzen! das macht CRUDBasicListForChild!
  CRUDBasicBodyOverflowYSave: string = null; // NIEMALS manuell setzen! das macht CRUDBasicListForChild!

  ngOnInit() { // HTML Template Plugins
    super.ngOnInit();
  //  this.globalService.registerHotKeyHandler(this); // eine Child-Table soll keine eigenen HotKeys haben!
  //  this.app.pageTitle = this['pageTitle'];
    this.CRUDBasicBodyOverflowYSave = document.body.style.overflowY;
  }
    
  ngAfterViewInit() {
    //console.log("CRUDBasicListForChild.ngAfterViewInit()");
  }

  ngOnDestroy() {
    //console.log("CRUDBasicListForChild.ngOnDestroy()");
    //this.globalService.unRegisterHotKeyHandler(this); // eine Child-Table soll keine eigenen HotKeys haben!
  }

  sizeTable() { // Overwrite zum CRUDBasic Standard // AM Parent-ChildTab-CRUD
    // in dem Fall (embedded Table) - Grösse unverändert lassen
  }

  clearFeedback() { // Overwrite zum CRUDBasic Standard // AM Parent-ChildTab-CRUD
    // Nichts! Feedback bei Childs NICHT clearen! // feedback
  }

  getCRUDItems() { // Overwrite zum CRUDBasic Standard // AM Parent-ChildTab-CRUD
    // die CRUDItems kommen hier als Input-Parameter aus der Parent-Comp
    let pagination : IPagination = {
      currentPage: 1,
      nextPageLink: null,
      pageSize: 0,
      previousPageLink: null,
      totalCount: 1,
      totalPages: 1
    }
    this.onCRUDItemsRetrieved(this.crudItems, pagination);
  }

  deleteCRUDItem(CRUDItem: /*ITitel*/any) { // Overwrite zum CRUDBasic Standard // AM Parent-ChildTab-CRUD
      this.confirmationService.confirm({
        message: this.translate.instant('CONFIRM_DELETE_RECORD'),
        header: this.translate.instant('Löschen', true) + '?',
        icon: 'fa fa-trash',
        key: 'CRUDBasicListConfirmDialog_'+this.CRUDItemBezeichnungPluralCapitalized, 
        accept: () => {
          // aus dem array der crudItems entfernen 
          //this.crudItems = this.crudItems.filter(i => i.id != CRUDItem.id); // nicht über die ID filtern! Weil bei 2 neu angelegten Items haben beide ID = 0!
          this.crudItems = this.crudItems.filter(i => i != CRUDItem);
          this.handleChange();
        },
        reject: () => {
        }
      });
  }
    
  addCRUDItem() {  // Overwrite zum CRUDBasic Standard // AM Parent-ChildTab-CRUD
    let newCRUDItem = this.crudItemService['initialize'+this.CRUDItemBezeichnungSingularCapitalized]();
    newCRUDItem['_alreadyAddedToArray'] = false;

    //newCRUDItem.aufgabenlisten = cloneDeep(this.parentCrudItem); // nicht einfach das parentCrudItem einsetzen, sondern EIN CLONE davon, sonst kann .aufgabenlistenPositionen NICHT DELETED WERDEN! (muss aber sonst rekurivitäts-problem beim save()->API)
    //delete newCRUDItem.aufgabenlisten.aufgabenlistenPositionen;
    //newCRUDItem.aufgabenlistenId = this.parentCrudItem.id;
    
    // MODI: im Gegensatz zu IMKE im child nur die parentId bestücken, nicht das parentObjekt! sonst gibt's Probleme beim PUT
    //newCRUDItem[this.CRUDItemParentName] = cloneDeep(this.parentCrudItem); // nicht einfach das parentCrudItem einsetzen, sondern EIN CLONE davon, sonst kann .aufgabenlistenPositionen NICHT DELETED WERDEN! (muss aber sonst rekursiv-problem beim save()->API)
    //delete newCRUDItem[this.CRUDItemParentName][this.CRUDItemChildName];
    newCRUDItem[this.CRUDItemParentName+"Id"] = this.parentCrudItem.id;
    //if(newCRUDItem[this.CRUDItemParentName+"Id"] == undefined || this.parentCrudItem.id == undefined) { // US 27612: im Zuge von DLP hier eine Prüfung eingebaut, weil es imemr wieder passiert, dass 'CRUDItemParentName' falsch definiert ist. Das soll die Fehlersuche erleichtern!
    //  console.error("CRUDBasicListForChild.addCRUDItem() error initializing newCRUDItem. Most likely 'CRUDItemParentName' (='"+this.CRUDItemParentName+"') is defined wrong? newCRUDItem/parentCrudItem:", newCRUDItem, this.parentCrudItem);
    //  debugger;
    //}
    this.selectedCrudItem = newCRUDItem;
    if(this.debugMode==true) console.log("CRUDBasicListForChild.addCRUDItem() selectedCrudItem:", this.selectedCrudItem);

    if(this.CRUDDetailFullscreen == false) {
      document.body.style.overflowY = "hidden"; // scrollbar ausblenden
      this.showEditDialog = true;
      }
    else {
      this.router.navigate(['/' + this.CRUDDetailFullscreenRoute + '/' + 0]); 
    }
  }

  selectCRUDItem(CRUDItem: /*ITitel*/any) { // Overwrite zum CRUDBasic Standard // AM Parent-ChildTab-CRUD
    this.selectedCrudItem = CRUDItem;
    console.log("CRUDBasicListForChild.selectCRUDItem() selectedCrudItem:", this.selectedCrudItem);

    if(this.CRUDDetailFullscreen == false) {
      document.body.style.overflowY = "hidden"; // scrollbar ausblenden
      this.showEditDialog = true;
    }
    else {
      this.router.navigate(['/' + this.CRUDDetailFullscreenRoute + '/' + CRUDItem.id]); // die /:id ist eigentlich irrelevant - ist nur eingebaut, damit wir den vorhandenen reuse-filter (endet mit *) nutzen können
    }
  }

  onCRUDItemValueChange($event) {
    console.log("CRUDBasicListForChild.onCRUDItemValueChange() $event:", $event);
    this.handleChange();
  }

  handleChange() { // AM Parent-ChildTab-CRUD
    console.log("CRUDBasicListForChild.handleChange()");
    this.crudItemsValueChange.emit(this.crudItems);
  }

  closeEditDialog($event, fullscreenMode?: boolean) {
    if(fullscreenMode != null && fullscreenMode == true) { // Detail ist Fullscreen, kein Popup!
      // 
    }
    else {
      document.body.style.overflowY = this.CRUDBasicBodyOverflowYSave; // scrollbar ggf. wieder herstellen (wichtig ist: wenn Dialog innerhalb Dialog -> soll sie aus bleiben!)
      this.showEditDialog=false; 
    }

    console.log("CRUDBasicListForChild.closeEditDialog() $event", $event);
    if(this.selectedCrudItem['_alreadyAddedToArray'] != null && this.selectedCrudItem['_alreadyAddedToArray'] == false) {
      console.log("CRUDBasicListForChild.closeEditDialog(): newCRUDItem needs to be added to the array:", this.selectedCrudItem);
      delete this.selectedCrudItem['_alreadyAddedToArray'];
      this.crudItems.push(this.selectedCrudItem);
      this.handleChange();
    }
    if(this.selectedCrudItem['_deleteFromArray'] != null && this.selectedCrudItem['_deleteFromArray'] == true) {
      console.log("CRUDBasicListForChild.closeEditDialog(): CRUDItem needs to be deleted from the array:", this.selectedCrudItem);
      //delete this.selectedCrudItem['_deleteFromArray']; // egal, wird ja eh jetzt deleted!
      this.crudItems = this.crudItems.filter(f => f != this.selectedCrudItem);
      this.handleChange();
    }
    console.log("CRUDBasicListForChild.closeEditDialog(): CRUDItem crudItems after edit:", this.crudItems);
  }
}
