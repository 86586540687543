<div [ngClass]="{'unterschrift-grid': !_app.inAppMode, 'unterschrift-grid-inAppMode': _app.inAppMode}" *ngIf="bautagebuch == false" style="padding-left: 0px;">
  <div class="unterschrift-grid-sub">
    <!-- width: 430px; height: 215px; --><canvas
      [style.width]="_app.inAppMode ? '100%' : '430px'" 
      style="max-width: 430px; border-color: grey; border-width: 1px; border-style: dotted;"  [ngClass]="(disable == true || disableAfterUpload == true) ? 'disableCanvas' : ''"></canvas>
  </div>
  <div class="unterschrift-grid-sub">
    <!--<div style="margin-bottom: 0.5em;"><button [disabled]="disable" type="button" pButton icon="fa fa-upload"
        [label]="translate.instant('Hochladen',true)" (click)="upload()"></button>
    </div>
    <div style="margin-bottom: 0.5em;"><button [disabled]="disable" type="button" pButton icon="fa fa-undo"
        [label]="translate.instant('Rückgängig',true)" (click)="undo()"></button>
    </div>-->
    <div style="margin-bottom: 0.5em;"><button [disabled]="disable" type="button" pButton icon="fa fa-trash"
        [label]="" (click)="clear()" pTooltip="Löschen"></button>
    </div>
  </div>
</div>

<div class="unterschrift-grid" style="width: inherit;" *ngIf="bautagebuch == true">
  <div class="unterschrift-grid-sub">
    <!-- width: 430px; height: 215px; --><canvas [ngClass]="(disable == true || disableAfterUpload == true) ? 'disableCanvas' : ''"
      style="border-color: grey; border-width: 1px; border-style: dotted;"></canvas>
  </div>
  <div class="unterschrift-grid-sub" style="margin-left: 20px">
    <!-- <div style="margin-bottom: 0.5em;"><button type="button" class="signatureUtilityBtn" [disabled]="disable" pButton icon="fa fa-upload"
        [label]="translate.instant('Hochladen',true)" (click)="upload()"></button>
    </div> -->
    <!-- <div style="margin-bottom: 0.5em;"><button type="button" class="signatureUtilityBtn" [disabled]="disable" pButton icon="fa fa-undo"
        [label]="translate.instant('Rückgängig',true)" (click)="undo()"></button>
    </div> -->
    <div style="margin-bottom: 0.5em;"><button type="button" class="signatureUtilityBtn" [disabled]="disable" pButton icon="fa fa-remove"
        [label]="translate.instant('Löschen',true)" (click)="clear()"></button>
    </div>
  </div>
</div>

<!-- fileUpload wird unsichtbar dargestellt - der upload Buttons soll vom parent gerendert werden. Es muss aber (unsichtbar) vorhanden sein, dass die parentComp CRUDBasicLogo.upload() callen kann -->
<div style="display: none">
  <p-fileUpload #fileUpload [style]="{'width':'90%'}" mode="basic" name="file[]" accept="image/png"
    maxFileSize="1000000" (onSelect)="uploader($event)" [auto]="true"
    [chooseLabel]="base64!=null && (''+base64).length > 0 ? translate.instant('Hochladen',true) : translate.instant('Hochladen',true)">
  </p-fileUpload>
</div>

<br>