import { CRUDBasicListComponent } from '../crud-basic-list/crud-basic-list.component';
//import { CRUDBasicListComponent_Template, CRUDBasicListComponent } from '../crud-basic-list/crud-basic-list.component'; // HTML Template Plugins
//import { StammdatenKorrekturListComponent_Template } from './stammdaten-korrektur-list.component.include_template'; // HTML Template Plugins

import { Component, OnInit, Inject, forwardRef, Injector } from '@angular/core';
import { AppComponent } from '../app.component';

//import { IStammdatenKorrektur } from '../_interfaces/stammdaten-korrektur';
import { StammdatenKorrekturService } from '../_services/stammdaten-korrektur.service';

@Component({
  selector: 'app-stammdaten-korrektur-list',
  templateUrl: '../crud-basic-list/crud-basic-list.component.html',
  //templateUrl: './stammdaten-korrektur-list.component.html',
  //template: `${CRUDBasicListComponent_Template || ''}${StammdatenKorrekturListComponent_Template}`,  // HTML Template Plugins
  styleUrls: ['../crud-basic-list/crud-basic-list.component.css'],
  host: {
    '(window:keydown)': 'hotkeys($event)',
    '(window:resize)': 'sizeTable()'
  }
})
export class StammdatenKorrekturListComponent extends CRUDBasicListComponent implements OnInit {
  pageTitle: string = this.translate.instant("Stammdaten Korrekturen", true);
  CRUDItemKurzform: string = "StammdatenKorrektur";
  CRUDItemButtonTitleNeu: string = /*this.translate.instant("Neue StammdatenKorrektur", true)*/null;
  CRUDItemBezeichnungSingularCapitalized: string = "StammdatenKorrektur";
  CRUDItemBezeichnungPluralCapitalized: string = "StammdatenKorrekturen";
  CRUDItemBezeichnungSingular: string = "stammdatenKorrektur";
  CRUDItemBezeichnungPlural: string = "stammdatenKorrekturen";
  CRUDItemHelpTopic: string = null;
  CRUDItemRouteSingular: string = "stammdaten-korrektur";
  CRUDItemRoutePlural: string = "stammdaten-korrekturen";
  
  CRUDItemAvailableCols: any[] = [
          {
            field: 'createdBy',
            header: this.translate.instant('CreatedBy', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 10,
            showByDefault: false
          },
          {
            field: 'created',
            header: this.translate.instant('Created', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 20,
            showByDefault: false
          },
          {
            field: 'modifiedBy',
            header: this.translate.instant('ModifiedBy', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 30,
            showByDefault: false
          },
          {
            field: 'modified',
            header: this.translate.instant('Modified', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 40,
            showByDefault: false
          },
          {
            field: 'status',
            header: this.translate.instant('Status', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 50,
            showByDefault: true
          },
          {
            field: 'kundennummer',
            header: this.translate.instant('Kundennummer', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 60,
            showByDefault: true
          },
          {
            field: 'behaelternummer',
            header: this.translate.instant('Behälternummer', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 70,
            showByDefault: true
          },
          {
            field: 'name_1',
            header: this.translate.instant('Name 1', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 80,
            showByDefault: true
          },
          {
            field: 'name_2',
            header: this.translate.instant('Name 2', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 90,
            showByDefault: true
          },
          {
            field: 'strasse',
            header: this.translate.instant('Strasse', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 100,
            showByDefault: true
          },
          {
            field: 'plz',
            header: this.translate.instant('PLZ', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 110,
            showByDefault: true
          },
          {
            field: 'ort',
            header: this.translate.instant('Ort', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 120,
            showByDefault: true
          },
          {
            field: 'ortzusatz',
            header: this.translate.instant('Ortzusatz', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 130,
            showByDefault: true
          },
          {
            field: 'telefon',
            header: this.translate.instant('Telefon', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 140,
            showByDefault: true
          },
          {
            field: 'eMail',
            header: this.translate.instant('E-Mail', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 145,
            showByDefault: true
          },
          {
            field: 'bemerkung',
            header: this.translate.instant('Bemerkung', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 150,
            showByDefault: true
          }
    ];

    constructor(
      @Inject(forwardRef(() => AppComponent)) public _app: AppComponent,
      private _crudItemService: StammdatenKorrekturService,
      private _injector: Injector
    ) {
      super(_app, _injector);
      this.crudItemService = _crudItemService;
    }

    //ngOnInit() { // HTML Template Plugins
    //  super.ngOnInit();
    //  this.app.pageTitle = this['pageTitle'];
    //}
}
