<!-- START CRUD STANDARD -->
<!--<p-blockUI [blocked]="loading"><i class="fa fa-spinner fa-pulse fa-3x fa-fw" style="position:absolute;top:25%;left:50%"></i></p-blockUI>-->
<p-confirmDialog [key]="'CRUDBasicListConfirmDialog_'+CRUDItemBezeichnungPluralCapitalized" appendTo="body" id="smth"
  [style.z-index]="'1006 !important'" [acceptLabel]="translate.instant('Ja',true)"
  [rejectLabel]="translate.instant('Nein',true)"></p-confirmDialog>
<!-- *ngIf="enableConfirm" -->
<ng-container *ngTemplateOutlet="CRUDTemplatePluginTop"></ng-container>
<!--<ng-container *ngIf="false; then CRUDTemplatePluginTop; else CRUDTemplatePluginTop_dummy"></ng-container>-->
<p-table *ngIf="killTable_notInUse != true" #dt id="crudDataTable" [columns]="cols" [value]="crudItems" selectionMode="single"
  [(selection)]="selectedCrudItem" (onRowSelect)="selectCRUDItem($event.data)" dataKey="id" [reorderableColumns]="true"
  (onColReorder)="handleColReorder($event)" [autoLayout]="true" [responsive]="true" csvSeparator=";"
  [exportFilename]="translate.instant(CRUDItemBezeichnungPluralCapitalized,true)" [scrollable]="true"
  [scrollHeight]="scrollHeight" [sortField]="CRUDSortField"
  [virtualRowHeight]="CRUDLazyLoadVirtualRowHeight != null ? CRUDLazyLoadVirtualRowHeight : null"
  [totalRecords]="totalRecords" [lazy]="CRUDLazyLoad" (onLazyLoad)="loadLazy($event)" [virtualScroll]="CRUDLazyLoad"
  [loading]="loadingTable" [rows]="CRUDLazyLoad == true ? 25 : null"
  [filters]="CRUDItemFilters"><!--MODI: vorbestückte filters!-->
  <!-- ungefähre Anzahl Zeilen, die auf den Bildschirm passen. primeNG lädt immer die doppelte Anzahl davon vor -->
  <!--   (onSort)="onSort(dt)" (onFilter)="onFilter(dt)"  -->
  <ng-template pTemplate="caption">
    <ng-container *ngTemplateOutlet="CRUDTemplatePluginTableCaption"></ng-container>
    <!--MODI ggü IMKE: Die Buttonleiste ist in einem Grid, weil in der Mitte: pageTitle -->
    <div *ngIf="CRUDTemplatePluginTableCaption==null" class="titlebar-button-grid">
        <div style="text-align: left;">
  
          <button *ngIf="CRUDItemButtonTitleNeu!=null && buttonItemsNeuButton==null" type="button" pButton
            icon="fa fa-plus" (click)="addCRUDItem()" [label]="translate.instant('Neu',true)"
            style="width: auto"></button>
          <!-- width: auto - weil: die ListComponent wird teils auch innerhalb eines cardContainers verwendet (Beispiel EingangsrechnungFreigabeCustomizingDetailComponent)-->
          <!--[label]="CRUDItemButtonTitleNeu"-->
  
          <p-splitButton *ngIf="CRUDItemButtonTitleNeu!=null && buttonItemsNeuButton!=null" icon="fa fa-plus"
            [label]="translate.instant('Neu',true)" (onClick)="neuSplitButtonClicked(null)"
            (onDropdownClick)="neuSplitButtonDropDown(null)" [model]="buttonItemsNeuButton" appendTo="body"
            [style]="{'width': 'auto', 'word-wrap': 'normal'}" [menuStyleClass]="'crudListNeuMenu'"></p-splitButton>
          <!-- [menuStyle]="{'background-color':'yellow !important', 'min-width':'25em !important'}" -->
          <!-- width: auto - weil: die ListComponent wird teils auch innerhalb eines cardContainers verwendet (Beispiel EingangsrechnungFreigabeCustomizingDetailComponent)-->
          <!-- word-wrap: normal - weil: die ListComponent wird teils auch innerhalb eines cardContainers verwendet (Beispiel EingangsrechnungFreigabeCustomizingDetailComponent)-->
  
          <!--<button type="button" pButton icon="fa fa-filter" (click)="filterClicked(dt)"
            [label]="filterEnabled ? translate.instant('Filter deaktivieren',true) : translate.instant('Filter aktivieren',true)"
            style="margin-left: 5px; width: auto;"></button>-->
          <!--<p-multiSelect [options]="columnOptions" [(ngModel)]="cols"
            [selectedItemsLabel]="'{0} ' + translate.instant('Spalte(n) ausgewählt',false)"
            [style]="{'minWidth': '200px','margin-left':'5px', 'width':'auto'}"
            [defaultLabel]="translate.instant('Spalten für Anzeige wählen',true)" [maxSelectedLabels]="0"
            (onChange)="handleColumnOptionChange($event)" dataKey="header">
          </p-multiSelect>-->
        </div>
        <div style="text-align: center;">
          <span style="line-height: 35px; font-size: medium; font-weight: 500;">{{pageTitle}}</span>
        </div>
        <div style="text-align: right;">
          <span class="p-input-icon-left">
            <i class="fa fa-search"></i>
          <input #gb class="globalFilter" type="text" pInputText [placeholder]="translate.instant('Suche',true)"
            (input)="dt.filterGlobal($event.target.value, 'contains')" style="width: auto">
          </span>
          <!--<button type="button" pButton icon="fa fa-download" label="CSV-Export" (click)="exportCSV(dt)"
            style="margin-left: 5px; width: auto;"></button>-->
          <button *ngIf="CRUDItemHelpTopic != null" type="button" pButton icon="fa fa-question"
            [label]="translate.instant('Hilfe',true)" (click)="showHelp()"
            style="margin-left: 5px; width: auto;"></button>
          <button *ngIf="CRUDItemButtonTitleAktualisieren!=null" type="button" pButton icon="fa fa-redo"
            [label]="translate.instant(CRUDItemButtonTitleAktualisieren,true)" (click)="refresh(dt, gb)"
            style="margin-left: 5px; width: auto;"></button>
        </div>
      </div>
  
  </ng-template>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th *ngFor="let col of columns" pReorderableColumn [pSortableColumn]="col.field">
        {{col.header}}
        <p-sortIcon [field]="col.field"></p-sortIcon>
      </th>
      <th class="CRUD_HideSplitColumn" style="width: 10em;"></th>
    </tr>
    <tr *ngIf="filterEnabled">
      <th *ngFor="let col of columns" [ngSwitch]="true">
        <!--<p-calendar #filterModified [locale]="DE" (onInput)="getCalendarInputString($event)" (onBlur)="handleCalendarBlur(dt,col.field,col.filterMatchMode,filterModified)"
          appendTo="body" *ngSwitchCase="col.field === 'modified'" dateFormat="dd.mm.yy"></p-calendar>
        <p-calendar #filterCreated [locale]="DE" (onInput)="getCalendarInputString($event)" (onBlur)="handleCalendarBlur(dt,col.field,col.filterMatchMode,filterCreated)"
          appendTo="body" *ngSwitchCase="col.field === 'created'" dateFormat="dd.mm.yy"></p-calendar>-->

        <!-- 'filterDynamicCalendar', habe ich so genannt, weil #filterDynamic_{{col.field}} ist nicht nutzbar, trotzdem funktioniert das, siehe: https://stackoverflow.com/questions/48347854/how-to-set-unique-template-reference-variables-inside-an-ngfor-angular -->
        <!-- Wichtig: onBlur() alleine reicht nicht, denn wenn man im Calendar auf "Monat zurück" - klickt feuert dann schon der Blur! - daher brauchen wir danach per OnSelect() denselben event. -->
        <p-calendar #filterDynamicCalendar (onInput)="getCalendarInputString($event)"
          (onBlur)="handleCalendarBlur(dt,col.field,col.filterMatchMode,filterDynamicCalendar)"
          (onSelect)="handleCalendarBlur(dt,col.field,col.filterMatchMode,filterDynamicCalendar)" appendTo="body"
          dateFormat="dd.mm.yy" [locale]="DE"
          *ngSwitchCase="crudItemService.propertyTypes!=null && crudItemService.propertyTypeOf!=null && crudItemService.propertyTypeOf(col.field)!=null && crudItemService.propertyTypeOf(col.field).type=='DateTime' || (col.field === 'modified') || (col.field === 'created')">
        </p-calendar>

        <!-- width: 100% ist wichtig, damit die checkbox linksbündig steht -->
        <p-triStateCheckbox #filterDynamicCheckbox
          *ngSwitchCase="crudItemService.propertyTypes!=null && crudItemService.propertyTypeOf!=null && crudItemService.propertyTypeOf(col.field)!=null && crudItemService.propertyTypeOf(col.field).type=='bool'"
          [style]="{width: '100%'}"
          (onChange)="filterCheckBoxChanged(dt,col.field,col.filterMatchMode,filterDynamicCheckbox)">
        </p-triStateCheckbox>
        <input [id]="'filter_' + col.field" *ngSwitchDefault pInputText type="text"
          (input)="dt.filter($event.target.value, col.field, col.filterMatchMode)">
      </th>
      <th class="CRUD_HideSplitColumn"></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-CRUDItem let-columns="columns">
    <tr #tr [pSelectableRow]="CRUDItem" (click)="clickTR(tr)">
      <ng-container *ngFor="let col of columns">
        <!--<td *ngFor="let col of columns" [ngSwitch]="true">-->
        <td [ngSwitch]="true" [class]="col.tdStyleClass"><!--MODI:style.width  [style.width]="(100 / (cols.length+1))+'%'"-->
          <div [style.height]="CRUDLazyLoadVirtualRowHeight != null ? (CRUDLazyLoadVirtualRowHeight-17)+'px' : null"
            [style.overflow]="CRUDLazyLoadVirtualRowHeight != null ? 'hidden' : null">
            <!-- bei lazyLoading / virtualScroll muss die TR eine fixe Grösse haben, sonst Scrolling ungenau - wird dynmisch gesetzt -->
            <!-- warum CRUDLazyLoadVirtualRowHeight-17 ?: Provisorium: eine TR ist 117 px hoch -->
            <span
              *ngSwitchCase="col.field === 'modified' || col.field === 'created'">{{CRUDItem[col.field] | crudBasic:'':propertyTypeModifiedAndCreated }}</span>
            <span
              *ngSwitchCase="typeOfCRUDItem(col.field)=='boolean' && CRUDItem[splitFieldName(col.field, false)]==null">
              <crud-basic-checkbox [disabled]="true" [nullable]="isCRUDItemNullable(splitFieldName(col.field, false))"
                [ngModel]="null"></crud-basic-checkbox>
            </span>
            <span
              *ngSwitchCase="typeOfCRUDItem(col.field)=='boolean' && CRUDItem[splitFieldName(col.field, false)]==true">
              <crud-basic-checkbox [disabled]="true" [nullable]="isCRUDItemNullable(splitFieldName(col.field, false))"
                [ngModel]="true"></crud-basic-checkbox>
            </span>
            <span
              *ngSwitchCase="typeOfCRUDItem(col.field)=='boolean' && CRUDItem[splitFieldName(col.field, false)]==false">
              <crud-basic-checkbox [disabled]="true" [nullable]="isCRUDItemNullable(splitFieldName(col.field, false))"
                [ngModel]="false"></crud-basic-checkbox>
            </span>
            <!--
        <span *ngSwitchCase="typeOfCRUDItem(col.field)=='boolean' && CRUDItem[splitFieldName(col.field, false)]==null">
          <p-triStateCheckbox disabled [ngModel]="null"></p-triStateCheckbox>
        </span>
        <span *ngSwitchCase="typeOfCRUDItem(col.field)=='boolean' && CRUDItem[splitFieldName(col.field, false)]==true">
          <p-triStateCheckbox disabled [ngModel]="true"></p-triStateCheckbox>
        </span>
        <span *ngSwitchCase="typeOfCRUDItem(col.field)=='boolean' && CRUDItem[splitFieldName(col.field, false)]==false">
          <p-triStateCheckbox disabled [ngModel]="false"></p-triStateCheckbox>
        </span>
      -->
            <!--
        <span *ngSwitchCase="typeOf(CRUDItem[splitFieldName(col.field, false)])=='boolean' && CRUDItem[splitFieldName(col.field, false)]==true">
          <p-triStateCheckbox disabled [ngModel]="true"></p-triStateCheckbox>
        </span>
        <span *ngSwitchCase="typeOf(CRUDItem[splitFieldName(col.field, false)])=='boolean' && CRUDItem[splitFieldName(col.field, false)]==false">
          <p-triStateCheckbox disabled [ngModel]="false"></p-triStateCheckbox>
        </span>
      -->

            <!-- HTML Text als innerHTML ausgeben: https://medium.com/@AAlakkad/angular-2-display-html-without-sanitizing-filtering-17499024b079 -->
            <!-- US 27617 24.08.2022: Erweiterung für Kombination aus customPipe und HTML -->
            <span *ngSwitchCase="(col.customPipe==null || col.customPipe==undefined) && col.spezialContentType!=null && col.spezialContentType=='HTML'"
              [class]="col.styleClass" [innerHTML]="CRUDItem[splitFieldName(col.field, false)] | keepHtml"></span>
            <span *ngSwitchCase="col.customPipe!=null && col.spezialContentType!='HTML'"
              [class]="col.styleClass">{{ transformByCustomPipe(col.field,CRUDItem[splitFieldName(col.field, false)]) }}</span>
            <span *ngSwitchCase="col.customPipe!=null && col.spezialContentType!=null && col.spezialContentType=='HTML'"
              [class]="col.styleClass" [innerHTML]="transformByCustomPipe(col.field,CRUDItem[splitFieldName(col.field, false)])"></span>
            <!--<span *ngSwitchDefault [class]="col.styleClass">{{CRUDItem[splitFieldName(col.field, false)] | crudBasic:splitFieldName(col.field, true): (crudItemService.typeOf!=null?crudItemService.typeOf(col.field):null) }}   /*3. parm: falls der service typeOf() unterstützt - mitgeben!*/   </span>-->
            <span *ngSwitchDefault [class]="col.styleClass"
              [innerHTML]="CRUDItem[splitFieldName(col.field, false)] | crudBasic:splitFieldName(col.field, true): (crudItemService.propertyTypes!=null&&crudItemService.propertyTypeOf!=null?crudItemService.propertyTypeOf(col.field):null)"></span>
          </div>
        </td>
      </ng-container>
      <td class="CRUD_HideSplitColumn floatRight" style="width: 10em;">
        <div>
          <p-splitButton id="splitBtn_{{CRUDItem.id}}" [label]="translate.instant('Mehr',true)" icon="fa fa-bars"
            (onClick)="openDropdownMenu(CRUDItem)" (onDropdownClick)="dropdownClicked(CRUDItem)" [model]="buttonItems"
            appendTo="body" [style]="{'word-wrap': 'normal'}"></p-splitButton>
          <!-- word-wrap: normal - weil: die ListComponent wird teils auch innerhalb eines cardContainers verwendet (Beispiel EingangsrechnungFreigabeCustomizingDetailComponent)-->
        </div>
      </td>
    </tr>
  </ng-template>
</p-table>
<!--<ng-template #CRUDTemplatePluginTop_dummy>
</ng-template>-->
<button *ngIf="debugMode == true" pButton (click)="debug(form)" icon="fa-bug" title="debug form"></button>
<!-- ENDE CRUD STANDARD -->


<!--<p-blockUI id="benutzerDetailBlock" *ngIf="showEditDialog" [blocked]="true" [style]="{'opacity':'0.99'}"><!-- alles innerhalb blockUI = Ersatz für nicht funktionierendes modal (- falls es nicht funktioniert)-->
    <div style="position: relative"><!--Höhe = 100vH - die 2rem die die Titelbar hoch ist-->
        <p-dialog *ngIf="showEditDialog" modal="true" id="editDialog" styleClass="editDialog" closeOnEscape="true" 
        [style]="{'width': '100vW', 'max-height': '100%', 'height': 'calc(100vH - 2rem)', 'top': '2rem'}" 
        [contentStyle]="{'overflow':'auto'}" [responsive]="true" [resizable]="true" [closable]="false" [visible]="true"
        [showHeader]="false">
        <!--height="auto" 'margin-right': '15px', 'height':'66%',  -->
            <p-header>
            </p-header>
                <!-- // AM Parent-ChildTab-CRUD -->
                <app-benutzer-detail [CRUDDialogId]="'editDialog'" [CRUDItem]="selectedCrudItem" (CRUDItemValueChange)="onCRUDItemValueChange($event)" (onClose)="closeEditDialog($event)"></app-benutzer-detail>
        </p-dialog>
    </div>
<!--</p-blockUI>-->

<!-- Versuch ohne Dialog: dafür müsste man aber parent (Detail) ausblenden: [style.display]="showEditDialog == false ? 'unset' : 'none'"
<app-checklistePosition-detail *ngIf="showEditDialog" [CRUDDialogId]="'editDialog'" [CRUDItem]="selectedCrudItem" (CRUDItemValueChange)="onCRUDItemValueChange($event)" (onClose)="closeEditDialog($event)"></app-checklistePosition-detail>
-->

<!--<p-blockUI id="benutzerDetailBlock" *ngIf="showEditDialog" [blocked]="true" [style]="{'opacity':'0.99'}"><!-- alles innerhalb blockUI = Ersatz für nicht funktionierendes modal (- falls es nicht funktioniert)-->
  <div style="position: relative">
    <p-dialog *ngIf="passwordResetShow" modal="true" id="editDialog" styleClass="editDialog" closeOnEscape="true" [style]="{'width': '44%'}" [contentStyle]="{'overflow':'auto'}" [responsive]="true" [resizable]="true" [closable]="false" [visible]="true">
    <!--height="auto" 'margin-right': '15px', 'height':'66%',  -->
        <p-header>
        </p-header>
        <app-passwort-reset [benutzerId]="selectedCrudItem.id" (zurueckClicked)="passwordZuruecksetzenZurueckClicked()" (saveClicked)="passwordZuruecksetzenSaveClicked()"></app-passwort-reset>
</p-dialog>
</div>
<!--</p-blockUI>-->

