import { Injectable } from '@angular/core';
//import { Observable } from 'rxjs/Observable';
import { Observable, of } from 'rxjs';
//import 'rxjs/add/operator/map';
//import 'rxjs/add/operator/do';
//import 'rxjs/add/operator/catch';
import { IPagination } from '../_interfaces/pagination'
import { IDokumente } from '../_interfaces/dokumente';
//import { IChoiceList } from '../_interfaces/_choice-list';
import * as moment from 'moment'; // DateTimeOffset-Fix
import * as cloneDeep from 'lodash/cloneDeep'; // DateTimeOffset-Fix

import { AppconfigService } from '../_services/appconfig.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';

import { CRUDBasicService, httpOptions } from '../_services/crud-basic.service';

import { IChoiceList } from '../_interfaces/_choice-list';

@Injectable()
export class DokumenteService extends CRUDBasicService {

  //typeOf deprecated -> CRUDBasicService.propertyTypeOf
  /*typeOf(propertyName: string) {  // welchen Typs sind die Properties IN DER API ?
    let property = this.propertyTypes.find(f => f.name == propertyName);
    if(property != null) return property.type;
    else return null;    
  }*/
  propertyTypes = [ // welchen Typs sind die Properties IN DER API ?
    {name: 'id', type: 'int'},
    {name: 'createdBy', type: 'string'},
    {name: 'created', type: 'DateTimeOffset'},
    {name: 'modifiedBy', type: 'string'},
    {name: 'modified', type: 'DateTimeOffset'},
    {name: 'rowVersion', type: 'byte[]'},
    {name: 'dokument', type: 'byte[]'},
    {name: 'bezeichnung', type: 'string'},
    {name: 'beschreibung', type: 'string'},
    {name: 'kopfdaten', type: 'ICollection<Kopfdaten>'},
    {name: 'summary', type: 'string'},
    {name: 'dateiname', type: 'string'},
    {name: 'dateiendung', type: 'string'},
    //{name: 'leitfaden', type: 'boolean'},
    {name: 'typ', type: 'string'},
    {name: 'reihenfolge', type: 'number'},
    {name: 'link', type: 'string'},

  ];
    
  getDokumenteCollection(pageNumber: number, pageSize: number, searchQuery: string): Observable<{ dokumente: IDokumente[], pagination: IPagination }> {
    return this.httpClient.get(this.apiBaseUrl + 'dokumente?pageNumber=' + pageNumber + '&pageSize=' + pageSize + '&searchQuery=' + searchQuery, { headers: httpOptions, observe: 'response', withCredentials: true })
      .pipe(
        map((response) => {
          return { dokumente: <IDokumente[]>response.body, pagination: /*<IPagination>JSON.parse(response.headers.get('x-pagination'))*/this.getDummyPagination((<any[]>response.body).length) };
        }), catchError(this.handleError))
  }

  getDokumenteCollectionLeitfadenOnly(pageNumber: number, pageSize: number, searchQuery: string): Observable<{ dokumente: IDokumente[], pagination: IPagination }> {
    return this.httpClient.get(this.apiBaseUrl + 'dokumente?pageNumber=' + pageNumber + '&pageSize=' + pageSize + '&searchQuery=' + searchQuery, { headers: httpOptions, observe: 'response', withCredentials: true })
      .pipe(
        map((response) => {
          
          let dokumente: IDokumente[] = <IDokumente[]>response.body;
          let onlyLeitfaeden = dokumente.filter(f => f.typ == 'LF');

          let sorted = onlyLeitfaeden.sort((a, b) => {
            if (a.reihenfolge > b.reihenfolge) {
              return 1;
            }
            if (a.reihenfolge < b.reihenfolge) {
              return -1;
            }
            return 0;
          });
  
          return { dokumente: /*<IDokumente[]>response.body*/sorted, pagination: /*<IPagination>JSON.parse(response.headers.get('x-pagination'))*/this.getDummyPagination((/*<any[]>response.body*/sorted).length) };
        }), catchError(this.handleError))
  }


  getDokument(id: number): Observable<IDokumente> {

    if (id === 0) {
      // return Observable.create((observer: any) => {
      //   observer.next(this.initializeDokumente());
      //   observer.complete();
      // })
      return of(this.initializeDokument());
    }

    return this.httpClient.get<IDokumente>(this.apiBaseUrl + 'dokumente?id=' + id, { headers: httpOptions, observe: 'body', withCredentials: true })
      .pipe(map((response) => 
      //response
      {
        console.log("DokumenteService.getKopfdaten() response: ", response)
        return response[0];
      }
      ), catchError(this.handleError));
  }

  saveDokument(dokumente: IDokumente): Observable<IDokumente> {
    // DateTimeOffset-Fix
    let itemToSave = cloneDeep(dokumente); // clonen, um sicherzustellen, dass das Original-Objekt nicht ver�ndert wird, evtl. passiert nach dem Save noch etwas damit ?
    //console.log("DokumenteService.saveSingularCapitalized#() itemToSave before DateTimeOffset-Fix:", itemToSave);
    this.propertyTypes.filter(f => f.type.toLowerCase() == 'datetimeoffset').forEach(propertyType => {
      if (itemToSave[propertyType.name] != null) {
        itemToSave[propertyType.name] = moment(itemToSave[propertyType.name]).format('YYYY-MM-DDTHH:mm:ss.SSSZ'); // alle datetimeoffset-felder entspr. formatieren, dass in der API auch der Offset ankommt!
      }
    });
    //console.log("DokumenteService.saveSingularCapitalized#() itemToSave after DateTimeOffset-Fix:", itemToSave);

    if (dokumente.id === 0) {
      return this.createDokument(/*dokumente*/itemToSave);
    }

    return this.updateDokument(/*dokumente*/itemToSave)

  }

  updateDokument(dokumente: IDokumente): Observable<IDokumente> {

    return this.httpClient.put<IDokumente>(this.apiBaseUrl + 'dokumente' /*+ dokumente.id*/, dokumente, { headers: httpOptions, observe: 'body', withCredentials: true })
    .pipe(catchError(this.handleError))

  }

  deleteDokument(id: number) {

    return this.httpClient.delete(this.apiBaseUrl + 'dokumente?id=' + id, { headers: httpOptions, observe: 'body', withCredentials: true })
      .pipe(
        catchError(this.handleError)
      )
  }

  createDokument(dokumente: IDokumente): Observable<IDokumente> {
    return this.httpClient.post<IDokumente>(this.apiBaseUrl + 'dokumente', dokumente, { headers: httpOptions, observe: 'body', withCredentials: true })
      .pipe(map((response) => response), catchError(this.handleError))
  }

  getValidChoiceList_Typ(): Observable<{ choiceListEntries: IChoiceList[] }> {
    return this.httpClient.get(this.apiBaseUrl + 'dokumente/GetValidChoiceList_Typ', { headers: httpOptions, observe: 'response', withCredentials: true })
      .pipe(
        map((response) => {
          //console.log("DokumenteService.getValidChoiceList_Typ() response.body:", response.body);
          return { 
            choiceListEntries: <IChoiceList[]>response.body
          };
        }), catchError(this.handleError))
  }

  private getDownloadDokumentURL(id: number): string {
    return this.apiBaseUrl + 'dokumente/download/' + id;
  }
  private downloadDokumentToBlob(id: number): Observable<any> { // https://www.youtube.com/watch?v=tAIxMGUEMqE
    let httpOptionsForBinaryContent = new HttpHeaders({
      //'Content-Type': 'application/pdf', // nicht nötig
      //'Access-Control-Allow-Origin': '*', // nicht nötig
      //responseType : 'blob',  // siehe unten: direkt als Parm mitgegeben
      //Accept : 'application/pdf',
      Accept : 'application/*', // nicht nur PDF - auch Word!
      //observe : 'response'  // siehe unten: direkt als Parm mitgegeben
    });

    let url = this.getDownloadDokumentURL(id);
    return this.httpClient.get(url, { headers: httpOptionsForBinaryContent, observe: 'response', responseType: 'blob', withCredentials: true })
      .pipe(
        tap((response) => { 
          console.log("DokumenteService.downloadDokumentToBlob() response:", response);
          return response;
        }),
        catchError(this.handleError));
  }
  public downloadDokumentViaBlob(dokumentId, targetDSN: string /*(D)ownload, (S)ame Window, (N)ew Window*/) {
    console.log("DokumenteService.downloadDokumentViaBlob dokumentId:", dokumentId);
    this.downloadDokumentToBlob(dokumentId)
    .subscribe(
      response => {
        console.log("DokumenteService.downloadDokumentViaBlob response:", response);
        
        let filename = response.headers.get('content-disposition')?.split(';')[1].split('=')[1]; // erfordert API-seitig: .WithExposedHeaders("content-disposition")
        if(filename.startsWith('"')) filename = filename.substr(1);
        if(filename.endsWith('"')) filename = filename.substr(0, filename.length-1);
        console.log("DokumenteService.downloadDokumentViaBlob filename:", filename);
        let blob = response.body as Blob;
        
        switch(targetDSN) {
          case "D": // download
              let a = document.createElement('a');
              a.download = filename;    // wenn man das auslässt geht die PDF direkt auf, statt download
              a.href = window.URL.createObjectURL(blob);
              a.click();
              break;
          case "S": // open in SAME Window
              let a2 = document.createElement('a');
              //a2.download = filename;    // wenn man das auslässt geht die PDF direkt auf, statt download
              a2.href = window.URL.createObjectURL(blob);
              a2.click();
              break;
          case "N": // open in NEW Window
              let fileurl = window.URL.createObjectURL(blob);
              window.open(fileurl, "_blank");
              break;
          default: 
              break;
        }

        //Alternative von https://www.fachinformatiker.de/topic/152173-javascript-windowopenurl-mit-token-im-header/    -> öffnet neues Fenster
        //let file = new Blob([response.body],{type:'application/pdf'});
        //let fileurl = window.URL.createObjectURL(file);
        //window.open(fileurl, "_blank");
      },
      error => this.handleError(error)
    );
  }

  private getOpenInNewTabURL(id: number): string {
    return this.apiBaseUrl + 'dokumente/openInNewTab/' + id /*+ "." + Math.floor(Math.random() * 65535)*/ + ".pdf";
  }
  private openInNewTabToBlob(id: number): Observable<any> { // https://www.youtube.com/watch?v=tAIxMGUEMqE
    let httpOptionsForBinaryContent = new HttpHeaders({
      //'Content-Type': 'application/pdf', // nicht nötig
      //'Access-Control-Allow-Origin': '*', // nicht nötig
      //responseType : 'blob',  // siehe unten: direkt als Parm mitgegeben
      //Accept : 'application/pdf',
      Accept : 'application/*', // nicht nur PDF - auch Word!
      //observe : 'response'  // siehe unten: direkt als Parm mitgegeben
    });

    let url = this.getOpenInNewTabURL(id);
    return this.httpClient.get(url, { headers: httpOptionsForBinaryContent, observe: 'response', responseType: 'blob', withCredentials: true })
      .pipe(
        tap((response) => { 
          console.log("DokumenteService.openInNewTabToBlob() response:", response);
          return response;
        }),
        catchError(this.handleError));
  }
  public openInNewTabViaBlob(dokumentId, targetDSN: string /*(D)ownload, (S)ame Window, (N)ew Window*/) {
    console.log("DokumenteService.openInNewTabViaBlob dokumentId:", dokumentId);
    this.downloadDokumentToBlob(dokumentId)
    .subscribe(
      response => {
        console.log("DokumenteService.openInNewTabViaBlob response:", response);
        
        let filename = response.headers.get('content-disposition')?.split(';')[1].split('=')[1]; // erfordert API-seitig: .WithExposedHeaders("content-disposition")
        if(filename.startsWith('"')) filename = filename.substr(1);
        if(filename.endsWith('"')) filename = filename.substr(0, filename.length-1);
        console.log("DokumenteService.openInNewTabViaBlob filename:", filename);
        let blob = response.body as Blob;
        
        switch(targetDSN) {
          case "D": // download
              let a = document.createElement('a');
              a.download = filename;    // wenn man das auslässt geht die PDF direkt auf, statt download
              a.href = window.URL.createObjectURL(blob);
              a.click();
              break;
          case "S": // open in SAME Window
              let a2 = document.createElement('a');
              //a2.download = filename;    // wenn man das auslässt geht die PDF direkt auf, statt download
              a2.href = window.URL.createObjectURL(blob);
              a2.click();
              break;
          case "N": // open in NEW Window
              let fileurl = window.URL.createObjectURL(blob);
              window.open(fileurl, "_blank");
              break;
          default: 
              break;
        }

        //Alternative von https://www.fachinformatiker.de/topic/152173-javascript-windowopenurl-mit-token-im-header/    -> öffnet neues Fenster
        //let file = new Blob([response.body],{type:'application/pdf'});
        //let fileurl = window.URL.createObjectURL(file);
        //window.open(fileurl, "_blank");
      },
      error => this.handleError(error)
    );
  }

  private getDownloadResultByGuidURL(guid: string): string {
    return this.apiBaseUrl + 'dokumente/downloadResultByGuid/' + guid;
  }
  private downloadResultByGuidURLToBlob(guid: string): Observable<any> { // https://www.youtube.com/watch?v=tAIxMGUEMqE
    let httpOptionsForBinaryContent = new HttpHeaders({
      //'Content-Type': 'application/pdf', // nicht nötig
      //'Access-Control-Allow-Origin': '*', // nicht nötig
      //responseType : 'blob',  // siehe unten: direkt als Parm mitgegeben
      //Accept : 'application/pdf',
      Accept : 'application/*', // nicht nur PDF - auch Word!
      //observe : 'response'  // siehe unten: direkt als Parm mitgegeben
    });

    let url = this.getDownloadResultByGuidURL(guid);
    return this.httpClient.get(url, { headers: httpOptionsForBinaryContent, observe: 'response', responseType: 'blob', withCredentials: true })
      .pipe(
        tap((response) => { 
          console.log("DokumenteService.downloadResultByGuidURLToBlob() response:", response);
          return response;
        }),
        catchError(this.handleError));
  }
  public downloadResultByGuidURLViaBlob(guid: string, targetDSN: string /*(D)ownload, (S)ame Window, (N)ew Window*/) {
    console.log("DokumenteService.downloadResultByGuidURLViaBlob dokumentId:", guid);
    this.downloadResultByGuidURLToBlob(guid)
    .subscribe(
      response => {
        console.log("DokumenteService.downloadResultByGuidURLViaBlob response:", response);
        
        let filename = response.headers.get('content-disposition')?.split(';')[1].split('=')[1]; // erfordert API-seitig: .WithExposedHeaders("content-disposition")
        if(filename.startsWith('"')) filename = filename.substr(1);
        if(filename.endsWith('"')) filename = filename.substr(0, filename.length-1);
        console.log("DokumenteService.downloadResultByGuidURLViaBlob filename:", filename);
        let blob = response.body as Blob;
        
        switch(targetDSN) {
          case "D": // download
              let a = document.createElement('a');
              a.download = filename;    // wenn man das auslässt geht die PDF direkt auf, statt download
              a.href = window.URL.createObjectURL(blob);
              a.click();
              break;
          case "S": // open in SAME Window
              let a2 = document.createElement('a');
              //a2.download = filename;    // wenn man das auslässt geht die PDF direkt auf, statt download
              a2.href = window.URL.createObjectURL(blob);
              a2.click();
              break;
          case "N": // open in NEW Window
              let fileurl = window.URL.createObjectURL(blob);
              window.open(fileurl, "_blank");
              break;
          default: 
              break;
        }

        //Alternative von https://www.fachinformatiker.de/topic/152173-javascript-windowopenurl-mit-token-im-header/    -> öffnet neues Fenster
        //let file = new Blob([response.body],{type:'application/pdf'});
        //let fileurl = window.URL.createObjectURL(file);
        //window.open(fileurl, "_blank");
      },
      error => this.handleError(error)
    );
  }


  initializeDokument(): IDokumente {
    return {
      id: 0,
      createdBy: '',
      created: /*''*/'0001-01-01T00:00:00.000Z', // fix
      modifiedBy: '',
      modified: /*''*/'0001-01-01T00:00:00.000Z', // fix
      rowVersion: '',
      dokument: '',
      bezeichnung: '',
      beschreibung: '',
      kopfdaten: null,
      summary: '',
      dateiname: '',
      dateiendung: '',
      //leitfaden: false,
      //leitfadenSortierung: null,
      typ: null,
      reihenfolge: null,
      link: null,
    };
  }


}
