        <!--ACHTUNG! wenn sich im li die *ngIF ändert (weiter unten) -> das 1:1 auch in app-menuitem nachziehen!-->
        <ul class="layout-menu" style="color: #657380; padding-bottom: 0;">
            <li *ngIf="benutzer != null" class="layout-menuitem-text" style="margin-top: 2em; margin-left: 1em; margin-bottom: -1em; font-weight: bold; cursor: pointer;"
            (click)="routeToMyDienstleister()" pTooltip="Meine Dienstleister-Einstellungen">
                <i class="fa fa-user" style="font-size: 1.5em;"></i>
                &nbsp;&nbsp;
                {{benutzer.vorname + ' ' + benutzer.name}}
                &nbsp;<small>({{benutzer['userName']}})</small>
            </li>
            <ng-container *ngFor="let item of modelAllInOne; let i = index;">
                <li app-menuitem
                [item]="item" [index]="i" [root]="true" [benutzer]="benutzer"></li>
            </ng-container>
            <!--<li class="layout-menuitem-text" style="margin-top: -1em; margin-bottom: -1em; ">
                <hr>
            </li>-->
        </ul>    


        <!--ACHTUNG! wenn sich im li die *ngIF ändert (weiter unten) -> das 1:1 auch in app-menuitem nachziehen!-->
        <!--
        <ul *ngIf="benutzer != null" class="layout-menu" style="color: #657380; padding-bottom: 0;">
            <li class="layout-menuitem-text" style="margin-top: 2em; margin-left: 1em; margin-bottom: -1em; font-weight: bold; cursor: pointer;"
            (click)="routeToMyDienstleister()" pTooltip="Meine Dienstleister-Einstellungen">
                <i class="fa fa-user" style="font-size: 1.5em;"></i>
                &nbsp;&nbsp;
                {{benutzer.vorname + ' ' + benutzer.name}}
                &nbsp;<small>({{benutzer['userName']}})</small>
            </li>
            <li app-menuitem *ngFor="let item of modelUser; let i = index;" [item]="item" [index]="i" [root]="true"></li>
            <li class="layout-menuitem-text" style="margin-top: -1em; margin-bottom: -1em; ">
                <hr>
            </li>
        </ul>    

        <ul *ngIf="benutzer == null" class="layout-menu" style="color: #657380; padding-bottom: 0;">
            <li class="layout-menuitem-text" app-menuitem *ngFor="let item of modelUserNotSignedIn; let i = index;" [item]="item" [index]="i" [root]="true"></li>
        </ul>    

        <ul *ngIf="benutzer != null" class="layout-menu">
            <ng-container *ngFor="let item of model; let i = index;">
                <li *ngIf="item.adminOnly != true || benutzer.istAdmin == true" app-menuitem [item]="item" [index]="i" [root]="true" [benutzer]="benutzer"></li>
            </ng-container>
        </ul>
        -->
