import { Component, OnInit } from '@angular/core';

// Map/Liste über Umweg laden, sonst kommt es bei Wechsel zw. dee beiden zu Fehler Leaflet map already initialised

@Component({
  selector: 'app-dummy',
  templateUrl: './dummy.component.html',
  styleUrls: ['./dummy.component.scss']
})
export class DummyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
