import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { of, Observable } from 'rxjs';
import { catchError, mapTo, map, tap } from 'rxjs/operators';
////import { config } from './../../config';
import { AppconfigService } from '../_services/appconfig.service';
import { AuthTokens } from './authTokens';

import { throwError as observableThrowError } from 'rxjs';
import { IBenutzer } from '../_interfaces/benutzer';
import { AppComponent } from '../app.component';

import { CRUDBasicService, httpOptions } from '../_services/crud-basic.service';
import * as cloneDeep from 'lodash/cloneDeep'; // DateTimeOffset-Fix

//import { Observable } from 'rxjs/Observable';
//import 'rxjs/add/operator/map';
//import 'rxjs/add/operator/do';
//import 'rxjs/add/operator/catch';

@Injectable({
  providedIn: 'root'
})
export class AuthService /*extends CRUDBasicService*/ {

  private readonly JWT_TOKEN = 'JWT_TOKEN';
  private readonly REFRESH_TOKEN = 'REFRESH_TOKEN';
  private readonly BENUTZER = 'BENUTZER';
  //private loggedUser: string;
  public _app: AppComponent = null;

  private debugMode: boolean = true;

  constructor(private http: HttpClient, private config: AppconfigService) {}

  login1FA(user: { username: string, password: string }): Observable<boolean> {
    if(this.debugMode==true) console.log("AuthService.login() trying to login as username: ", user.username);
    return this.http.post<any>(`${this.config.get('apiBaseUrl')}auth/logIn1FA`, user)
      .pipe(
        tap(tokens => this.doLoginUser(user.username, tokens)),
        mapTo(true)//,
        //catchError(error => {
        //  alert(error.error);
        //  return of(false);
        //}
        //)
        );
  }

  login1FAForDebug(user: { username: string, password: string }): Observable<boolean> {
    if(this.debugMode==true) console.log("AuthService.login() trying to login as username: ", user.username);
    return this.http.post<any>(`${this.config.get('apiBaseUrl')}auth/logIn1FA`, user)
    .pipe(map((response) => response), catchError(this.handleError))
  }

  login2FA_Step1(user: { username: string, password: string }): Observable<any> {
    return this.http.post<any>(`${this.config.get('apiBaseUrl')}auth/logIn2FAStep1`, user, { headers: httpOptions, observe: 'body', withCredentials: false })
      .pipe(map((response) => response), catchError(this.handleError))
  }

  login2FA_Step2(user: { username: string, password: string, code: string }): Observable<any> {
    return this.http.post<any>(`${this.config.get('apiBaseUrl')}auth/logIn2FAStep2`, user, { headers: httpOptions, observe: 'body', withCredentials: false })
      .pipe(
        tap(tokens => this.doLoginUser(user.username, tokens)),
        map((response) => response), catchError(this.handleError)
        )
  }

  logout() {
    if(this.debugMode==true) console.log("AuthService.logout() trying to logout ...");
    return this.http.post<any>(`${this.config.get('apiBaseUrl')}auth/logout`, {
      'refreshToken': this.getRefreshToken()
    }).pipe(
      tap(() => this.doLogoutUser()),
      mapTo(true),
      catchError(error => {
        alert(error.error);
        return of(false);
      }));
  }

  isLoggedIn() {
    if(this.debugMode==true) console.log("AuthService.isLoggedIn()");
    let retVal = !!this.getJwtToken();
    ///*if(this.debugMode==true)*/ console.log("AuthService.isLoggedIn() retVal:", retVal);
    return retVal;
  }

  checkAuthTestController() {
    if(this.debugMode==true) console.log("AuthService.checkAuthTestController() testing ...");
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      /*'Authorization': `Bearer ${this.getJwtToken()}`*/
    });
    return this.http.get(`${this.config.get('apiBaseUrl')}authTest`, { headers: headers, observe: 'response', withCredentials: true }) 
    .pipe(
      map((response) => {
        let authTest = <any>response.body;
        if(this.debugMode==true) console.log("AuthService.checkAuthTestController() response authTest:", authTest);
        return { authTest: authTest };
      }), catchError(
        this.handleError))

  }


  refreshToken() {
    if(this.debugMode==true) console.log("AuthService.refreshToken()");
    return this.http.post<any>(`${this.config.get('apiBaseUrl')}auth/refreshToken`, {
      'refreshToken': this.getRefreshToken()
    }).pipe(tap((tokens: AuthTokens) => {
      this.storeJwtToken(tokens.jwt);
      if(this.debugMode==true) console.log("AuthService.refreshToken() refreshed token: ", tokens.jwt);
    }));
  }

  getJwtToken() {
    if(this.debugMode==true) console.log("AuthService.getJwtToken()");
    return localStorage.getItem(this.JWT_TOKEN);
  }

  private doLoginUser(username: string, tokens: AuthTokens) {
    if(this.debugMode==true) console.log("AuthService.doLoginUser() username/_app/tokens:", username, this._app, tokens);
    //this.loggedUser = username;
    //this._app.benutzer = tokens.benutzer;

    // Herkunft der Benutzer-Info mitgeben:
    //let benutzerClone = cloneDeep(tokens.benutzer);
    //benutzerClone['___comesFrom'] = 'doLoginUser';
    console.error("authService.doLoginUser() benutzer:", /*benutzerClone*/tokens.benutzer);
    this._app.benutzerBehaviourSubject.next(/*benutzerClone*/tokens.benutzer);

    this.storeTokens(tokens);
  }

  /*private*/public doLogoutUser() {
    if(this.debugMode==true) console.log("AuthService.doLogoutUser()");
    //this.loggedUser = null;
    //this._app.benutzer = null;
    this._app.benutzerBehaviourSubject.next(null);
    this.removeTokens(); 
  }

  public getRefreshToken() {
    if(this.debugMode==true) console.log("AuthService.getRefreshToken()");
    return localStorage.getItem(this.REFRESH_TOKEN);
  }

  private storeJwtToken(jwt: string) {
    if(this.debugMode==true) console.log("AuthService.storeJwtToken()");
    localStorage.setItem(this.JWT_TOKEN, jwt);
  }

  public storeTokens(tokens: AuthTokens) {
    if(this.debugMode==true) console.log("AuthService.storeTokens()");
    localStorage.setItem(this.JWT_TOKEN, tokens.jwt);
    localStorage.setItem(this.REFRESH_TOKEN, tokens.refreshToken);
    this.storeBenutzer(tokens.benutzer);
  }

  public removeTokens() { // public, weil auch aus app.component aufgerufen
    if(this.debugMode==true) console.log("AuthService.removeTokens()");
    localStorage.removeItem(this.JWT_TOKEN);
    localStorage.removeItem(this.REFRESH_TOKEN);
    localStorage.removeItem(this.BENUTZER);
  }

  private storeBenutzer(benutzer: IBenutzer) {
    let benutzerAlsString = JSON.stringify(benutzer);
    localStorage.setItem(this.BENUTZER, benutzerAlsString);
    if(this.debugMode==true) console.log("AuthService.storeBenutzer(): ", benutzerAlsString);
  }

  public getBenutzer() {
    let benutzerAlsString = localStorage.getItem(this.BENUTZER);
    let benutzerAlsJSON = JSON.parse(benutzerAlsString);
    if(this.debugMode==true) console.log("AuthService.getBenutzer() benutzerAlsJSON: ", benutzerAlsJSON);
    return benutzerAlsJSON;
  }

  public isAdmin() {
    let benutzer = this.getBenutzer();
    //if(benutzer != null && benutzer.unternehmen.id == 1) return true;
    if(benutzer != null && benutzer.istAdmin == true) return true;
    else return false;
  }

  /*public isMyUnternehmen(unternehmenId: number) {
    let benutzer = this.getBenutzer();
    if(benutzer != null && benutzer.unternehmen.id == unternehmenId) return true;
    else return false;
  }*/

  // JWT decoden:
  // https://stackoverflow.com/questions/48075688/how-to-decode-the-jwt-encoded-token-payload-on-client-side-in-angular-5
  private chars: string = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
  private atob(input) {
      var str = String(input).replace(/=+$/, '');
      if (str.length % 4 == 1) {
          throw new Error("'atob' failed: The string to be decoded is not correctly encoded.");
      }
      for (
          // initialize result and counters
          var bc = 0, bs, buffer, idx = 0, output = '';
          // get next character
          buffer = str.charAt(idx++);
          // character found in table? initialize bit storage and add its ascii value;
          ~buffer && (bs = bc % 4 ? bs * 64 + buffer : buffer,
              // and if not first of each 4 characters,
              // convert the first 8 bits to one ascii character
              bc++ % 4) ? output += String.fromCharCode(255 & bs >> (-2 * bc & 6)) : 0
      ) {
          // try to find character in table (0-63, not found => -1)
          buffer = this.chars.indexOf(buffer);
      }
      return output;
  };

  parseJwt(token) {
      var base64Url = token.split('.')[1];
      var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      var jsonPayload = decodeURIComponent(this.atob(base64).split('').map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));

      return JSON.parse(jsonPayload);
  };

  /*private*/protected handleError(error) {
    if(this.debugMode==true) console.log("AuthService.handleError error: ", error);
    return observableThrowError(error);
    //return Observable.throw(error);


    /*
    if(error.status === 422 && error.error.DbUpdateException) { // Modi: ohne das: webpack-error!
      return observableThrowError(error);
    }
    else if (error.status === 422 || error.status === 401) {
      if (error.status === 422) {
        //console.log("CRUDBasicService.handleError error: ", error);
      }
      return Observable.throw(error.Concurrency || error.DbUpdateException || error.Error || 'Server Error');
    }
    return Observable.throw(error.error || 'Server error')
    */
  }
}
