import { CRUDBasicListComponent } from '../crud-basic-list/crud-basic-list.component';
//import { CRUDBasicListComponent_Template, CRUDBasicListComponent } from '../crud-basic-list/crud-basic-list.component'; // HTML Template Plugins
//import { LeistungListComponent_Template } from './leistung-list.component.include_template'; // HTML Template Plugins

import { Component, OnInit, Inject, forwardRef, Injector } from '@angular/core';
import { AppComponent } from '../app.component';

//import { ILeistung } from '../_interfaces/leistung';
import { LeistungService } from '../_services/leistung.service';

import { DomSanitizer } from '@angular/platform-browser';
import { FAIconPipe } from '../_pipes/faicon.pipe';

@Component({
  selector: 'app-leistung-list',
  templateUrl: '../crud-basic-list/crud-basic-list.component.html',
  //templateUrl: './leistung-list.component.html',
  //template: `${CRUDBasicListComponent_Template || ''}${LeistungListComponent_Template}`,  // HTML Template Plugins
  styleUrls: ['../crud-basic-list/crud-basic-list.component.css'],
  host: {
    '(window:keydown)': 'hotkeys($event)',
    '(window:resize)': 'sizeTable()'
  }
})
export class LeistungListComponent extends CRUDBasicListComponent implements OnInit {
  pageTitle: string = this.translate.instant("Leistungen", true);
  CRUDItemKurzform: string = "Leistung";
  CRUDItemButtonTitleNeu: string = this.translate.instant("Neue Leistung", true);
  CRUDItemBezeichnungSingularCapitalized: string = "Leistung";
  CRUDItemBezeichnungPluralCapitalized: string = "Leistungen";
  CRUDItemBezeichnungSingular: string = "leistung";
  CRUDItemBezeichnungPlural: string = "leistungen";
  CRUDItemHelpTopic: string = null;
  CRUDItemRouteSingular: string = "leistung";
  CRUDItemRoutePlural: string = "leistungen";

  // customPipe !!!
  fAIconPipe: FAIconPipe = new FAIconPipe(this._app, this.sanitizer);
    
  CRUDItemAvailableCols: any[] = [
          {
            field: 'leistung',
            header: this.translate.instant('Leistung', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 10,
            showByDefault: true
          },
          {
            field: 'typ',
            header: this.translate.instant('Typ', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 20,
            showByDefault: true
          },
          {
            field: 'bezeichnung',
            header: this.translate.instant('Bezeichnung', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 30,
            showByDefault: true
          },
          {
            field: 'icon',
            header: this.translate.instant('Icon', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 40,
            showByDefault: true,
            spezialContentType: 'HTML',
            customPipe: this.fAIconPipe
          },
          {
            field: 'createdBy',
            header: this.translate.instant('CreatedBy', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 50,
            showByDefault: false
          },
          {
            field: 'created',
            header: this.translate.instant('Created', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 60,
            showByDefault: false
          },
          {
            field: 'modifiedBy',
            header: this.translate.instant('ModifiedBy', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 70,
            showByDefault: false
          },
          {
            field: 'modified',
            header: this.translate.instant('Modified', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 80,
            showByDefault: false
          },
          {
            field: 'geringfuegiger_Mangel',
            header: this.translate.instant('Geringfügiger Mangel', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 90,
            showByDefault: true
          },
          {
            field: 'erheblicher_Mangel',
            header: this.translate.instant('Erheblicher Mangel', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 100,
            showByDefault: true
          },
          {
            field: 'pumpentyp',
            header: this.translate.instant('Pumpentyp', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 103,
            showByDefault: true,
            nullable: true
          },
          {
            field: 'anstrich',
            header: this.translate.instant('Anstrich', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 106,
            showByDefault: true,
            nullable: true
          },
          {
            field: 'unterschriftKunde',
            header: this.translate.instant('Unterschrift Kunde', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 109,
            showByDefault: true,
            nullable: true
          },
          {
            field: 'pruefergebnis',
            header: this.translate.instant('Prüfergebnis', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 112,
            showByDefault: true,
            nullable: true
          },
          {
            field: 'checkliste.bezeichnung',
            header: this.translate.instant('Checkliste', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 120,
            showByDefault: true
          },
    ];

    constructor(
      @Inject(forwardRef(() => AppComponent)) public _app: AppComponent,
      private _crudItemService: LeistungService,
      private sanitizer: DomSanitizer,
      private _injector: Injector
    ) {
      super(_app, _injector);
      this.crudItemService = _crudItemService;
    }

    //ngOnInit() { // HTML Template Plugins
    //  super.ngOnInit();
    //  this.app.pageTitle = this['pageTitle'];
    //}

    onCRUDItemsRetrieved(CRUDItems: /*ITitel*/any[], pagination: /*IPagination*/any) {
      // leistungen kommen ohne id! - künstlich eine id nachtragen: id = leistung
      CRUDItems.forEach(CRUDItem => {
        CRUDItem.id = CRUDItem.leistung
      });
      super.onCRUDItemsRetrieved(CRUDItems, pagination);
    }

    afterRefreshRow(crudItem: any) {
      // leistungen kommen ohne id! - künstlich eine id nachtragen: id = leistung
      crudItem.id = crudItem.leistung
    }

}
