//import { CRUDBasicListComponent_Template, CRUDBasicListComponent } from '../crud-basic-list/crud-basic-list.component'; // HTML Template Plugins
import { /*CRUDBasicListComponent_Template,*/ CRUDBasicListComponent } from '../crud-basic-list/crud-basic-list.component'; // HTML Template Plugins
import { /*CRUDBasicListComponent_Template,*/ CRUDBasicListForChildComponent } from '../crud-basic-list-for-child/crud-basic-list-for-child.component'; // HTML Template Plugins
//import { ChecklistePositionenFeldwertListComponent_Template } from './checkliste-positionen-feld-list.component.include_template'; // HTML Template Plugins

import { Component, OnInit, Inject, forwardRef, Injector, Input, SimpleChanges } from '@angular/core';
import { AppComponent } from '../app.component';

//import { IChecklistePositionenFeldwert } from '../_interfaces/checkliste-positionen-feld';
import { ChecklistePositionenFeldwertService } from '../_services/checkliste-positionen-feldwert.service';

@Component({
  selector: 'app-checkliste-positionen-feldwert-list',
  //templateUrl: '../crud-basic-list/crud-basic-list.component.html',
  templateUrl: './checkliste-positionen-feldwert-list.component.html',
  //template: `${CRUDBasicListComponent_Template || ''}${ChecklistePositionenFeldwertListComponent_Template}`,  // HTML Template Plugins
  styleUrls: ['../crud-basic-list/crud-basic-list.component.css'],
  host: {
    '(window:keydown)': 'hotkeys($event)',
    '(window:resize)': 'sizeTable()'
  }
})
export class ChecklistePositionenFeldwertListComponent extends CRUDBasicListForChildComponent implements OnInit {
  @Input('typ') typ: string; // dropdown / combobox
  pageTitle: string = this.translate.instant(/*"Checkliste Positionen Feldwerte"*/"Auswahl-Liste", true);
  CRUDItemKurzform: string = "ChecklistePositionenFeldwert";
  CRUDItemButtonTitleNeu: string = this.translate.instant(/*"Neuer ChecklistePositionenFeldwert"*/"Neuer Auswahl-Listen-Wert", true);
  CRUDItemBezeichnungSingularCapitalized: string = "ChecklistePositionenFeldwert";
  CRUDItemBezeichnungPluralCapitalized: string = "ChecklistePositionenFeldwert";
  CRUDItemBezeichnungSingular: string = "checklistePositionenFeldwert";
  CRUDItemBezeichnungPlural: string = "checklistePositionenFeldwert";
  CRUDItemHelpTopic: string = null;
  CRUDItemRouteSingular: string = "checkliste-positionen-feldwert";
  CRUDItemRoutePlural: string = "checkliste-positionen-feldwerte";

  CRUDItemParentName : string = "checklistePositionenFelder"; // wie heisst die Navigation zum Parent innerhalb des CRUDItem ? // AM Parent-ChildTab-CRUD
  CRUDItemChildName : string = "checklistePositionenFeldwerte"; // wie heisst (das Array) der Childs innerhalb dem Parent ? // AM Parent-ChildTab-CRUD
  
  CRUDItemAvailableCols: any[] = [
          {
            field: 'createdBy',
            header: this.translate.instant('CreatedBy', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 10,
            showByDefault: false
          },
          {
            field: 'created',
            header: this.translate.instant('Created', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 20,
            showByDefault: false
          },
          {
            field: 'modifiedBy',
            header: this.translate.instant('ModifiedBy', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 30,
            showByDefault: false
          },
          {
            field: 'modified',
            header: this.translate.instant('Modified', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 40,
            showByDefault: false
          },
          {
            field: 'reihenfolge',
            header: this.translate.instant('Reihenfolge', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 50,
            showByDefault: true
          },
          {
            field: 'bezeichnung',
            header: this.translate.instant('Bezeichnung', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 55,
            showByDefault: false // siehe ngoninit()
          },
          {
            field: 'feldwert',
            header: this.translate.instant('Feldwert', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 80,
            showByDefault: true
          },
          /*{
            field: 'checklistePositionenFeldwerterId',
            header: this.translate.instant('Checkliste Positionen Felder Id', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 90,
            showByDefault: true
          },*/
          {
            field: 'mangel',
            header: this.translate.instant('Mangel', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 150,
            showByDefault: true
          },
    ];

    constructor(
      @Inject(forwardRef(() => AppComponent)) public _app: AppComponent,
      private _crudItemService: ChecklistePositionenFeldwertService,
      private _injector: Injector
    ) {
      super(_app, _injector);
      this.crudItemService = _crudItemService;
    }

    ngOnInit() { // HTML Template Plugins
      // bei dropdown die Bezeichnung noch einblenden (bei combobox nicht!)
      this.refreshColumnsVisibility();

      super.ngOnInit();
      //this.app.pageTitle = this['pageTitle'];
    }

    //https://ngdev.space/angular-2-input-property-changes-detection-3ccbf7e366d2
    ngOnChanges(changes: SimpleChanges) { // monitoring Input-Changes - nur monitoring/debug - ohne weitere Funktion!!! (kann man weglassen!)
      console.log("ChecklistePositionenFeldListComponent.ngOnChanges():", changes);
    
      if(changes.typ != null) {
        if(this.debugMode==true) console.log("ChecklistePositionenFeldListComponent.ngOnChanges().it's 'typ'");      
        if(this.debugMode==true) console.log("ChecklistePositionenFeldListComponent.ngOnChanges().typ.currentvalue/typ=", changes.typ.currentValue, this.typ);
        this.refreshColumnsVisibility();
      }
      else {
        if(this.debugMode==true) console.log("ChecklistePositionenFeldListComponent.ngOnChanges().something else changed.");      
      }
    }

    refreshColumnsVisibility() {
      let colBezeichnung = this.CRUDItemAvailableCols.filter(f => f.field == 'bezeichnung');
      if(/*this.parentCrudItem.*/this.typ == 'dropdown') colBezeichnung[0].showByDefault = true;
      else colBezeichnung[0].showByDefault = false;

      // Folgende Logik funktioniert aber nur solange keine UserTableColumnState / bzw ignoriert UserTableColumnState
      this.cols = this.CRUDItemAvailableCols.filter(f => f.showByDefault == true);
      this.columnOptions = this.CRUDItemAvailableCols.filter(f => f.showByDefault == false);
    }
}
