import { Message, ConfirmationService/*, AutoComplete*/ } from 'primeng/api';
import { CRUDBasicListForChildComponent } from '../crud-basic-list-for-child/crud-basic-list-for-child.component';
import { MessageWrapperService } from '../_services/message-wrapper.service';
import { TranslateService } from '../_services/translate.service';

export class CopyPaste {

  constructor(
    private crudBasicListComponent: CRUDBasicListForChildComponent,
    private messageWrapperService: MessageWrapperService,
    private translate: TranslateService,
    private confirmationService: ConfirmationService,
) { }

  copyItem(item:/*IChecklistePosition*/any, type: string /*zB 'checkliste-position'*/) {
    const getCircularReplacer = () => {
      const seen = new WeakSet();
      return (key, value) => {
        if (typeof value === "object" && value !== null) {
          if (seen.has(value)) {
            // AM Modi: wenn aufgrund Rekursion nicht weiter ausgegeben wird, dann wenigstens ein Hinweis, auf
            //          was für ein Objekttyp da kommt
            let objType: string = "???";
            try {
              objType = value.constructor.name;
            }
            catch (e) { }
            return '(recursive pointer) ' + objType;
          }
          seen.add(value);
        }
        return value;
      };
    };

    let itemString = JSON.stringify(item, getCircularReplacer(), 2); // ,2 = lesbare Formatierung
    let lastCopiedElementObj = JSON.parse(itemString);
    lastCopiedElementObj = this.removeIdsEtcInCopiedItem(lastCopiedElementObj); // alle ids, modified etc. raus!
    //itemString = JSON.stringify(lastCopiedElementObj, getCircularReplacer(), 2); // ,2 = lesbare Formatierung

    //console.log("ChecklistePositionListComponent.copyItem() itemString:", itemString);

    //this.app.lastCopiedElement = {type: 'checkliste-position',
    //  value: itemString
    //}
    let stringForclipBoard = JSON.stringify({
      type: type,
      value: lastCopiedElementObj
    }, getCircularReplacer(), 2); // ,2 = lesbare Formatierung
    navigator.clipboard.writeText(stringForclipBoard).then(() => {
      /* clipboard successfully set */
      console.log("Checkliste*ListComponent.copyItem() stringForclipBoard:", stringForclipBoard);
    }, () => {
      /* clipboard write failed */
      let message: Message = { severity: 'error', summary: this.translate.instant('Kopieren', true), detail: `Der Datensatz konnte nicht in die Zwischenablage kopiert werden` };
      this.messageWrapperService.postTimedMessage(message); 
    });
  }

  removeIdsEtcInCopiedItem(item: any) {
    for(var propertyName in item) {
      if(propertyName == 'id') item[propertyName] = 0;
      else if(propertyName == 'createdBy') item[propertyName] = '';
      else if(propertyName == 'created') item[propertyName] = '0001-01-01T00:00:00.000Z';
      else if(propertyName == 'modifiedBy') item[propertyName] = '';
      else if(propertyName == 'modified') item[propertyName] = '0001-01-01T00:00:00.000Z';
      else if(propertyName == 'rowVersion') item[propertyName] = '';
      else if(typeof item[propertyName] === 'object') {
        this.removeIdsEtcInCopiedItem(item[propertyName]);
      }
    }
    return item;
  }

  pasteItem( type: string /*zB 'checkliste-position'*/) {
    try {
      navigator.clipboard.readText().then((stringFromclipBoard) =>
      {
        try {
          let lastCopiedElement = JSON.parse(stringFromclipBoard);
          if(lastCopiedElement != null && lastCopiedElement.type == type) {
            //let lastCopiedElementObj = JSON.parse(lastCopiedElement.value);
            let lastCopiedElementObj = lastCopiedElement.value;
            this.confirmationService.confirm({
              message: this.translate.instant('CONFIRM_PASTE_RECORD'),
              header: this.translate.instant('Einfügen', true) + '?',
              icon: 'fa fa-paste',
              key: 'CRUDBasicListConfirmDialog_'+this.crudBasicListComponent.CRUDItemBezeichnungPluralCapitalized, 
              accept: () => {
                // Logik ähnlich wie aus CRUDBasicListForChild.addCrudItem():
                let newCRUDItem = lastCopiedElementObj;
            
                newCRUDItem[this.crudBasicListComponent.CRUDItemParentName+"Id"] = this.crudBasicListComponent.parentCrudItem.id;
                this.crudBasicListComponent.selectedCrudItem = newCRUDItem;
                if(this.crudBasicListComponent.debugMode==true) console.log("Checkliste*List.addCRUDItem() selectedCrudItem:", this.crudBasicListComponent.selectedCrudItem);
            
                newCRUDItem['_alreadyAddedToArray'] = true;
                this.crudBasicListComponent.crudItems.push(this.crudBasicListComponent.selectedCrudItem);
                this.crudBasicListComponent.handleChange();
              },
              reject: () => {
              }
            });
          }
          else {
            throw 'clipboard does not contain a object that fits'
          }
        }
        catch (e) {
          let message: Message = { severity: 'error', summary: this.translate.instant('Einfügen', true), detail: `In der Zwischenablage befindet sich kein Datensatz, der hier eingefügt werden kann` };
          this.messageWrapperService.postTimedMessage(message); 
        }
      });
    }
    catch (e) {
      let message: Message = { severity: 'error', summary: this.translate.instant('Einfügen', true), detail: `In der Zwischenablage befindet sich kein Datensatz, der hier eingefügt werden kann` };
      this.messageWrapperService.postTimedMessage(message); 
    }
  }
}
