import { Injectable } from '@angular/core';
//import { Observable } from 'rxjs/Observable';
import { Observable, of } from 'rxjs';
//import 'rxjs/add/operator/map';
//import 'rxjs/add/operator/do';
//import 'rxjs/add/operator/catch';
import { IPagination } from '../_interfaces/pagination'
import { ICheckliste } from '../_interfaces/checkliste';
import { IChecklisteFreitexte } from '../_interfaces/checkliste-freitexte';
import { IChecklistePosition } from '../_interfaces/checkliste-position';
import { IChecklistePositionenFeld } from '../_interfaces/checkliste-positionen-feld';

//import { IChoiceList } from '../_interfaces/_choice-list';
import * as moment from 'moment'; // DateTimeOffset-Fix
import * as cloneDeep from 'lodash/cloneDeep'; // DateTimeOffset-Fix

import { AppconfigService } from '../_services/appconfig.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';

import {ChecklisteFreitexteService} from './checkliste-freitexte.service';
import {ChecklistePositionService} from './checkliste-position.service';
import {ChecklistePositionenFeldService} from './checkliste-positionen-feld.service';

import { CRUDBasicService, httpOptions } from '../_services/crud-basic.service';

@Injectable()
export class ChecklisteService extends CRUDBasicService {

  constructor(
    /*private*/protected httpClient: HttpClient,
    //protected translate: TranslateService,
    /*private*/protected config: AppconfigService,
    private checklisteFreitexteService: ChecklisteFreitexteService,
    private checklistePositionService: ChecklistePositionService,
    private checklistePositionenFeldService: ChecklistePositionenFeldService,
    ) 
  {
    super(httpClient, config);
    console.log("ChecklisteService THIS: ", this)

    this.apiBaseUrl = this.config.get('apiBaseUrl');
  }

  //typeOf deprecated -> CRUDBasicService.propertyTypeOf
  /*typeOf(propertyName: string) {  // welchen Typs sind die Properties IN DER API ?
    let property = this.propertyTypes.find(f => f.name == propertyName);
    if(property != null) return property.type;
    else return null;    
  }*/
  propertyTypes = [ // welchen Typs sind die Properties IN DER API ?
    {name: 'id', type: 'int'},
    {name: 'createdBy', type: 'string'},
    {name: 'created', type: 'DateTimeOffset'},
    {name: 'modifiedBy', type: 'string'},
    {name: 'modified', type: 'DateTimeOffset'},
    {name: 'rowVersion', type: 'byte[]'},
    {name: 'bezeichnung', type: 'string'},
    {name: 'hinweistext', type: 'string'},
    {name: 'imAuftrag', type: 'string'},
    {name: 'summary', type: 'string'}
  ];
    
  getChecklistenCollection(pageNumber: number, pageSize: number, searchQuery: string): Observable<{ checklisten: ICheckliste[], pagination: IPagination }> {
    return this.httpClient.get(this.apiBaseUrl + 'checkliste?pageNumber=' + pageNumber + '&pageSize=' + pageSize + '&searchQuery=' + searchQuery, { headers: httpOptions, observe: 'response', withCredentials: true })
      .pipe(
        map((response) => {
          return { checklisten: <ICheckliste[]>response.body, pagination: /*<IPagination>JSON.parse(response.headers.get('x-pagination'))*/this.getDummyPagination((<any[]>response.body).length) };
        }), catchError(this.handleError))
  }

  getChecklistenCollectionCroppedBare(pageNumber: number, pageSize: number, searchQuery: string): Observable<{ checklisten: ICheckliste[], pagination: IPagination }> {
    return this.httpClient.get(this.apiBaseUrl + 'checkliste?pageNumber=' + pageNumber + '&pageSize=' + pageSize + '&searchQuery=' + searchQuery + '&cropDokument=bare&cropLeitfaden=bare', { headers: httpOptions, observe: 'response', withCredentials: true })
      .pipe(
        map((response) => {
          return { checklisten: <ICheckliste[]>response.body, pagination: /*<IPagination>JSON.parse(response.headers.get('x-pagination'))*/this.getDummyPagination((<any[]>response.body).length) };
        }), catchError(this.handleError))
  }

  getCheckliste(id: number, cropDokument?: boolean, cropLeitfaden?: boolean): Observable<ICheckliste> {

    if (id === 0) {
      // return Observable.create((observer: any) => {
      //   observer.next(this.initializeCheckliste());
      //   observer.complete();
      // })
      return of(this.initializeCheckliste());
    }

    return this.httpClient.get<ICheckliste>(this.apiBaseUrl + 'checkliste?id=' + id + (cropDokument == true ? '&cropDokument=T' : '') + (cropLeitfaden == true ? '&cropLeitfaden=T' : ''), { headers: httpOptions, observe: 'body', withCredentials: true })
      .pipe(map((response) => 
            //response
            {
              console.log("ChecklisteService.getCheckliste() response: ", response)
              return response[0];
            }
      ), catchError(this.handleError));

    //let retVal =   this.___temp_getdummyChecklist();
    //return of(retVal);
  }

  /*___temp_getdummyChecklist___not_in_use() {
    let retVal = this.initializeCheckliste();
    retVal.bezeichnung = "Prüfung";
    retVal.hinweistext = "Prüfung";
    let freitext = this.checklisteFreitexteService.initializeChecklisteFreitexte();
    retVal.checklisteFreitexte.push(freitext);    
    
    let pos1 = this.checklistePositionService.initializeChecklistePosition();
    pos1.bezeichnung = "Beurteilung Allgemeinzustand der Anlage (Behälter und Abgabeeinrichtung)";
    pos1.hinweistext = "Beurteilung Allgemeinzustand der Anlage (Behälter und Abgabeeinrichtung)";
    pos1.mussfeld = false;
    pos1.bewertung = true;
    let posFeld11 = this.checklistePositionenFeldService.initializeChecklistePositionenFeld();
    posFeld11.id = 1;
    posFeld11.bezeichnung = "Schlauch-Nr. bzw. Schlauch-Ø";
    posFeld11.mussfeld = false;
    pos1.checklistePositionenFelder.push(posFeld11);   
    let posFeld12 = this.checklistePositionenFeldService.initializeChecklistePositionenFeld();
    posFeld11.id = 2;
    posFeld12.bezeichnung = "Schlauchlänge (m)";
    pos1.checklistePositionenFelder.push(posFeld12);   
    retVal.checklistePositionen.push(pos1);   

    let pos2 = this.checklistePositionService.initializeChecklistePosition();
    pos2.bezeichnung = "Pos 2";
    pos2.hinweistext = "Pos 2";
    pos2.mussfeld = false;
    pos2.bewertung = true;
    let posFeld21 = this.checklistePositionenFeldService.initializeChecklistePositionenFeld();
    posFeld21.id = 1;
    posFeld21.bezeichnung = "Pos 2.1";
    posFeld21.mussfeld = false;
    pos2.checklistePositionenFelder.push(posFeld21);   
    retVal.checklistePositionen.push(pos2);   

    let pos3 = this.checklistePositionService.initializeChecklistePosition();
    pos3.bezeichnung = "Pos 3";
    pos3.hinweistext = "Pos 3";
    pos3.mussfeld = false;
    //pos3.bewertung = true;
    let posFeld31 = this.checklistePositionenFeldService.initializeChecklistePositionenFeld();
    posFeld31.id = 1;
    posFeld31.bezeichnung = "Pos 3.1 boolean";
    posFeld31.mussfeld = true;
    posFeld31.felddefinition = 'bool'; // Checkbox, Textfeld, Zahl, Datum (ohne Zeit), Uhrzeit, Dezimal (aktuell fix mit 2 Nachkommastellen)
    pos3.checklistePositionenFelder.push(posFeld31);   
    let posFeld32 = this.checklistePositionenFeldService.initializeChecklistePositionenFeld();
    posFeld32.id = 2;
    posFeld32.bezeichnung = "Pos 3.2 alpha";
    posFeld32.mussfeld = true;
    posFeld32.felddefinition = 'string'; // Checkbox, Textfeld, Zahl, Datum (ohne Zeit), Uhrzeit, Dezimal (aktuell fix mit 2 Nachkommastellen)
    pos3.checklistePositionenFelder.push(posFeld32);   
    let posFeld33 = this.checklistePositionenFeldService.initializeChecklistePositionenFeld();
    posFeld33.id = 3;
    posFeld33.bezeichnung = "Pos 3.3 numeric (without decimal)";
    posFeld33.mussfeld = true;
    posFeld33.felddefinition = 'int'; // Checkbox, Textfeld, Zahl, Datum (ohne Zeit), Uhrzeit, Dezimal (aktuell fix mit 2 Nachkommastellen)
    pos3.checklistePositionenFelder.push(posFeld33);   
    let posFeld34 = this.checklistePositionenFeldService.initializeChecklistePositionenFeld();
    posFeld34.id = 4;
    posFeld34.bezeichnung = "Pos 3.4 date without time";
    posFeld34.mussfeld = true;
    posFeld34.felddefinition = 'date'; // Checkbox, Textfeld, Zahl, Datum (ohne Zeit), Uhrzeit, Dezimal (aktuell fix mit 2 Nachkommastellen)
    pos3.checklistePositionenFelder.push(posFeld34);   
    let posFeld35 = this.checklistePositionenFeldService.initializeChecklistePositionenFeld();
    posFeld35.id = 5;
    posFeld35.bezeichnung = "Pos 3.5 time";
    posFeld35.mussfeld = true;
    posFeld35.felddefinition = 'time'; // Checkbox, Textfeld, Zahl, Datum (ohne Zeit), Uhrzeit, Dezimal (aktuell fix mit 2 Nachkommastellen)
    pos3.checklistePositionenFelder.push(posFeld35);   
    let posFeld36 = this.checklistePositionenFeldService.initializeChecklistePositionenFeld();
    posFeld36.id = 6;
    posFeld36.bezeichnung = "Pos 3.6 decimal .2";
    posFeld36.mussfeld = true;
    posFeld36.felddefinition = 'decimal'; // Checkbox, Textfeld, Zahl, Datum (ohne Zeit), Uhrzeit, Dezimal (aktuell fix mit 2 Nachkommastellen)
    pos3.checklistePositionenFelder.push(posFeld36);      retVal.checklistePositionen.push(pos3);   
    return retVal;
  }*/

  saveCheckliste(checkliste: ICheckliste): Observable<ICheckliste> {
    // DateTimeOffset-Fix
    let itemToSave = cloneDeep(checkliste); // clonen, um sicherzustellen, dass das Original-Objekt nicht ver�ndert wird, evtl. passiert nach dem Save noch etwas damit ?
    //console.log("ChecklisteService.saveSingularCapitalized#() itemToSave before DateTimeOffset-Fix:", itemToSave);
    this.propertyTypes.filter(f => f.type.toLowerCase() == 'datetimeoffset').forEach(propertyType => {
      if (itemToSave[propertyType.name] != null) {
        itemToSave[propertyType.name] = moment(itemToSave[propertyType.name]).format('YYYY-MM-DDTHH:mm:ss.SSSZ'); // alle datetimeoffset-felder entspr. formatieren, dass in der API auch der Offset ankommt!
      }
    });
    //console.log("ChecklisteService.saveSingularCapitalized#() itemToSave after DateTimeOffset-Fix:", itemToSave);

    if (checkliste.id === 0) {
      return this.createCheckliste(/*checkliste*/itemToSave);
    }

    return this.updateCheckliste(/*checkliste*/itemToSave)

  }

  updateCheckliste(checkliste: ICheckliste): Observable<ICheckliste> {

    return this.httpClient.put<ICheckliste>(this.apiBaseUrl + 'checkliste' /*+ checkliste.id*/, checkliste, { headers: httpOptions, observe: 'body', withCredentials: true })
    .pipe(catchError(this.handleError))

  }

  deleteCheckliste(id: number) {

    return this.httpClient.delete(this.apiBaseUrl + 'checkliste?id=' + id, { headers: httpOptions, observe: 'body', withCredentials: true })
      .pipe(
        catchError(this.handleError)
      )
  }

  createCheckliste(checkliste: ICheckliste): Observable<ICheckliste> {
    return this.httpClient.post<ICheckliste>(this.apiBaseUrl + 'checkliste', checkliste, { headers: httpOptions, observe: 'body', withCredentials: true })
      .pipe(map((response) => response), catchError(this.handleError))
  }

  initializeCheckliste(): ICheckliste {
    return {
      id: 0,
      createdBy: '',
      created: /*''*/'0001-01-01T00:00:00.000Z', // fix
      modifiedBy: '',
      modified: /*''*/'0001-01-01T00:00:00.000Z', // fix
      rowVersion: '',
      bezeichnung: '',
      hinweistext: '',
      imAuftrag: '',
      imAuftragTelefon: '',
      summary: '',

      dokumentId: null,
      dokument: null,
      leitfadenId: null,
      leitfaden: null,

      checklisteFreitexte: [],
      checklistePositionen: [],
      
    };
  }


}
