import { Injectable } from '@angular/core';
//import { Observable } from 'rxjs/Observable';
import { Observable, of } from 'rxjs';
//import 'rxjs/add/operator/map';
//import 'rxjs/add/operator/do';
//import 'rxjs/add/operator/catch';
import { IPagination } from '../_interfaces/pagination'
import { IPositionsdatenFotos } from '../_interfaces/positionsdaten-fotos';
//import { IChoiceList } from '../_interfaces/_choice-list';
import * as moment from 'moment'; // DateTimeOffset-Fix
import * as cloneDeep from 'lodash/cloneDeep'; // DateTimeOffset-Fix

import { AppconfigService } from '../_services/appconfig.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';

import { HttpEvent, HttpEventType } from '@angular/common/http';

import { CRUDBasicService, httpOptions } from '../_services/crud-basic.service';

@Injectable()
export class PositionsdatenFotosService extends CRUDBasicService {

  //typeOf deprecated -> CRUDBasicService.propertyTypeOf
  /*typeOf(propertyName: string) {  // welchen Typs sind die Properties IN DER API ?
    let property = this.propertyTypes.find(f => f.name == propertyName);
    if(property != null) return property.type;
    else return null;    
  }*/
  propertyTypes = [ // welchen Typs sind die Properties IN DER API ?
    {name: 'id', type: 'int'},
    {name: 'createdBy', type: 'string'},
    {name: 'created', type: 'DateTimeOffset'},
    {name: 'modifiedBy', type: 'string'},
    {name: 'modified', type: 'DateTimeOffset'},
    {name: 'rowVersion', type: 'byte[]'},
    {name: 'behoben', type: 'bool'},
    {name: 'bemerkung', type: 'string'},
    {name: 'foto', type: 'byte[]'}
  ];

  getThumbDownloadUrl(guid : string, dateiendung : string) {
    //console.log("PositionsdatenFotosService.getDownloadUrl() guid:", guid);
    return this.apiBaseUrl + 'positionsdatenfotos/downloadthumbdirect/' + guid +"/"+dateiendung;
  }
    
  getPositionsdatenFotosCollection(pageNumber: number, pageSize: number, searchQuery: string): Observable<{ positionsdatenFotos: IPositionsdatenFotos[], pagination: IPagination }> {
    return this.httpClient.get(this.apiBaseUrl + 'positionsdatenfotos?pageNumber=' + pageNumber + '&pageSize=' + pageSize + '&searchQuery=' + searchQuery, { headers: httpOptions, observe: 'response', withCredentials: true })
      .pipe(
        map((response) => {
          return { positionsdatenFotos: <IPositionsdatenFotos[]>response.body, pagination: <IPagination>JSON.parse(response.headers.get('x-pagination')) };
        }), catchError(this.handleError))
  }

  getPositionsdatenFotos(id: number): Observable<IPositionsdatenFotos> {

    if (id === 0) {
      // return Observable.create((observer: any) => {
      //   observer.next(this.initializePositionsdatenFotos());
      //   observer.complete();
      // })
      return of(this.initializePositionsdatenFotos());
    }

    return this.httpClient.get<IPositionsdatenFotos>(this.apiBaseUrl + 'positionsdatenfotos/' + id, { headers: httpOptions, observe: 'body', withCredentials: true })
      .pipe(map((response) => response), catchError(this.handleError));
  }

  savePositionsdatenFotos(positionsdatenFotos: IPositionsdatenFotos): Observable<IPositionsdatenFotos> {
    // DateTimeOffset-Fix
    let itemToSave = cloneDeep(positionsdatenFotos); // clonen, um sicherzustellen, dass das Original-Objekt nicht ver�ndert wird, evtl. passiert nach dem Save noch etwas damit ?
    //console.log("PositionsdatenFotosService.saveSingularCapitalized#() itemToSave before DateTimeOffset-Fix:", itemToSave);
    this.propertyTypes.filter(f => f.type.toLowerCase() == 'datetimeoffset').forEach(propertyType => {
      if (itemToSave[propertyType.name] != null) {
        itemToSave[propertyType.name] = moment(itemToSave[propertyType.name]).format('YYYY-MM-DDTHH:mm:ss.SSSZ'); // alle datetimeoffset-felder entspr. formatieren, dass in der API auch der Offset ankommt!
      }
    });
    //console.log("PositionsdatenFotosService.saveSingularCapitalized#() itemToSave after DateTimeOffset-Fix:", itemToSave);

    if (positionsdatenFotos.id === 0) {
      return this.createPositionsdatenFotos(/*positionsdatenFotos*/itemToSave);
    }

    return this.updatePositionsdatenFotos(/*positionsdatenFotos*/itemToSave)

  }

  updatePositionsdatenFotos(positionsdatenFotos: IPositionsdatenFotos): Observable<IPositionsdatenFotos> {

    return this.httpClient.put<IPositionsdatenFotos>(this.apiBaseUrl + 'positionsdatenfotos/' + positionsdatenFotos.id, positionsdatenFotos, { headers: httpOptions, observe: 'body', withCredentials: true })
    .pipe(catchError(this.handleError))

  }

  deletePositionsdatenFotos(id: number) {

    return this.httpClient.delete(this.apiBaseUrl + 'positionsdatenfotos/' + id, { headers: httpOptions, observe: 'body', withCredentials: true })
      .pipe(
        catchError(this.handleError)
      )
  }

  createPositionsdatenFotos(positionsdatenFotos: IPositionsdatenFotos): Observable<IPositionsdatenFotos> {
    return this.httpClient.post<IPositionsdatenFotos>(this.apiBaseUrl + 'positionsdatenfotos', positionsdatenFotos, { headers: httpOptions, observe: 'body', withCredentials: true })
      .pipe(map((response) => response), catchError(this.handleError))
  }

  uploadPositionsdatenFotoToTemp(fileToUpload: File, positionsdatenFotosForCreate: IPositionsdatenFotos, callbackInstance:any, callbackProgress:any, callbackSuccess:any, callbackError:any) {
      //https://code-maze.com/upload-files-dot-net-core-angular/
      //let fileToUpload : File = event.files[0];
      const formData = new FormData();
      formData.append('file', fileToUpload, fileToUpload.name);
      
      // nicht nur das file uploaden, sondern auch die Entity "positionsdatenFotos"
      //formData.append('positionsdatenFoto.dateiname', positionsdatenFotosForCreate.dateiname);
      formData.append('positionsdatenFoto.dateiendung', positionsdatenFotosForCreate.dateiendung);
      //formData.append('positionsdatenFoto.bezeichnung', positionsdatenFotosForCreate.bezeichnung);
      //formData.append('positionsdatenFoto.sortierung', ""+positionsdatenFotosForCreate.sortierung);
      formData.append('positionsdatenFoto.bemerkung', ""+positionsdatenFotosForCreate.bemerkung);
      //formData.append('positionsdatenFoto.behoben', ""+positionsdatenFotosForCreate.behoben);
      formData.append('positionsdatenFoto.bereich', ""+positionsdatenFotosForCreate.bereich);

      //try {
        this.httpClient.post(this.apiBaseUrl + 'positionsdatenfotos/uploadtotemp', formData, {reportProgress: true, observe: 'events', withCredentials: true})
        .subscribe(event => {
          console.log("PositionsdatenFotoService.uploadPositionsdatenFoto() event:", event);
          if (event.type === HttpEventType.UploadProgress) {
            let progress = Math.round(100 * event.loaded / event.total);
            console.log("PositionsdatenFotoService.uploadPositionsdatenFoto() progress:", progress);
            callbackProgress(callbackInstance, progress);
          }
          else if (event.type === HttpEventType.Response) {
            console.log("PositionsdatenFotoService.uploadPositionsdatenFoto() success!");
            callbackSuccess(callbackInstance, event.body);
          }
          else if (event.type === HttpEventType.ResponseHeader) {
            console.log("PositionsdatenFotoService.uploadPositionsdatenFoto() ResponseHeader!");
            if(event.status >= 400) {
              //throw(event);
              console.log("PositionsdatenFotoService.uploadPositionsdatenFoto() it's error!");
              callbackError(callbackInstance, event);
            }
          }
          //else {
          //  console.log("PositionsdatenFotoService.uploadPositionsdatenFoto() received unexpected event:", event);
          //}
        })
        ;
      //}
      //catch(e) {
      //  console.error("PositionsdatenFotoService.uploadPositionsdatenFoto() error:", e);
      //}
  }

  initializePositionsdatenFotos(): IPositionsdatenFotos {
    return {
      id: 0,
      createdBy: '',
      created: /*''*/'0001-01-01T00:00:00.000Z', // fix
      modifiedBy: '',
      modified: /*''*/'0001-01-01T00:00:00.000Z', // fix
      rowVersion: '',
      bemerkung: '',
      //foto: ''

      guid: null,
      dateiendung: '',

      //behoben: null,
      bereich: null,

      positionsdaten: null,
      positionsdatenId: null,
    };
  }


}
