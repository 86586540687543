<p-splitter *ngIf="this.app.inAppMode != true" #splitter id="mapComponentSplitter" [style]="{'height': '100%'}" [panelSizes]="panelSizes" [layout]="splitterLayout" [gutterSize]="splitterGutterSize" (onResizeEnd)="splitterMoved">
    <ng-template pTemplate>
        <div style="overflow-y: auto;" [style.maxHeight]="panelMaxHeight" [style.width]="panelWidth" id="auftragslistDiv">
            <app-auftragsdaten-list #auftragsdatenList  
            [style.display]="fakeCRUDItem != null && fakeCRUDItem.auftraegeFiltered != null && viewType != 'mapOnly' ? unset : none"
            [mode]="mode"
            [parentCrudItem]="fakeCRUDItem" [parentComponent]="this"
            [crudItems]="fakeCRUDItem.auftraegefiltered"
            (crudItemsValueChange)="onCrudItemsValueChangeAuftraege($event)" [style.width]="'100%'"></app-auftragsdaten-list>
            <!--*ngIf="viewType != 'mapOnly'"-->
        </div>
        <div class="btnFloating">
            <button pButton type="button" label="debug" icon="fa-solid fa-debug" iconPos="left" style="float:left" (click)="debug()"></button>
        </div>
    </ng-template>
    <ng-template pTemplate>
        <div class="map-container" id="mapComponentMapContainer">
            <div class="map-frame">
                <div id="map"></div>
            </div>
        </div>
    </ng-template>
</p-splitter>
<div *ngIf="this.app.inAppMode == true" style="display: grid; gap: 0px;" style="{{inAppModeStyleGrid}}">
    <div class="map-container" id="mapComponentMapContainer" style="{{inAppModeStyleMapContainer}}"
    [style.display]="mode == 'map' && (fakeCRUDItem != null && fakeCRUDItem.auftraegeFiltered != null) ? 'unset' : 'none'"><!--mit topbar wären 50px-->
        <div class="map-frame" style="{{inAppModeStyleMapFrame}}"><!--mit topbar wären 50px-->
            <div id="map" style="{{inAppModeStyleMapMap}}"></div><!--mit topbar wären 50px, TODO: warum -2 ??? frame ???-->
        </div>
    </div>

    <div style="{{inAppModeStyleListContainer}}">
        <div id="auftragslistDiv" [style.display]="auftraegeListAppModeShow == true ? 'unset' : 'none'">
                <app-auftragsdaten-list #auftragsdatenList  
                [style.display]="fakeCRUDItem != null && fakeCRUDItem.auftraegeFiltered != null ? 'unset' : 'none'"
                [mode]="mode"
                [parentCrudItem]="fakeCRUDItem" [parentComponent]="this"
                [crudItems]="fakeCRUDItem.auftraegefiltered"
                (crudItemsValueChange)="onCrudItemsValueChangeAuftraege($event)" [style.width]="'100%'"></app-auftragsdaten-list>
        </div>
    </div>

    <div class="btnFloating">
        <button pButton *ngIf="auftraegeListAppModeShow == true" style="padding: 0.25em; padding-right: 1em;    margin-top: 0.25em;"
        type="button" label="Liste" iconPos="left" (click)="showList_changed('M')">
            <span class="fa-stack">
              <i class="fa-solid fa-list fa-stack-1x"></i>
              <i class=" fa-solid fa-slash fa-stack-1x"></i>
            </span>
        </button>
        <button pButton *ngIf="auftraegeListAppModeShow == false" type="button" label="Liste" icon="fa-solid fa-list" iconPos="left" (click)="showList_changed('L')">
        </button>
    </div>
</div>

<!--<button pButton type="button" label="debug" icon="fa-solid fa-debug" iconPos="left" style="float:left" (click)="debug()"></button>-->

<div class="btnFloating" *ngIf="this.app.inAppMode != true">
  <button pButton *ngIf="mode=='map' && alreadyAusgeblendet == false" style="padding: 0.25em; padding-right: 1em;    margin-top: 0.25em;"
  type="button" label="Liste ausblenden" iconPos="left" (click)="showList_changed('L')">
      <span class="fa-stack">
        <i class="fa-solid fa-list fa-stack-1x"></i>
        <i class=" fa-solid fa-slash fa-stack-1x"></i>
      </span>
  </button>
  <button pButton *ngIf="mode=='map' && alreadyAusgeblendet == true" type="button" label="Liste einblenden" icon="fa-solid fa-list" iconPos="left" (click)="showList_changed('L')">
  </button>

  <!-- Spezialfall: man kann auch im List-Mode die Liste ausblenden! -->
  <button pButton *ngIf="mode=='list' && alreadyAusgeblendet == false" style="padding: 0.25em; padding-right: 1em;    margin-top: 0.25em;"
  type="button" label="Liste ausblenden&nbsp;" iconPos="left" (click)="showList_changed('L')">
      <span class="fa-stack">
        <i class="fa-solid fa-list fa-stack-1x"></i>
        <i class=" fa-solid fa-slash fa-stack-1x"></i>
      </span>
  </button>
  <button pButton *ngIf="mode=='list' && alreadyAusgeblendet == true && alreadyAusgeblendetLastML=='L'" type="button" label="Liste einblenden" icon="fa-solid fa-list" iconPos="left" (click)="showList_changed('L')">
  </button>
  <span *ngIf="mode=='list'"><br></span>

  <button pButton *ngIf="mode=='list' && alreadyAusgeblendet == false" style="padding: 0.25em; padding-right: 1em;    margin-top: 0.25em;"
  type="button" label="Karte ausblenden" iconPos="left" (click)="showList_changed(undefined, true)">
      <span class="fa-stack">
        <i class="fa-solid fa-map fa-stack-1x"></i>
        <i class=" fa-solid fa-slash fa-stack-1x"></i>
      </span>
  </button>
  <button pButton *ngIf="mode=='list' && alreadyAusgeblendet == true && alreadyAusgeblendetLastML!='L'" type="button" label="Karte einblenden" icon="fa-solid fa-map" iconPos="left" (click)="showList_changed(undefined, true)">
  </button>
</div>

<app-topbar-replacement-for-app [style.display]="app.inAppMode == true ? 'unset' : 'none'"></app-topbar-replacement-for-app>

<p-dialog [(visible)]="dialogAuftragShow" [modal]="true" [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '50vw'}">
    <ng-template pTemplate="header">
        <div class="details-grid-container">
            <!-- zeile 1 -->
            <div>
                <i class="fa fa-{{ getLeistungForAuftrag(lastclickedAuftrag).icon }} fa-xl"></i>
            </div>
            <div>
                <big>{{ getLeistungForAuftrag(lastclickedAuftrag).bezeichnung }}:</big>
                <small *ngIf="debugMode==true"> {{lastclickedAuftrag.id}}</small>
            </div>
        </div>
    </ng-template>
    <ng-container *ngIf="lastclickedAuftrag != null">
        <div class="details-grid-container">
            <!-- zeile 1: Behälterstandort -->
            <div>
                <i class="fa fa-location"></i>
            </div>
            <div>
                {{lastclickedAuftrag.name_1Behaelterstandort}} {{lastclickedAuftrag.name_2Behaelterstandort}}<br>
                {{lastclickedAuftrag.strasseBehaelterstandort}}<br>
                {{lastclickedAuftrag.plzBehaelterstandort}} {{lastclickedAuftrag.ortBehaelterstandort}} {{lastclickedAuftrag.ortzusatzBehaelterstandort}}
            </div>
            <!-- nächste -->
            <div *ngIf="lastclickedAuftrag.telefonBehaelterstandort != null">
                <i class="fa-solid fa-phone"></i>
            </div>
            <div *ngIf="lastclickedAuftrag.telefonBehaelterstandort != null">
                <a *ngIf="app.inAppMode!=true" href="tel:{{lastclickedAuftrag.telefonBehaelterstandort | phoneCanonical}}">{{lastclickedAuftrag.telefonBehaelterstandort | phoneCanonical}}</a>
                <a *ngIf="app.inAppMode==true" style="cursor: pointer" (click)="phoneNrClicked(lastclickedAuftrag.telefonBehaelterstandort)">{{lastclickedAuftrag.telefonBehaelterstandort | phoneCanonical}}</a>
            </div>
        </div>

        <div class="details-grid-container-with-right">
            <!-- nächste: Kunde -->
            <div>
                <div style="min-height: 1.25em;"></div>
                <i class="fa-solid fa-user"></i>
            </div>
            <div>
                <div style="min-height: 1.25em;"></div>
                {{lastclickedAuftrag.name_1Ansprechpartner != null ? lastclickedAuftrag.name_1Ansprechpartner : lastclickedAuftrag.name_1Kunde}} {{lastclickedAuftrag.name_1Ansprechpartner != null ? lastclickedAuftrag.name_2Ansprechpartner : lastclickedAuftrag.name_2Kunde}}<br><!-- das ist absicht, dass die 2. condition ebenfalls auf name1 abfrägt!!!-->
                <!--{{lastclickedAuftrag.strasseKunde}}<br>
                {{lastclickedAuftrag.plzKunde}}<br> {{lastclickedAuftrag.ortKunde}}-->
            </div>
            <div>
                <button pButton type="button" label="techn. Ansprechpartner" icon="fa-solid fa-edit" iconPos="left" style="float:right" (click)="stammdatenAendernClicked(lastclickedAuftrag)"></button>
            </div>
        </div>

        <div class="details-grid-container" style="margin-top: 10px;">
            <!-- nächste -->
            <div *ngIf="lastclickedAuftrag.telefonAnsprechpartner != null">
                <i class="fa-solid fa-phone"></i>
            </div>
            <div *ngIf="lastclickedAuftrag.telefonAnsprechpartner != null">
                <a *ngIf="app.inAppMode!=true && lastclickedAuftrag.telefonAnsprechpartner != null" href="tel:{{lastclickedAuftrag.telefonAnsprechpartner | phoneCanonical}}">{{lastclickedAuftrag.telefonAnsprechpartner | phoneCanonical}}</a>
                <a *ngIf="app.inAppMode==true && lastclickedAuftrag.telefonAnsprechpartner != null" style="cursor: pointer" (click)="phoneNrClicked(lastclickedAuftrag.telefonAnsprechpartner)">{{lastclickedAuftrag.telefonAnsprechpartner | phoneCanonical}}</a>
            </div>
            <div *ngIf="lastclickedAuftrag.eMailAnsprechpartner != null">
                <i class="fa-solid fa-envelope"></i>
            </div>
            <div *ngIf="lastclickedAuftrag.eMailAnsprechpartner != null">
                <a *ngIf="app.inAppMode!=true && lastclickedAuftrag.eMailAnsprechpartner != null" href="mailto:{{lastclickedAuftrag.eMailAnsprechpartner}}">{{lastclickedAuftrag.eMailAnsprechpartner}}</a>
                <a *ngIf="app.inAppMode==true && lastclickedAuftrag.eMailAnsprechpartner != null" style="cursor: pointer" (click)="eMailClicked(lastclickedAuftrag.eMailAnsprechpartner)">{{lastclickedAuftrag.eMailAnsprechpartner}}</a>
            </div>

            <!-- nächste: Ansprechpartner Versorger -->
            <div>
                <br>
                <i class="fa-solid fa-building"></i>
            </div>
            <div>
                <br>
                <!--???-->{{versorgerAnsprechpartnerText}}<!--???--><br>
                <!--{{lastclickedAuftrag.name_2Kunde}}<br *ngIf="lastclickedAuftrag.name_2Kunde != null">-->
                <!--{{lastclickedAuftrag.strasseKunde}}<br>
                {{lastclickedAuftrag.plzKunde}}<br> {{lastclickedAuftrag.ortKunde}}-->
            </div>
            <!-- nächste -->
            <div *ngIf="lastclickedAuftrag.telefonKunde != null">
                <i class="fa-solid fa-phone"></i>
            </div>
            <div *ngIf="lastclickedAuftrag.telefonKunde != null">
                <a *ngIf="app.inAppMode!=true && lastclickedAuftrag_Checkliste != null" href="tel:{{lastclickedAuftrag_Checkliste.imAuftragTelefon | phoneCanonical}}">{{lastclickedAuftrag_Checkliste.imAuftragTelefon | phoneCanonical}}</a>
                <a *ngIf="app.inAppMode==true && lastclickedAuftrag_Checkliste != null" style="cursor: pointer" (click)="phoneNrClicked(lastclickedAuftrag_Checkliste.imAuftragTelefon)">{{lastclickedAuftrag_Checkliste.imAuftragTelefon | phoneCanonical}}</a>
            </div>

            <!-- nächste: Disponiert -->
            <div *ngIf="lastclickedAuftrag.dispodatum != null || lastclickedAuftrag.benutzerId != null">
                <div style="font-size: 0.25em;">
                    <br>
                </div>
                <i class="fa-regular fa-calendar"></i>
            </div>
            <div *ngIf="lastclickedAuftrag.dispodatum != null || lastclickedAuftrag.benutzerId != null">
                <div style="font-size: 0.25em;">
                    <br>
                </div>
                Disponiert
            </div>
            <div *ngIf="lastclickedAuftrag.dispodatum != null || lastclickedAuftrag.benutzerId != null">
                <i class="fa-solid fa-phone"></i>
            </div>
            <div *ngIf="lastclickedAuftrag.dispodatum != null || lastclickedAuftrag.benutzerId != null">
                {{lastclickedAuftrag.dispodatum | datumOhneZeit}}
                {{lastclickedAuftrag.benutzerId | benutzer}}
            </div>

            <!-- nächste: Abstand vor "Aufkleber"/Bemerkung / Notiz -->
            <div *ngIf="lastclickedAuftrag.auftragsbemerkung != null && lastclickedAuftrag.auftragsbemerkung.length > 0 || lastclickedAuftrag.notizDienstleister != null && lastclickedAuftrag.notizDienstleister.length > 0">
                <br>
            </div>
            <div *ngIf="lastclickedAuftrag.auftragsbemerkung != null && lastclickedAuftrag.auftragsbemerkung.length > 0 || lastclickedAuftrag.notizDienstleister != null && lastclickedAuftrag.notizDienstleister.length > 0">
                <br>
            </div>

            <!-- nächste: "Aufkleber" -->
            <div style="color: red;" *ngIf="getLeistungForAuftrag(lastclickedAuftrag).anstrich == true && aufkleberVertragsartenKeinAufkleber != null && aufkleberVertragsartenKeinAufkleber.length > 0 && lastclickedAuftrag != null">
                <i class="fa-solid fa-circle-info"></i>
            </div>
            <div style="color: red; font-weight: bold;" *ngIf="getLeistungForAuftrag(lastclickedAuftrag).anstrich == true && aufkleberVertragsartenKeinAufkleber != null && aufkleberVertragsartenKeinAufkleber.length > 0 && lastclickedAuftrag != null">
                <!--<small>DEBUG: auftragId:{{lastclickedAuftrag.id}} vertragsartenKEINAufkleber:{{aufkleberVertragsartenKeinAufkleber}} vertragsart:{{lastclickedAuftrag.verwaltungsdaten}} textKEINAufkleber:{{aufkleberTextKeinAufkleber}} textAufkleber:{{aufkleberTextAufkleber}}<br></small>-->
                <span innerHTML="{{aufkleberVertragsartenKeinAufkleber.indexOf('\''+lastclickedAuftrag.verwaltungsdaten+'\'') > -1 ? aufkleberTextKeinAufkleber : aufkleberTextAufkleber}}"></span>
            </div>
            
            <!-- nächste: Bemerkung -->
            <div style="color: red;" *ngIf="lastclickedAuftrag.auftragsbemerkung != null && lastclickedAuftrag.auftragsbemerkung.length > 0">
                <i class="fa-solid fa-circle-info"></i>
            </div>
            <div style="color: red; font-weight: bold;" *ngIf="lastclickedAuftrag.auftragsbemerkung != null && lastclickedAuftrag.auftragsbemerkung.length > 0">
                {{lastclickedAuftrag.auftragsbemerkung}}
            </div>

            <!-- nächste: Notizen -->
            <!--<div class="details-grid-container-with-right" *ngIf="lastclickedAuftrag.notizDienstleister != null && lastclickedAuftrag.notizDienstleister.length > 0">
                <div>
                    <i class="fa-solid fa-circle-info"></i>
                </div>
                <div>
                    {{lastclickedAuftrag.notizDienstleister}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </div>
                <div>
                    <button *ngIf="false" pButton type="button" label="Notiz ändern" icon="fa-solid fa-edit" iconPos="left" style="float:right" (click)="notizAendernClicked(lastclickedAuftrag)"></button>
                </div>
            </div>-->
            <div *ngIf="lastclickedAuftrag.notizDienstleister != null && lastclickedAuftrag.notizDienstleister.length > 0">
                <i class="fa-solid fa-circle-info"></i>
            </div>
            <div *ngIf="lastclickedAuftrag.notizDienstleister != null && lastclickedAuftrag.notizDienstleister.length > 0">
                {{lastclickedAuftrag.notizDienstleister}}
            </div>

            <!-- nächste: Kunden-Nr. / Behälter / Fälligkeit -->
            <div>
                &nbsp;
            </div>
            <div style="width: 100%">
                
                <div class="" [ngClass]="{'details-grid-tablelike-container': !app.inAppMode, 'details-grid-tablelike-container-inAppMode': app.inAppMode}">
                    <!-- zeile 1: Kunden-Nr. + Beh.Nr. -->
                    <div>
                        Kunden-Nr.:
                    </div>
                    <div>
                        {{lastclickedAuftrag.kundennummer}}
                    </div>
                    <div>
                        Behälter-Nr.:
                    </div>
                    <div>
                        {{lastclickedAuftrag.behaelternummer}}
                    </div>
                    <!-- zeile 2: Behälter-BJ. + Inhalt -->
                    <div>
                        Baujahr:
                    </div>
                    <div>
                        {{lastclickedAuftrag.baujahr | datumOhneZeit}}
                    </div>
                    <div>
                        Nettoinhalt:
                    </div>
                    <div>
                        {{lastclickedAuftrag.fuellvolumen}} Liter
                    </div>
                    <!-- zeile 3: Hersteller und Lagerungsart -->
                    <div>
                        Hersteller:
                    </div>
                    <div>
                        {{lastclickedAuftrag.auftragsdatenHersteller.bezeichnung}}
                    </div>
                    <div>
                        {{lastclickedAuftrag.lagerungsart == '01' ? 'Tankfarbe:' : null}}<div *ngIf="lastclickedAuftrag.lagerungsart == '01'" style="font-size: 0.25em;"><br></div>
                        <p-checkbox *ngIf="lastclickedAuftrag.lagerungsart == '01'" label="oberirdisch" [ngModel]="true" readonly="true" [binary]="true"></p-checkbox>
                        <p-checkbox *ngIf="!(lastclickedAuftrag.lagerungsart == '01')" label="oberirdisch" [ngModel]="false" readonly="true" [binary]="true"></p-checkbox>
                    </div>
                    <div>
                        {{lastclickedAuftrag.lagerungsart == '01' ? lastclickedAuftrag.tankfarbe : null}}<div *ngIf="lastclickedAuftrag.lagerungsart == '01'" style="font-size: 0.25em;"><br></div>
                        <p-checkbox *ngIf="lastclickedAuftrag.lagerungsart >= '02'" label="erdgedeckt" [ngModel]="true" readonly="true" [binary]="true"></p-checkbox>
                        <p-checkbox *ngIf="!(lastclickedAuftrag.lagerungsart >= '02')" label="erdgedeckt" [ngModel]="false" readonly="true" [binary]="true"></p-checkbox>
                    </div>
                    <!-- zeile 4: Pumpentyp
                    <div>
                        Pumpentyp:
                    </div>
                    <div>
                    </div>
                    <div>
                    </div>
                    <div>
                    </div>-->
                    <!-- zeile 5: Fälligkeitsdatum -->
                    <div>
                        Fälligkeitsdatum:
                    </div>
                    <div>
                        {{lastclickedAuftrag.endedatum | datumOhneZeit}}
                    </div>
                    <div>
                    </div>
                    <div>
                    </div>
                </div>

            </div>

        </div>

    </ng-container>
    <ng-template pTemplate="footer">
        <br>
        <button pButton type="button" label="Aufgabe starten" icon="fa-solid fa-clipboard-list" iconPos="left" class="p-button-success" style="float:left" (click)="aufgabeStartenClicked(lastclickedAuftrag.id)"></button>

        <button pButton type="button" label="Navigation" icon="fa-solid fa-diamond-turn-right" iconPos="left" (click)="navigationClicked(lastclickedAuftrag.id)"></button>

        <button pButton *ngIf="app.inAppMode != true && benutzer != null && benutzer.istAdmin == true" type="button" label="Disponieren" icon="fa-regular fa-calendar" iconPos="left" (click)="app.dispositionStart(lastclickedAuftrag.id)"></button>

        <!-- button mit stacked icons -->
        <button pButton style="padding: 0.25em; padding-right: 1em;"
        *ngIf="app.inAppMode != true && lastclickedAuftrag_in_Routenplanung != null && lastclickedAuftrag_in_Routenplanung == true" type="button" label="{{benutzer != null && benutzer.istAdmin == true ? 'Markierung aufheben' : 'Route entfernen'}}" iconPos="left" (click)="demarkierenClicked(lastclickedAuftrag.id)">
            <span class="fa-stack">
              <i class="fa-solid fa-route fa-stack-1x"></i>
              <i class=" fa-solid fa-slash fa-stack-1x"></i>
            </span>
        </button>

        <button pButton *ngIf="app.inAppMode != true && lastclickedAuftrag_in_Routenplanung != null && lastclickedAuftrag_in_Routenplanung == false" type="button" label="{{benutzer != null && benutzer.istAdmin == true ? 'Auftrag markieren' : 'Route hinzufügen'}}" icon="fa-solid fa-route" iconPos="left" (click)="markierenClicked(lastclickedAuftrag.id)"></button>

        <!--
            AEP can-food
            IP  bullseye-pointer
            allg Reparatur toolbox
            Anstrich brush
            Rohrleitungsprüfung pipe-circle-check
            Sicherheitsventil pipe-valve
            Verdampfer droplet
            Wartung screwdriver-wrench
        -->
        <!-- Versuch, AEP aus 2 Icons zusammenzusetzen
        <span class="fa-stack fa-2x">
            <i class="fa fa-map-marker fa-stack-2x" style="color: #08f; font-size: 2em;"></i>

            <i class="fa fa-thin fa-can-food fa-stack-1x" style="color: white; font-size: 1em; margin-left: 1.25px; margin-top: -2.25px;"></i>

            <i class="fa fa-can-food fa-stack-1x" style="color: white; font-size: 0.75em; margin-left: 1px; margin-top: -2px;"></i>
        </span>     
        -->
        
    </ng-template>
</p-dialog>

<p-dialog [(visible)]="dialogEditNotizShow" [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '50vw'}">
    <ng-template pTemplate="header">
        <div class="details-grid-container">
            <!-- zeile 1 -->
            <div>
                <i class="fa fa-{{ getLeistungForAuftrag(lastclickedAuftrag).icon }} fa-xl"></i>
            </div>
            <div>
                <big>{{ getLeistungForAuftrag(lastclickedAuftrag).bezeichnung }}:</big>
            </div>
        </div>
    </ng-template>
    <ng-container *ngIf="lastclickedAuftrag != null">
        <div class="details-grid-container">
            <!-- zeile 1: Behälterstandort -->
            <div>
                <i class="fa fa-location"></i>
            </div>
            <div>
                {{lastclickedAuftrag.name_1Behaelterstandort}} {{lastclickedAuftrag.name_2Behaelterstandort}}<br>
                {{lastclickedAuftrag.strasseBehaelterstandort}}<br>
                {{lastclickedAuftrag.plzBehaelterstandort}} {{lastclickedAuftrag.ortBehaelterstandort}} {{lastclickedAuftrag.ortzusatzBehaelterstandort}}
            </div>
            <!-- nächste -->
            <div *ngIf="lastclickedAuftrag.telefonBehaelterstandort != null">
                <i class="fa-solid fa-phone"></i>
            </div>
            <div *ngIf="lastclickedAuftrag.telefonBehaelterstandort != null">
                <a *ngIf="app.inAppMode!=true" href="tel:{{lastclickedAuftrag.telefonBehaelterstandort | phoneCanonical}}">{{lastclickedAuftrag.telefonBehaelterstandort | phoneCanonical}}</a>
                <a *ngIf="app.inAppMode==true" style="cursor: pointer" (click)="phoneNrClicked(lastclickedAuftrag.telefonBehaelterstandort)">{{lastclickedAuftrag.telefonBehaelterstandort | phoneCanonical}}</a>
            </div>
        </div>

        <div class="details-grid-container" style="margin-top: 10px;">
            <!-- nächste -->
            <div>
                <i class="fa-solid fa-circle-info"></i>
            </div>
            <div>
                <span class="p-float-label">
                    <textarea #bemerkungPos style="width:100%; padding-top: 0.5em; padding-bottom: 0.5em;" [rows]="4" pInputTextarea 
                    [(ngModel)]="lastclickedAuftrag.notizDienstleister"
                    ></textarea><!--kein autoResize="autoResize" sonst optisches Problem beim wieder-reinkommen-->
                    <label>Notiz</label>
                </span>
            </div>
        </div>

    </ng-container>
    <ng-template pTemplate="footer">
        <br>
        <button pButton type="button" label="Speichern" iconPos="left" (click)="notizSpeichernClicked()"></button>
    </ng-template>
</p-dialog>