import { /*CRUDBasicDetailComponent_Template, */CRUDBasicDetailComponent } from '../crud-basic-detail/crud-basic-detail.component';

import { Component, OnInit, Inject, forwardRef, Injector, ViewChild, Input } from '@angular/core';
import { Validators, FormControl, FormGroup, FormBuilder, AbstractControl, FormControlName } from '@angular/forms';
import { AppComponent } from '../app.component';
import { TranslateService } from '../_services/translate.service';
import { GenericValidator } from '../_helpers/generic-validator';
//import { AutoComplete } from 'primeng/autocomplete';

import { IBenutzer } from '../_interfaces/benutzer';
import { IDienstleister } from '../_interfaces/dienstleister';
import { DienstleisterService } from '../_services/dienstleister.service';
import { DienstleisterDetailGuard } from './dienstleister-detail.guard';

//import { DienstleisterDetailComponent_Template } from './dienstleister-detail.component.include_template';

import * as cloneDeep from 'lodash/cloneDeep'; // DateTimeOffset-Fix

import { Subscription } from 'rxjs';

@Component({
  selector: 'app-dienstleister-detail',
  //template: `${CRUDBasicDetailComponent_Template || ''}${DienstleisterDetailComponent_Template}`,
  templateUrl: './dienstleister-detail.component.html',
  styleUrls: ['../crud-basic-detail/crud-basic-detail.component.css'],
  host: { '(window:keydown)': 'hotkeys($event)' }
})
export class DienstleisterDetailComponent extends CRUDBasicDetailComponent implements OnInit {
  // CHILD-spezifisch: Konstanten - START
  CRUDItemKurzform: string = "Dienstleister";
  CRUDPageTitleNeu: string = this.translate.instant("Neuer Dienstleister", true);
  CRUDPageTitleBearbeiten: string = this.translate.instant("Dienstleister bearbeiten", true);
  CRUDItemBezeichnungSingularCapitalized: string = "Dienstleister";
  CRUDItemBezeichnungPluralCapitalized: string = "Dienstleister";
  CRUDItemBezeichnungSingular: string = "dienstleister";
  CRUDItemBezeichnungPlural: string = "dienstleister";
  CRUDItemRouteSingular: string = "dienstleister";
  CRUDItemRoutePlural: string = /*"dienstleister"*/"map"; // siehe ngONInit.
  CRUDItemHelpTopic: string = null;
  // CHILD-spezifisch: Konstanten - Ende

  // CHILD-spezifisch: zusätzliche Widgets (ausser Standard Inputs, Autocomplete) - START
  benutzer: IBenutzer = null;
  benutzerSubscription: Subscription = null;
  // CHILD-spezifisch: zusätzliche Widgets (ausser Standard Inputs, Autocomplete) - ENDE

  constructor(
    @Inject( forwardRef(() => AppComponent)) public _app: AppComponent,
    private _injector: Injector,
    private _translate: TranslateService,
    private _crudItemService: DienstleisterService,
    private _guard: DienstleisterDetailGuard,

    // CHILD-spezifisch: zusätzliche Services - START
    // CHILD-spezifisch: zusätzliche Services - ENDE
  ) {
    super(_app, _injector);

    this.crudItemService = _crudItemService;
    this.guard = _guard;

    // CHILD-spezifisch die Validator Messages bestücken - START
    this.validationMessages =
    {
      bezeichnung: {
        required: this._translate.instant('Name', true) + ': ' + this._translate.instant('ist erforderlich', true),
        maxlength: this._translate.instant('Name', true) + ' ' + this._translate.instant('darf 255 Zeichen nicht überschreiten', true)
      },

      strasse: {
        required: this._translate.instant('Strasse', true) + ': ' + this._translate.instant('ist erforderlich', true),
        maxlength: this._translate.instant('Strasse', true) + ' ' + this._translate.instant('darf 35 Zeichen nicht überschreiten', true)
      },

      plz: {
        required: this._translate.instant('Postleitzahl', true) + ': ' + this._translate.instant('ist erforderlich', true),
        maxlength: this._translate.instant('Postleitzahl', true) + ' ' + this._translate.instant('darf 10 Zeichen nicht überschreiten', true)
      },

      ort: {
        required: this._translate.instant('Ort', true) + ': ' + this._translate.instant('ist erforderlich', true),
        maxlength: this._translate.instant('Ort', true) + ' ' + this._translate.instant('darf 35 Zeichen nicht überschreiten', true)
      },

      ortzusatz: {
        maxlength: this._translate.instant('Ortzusatz', true) + ' ' + this._translate.instant('darf 35 Zeichen nicht überschreiten', true)
      },

      email: {
        required: this._translate.instant('Email', true) + ': ' + this._translate.instant('ist erforderlich', true),
        maxlength: this._translate.instant('Email', true) + ' ' + this._translate.instant('darf 255 Zeichen nicht überschreiten', true)
      },

      zuordnung: {
        //required: this._translate.instant('Zuordnung', true) + ': ' + this._translate.instant('ist erforderlich', true),
        maxlength: this._translate.instant('Zuordnung', true) + ' ' + this._translate.instant('darf 255 Zeichen nicht überschreiten', true)
      },

      /*benutzer: {
        required: this._translate.instant('Benutzer', true) + ': ' + this._translate.instant('ist erforderlich', true),
      },

      kopfdaten: {
        required: this._translate.instant('Kopfdaten', true) + ': ' + this._translate.instant('ist erforderlich', true),
      },*/
};
    // CHILD-spezifisch die Validator Messages bestücken - ENDE
    
    this.genericValidator = new GenericValidator(this.validationMessages);

    this.buildForm();
  }

  buildForm() {
    // CHILD-spezifisch die Form aufbauen - START
    this.CRUDForm = this.fb.group({
      bezeichnung: ['', [Validators.required, Validators.maxLength(255)]],
      strasse: ['', [Validators.required, Validators.maxLength(35)]],
      plz: ['', [Validators.required, Validators.maxLength(10)]],
      ort: ['', [Validators.required, Validators.maxLength(35)]],
      ortzusatz: ['', [Validators.maxLength(35)]],
      email: ['', [Validators.required, Validators.maxLength(255)]],
      zuordnung: ['', [/*Validators.required, */Validators.maxLength(255)]],
      /*benutzer: [null, [Validators.required]],
      kopfdaten: [null, [Validators.required]],*/
    },{
      validator: this.formValidator // auf FormEbene!
    });

    this.CRUDForm['___component'] = this; // trick, um im formValidator wieder an die component zu kommen.
    // CHILD-spezifisch die Form aufbauen - ENDE
  }

  ngOnInit() {
    this.blockedDocument = true;

    // CHILD-spezifische Zusätze, z.B. Nachladen anderer Entities - START
    this.benutzerSubscription = this.app.benutzerBehaviourSubject.subscribe(benutzer => {
      if(benutzer != null) {
          console.log("DienstleisterDetailComponent.ngOnInit() got benutzer:", benutzer);
          this.benutzer = benutzer;
          setTimeout(() => {
            this.benutzerSubscription.unsubscribe(); 
          }, 10);

          // Berechtigung für diesen Dienstleister ?
          if(benutzer.istAdmin == true || benutzer.dienstleisterId == this.dataId) {
            // ok
            // wenn superadmin, dann darf er auch auf die Liste der Dienstleister (ansonsten = map - siehe Init Wert oben)
            if(benutzer.dienstleister.zuordnung == null || benutzer.dienstleister.zuordnung == '') this.CRUDItemRoutePlural = "dienstleister";
          }
          else {
            this.messageWrapperService.postStaticMessage({ severity: 'error', summary: "Nicht Berechtigt", detail: "Sie sind für diese Funktion nicht berechtigt." }); 
            setTimeout(() => {
              this.router.navigate(['/map']);
            }, 10);	
          }
      }
    });
    // CHILD-spezifische Zusätze, z.B. Nachladen anderer Entities - ENDE

    super.ngOnInit();
  }

  getValuesFromForm() {
    let a = super.getValuesFromForm(); // Standard! do not change!

    // CHILD-spezifische assigns, z.B. spracheId aus strache.Id bestücken - START
    // CHILD-spezifische assigns, z.B. spracheId aus strache.Id bestücken - ENDE

    //console.log("DienstleisterDetailComponent.getValuesFromForm() a:", a);
    return a;
  }

  sendValuesToForm() {
    // CHILD-spezifisch die Form patchen - START
    this.CRUDForm.patchValue({
      bezeichnung: this.CRUDItem.bezeichnung,
      strasse: this.CRUDItem.strasse,
      plz: this.CRUDItem.plz,
      ort: this.CRUDItem.ort,
      ortzusatz: this.CRUDItem.ortzusatz,
      email: this.CRUDItem.email,
      zuordnung: this.CRUDItem.zuordnung,
/*      benutzer: this.CRUDItem.benutzer,
      kopfdaten: this.CRUDItem.kopfdaten*/
});
    // CHILD-spezifisch die Form patchen - ENDE
    //console.log("DienstleisterDetailComponent.sendValuesToForm() CRUDForm:", this.CRUDForm);
    super.sendValuesToForm(); // haben nicht alle DetailComponents - erst ab Ticket 9412 17.07.2019
  }

  onCrudItemsValueChangeBenutzer($event) { // Parent+Child-CRUD
    console.log("Dienstleister.onCrudItemsValueChangeChecklistePositionen() this:", this);
    console.log("Dienstleister.onCrudItemsValueChangeChecklistePositionen() $event:", $event);
    this.CRUDItem.benutzer = $event;
    this.CRUDForm.markAsDirty();

    this.displayMessageForm = {}; // validity neu prüfen
    this.CRUDForm.updateValueAndValidity(); // validity neu prüfen
  }

  formValidator(form: FormGroup) { // validate auf FORM-Ebene!
    let errors : any[] = [];
    let errorsFound : boolean = false;

    console.log("DienstleisterDetailComponent.formValidator() form:", form);

    // dazu erstmal an die in der Form verlinkte Component kommen
    if(form['___component'] != null/* && form['___component'].dataId != null && form['___component'].dataId == 0*/) {
      let thisInstance = form['___component'];
      console.log("DienstleisterDetailComponent.formValidator() thisInstance:", thisInstance);

      if(thisInstance.CRUDItem == null) return null;
      let createMode = thisInstance.CRUDItem.id != null && thisInstance.CRUDItem.id <= 0;

      // Bei einem User Filterung = Umschaltbar kann nicht ausgewählt werden, wenn Dienstleister-Filter angezeigt wird
      if(form.value.zuordnung == null || form.value.zuordnung == '') { // Dienstleister-Filter wird angezeigt!
        let found = thisInstance.CRUDItem.benutzer.filter(f => f.filterung == 'U');
        if(found != null && found.length > 0) {
          let commaSeparatedListOfBenutzer = "";
          found.forEach(benutzer => {
            if(commaSeparatedListOfBenutzer.length > 0) commaSeparatedListOfBenutzer += ", ";
            commaSeparatedListOfBenutzer += benutzer.userName;
          });
          errors["Filterung 'alle Aufträge anzeigen möglich' kann für Benutzer nicht ausgewählt werden, wenn keine Zuordnung angegeben. " + "\nBenutzernamen: " + commaSeparatedListOfBenutzer]=true;
          errorsFound = true;
        }
      }
    }

    //form.setErrors(errorsFound ? errors : null);
    if(errorsFound) return errors;
    else return null;
  }

}
