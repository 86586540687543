import { Injectable } from '@angular/core';
//import { Observable } from 'rxjs/Observable';
import { Observable, of } from 'rxjs';
//import 'rxjs/add/operator/map';
//import 'rxjs/add/operator/do';
//import 'rxjs/add/operator/catch';
import { IPagination } from '../_interfaces/pagination'
import { IChecklisteFreitexte } from '../_interfaces/checkliste-freitexte';
//import { IChoiceList } from '../_interfaces/_choice-list';
import * as moment from 'moment'; // DateTimeOffset-Fix
import * as cloneDeep from 'lodash/cloneDeep'; // DateTimeOffset-Fix

import { AppconfigService } from '../_services/appconfig.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';

import { CRUDBasicService, httpOptions } from '../_services/crud-basic.service';

@Injectable()
export class ChecklisteFreitexteService extends CRUDBasicService {

  //typeOf deprecated -> CRUDBasicService.propertyTypeOf
  /*typeOf(propertyName: string) {  // welchen Typs sind die Properties IN DER API ?
    let property = this.propertyTypes.find(f => f.name == propertyName);
    if(property != null) return property.type;
    else return null;    
  }*/
  propertyTypes = [ // welchen Typs sind die Properties IN DER API ?
    {name: 'id', type: 'int'},
    {name: 'createdBy', type: 'string'},
    {name: 'created', type: 'DateTimeOffset'},
    {name: 'modifiedBy', type: 'string'},
    {name: 'modified', type: 'DateTimeOffset'},
    {name: 'rowVersion', type: 'byte[]'},
    {name: 'bezeichnung', type: 'string'},
    {name: 'platzhalter', type: 'string'},
    {name: 'wert', type: 'string'},
    {name: 'summary', type: 'string'}
  ];
    
  getChecklisteFreitexteCollection(pageNumber: number, pageSize: number, searchQuery: string): Observable<{ checklisteFreitexte: IChecklisteFreitexte[], pagination: IPagination }> {
    return this.httpClient.get(this.apiBaseUrl + 'checklistefreitexte?pageNumber=' + pageNumber + '&pageSize=' + pageSize + '&searchQuery=' + searchQuery, { headers: httpOptions, observe: 'response', withCredentials: true })
      .pipe(
        map((response) => {
          return { checklisteFreitexte: <IChecklisteFreitexte[]>response.body, pagination: <IPagination>JSON.parse(response.headers.get('x-pagination')) };
        }), catchError(this.handleError))
  }

  getChecklisteFreitexte(id: number): Observable<IChecklisteFreitexte> {

    if (id === 0) {
      // return Observable.create((observer: any) => {
      //   observer.next(this.initializeChecklisteFreitexte());
      //   observer.complete();
      // })
      return of(this.initializeChecklisteFreitexte());
    }

    return this.httpClient.get<IChecklisteFreitexte>(this.apiBaseUrl + 'checklistefreitexte/' + id, { headers: httpOptions, observe: 'body', withCredentials: true })
      .pipe(map((response) => response), catchError(this.handleError));
  }

  saveChecklisteFreitexte(checklisteFreitexte: IChecklisteFreitexte): Observable<IChecklisteFreitexte> {
    // DateTimeOffset-Fix
    let itemToSave = cloneDeep(checklisteFreitexte); // clonen, um sicherzustellen, dass das Original-Objekt nicht ver�ndert wird, evtl. passiert nach dem Save noch etwas damit ?
    //console.log("ChecklisteFreitexteService.saveSingularCapitalized#() itemToSave before DateTimeOffset-Fix:", itemToSave);
    this.propertyTypes.filter(f => f.type.toLowerCase() == 'datetimeoffset').forEach(propertyType => {
      if (itemToSave[propertyType.name] != null) {
        itemToSave[propertyType.name] = moment(itemToSave[propertyType.name]).format('YYYY-MM-DDTHH:mm:ss.SSSZ'); // alle datetimeoffset-felder entspr. formatieren, dass in der API auch der Offset ankommt!
      }
    });
    //console.log("ChecklisteFreitexteService.saveSingularCapitalized#() itemToSave after DateTimeOffset-Fix:", itemToSave);

    if (checklisteFreitexte.id === 0) {
      return this.createChecklisteFreitexte(/*checklisteFreitexte*/itemToSave);
    }

    return this.updateChecklisteFreitexte(/*checklisteFreitexte*/itemToSave)

  }

  updateChecklisteFreitexte(checklisteFreitexte: IChecklisteFreitexte): Observable<IChecklisteFreitexte> {

    return this.httpClient.put<IChecklisteFreitexte>(this.apiBaseUrl + 'checklistefreitexte/' + checklisteFreitexte.id, checklisteFreitexte, { headers: httpOptions, observe: 'body', withCredentials: true })
    .pipe(catchError(this.handleError))

  }

  deleteChecklisteFreitexte(id: number) {

    return this.httpClient.delete(this.apiBaseUrl + 'checklistefreitexte/' + id, { headers: httpOptions, observe: 'body', withCredentials: true })
      .pipe(
        catchError(this.handleError)
      )
  }

  createChecklisteFreitexte(checklisteFreitexte: IChecklisteFreitexte): Observable<IChecklisteFreitexte> {
    return this.httpClient.post<IChecklisteFreitexte>(this.apiBaseUrl + 'checklistefreitexte', checklisteFreitexte, { headers: httpOptions, observe: 'body', withCredentials: true })
      .pipe(map((response) => response), catchError(this.handleError))
  }

  initializeChecklisteFreitexte(): IChecklisteFreitexte {
    return {
      id: 0,
      createdBy: '',
      created: '',
      modifiedBy: '',
      modified: '',
      rowVersion: '',
      bezeichnung: '',
      platzhalter: '',
      wert: '',
      summary: ''
    };
  }


}
