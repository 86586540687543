// HTML Text als innerHTML ausgeben: https://medium.com/@AAlakkad/angular-2-display-html-without-sanitizing-filtering-17499024b079
import { Pipe, PipeTransform } from '@angular/core';
import { Inject, forwardRef } from '@angular/core';

import { AppComponent } from '../app.component';

import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ name: 'FAIcon', pure: false })
export class FAIconPipe implements PipeTransform {
  constructor(@Inject(forwardRef(() => AppComponent)) public app: AppComponent,
    private sanitizer: DomSanitizer) {
  }

  transform(content) {
    return this.sanitizer.bypassSecurityTrustHtml(`<i class="fa fa-${content}"></i>&nbsp;${content}`);
  }
}