<div class="grid">
    <p-toast></p-toast>
    <div class="col-12 lg:col-6">
        <div class="card p-fluid">
            <h5>Dialog</h5>
            <p-dialog header="Dialog" [(visible)]="display" modal="modal" showEffect="fade" [style]="{width: '30vw'}" [breakpoints]="{'960px': '75vw'}">
                <p class="line-height-3 m-0">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                    quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit
                    in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                    Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                </p>
                <ng-template pTemplate="footer">
                        <button pButton icon="pi pi-check" (click)="display=false" label="Ok" class="p-button-outlined"></button>
                </ng-template>
            </p-dialog>
            <div class="grid">
                <div class="col-12">
                    <button (click)="display=true" pButton icon="pi pi-external-link" label="Show" style="width:auto;"></button>
                </div>
            </div>
        </div>

        <div class="card p-fluid">
            <h5>Overlay Panel</h5>
            <div class="grid formgrid">
                <div class="col-6">
                    <button type="button" pButton label="Image" (click)="op1.toggle($event)" class="p-button-success"></button>
                    <p-overlayPanel #op1 [showCloseIcon]="true" [style]="{width: '450px'}">
                        <img src="assets/demo/images/product/bamboo-watch.jpg" alt="Nature 1" style="width:100%"/>
                    </p-overlayPanel>
                </div>
                <div class="col-6">
                    <button type="button" pButton label="DataTable" (click)="op2.toggle($event)" class="p-button-success"></button>
                    <p-overlayPanel #op2 [showCloseIcon]="true" [style]="{width: '450px'}">
                        <ng-template pTemplate>
                            <p-table [value]="products" selectionMode="single" [(selection)]="selectedProduct" [paginator]="true" [rows]="5" (onRowSelect)="op2.hide()" responsiveLayout="scroll">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th pSortableColumn="name">Name<p-sortIcon field="name"></p-sortIcon></th>
                                        <th>Image</th>
                                        <th pSortableColumn="price">Price <p-sortIcon field="price"></p-sortIcon></th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-rowData let-product>
                                    <tr [pSelectableRow]="rowData">
                                        <td style="min-width: 10rem;">{{product.name}}</td>
                                        <td style="min-width: 10rem;"><img src="assets/demo/images/product/{{product.image}}" [alt]="product.image" width="100" class="shadow-2"/></td>
                                        <td style="min-width: 12rem;">{{formatCurrency(product.price)}}</td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </ng-template>
                    </p-overlayPanel>
                </div>
            </div>
        </div>
    </div>

    <div class="col-12 lg:col-6">
        <div class="card p-fluid">
            <h5>Confirmation</h5>
            <p-confirmDialog header="Confirmation" key="confirm1" icon="pi pi-exclamation-triangle" message="Are you sure you want to proceed?"
                             [style]="{width: '350px'}" acceptButtonStyleClass="p-button-text" rejectButtonStyleClass="p-button-text"></p-confirmDialog>
            <div class="grid">
                <div class="col-12">
                    <button type="text" (click)="confirm1()" pButton icon="pi pi-trash" label="Delete" class="p-button-danger" style="width:auto;"></button>
                </div>
            </div>
        </div>

        <div class="card">
            <h5>Sidebar</h5>
            <p-sidebar [(visible)]="visibleSidebar1" [baseZIndex]="10000">
                <h3 style="font-weight:normal">Left Sidebar</h3>
            </p-sidebar>

            <p-sidebar [(visible)]="visibleSidebar2" position="right" [baseZIndex]="10000">
                <h3 style="font-weight:normal">Right Sidebar</h3>
            </p-sidebar>

            <p-sidebar [(visible)]="visibleSidebar3" position="top" [baseZIndex]="10000">
                <h3 style="font-weight:normal">Top Sidebar</h3>
            </p-sidebar>

            <p-sidebar [(visible)]="visibleSidebar4" position="bottom" [baseZIndex]="10000">
                <h3 style="font-weight:normal">Bottom Sidebar</h3>
            </p-sidebar>

            <p-sidebar [(visible)]="visibleSidebar5" [fullScreen]="true" [baseZIndex]="10000">
                <h3 style="font-weight:normal">Full Screen Sidebar</h3>
            </p-sidebar>

            <button pButton type="button" (click)="visibleSidebar1 = true" icon="pi pi-arrow-right" class="p-button-warning mr-2"></button>
            <button pButton type="button" (click)="visibleSidebar2 = true" icon="pi pi-arrow-left" class="p-button-warning mr-2"></button>
            <button pButton type="button" (click)="visibleSidebar3 = true" icon="pi pi-arrow-down" class="p-button-warning mr-2"></button>
            <button pButton type="button" (click)="visibleSidebar4 = true" icon="pi pi-arrow-up" class="p-button-warning mr-2"></button>
            <button pButton type="button" (click)="visibleSidebar5 = true" icon="pi pi-external-link" class="p-button-warning"></button>
        </div>
    </div>

    <div class="col-12 lg:col-6">
        <div class="card">
            <h5>Tooltip</h5>
            <div class="formgroup-inline">
                <div class="field">
                    <label for="firstname5" class="p-sr-only">Username</label>
                    <input id="firstname5" type="text" pInputText placeholder="Username" pTooltip="Enter your username">
                </div>
                <button pButton pRipple type="button" label="Save" icon="pi pi-check" pTooltip="Click to proceed"></button>
            </div>
        </div>
    </div>

    <div class="col-12 lg:col-6">
        <div class="card">
            <h5>ConfirmPopup</h5>

            <p-toast></p-toast>
            <button (click)="confirm2($event)" pButton icon="pi pi-check" label="Confirm"></button>
            <p-confirmPopup key="confirm2"></p-confirmPopup>
        </div>
    </div>
</div>
