//import { /*CRUDBasicDetailComponent_Template,*/ CRUDBasicDetailComponent } from '../crud-basic-detail/crud-basic-detail.component';
import { CRUDBasicDetailForChildComponent } from '../crud-basic-detail-for-child/crud-basic-detail-for-child.component';


import { Component, OnInit, Inject, forwardRef, Injector, ViewChild, Input } from '@angular/core';
import { Validators, FormControl, FormGroup, FormBuilder, AbstractControl, FormControlName } from '@angular/forms';
import { AppComponent } from '../app.component';
import { TranslateService } from '../_services/translate.service';
import { GenericValidator } from '../_helpers/generic-validator';
//import { AutoComplete } from 'primeng/autocomplete';

//import { IChecklistePositionenFeld } from '../_interfaces/checkliste-positionen-feld';
import { ChecklistePositionenFeldService } from '../_services/checkliste-positionen-feld.service';
import { ChecklistePositionenFeldDetailGuard } from './checkliste-positionen-feld-detail.guard';
//import { GalleriaThumbnails } from 'primeng/galleria';

//import { ChecklistePositionenFeldDetailComponent_Template } from './checkliste-positionen-feld-detail.component.include_template';

@Component({
  selector: 'app-checklistePositionenFeld-detail',
  //template: `${CRUDBasicDetailComponent_Template || ''}${ChecklistePositionenFeldDetailComponent_Template}`,
  templateUrl: './checkliste-positionen-feld-detail.component.html',  
  styleUrls: ['../crud-basic-detail/crud-basic-detail.component.css'],
  host: { '(window:keydown)': 'hotkeys($event)' }
})
export class ChecklistePositionenFeldDetailComponent extends CRUDBasicDetailForChildComponent implements OnInit {
  // CHILD-spezifisch: Konstanten - START
  CRUDItemKurzform: string = "ChecklistePositionenFelder";
  CRUDPageTitleNeu: string = this.translate.instant("Neues Checkliste Positionen Feld", true);
  CRUDPageTitleBearbeiten: string = this.translate.instant(/*"Checkliste Positionen Feld bearbeiten"*/"Checkliste Position Feld", true);
  CRUDItemBezeichnungSingularCapitalized: string = "ChecklistePositionenFeld";
  CRUDItemBezeichnungPluralCapitalized: string = "ChecklistePositionenFelder";
  CRUDItemBezeichnungSingular: string = "checklistePositionenFeld";
  CRUDItemBezeichnungPlural: string = "checklistePositionenFelder";
  CRUDItemRouteSingular: string = "checkliste-positionen-feld";
  CRUDItemRoutePlural: string = "checkliste-positionen-felder";
  CRUDItemHelpTopic: string = null;
  // CHILD-spezifisch: Konstanten - Ende

  // CHILD-spezifisch: zusätzliche Widgets (ausser Standard Inputs, Autocomplete) - START
  typOptions = [
    {value: 'bool', summary: 'bool (Ja/Nein Buttons)'},
    {value: 'checkbox', summary: 'checkbox (Ja/Nein Checkbox)'},
    {value: 'string', summary: 'string (Zeichenfolge)'},
    {value: 'int', summary: 'int (Zahl ohne Nachkommastellen)'},
    {value: 'decimal', summary: 'decimal (Zahl mit Nachkommastellen)'},
    {value: 'date', summary: 'date (Datum ohne Zeit)'},
    {value: 'dateMY', summary: 'dateMY (Monat/Jahr)'},
    {value: 'dateY', summary: 'dateY (Jahr)'},
    {value: 'time', summary: 'time (Uhrzeit)'},
    {value: 'dropdown', summary: 'dropdown (Auswahl-Liste)'}, // wikipedia: dropdown ist nur List-Auswahl, combobox ist mit Alternativer Eingabe
    {value: 'combobox', summary: 'combobox (Auswahl oder Eingabe)'}, // wikipedia: dropdown ist nur List-Auswahl, combobox ist mit Alternativer Eingabe
  ];
  typOptionSelected = null;

  // CHILD-spezifisch: zusätzliche Widgets (ausser Standard Inputs, Autocomplete) - ENDE

  constructor(
    @Inject( forwardRef(() => AppComponent)) public _app: AppComponent,
    private _injector: Injector,
    private _translate: TranslateService,
    private _crudItemService: ChecklistePositionenFeldService,
    private _guard: ChecklistePositionenFeldDetailGuard,

    // CHILD-spezifisch: zusätzliche Services - START
    // CHILD-spezifisch: zusätzliche Services - ENDE
  ) {
    super(_app, _injector);

    this.crudItemService = _crudItemService;
    this.guard = _guard;

    // CHILD-spezifisch die Validator Messages bestücken - START
    this.validationMessages =
    {
      platzhalter: {
        maxlength: this._translate.instant('Platzhalter', true) + ' ' + this._translate.instant('darf 255 Zeichen nicht überschreiten', true)
      },

      info: {
        maxlength: this._translate.instant('Info', true) + ' ' + this._translate.instant('darf 255 Zeichen nicht überschreiten', true)
      },

      abhaengigkeit: {
        maxlength: this._translate.instant('Abhängigkeit', true) + ' ' + this._translate.instant('darf 255 Zeichen nicht überschreiten', true)
      },

      bezeichnung: {
        required: this._translate.instant('Bezeichnung', true) + ': ' + this._translate.instant('ist erforderlich', true),
        maxlength: this._translate.instant('Bezeichnung', true) + ' ' + this._translate.instant('darf 255 Zeichen nicht überschreiten', true)
      },

      typ: {
        maxlength: this._translate.instant('Typ', true) + ' ' + this._translate.instant('darf 255 Zeichen nicht überschreiten', true)
      },

      felddefinition: {
        maxlength: this._translate.instant('Felddefinition', true) + ' ' + this._translate.instant('darf 255 Zeichen nicht überschreiten', true)
      },

      mangel: {
        maxlength: this._translate.instant('Mangel', true) + ' ' + this._translate.instant('darf 6 Zeichen nicht überschreiten', true)
      },

      checklistePositionId: {
        required: this._translate.instant('Checkliste Position Id', true) + ': ' + this._translate.instant('ist erforderlich', true),
      },

      checklistePositionenFeldwerte: {
        required: this._translate.instant('Checkliste Positionen Feldwerte', true) + ': ' + this._translate.instant('ist erforderlich', true),
      },
};
    // CHILD-spezifisch die Validator Messages bestücken - ENDE
    
    this.genericValidator = new GenericValidator(this.validationMessages);

    this.buildForm();
  }

  buildForm() {
    // CHILD-spezifisch die Form aufbauen - START
    this.CRUDForm = this.fb.group({
      reihenfolge: [0],
      platzhalter: ['', [Validators.maxLength(255)]],
      info: ['', [Validators.maxLength(255)]],
      mussfeld: [false],
      abhaengigkeit: ['', [Validators.maxLength(255)]],
      bezeichnung: ['', [Validators.required, Validators.maxLength(255)]],
      typ: ['', [Validators.maxLength(255)]],
      felddefinition: ['', [Validators.maxLength(255)]],
      mangel: ['', [Validators.maxLength(6)]],
      checklistePositionId: [0, [Validators.required]],
      //checklistePositionenFeldwerte: [null, [Validators.required]],
    });
    // CHILD-spezifisch die Form aufbauen - ENDE
  }

  ngOnInit() {
    this.blockedDocument = true;

    // CHILD-spezifische Zusätze, z.B. Nachladen anderer Entities - START
    // CHILD-spezifische Zusätze, z.B. Nachladen anderer Entities - ENDE

    super.ngOnInit();
  }

  getValuesFromForm() {
    let a = super.getValuesFromForm(); // Standard! do not change!

    // CHILD-spezifische assigns, z.B. spracheId aus strache.Id bestücken - START
    a.typ = this.typOptionSelected.value;
    // CHILD-spezifische assigns, z.B. spracheId aus strache.Id bestücken - ENDE

    //console.log("ChecklistePositionenFeldDetailComponent.getValuesFromForm() a:", a);
    return a;
  }

  sendValuesToForm() {
    // CHILD-spezifisch die Form patchen - START
    this.CRUDForm.patchValue({
      reihenfolge: this.CRUDItem.reihenfolge,
      platzhalter: this.CRUDItem.platzhalter,
      info: this.CRUDItem.info,
      mussfeld: this.CRUDItem.mussfeld,
      abhaengigkeit: this.CRUDItem.abhaengigkeit,
      bezeichnung: this.CRUDItem.bezeichnung,
      typ: this.CRUDItem.typ,
      felddefinition: this.CRUDItem.felddefinition,
      mangel: this.CRUDItem.mangel,
      checklistePositionId: this.CRUDItem.checklistePositionId,
      checklistePositionenFeldwerte: this.CRUDItem.checklistePositionenFeldwerte
    });

    let typOptionsSelectedArr = this.typOptions.filter(f => f.value == this.CRUDItem.typ);
    if(typOptionsSelectedArr != null && typOptionsSelectedArr.length > 0) {
      this.typOptionSelected = typOptionsSelectedArr[0];
    }
    // CHILD-spezifisch die Form patchen - ENDE
    //console.log("ChecklistePositionenFeldDetailComponent.sendValuesToForm() CRUDForm:", this.CRUDForm);
    super.sendValuesToForm(); // haben nicht alle DetailComponents - erst ab Ticket 9412 17.07.2019
  }

  typOptionChanged() {
    console.log("ChecklistePositionenFeldDetailComponent.typOptionChanged() a:", null);
  }

  onCrudItemsValueChangeChecklistePositionenFeldwerte($event) { // Parent+Child-CRUD
    console.log("ChecklistePositionenFeldDetailComponent.onCrudItemsValueChangeChecklistePositionenFeldwerte() this:", this);
    console.log("ChecklistePositionenFeldDetailComponent.onCrudItemsValueChangeChecklistePositionenFeldwerte() $event:", $event);
    this.CRUDItem.checklistePositionenFeldwerte = $event;
    this.CRUDForm.markAsDirty();

    //this.displayMessageForm = {}; // validity neu prüfen
    //this.CRUDForm.updateValueAndValidity(); // validity neu prüfen
  }

}
