//import { /*CRUDBasicDetailComponent_Template,*/ CRUDBasicDetailComponent } from '../crud-basic-detail/crud-basic-detail.component';
import { CRUDBasicDetailForChildComponent } from '../crud-basic-detail-for-child/crud-basic-detail-for-child.component';

import { Component, OnInit, Inject, forwardRef, Injector, ViewChild, Input } from '@angular/core';
import { Validators, FormControl, FormGroup, FormBuilder, AbstractControl, FormControlName } from '@angular/forms';
import { AppComponent } from '../app.component';
import { TranslateService } from '../_services/translate.service';
import { GenericValidator } from '../_helpers/generic-validator';
//import { AutoComplete } from 'primeng/autocomplete';
import { IBenutzer } from '../_interfaces/benutzer';
import { BenutzerService } from '../_services/benutzer.service';
import { BenutzerDetailGuard } from './benutzer-detail.guard';
import { Subscription } from 'rxjs';

//import { BenutzerDetailComponent_Template } from './benutzer-detail.component.include_template';

import { validateBenutzerAlreadyExists, validateEMailAlreadyExists } from '../_validators/benutzer-validators';

@Component({
  selector: 'app-benutzer-detail',
  //template: `${CRUDBasicDetailComponent_Template || ''}${BenutzerDetailComponent_Template}`,
  templateUrl: './benutzer-detail.component.html',
  styleUrls: ['../crud-basic-detail/crud-basic-detail.component.css'],
  host: { '(window:keydown)': 'hotkeys($event)' }
})
export class BenutzerDetailComponent extends CRUDBasicDetailForChildComponent implements OnInit {
  // CHILD-spezifisch: Konstanten - START
  CRUDItemKurzform: string = "Benutzer";
  CRUDPageTitleNeu: string = this.translate.instant("Neuer Benutzer", true);
  CRUDPageTitleBearbeiten: string = this.translate.instant("Benutzer bearbeiten", true);
  CRUDItemBezeichnungSingularCapitalized: string = "Benutzer";
  CRUDItemBezeichnungPluralCapitalized: string = "Benutzer";
  CRUDItemBezeichnungSingular: string = "benutzer";
  CRUDItemBezeichnungPlural: string = "benutzer";
  CRUDItemRouteSingular: string = "benutzer";
  CRUDItemRoutePlural: string = "benutzer";
  CRUDItemHelpTopic: string = null;
  // CHILD-spezifisch: Konstanten - Ende

  // CHILD-spezifisch: zusätzliche Widgets (ausser Standard Inputs, Autocomplete) - START
  //debugMode: boolean = true;

  benutzer: IBenutzer = null;
  benutzerSubscription: Subscription = null;

  // CHILD-spezifisch: zusätzliche Widgets (ausser Standard Inputs, Autocomplete) - ENDE

  constructor(
    @Inject( forwardRef(() => AppComponent)) public _app: AppComponent,
    private _injector: Injector,
    private _translate: TranslateService,
    private _crudItemService: BenutzerService,
    private _guard: BenutzerDetailGuard,

    // CHILD-spezifisch: zusätzliche Services - START
    // CHILD-spezifisch: zusätzliche Services - ENDE
  ) {
    super(_app, _injector);

    this.crudItemService = _crudItemService;
    this.guard = _guard;

    // CHILD-spezifisch die Validator Messages bestücken - START
    this.validationMessages =
    {
      /*aspNetUserId: {
        required: this._translate.instant('Asp Net User Id', true) + ': ' + this._translate.instant('ist erforderlich', true),
      },*/

      aktiv: {
        required: this._translate.instant('Aktiv', true) + ': ' + this._translate.instant('ist erforderlich', true),
      },

      admin: {
        required: this._translate.instant('Administrator', true) + ': ' + this._translate.instant('ist erforderlich', true),
      },

      istBenutzer: {
        required: this._translate.instant('ist Benutzer', true) + ': ' + this._translate.instant('ist erforderlich', true),
      },

      istAnsprechpartner: {
        required: this._translate.instant('ist Ansprechpartner', true) + ': ' + this._translate.instant('ist erforderlich', true),
      },

      vorname: {
        required: this._translate.instant('Vorname', true) + ': ' + this._translate.instant('ist erforderlich', true),
        maxlength: this._translate.instant('Vorname', true) + ' ' + this._translate.instant('darf 255 Zeichen nicht überschreiten', true)
      },

      name: {
        required: this._translate.instant('Name', true) + ': ' + this._translate.instant('ist erforderlich', true),
        maxlength: this._translate.instant('Name', true) + ' ' + this._translate.instant('darf 255 Zeichen nicht überschreiten', true)
      },

      telefon: {
        required: this._translate.instant('Telefon', true) + ': ' + this._translate.instant('ist erforderlich', true),
        maxlength: this._translate.instant('Telefon', true) + ' ' + this._translate.instant('darf 255 Zeichen nicht überschreiten', true)
      },

      mobil: {
        required: this._translate.instant('Mobil', true) + ': ' + this._translate.instant('ist erforderlich', true),
        maxlength: this._translate.instant('Mobil', true) + ' ' + this._translate.instant('darf 255 Zeichen nicht überschreiten', true)
      },

      email: {
        required: this._translate.instant('Email', true) + ': ' + this._translate.instant('ist erforderlich', true),
        maxlength: this._translate.instant('Email', true) + ' ' + this._translate.instant('darf 255 Zeichen nicht überschreiten', true)
      },

      /*dienstleisterId: {
        required: this._translate.instant('Dienstleister Id', true) + ': ' + this._translate.instant('ist erforderlich', true),
      },

      sessions: {
        required: this._translate.instant('Sessions', true) + ': ' + this._translate.instant('ist erforderlich', true),
      },*/

      userName: {
        required: this._translate.instant('Benutzername', true) + ': ' + this._translate.instant('ist erforderlich', true),
        maxlength: this._translate.instant('Benutzername', true) + ' ' + this._translate.instant('darf 255 Zeichen nicht überschreiten', true),
        Benutzername_exists: this._translate.instant('Benutzername_exists', true) // async validator
      },

      identifikation: {
        //required: this._translate.instant('Passwort', true) + ': ' + this._translate.instant('ist erforderlich', true),
        maxlength: this._translate.instant('Passwort', true) + ' ' + this._translate.instant('darf 255 Zeichen nicht überschreiten', true)
      },

      _identifikationBestaetigen: {
        //required: this._translate.instant('Passwort Bestätigen', true) + ': ' + this._translate.instant('ist erforderlich', true),
        maxlength: this._translate.instant('Passwort Bestätigen', true) + ' ' + this._translate.instant('darf 255 Zeichen nicht überschreiten', true)
      },

      prueferKennzeichen: {
        //required: this._translate.instant('Prüfer-Kennzeichen', true) + ': ' + this._translate.instant('ist erforderlich', true),
        maxlength: this._translate.instant('Prüfer-Kennzeichen', true) + ' ' + this._translate.instant('darf 100 Zeichen nicht überschreiten', true)
      },


};
    // CHILD-spezifisch die Validator Messages bestücken - ENDE
    
    this.genericValidator = new GenericValidator(this.validationMessages);

    this.buildForm();
  }

  buildForm() {
    // CHILD-spezifisch die Form aufbauen - START
    this.CRUDForm = this.fb.group({
      aspNetUserId: [null/*, [Validators.required]*/],
      aktiv: [false, [Validators.required]],
      istAdmin: [false, [Validators.required]],
      istBenutzer: [false/*, [Validators.required]*/],
      istAnsprechpartner: [false/*, [Validators.required]*/],
      vorname: ['', [Validators.required, Validators.maxLength(255)]],
      name: ['', [Validators.required, Validators.maxLength(255)]],
      telefon: ['', [/*Validators.required, */Validators.maxLength(255)]],
      mobil: ['', [/*Validators.required, */Validators.maxLength(255)]],
      email: ['', [Validators.required, Validators.maxLength(255)]],
      dienstleisterId: [0/*, [Validators.required]*/],
      dienstleister: [null],
      sessions: [null/*, [Validators.required]*/],
      userName: ['', [Validators.required, Validators.maxLength(255)], [validateBenutzerAlreadyExists(this.crudItemService)]], // async validator https://www.tektutorialshub.com/angular/angular-async-validator-example/
      identifikation: ['', [/*Validators.required, */Validators.maxLength(255)]], // im Modify-Mode keine Validators - siehe ngOnInit
      _identifikationBestaetigen: ['', [/*Validators.required, */Validators.maxLength(255)]], // im Modify-Mode keine Validators - siehe ngOnInit
      prueferKennzeichen: ['', [Validators.maxLength(100)]],
      filterung: [null, [/*Validators.required*/]],
    },{
      validator: this.formValidator // auf FormEbene!
    });

    this.CRUDForm['___component'] = this; // trick, um im formValidator wieder an die component zu kommen.
    // CHILD-spezifisch die Form aufbauen - ENDE
  }

  ngOnInit() {
    this.blockedDocument = true;

    // CHILD-spezifische Zusätze, z.B. Nachladen anderer Entities - START
        // wenn NICHT create-Modus, dann Validator für Benutzername_exists + eMail_exists deaktivieren!
        if(this.CRUDItem.id > 0) {
          this.CRUDForm.controls.userName.clearAsyncValidators();
          //this.CRUDForm.controls.email.clearAsyncValidators(); // für eMail aktiv lassen, weil die kann man ändern
          console.log("BenutzerDetail.ngOnInit() deactivated validator for 'Benutzername_exists'. controls.this.CRUDForm.controls.userName: ", this.CRUDForm.controls.userName);
        }

        this.benutzerSubscription = this.app.benutzerBehaviourSubject.subscribe(benutzer => {
          if(benutzer != null) {
              console.log("BenutzerDetailComponent.ngOnInit() got benutzer:", benutzer);
              this.benutzer = benutzer;
              setTimeout(() => {
                this.benutzerSubscription.unsubscribe(); 
              }, 10);
          }
        });
    
    // CHILD-spezifische Zusätze, z.B. Nachladen anderer Entities - ENDE

    super.ngOnInit();
  }

  getValuesFromForm() {
    let a = super.getValuesFromForm(); // Standard! do not change!

    // CHILD-spezifische assigns, z.B. spracheId aus strache.Id bestücken - START
    //a.summary = a.vorname + " " + a.nachname; // nur deshalb, weil die Liste der Benutzer als Auswahl bei den Ansprechpartnern kommt (ohne dass die zwischenzeitlich nochmal durch die API laufen)

    delete a.filterung_ChoiceListEntry;
    // CHILD-spezifische assigns, z.B. spracheId aus strache.Id bestücken - ENDE

    //console.log("BenutzerDetailComponent.getValuesFromForm() a:", a);
    return a;
  }

  sendValuesToForm() {
    // CHILD-spezifisch die Form patchen - START
    this.CRUDForm.patchValue({
      aspNetUserId: this.CRUDItem.aspNetUserId,
      aktiv: this.CRUDItem.aktiv,
      istAdmin: this.CRUDItem.istAdmin,
      istBenutzer: this.CRUDItem.istBenutzer,
      istAnsprechpartner: this.CRUDItem.istAnsprechpartner,
      vorname: this.CRUDItem.vorname,
      name: this.CRUDItem.name,
      telefon: this.CRUDItem.telefon,
      mobil: this.CRUDItem.mobil,
      email: this.CRUDItem.email,
      dienstleisterId: this.CRUDItem.dienstleisterId,
      dienstleister: this.CRUDItem.dienstleister,
      sessions: this.CRUDItem.sessions,
      userName: this.CRUDItem.userName,
      identifikation: this.CRUDItem.identifikation,
      _identifikationBestaetigen:  this.CRUDItem._identifikationBestaetigen,
      filterung: this.CRUDItem.filterung,
      prueferKennzeichen: this.CRUDItem.prueferKennzeichen,
    });
    // CHILD-spezifisch die Form patchen - ENDE
    //console.log("BenutzerDetailComponent.sendValuesToForm() CRUDForm:", this.CRUDForm);
    super.sendValuesToForm(); // haben nicht alle DetailComponents - erst ab Ticket 9412 17.07.2019
  }

  benutzerGet(thisInstance: BenutzerDetailComponent) {
    return thisInstance.CRUDItem;
  }

  /*onCrudItemsValueChangeChecklistePositionenFelder($event) { // Parent+Child-CRUD
    console.log("BenutzerDetailComponent.onCrudItemsValueChangeChecklistePositionenFelder() this:", this);
    console.log("BenutzerDetailComponent.onCrudItemsValueChangeChecklistePositionenFelder() $event:", $event);
    this.CRUDItem.BenutzerChildEntity = $event;
    this.CRUDForm.markAsDirty();

    //this.displayMessageForm = {}; // validity neu prüfen
    //this.CRUDForm.updateValueAndValidity(); // validity neu prüfen
  }*/

  getCRUDBezeichnung() { // die Bezeichnung ermitteln - um sie z.B. beim Save als "Erfolgreich-Message" auszugeben. - kann von child-Klasse überschrieben werden!
    if(this.CRUDForm != null && this.CRUDForm.get('vorname') != null && this.CRUDForm.get('name') != null)
      return this.CRUDItem.vorname+" "+this.CRUDItem.name;
    else
      return null;
  }

  formValidator(form: FormGroup) { // validate auf FORM-Ebene!
    let errors : any[] = [];
    let errorsFound : boolean = false;

    console.log("BenutzerDetailComponent.formValidator() form:", form);

    // dazu erstmal an die in der Form verlinkte Component kommen
    if(form['___component'] != null/* && form['___component'].dataId != null && form['___component'].dataId == 0*/) {
      let thisInstance = form['___component'];
      console.log("BenutzerDetailComponent.formValidator() thisInstance:", thisInstance);

      let createMode = thisInstance.CRUDItem.id != null && thisInstance.CRUDItem.id <= 0;
      let valueUsername = form.value.userName;
      let valuePassword = form.value.identifikation;
      let valuePasswordBestaetigen = form.value._identifikationBestaetigen;

        if(valueUsername == null) {
          //errors['Benutzername_ist_erforderlich']=true;  // durch "normalen" validator abgedeckt!
          //errorsFound = true;
        }
        else {
          let ungueltigeZeichenGefunden = thisInstance._crudItemService.validateUserName(valueUsername);
          if(ungueltigeZeichenGefunden != null) {
            errors['Benutzername_enthaelt_ungueltige_Zeichen']=true;
            errorsFound = true;
          }
        }

        // wenn Benutzer und es war bisher keiner, dann brauchen wir ein Passwort!
        console.log("BenutzerDetailComponent.formValidator() createMode:", createMode);
        console.log("BenutzerDetailComponent.formValidator() thisInstance.CRUDItem.istBenutzer:", thisInstance.CRUDItem.istBenutzer);
        if(createMode == true) {
          console.log("BenutzerDetailComponent.formValidator() wir brauchen ein Passwort!");
          if(valuePassword == null) {
            errors['Passwort_ist_erforderlich']=true;
            errorsFound = true;
          }

          if(valuePassword != null /*&& valuePasswordBestaetigen != null*/ && valuePassword != valuePasswordBestaetigen) {
            errors['Die_Passwoerter_stimmen_nicht_ueberein']=true;
            errorsFound = true;
          }
        }
    }

    //form.setErrors(errorsFound ? errors : null);
    if(errorsFound) return errors;
    else return null;
  }

}
