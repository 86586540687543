<div class="layout-invoice-page">
    <button pButton pRipple type="button" label="Print" icon="pi pi-print" (click)="print()" class="mb-3"></button>

    <div class="grid layout-invoice-content">
        <div class="col-12">
            <div class="card">
                <div class="flex justify-content-between">
                    <h5>INVOICE</h5>
                    <div class="flex flex-column align-items-end">
                        <img src="assets/layout/images/extensions/prime-logo.svg" alt="invoice-logo"/>
                        <p class="mt-1">9137 3rd Lane California City, CA 93504.</p>
                    </div>
                </div>

                <div class="p-3">
                    <p-table [columns]="billCols" [value]="billData" styleClass="p-invoice-datatable-responsive pb-3">
                        <ng-template pTemplate="header" let-columns>
                            <tr>
                                <th *ngFor="let col of columns">
                                    {{col.header}}
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData let-columns="columns">
                            <tr>
                                <td *ngFor="let col of columns">
                                    <span class="p-column-title">{{col.header}}</span>
                                    {{rowData[col.field]}}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>

                    <p-table [columns]="productCols" [value]="productData" styleClass="p-invoice-datatable-responsive p-datatable-sm py-6">
                        <ng-template pTemplate="header" let-columns>
                            <tr>
                                <th *ngFor="let col of columns">
                                    {{col.header}}
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData let-columns="columns">
                            <tr>
                                <td *ngFor="let col of columns">
                                    <span class="p-column-title">{{col.header}}</span>
                                    {{rowData[col.field]}}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>

                    <div class="grid">
                        <div class="col-12 md:col-6">
                            <p-panel header="BANK">
                                <div class="flex align-items-center justify-content-between py-2">
                                    <span>Bank</span>
                                    <span class="font-bold">BestBank</span>
                                </div>
                                <div class="flex align-items-center justify-content-between pt-2 py-2">
                                    <span>ACCOUNT BENEFICIARY</span>
                                    <span class="font-bold">Edward Williams</span>
                                </div>
                                <div class="flex align-items-center justify-content-between pt-2 py-2">
                                    <span>SWIFT</span>
                                    <span class="font-bold">PJNWBXND</span>
                                </div>
                                <div class="flex align-items-center justify-content-between pt-2 py-2">
                                    <span>IBAN</span>
                                    <span class="font-bold">GB04420235692263866724650931</span>
                                </div>
                            </p-panel>
                        </div>
                        <div class="col-12 md:col-6">
                            <p-panel header="TOTAL">
                                <div class="grid text-right">
                                    <div class="col-10">
                                        SUBTOTAL
                                    </div>
                                    <div class="col-2">
                                        <span class="pink-color">$1304.00</span>
                                    </div>

                                    <div class="col-10">
                                        <span>VAT</span>
                                    </div>
                                    <div class="col-2">
                                        <span class="pink-color">$234.72</span>
                                    </div>

                                    <div class="col-10">
                                        <span>TOTAL</span>
                                    </div>
                                    <div class="col-2">
                                        <span class="pink-color">$1538.72</span>
                                    </div>
                                </div>
                            </p-panel>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
